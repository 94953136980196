import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';

import {
  getSingleInvention,
  isSingleInventionLoading,
  getSurveyId,
  getCurrentInventionId
} from 'store/singleInvention/singleInventionSelectors';
import {
  fetchSingleInvention,
  clearSingleInventionsData
} from 'actions/singleInventionActions';
import { downloadFile } from 'actions/attachmentsActions';
import { STATUS_PRE_ASSESSMENT } from 'constants/attachmentStatuses';
import { STATUS_DELETED } from 'constants/inventionStatuses';
import { INVENTIONS_PAGE } from 'constants/routingConstants';
import usePrevious from 'helpers/hooks/usePrevious';

import Spinner from 'Atoms/Spinner/Spinner';

import {
  Column,
  ContentWrapper,
  Header,
  TabsWrapper,
  CollapseButton
} from './styles';

import InventionPreviewActions from './InventionPreviewActions';
import InventionPreviewDetailsTabs from './InventionPreviewDetailsTabs';
import StatusTimeline from './StatusTimeline';
import InventionAssessmentsSection from './InventionAssessmentsSection';
import InventionPreviewHeader from './InventionPreviewHeader';
import InventionMetaData from 'Elements/InventionMetaData';
import InventionFormPreview from 'Elements/InventionFormPreview';

const SingleInventionPage = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [collapsed, setCollapsed] = useState(props.collapsed || false);

  const previousStatus = usePrevious(props.invention.status);

  useEffect(() => {
    const id = (props.match && props.match.params.id) || props.inventionId;

    props.fetchSingleInvention(id);
    return () => {
      props.clearSingleInventionsData();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      props.invention.status === STATUS_DELETED &&
      previousStatus !== props.invention.status
    ) {
      history.push(INVENTIONS_PAGE);
    }
    // eslint-disable-next-line
  }, [props.invention.status]);

  const filterProperAttachments = () =>
    props.invention &&
    props.invention.attachments &&
    props.invention.attachments.filter(
      el => el.data.attributes.status === STATUS_PRE_ASSESSMENT
    );

  const collapseRightCollumn = () => setCollapsed(!collapsed);

  return (
    <ContentWrapper className="container-md">
      {!props.assessmentPreview && (
        <Header>
          <StatusTimeline status={!props.isLoading && props.invention.status} />
        </Header>
      )}
      <div className="row" style={{ height: '95%' }}>
        <div
          className={collapsed ? 'col-12' : 'col-8'}
          style={{ ...(props.assessmentPreview && { padding: 0 }) }}
        >
          <CollapseButton
            removeRightValue={props.assessmentPreview}
            onClick={collapseRightCollumn}
          >
            <Tooltip
              content={collapsed ? t('showSection') : t('hideSection')}
              direction={collapsed ? 'left' : 'right'}
              useDefaultStyles
            >
              <FontAwesomeIcon icon={collapsed ? faArrowLeft : faArrowRight} />
            </Tooltip>
          </CollapseButton>
          <Column className={!props.assessmentPreview && 'mr-2'} grid>
            {props.isLoading ? (
              <Spinner center />
            ) : (
              <>
                {!props.assessmentPreview && (
                  <InventionPreviewHeader
                    reviews={
                      (!isEmpty(props.invention) &&
                        props.invention.draft &&
                        props.invention.draft.data.attributes.survey.data
                          .attributes.reviews) ||
                      {}
                    }
                    comments={props.invention.comments}
                    inventionId={props.invention.id}
                    inventionName={props.invention.name}
                    inventionStatus={props.invention.status}
                    serialNumber={props.invention.serial_number}
                  >
                    <InventionPreviewActions
                      currentInventionId={
                        (props.match && props.match.params.id) ||
                        props.inventionId
                      }
                      sectionsLength={
                        props.invention.draft
                          ? props.invention.draft.data.attributes.survey.data
                              .attributes.form.data.attributes.sections.length -
                            1
                          : 0
                      }
                      attachments={filterProperAttachments()}
                      coInventors={props.invention.co_inventors}
                      decisionMaker={props.invention.decision_maker}
                      inventor={props.invention.inventor}
                      proposedExperts={props.invention.proposed_experts}
                      reviewManager={props.invention.review_manager}
                      status={props.invention.status}
                    />
                  </InventionPreviewHeader>
                )}
                <InventionMetaData disableActions={props.assessmentPreview} />
                {!props.assessmentPreview && (
                  <InventionAssessmentsSection
                    currentInventionId={props.match.params.id}
                    decisionMaker={props.invention.decision_maker}
                    inventionStatus={props.invention.status}
                    reviewManager={props.invention.review_manager}
                  />
                )}
                <TabsWrapper>
                  {Object.keys(props.invention).length > 0 && (
                    <InventionFormPreview isInSinglePage />
                  )}
                </TabsWrapper>
              </>
            )}
          </Column>
        </div>
        {!collapsed && (
          <Column className="col-4" paddingTop>
            <InventionPreviewDetailsTabs
              currentInventionId={
                (props.match && props.match.params.id) || props.inventionId
              }
              attachments={props.invention.attachments}
              downloadFile={downloadFile}
              notes={props.invention.notes}
            />
          </Column>
        )}
      </div>
    </ContentWrapper>
  );
};

SingleInventionPage.propTypes = {
  assessmentPreview: PropTypes.bool,
  clearSingleInventionsData: PropTypes.func,
  collapsed: PropTypes.bool,
  currentInventionId: PropTypes.string,
  fetchSingleInvention: PropTypes.func,
  history: PropTypes.object,
  invention: PropTypes.object,
  inventionId: PropTypes.string,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  surveyId: PropTypes.string
};

const mapStateToProps = createPropsSelector({
  invention: getSingleInvention,
  isLoading: isSingleInventionLoading,
  surveyId: getSurveyId,
  currentInventionId: getCurrentInventionId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSingleInvention,
      downloadFile,
      clearSingleInventionsData
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleInventionPage);
