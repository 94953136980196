import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeInventionStatus } from 'actions/singleInventionActions';
import { getExperts } from 'store/singleInvention/singleInventionSelectors';

import { STATUS_COMPLETED } from 'constants/assessmentStatuses';
import { STATUS_PENDING_DECISION } from 'constants/inventionStatuses';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Textarea from 'Atoms/Textarea';

const MoveToDecisionModal = ({
  isOpen,
  onHide,
  changeInventionStatus,
  inventionId,
  ...props
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState();
  const [showOpenAssessmentWarning, setShowOpenAssessmentWarning] =
    useState(false);

  useEffect(() => {
    const hasOpenAssessment = props.experts.reduce(
      (acc, el) => (el.assessmentStatus < STATUS_COMPLETED ? acc || true : acc),
      false
    );

    hasOpenAssessment && setShowOpenAssessmentWarning(true);
    // eslint-disable-next-line
  }, []);

  const handleStatusChange = () => {
    const data = {
      data: {
        type: 'invention',
        attributes: {
          status: STATUS_PENDING_DECISION,
          comment
        }
      }
    };

    changeInventionStatus({ id: inventionId, data });
    onHide();
  };

  const renderContent = () => (
    <Textarea label={t('commentYourChange')} onChange={setComment} />
  );

  const renderWarning = () => <Fragment>{t('openAssessmentWarning')}</Fragment>;

  const renderFooter = () => (
    <Fragment>
      <Button value={t('close')} onClick={onHide} />
      <Button
        colorVariant="green"
        value={t('confirm')}
        onClick={handleStatusChange}
      />
    </Fragment>
  );

  const renderWarningFooter = () => (
    <Fragment>
      <Button colorVariant="red" value={t('no')} onClick={onHide} />
      <Button
        colorVariant="green"
        value={t('yes')}
        onClick={() => setShowOpenAssessmentWarning(false)}
      />
    </Fragment>
  );

  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      title={t('moveForDecision')}
      content={showOpenAssessmentWarning ? renderWarning() : renderContent()}
      footer={
        showOpenAssessmentWarning ? renderWarningFooter() : renderFooter()
      }
    />
  );
};

MoveToDecisionModal.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  changeInventionStatus: PropTypes.func,
  inventionId: PropTypes.string,
  experts: PropTypes.array
};

const mapStateToProps = state => ({
  experts: getExperts(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeInventionStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveToDecisionModal);
