import { createRoutine } from 'redux-saga-routines';

const fetchInventionAssessments = createRoutine(
  'inventionAssessments/FETCH_INVENTION_ASSESSMENTS'
);

const fetchAssessmentTypes = createRoutine(
  'inventionAssessments/FETCH_ASSESSMENT_TYPES'
);

const assignInventionExpert = createRoutine(
  'inventionAssessments/ASSIGN_EXPERT'
);

const fetchSingleAssessment = createRoutine(
  'inventionAssessments/FETCH_SINGLE_ASSESSMENT'
);

const answerAssessment = createRoutine(
  'inventionAssessments/ANSWER_ASSESSMENT'
);

const reviewAssessment = createRoutine(
  'inventionAssessments/REVIEW_ASSESSMENT'
);

const CLEAR_SINGLE_ASSESSMENT = 'inventionAssessments/CLEAR_SINGLE_ASSESSMENT';

const clearSingleAssessment = () => ({
  type: CLEAR_SINGLE_ASSESSMENT
});

const fetchAssessmentsCounter = createRoutine(
  'inventionAssessments/FETCH_ASSESSMENTS_COUNTER'
);

const fetchPreassessmentsCounter = createRoutine(
  'inventionAssessments/FETCH_PREASSESSMENTS_COUNTER'
);

const fetchUnassignedIdeasCounter = createRoutine(
  'inventionAssessments/FETCH_IDEAS_COUNTER'
);

export {
  fetchInventionAssessments,
  fetchAssessmentTypes,
  assignInventionExpert,
  fetchSingleAssessment,
  answerAssessment,
  reviewAssessment,
  CLEAR_SINGLE_ASSESSMENT,
  clearSingleAssessment,
  fetchAssessmentsCounter,
  fetchPreassessmentsCounter,
  fetchUnassignedIdeasCounter
};
