import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faLock
} from '@fortawesome/free-solid-svg-icons';

import {
  getInventionNotes,
  getAreNotesLoading
} from 'store/singleInvention/singleInventionSelectors';
import {
  getCurrentUserRoles,
  getCurrentUserFullName,
  getCurrentUserId
} from 'store/users/usersSelectors';

import {
  addNewNote,
  fetchInventionNotes
} from 'actions/singleInventionActions';

import Spinner from 'Atoms/Spinner';
import AddNewButton from 'Atoms/AddNewButton';
import Button from 'Atoms/Button';

import { DEFAULT_BLUE } from 'constants/colors';
import {
  REVIEW_MANAGER,
  DECISION_MAKER,
  SUPERVISOR
} from 'constants/userRoles';

import AddNewNoteModal from './AddNewNoteModal';
import {
  NotesWrapper,
  ButtonWrapper,
  SingleNote,
  Details,
  IconWrapper,
  NoteTitleWrapper
} from './styles';
import { isOnlySupervisor } from 'helpers/usersHelpers';

const Notes = ({ ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [noteId, setNoteId] = useState();
  const [authorId, setAuthorId] = useState();
  const [expandedNotes, setExpandedNotes] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchInventionNotes();
    // eslint-disable-next-line
  }, []);

  const handleModal = () => setShowModal(!showModal);

  const shouldDisplayNote = note => {
    if (note.recipientId) {
      return (
        note.recipientId === props.currentUserId ||
        note.authorId === props.currentUserId
      );
    }
    if (note.isConfidential) {
      return (
        props.currentUserRoles.some(role =>
          [SUPERVISOR, REVIEW_MANAGER, DECISION_MAKER].includes(role)
        ) || note.author === props.currentUserFullName
      );
    }
    return true;
  };

  const handleAddNewNote = () => {
    setNoteId(null);
    handleModal();
  };

  const handleReply = (noteId, authorId) => {
    setNoteId(noteId);
    setAuthorId(authorId);
    handleModal();
  };

  const handleExpandedNotes = noteId => {
    if (expandedNotes.includes(noteId)) {
      setExpandedNotes(expandedNotes.filter(e => e !== noteId));
    } else {
      setExpandedNotes([...expandedNotes, noteId]);
    }
  };

  const hasOnlySupervisorRole = isOnlySupervisor(props.currentUserRoles);

  const renderNote = el => (
    <SingleNote key={el.id}>
      <NoteTitleWrapper>
        <div dangerouslySetInnerHTML={{ __html: el.note }} />
        <>
          {el.isConfidential && (
            <IconWrapper>
              <FontAwesomeIcon icon={faLock} />
            </IconWrapper>
          )}
        </>
      </NoteTitleWrapper>
      <Details>
        <div>{el.createdAt}</div>
        <div>{el.author}</div>
      </Details>
      {!hasOnlySupervisorRole && el.recipientId && !el.replied && (
        <Button
          colorVariant="blue"
          value={t('reply')}
          onClick={() => handleReply(el.id, el.authorId)}
        />
      )}
      {!isEmpty(el.replies) && (
        <IconWrapper onClick={() => handleExpandedNotes(el.id)}>
          <FontAwesomeIcon
            color={DEFAULT_BLUE}
            icon={expandedNotes.includes(el.id) ? faArrowUp : faArrowDown}
          />
        </IconWrapper>
      )}
      {expandedNotes.includes(el.id) &&
        el.replies.map(reply => renderNote(reply))}
    </SingleNote>
  );

  return (
    <Fragment>
      {showModal && (
        <AddNewNoteModal
          addNewNote={props.addNewNote}
          authorId={authorId}
          isOpen={showModal}
          noteId={noteId}
          onCancel={handleModal}
        />
      )}
      {props.isLoading ? (
        <Spinner center />
      ) : (
        <NotesWrapper>
          {!hasOnlySupervisorRole && (
            <ButtonWrapper>
              <AddNewButton
                description={t('addNewNote')}
                onClick={handleAddNewNote}
              />
            </ButtonWrapper>
          )}
          {props.data.map(el => shouldDisplayNote(el) && renderNote(el))}
        </NotesWrapper>
      )}
    </Fragment>
  );
};

const mapStateToProps = createPropsSelector({
  data: getInventionNotes,
  isLoading: getAreNotesLoading,
  currentUserRoles: getCurrentUserRoles,
  currentUserFullName: getCurrentUserFullName,
  currentUserId: getCurrentUserId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventionNotes,
      addNewNote
    },
    dispatch
  );

Notes.propTypes = {
  addNewNote: PropTypes.func,
  currentUserFullName: PropTypes.string,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  fetchInventionNotes: PropTypes.func,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
