import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsInventionBeingCreated } from 'store/singleInvention/singleInventionSelectors';
import Button from 'Atoms/Button';
import TabsButtons from 'Elements/TabsButtons';
import { ButtonsWrapper } from 'Pages/CreateInventionPage/Elements/Form/styles';

const FormButtonsSection = ({
  currentSectionIndex,
  inventionId,
  isDraftBeingSaved,
  isInventionBeingCreated,
  onSave,
  onSaveDraft,
  sectionsLength,
  setCurrentSectionIndex
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ButtonsWrapper>
      <Button
        value={t('goBack')}
        margin="20px 5px"
        onClick={() => history.goBack()}
      />
      {sectionsLength > 0 && (
        <TabsButtons
          sectionsLength={sectionsLength}
          onTabChange={setCurrentSectionIndex}
          currentIndex={currentSectionIndex}
        />
      )}
      <>
        <Button
          colorVariant="darkBlue"
          isFetching={isDraftBeingSaved && isInventionBeingCreated}
          margin="20px 5px"
          value={isNaN(inventionId) ? t('saveDraft') : t('updateDraft')}
          onClick={onSaveDraft}
        />
        {currentSectionIndex === sectionsLength && (
          <Button
            colorVariant="green"
            isFetching={isInventionBeingCreated && !isDraftBeingSaved}
            margin="20px 5px"
            value={t('save')}
            onClick={onSave}
          />
        )}
      </>
    </ButtonsWrapper>
  );
};

FormButtonsSection.propTypes = {
  currentSectionIndex: PropTypes.number,
  inventionId: PropTypes.number,
  isDraftBeingSaved: PropTypes.bool,
  isInventionBeingCreated: PropTypes.bool,
  onSave: PropTypes.func,
  onSaveDraft: PropTypes.func,
  sectionsLength: PropTypes.number,
  setCurrentSectionIndex: PropTypes.func
};

const mapStateToProps = state => ({
  isInventionBeingCreated: getIsInventionBeingCreated(state)
});

export default connect(mapStateToProps)(FormButtonsSection);
