/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import FormScrollableSection from './FormScrollableSection';
import FormButtonsSection from './FormButtonsSection';
import { FormWrapper } from 'Pages/CreateInventionPage/Elements/Form/styles';
import { getFields } from 'store/newInventionForm/newInventionFormSelectors';
import { getUsersForOptions } from 'store/users/usersSelectors';

import {
  createFullInventionAnswer,
  createInvention
} from 'actions/inventionsActions';

import {
  STATUS_ASSIGNMENT,
  STATUS_DRAFT,
  STATUS_PRE_ASSESSMENT
} from 'constants/inventionStatuses';

import {
  getChooseInventionTypeId,
  getChooseInventionTypeObject
} from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';

import { fetchOrganisationUsers } from 'actions/organisationActions';
import { createAnswerRoutine } from 'actions/answersActions';
import { validateForms } from 'helpers/validationHelper';
import usePrevious from 'helpers/hooks/usePrevious';

const FormContainer = ({
  allowCoinventors,
  chooseInventionTypeObject,
  createFullInventionAnswer,
  experts,
  fetchOrganisationUsers,
  fields,
  formModel,
  inventors,
  inventionTypeId,
  match,
  reviews,
  reviewManagers
}) => {
  const [name, setName] = useState('');
  const [co_inventors, setCoInventors] = useState([]);
  const [proposed_experts, setProposedExperts] = useState([]);
  const [proposed_review_managers, setProposedReviewManagers] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [unfilledFields, setUnfilledFields] = useState([]);
  const [isDraftBeingSaved, setIsDraftBeingSaved] = useState(false);

  const previousFields = usePrevious(fields);
  const previousInventionTypeId = usePrevious(inventionTypeId);
  const previousMatch = usePrevious(match);
  const previousChooseInventionTypeObject = usePrevious(
    chooseInventionTypeObject
  );

  const { t } = useTranslation();

  useEffect(() => {
    fetchOrganisationUsers();
    return () => {
      if (localStorage.getItem('figureImage')) {
        localStorage.removeItem('figureImage');
      }
    };
    // eslint-disable-next-line
  }, []);

  const prepareInitialNameState = chooseInventionTypeObject =>
    chooseInventionTypeObject && chooseInventionTypeObject.attributes.draft
      ? chooseInventionTypeObject.attributes.name
      : '';

  const prepareUsersDataField = type =>
    chooseInventionTypeObject && chooseInventionTypeObject.attributes[type]
      ? chooseInventionTypeObject.attributes[type].map(el => ({
          label: `${el.data.attributes.first_name} ${el.data.attributes.last_name}`,
          value: el.data.id
        }))
      : [];

  useEffect(() => {
    if (
      fields.length < previousFields ||
      inventionTypeId !== previousInventionTypeId ||
      (match.params.id && match.params.id !== previousMatch.params.id)
    ) {
      setName('');
      if (localStorage.getItem('figureImage')) {
        localStorage.removeItem('figureImage');
      }
    }

    if (
      chooseInventionTypeObject.type !== previousChooseInventionTypeObject?.type
    ) {
      setName(prepareInitialNameState(chooseInventionTypeObject));
      setCoInventors(prepareUsersDataField('co_inventors'));
      setProposedExperts(prepareUsersDataField('proposed_experts'));
      setProposedReviewManagers(
        prepareUsersDataField('proposed_review_managers')
      );
    }
    // eslint-disable-next-line
  }, [fields, inventionTypeId, match, chooseInventionTypeObject]);

  // eslint-disable-next-line consistent-return
  const setStateField = (name, value) => {
    switch (name) {
      case 'name':
        return setName(value);
      case 'co_inventors':
        return setCoInventors(value);
      case 'proposed_experts':
        return setProposedExperts(value);
      case 'proposed_review_managers':
        return setProposedReviewManagers(value);
      case 'currentSectionIndex':
        return setCurrentSectionIndex(value);
      case 'unfilledFields':
        return setUnfilledFields(value);
      case 'isDraftBeingSaved':
        return setIsDraftBeingSaved(value);
      default:
        break;
    }
  };

  const getSections = chooseInventionTypeObject =>
    chooseInventionTypeObject.attributes.form
      ? chooseInventionTypeObject.attributes.form.data.attributes.sections
      : chooseInventionTypeObject.attributes.draft.data.attributes.survey.data
          .attributes.form.data.attributes.sections;

  const sections = getSections(chooseInventionTypeObject);

  const setCurrentSectionIndexState = index => {
    setCurrentSectionIndex(index);
  };

  const validateContent = () => {
    const sectionsWithErrors = [];
    let fieldsArray = [];
    const sections = getSections(chooseInventionTypeObject);

    sections.map(el => {
      fieldsArray = fieldsArray
        .concat(el.data.attributes.fields)
        .filter(el => el.data.attributes.required === true);
    });

    const emptyFields = validateForms(fieldsArray, fields);

    if (isEmpty(name)) {
      emptyFields.push('name');
      sectionsWithErrors.push('name');
    }

    emptyFields.map(el => {
      sections.map(section => {
        if (
          section.data.attributes.fields.find(field => field.data.id === el)
        ) {
          sectionsWithErrors.push(section.data.attributes.name);
        }
      });
    });

    if (sectionsWithErrors.length) {
      const uniqueSections = [...new Set(sectionsWithErrors)];

      if (uniqueSections.length === 1 && uniqueSections[0] === 'name') {
        toast.error(t('fillInName'));
      } else {
        toast.error(
          t('fillSectionsWithErrors', {
            sectionsNames: uniqueSections.join(', ')
          })
        );
      }
    }

    setUnfilledFields(emptyFields);

    return Boolean(emptyFields.length);
  };

  const prepareRequest = type => {
    setIsDraftBeingSaved(Boolean(type));

    createFullInventionAnswer({
      fields,
      inventionTypeId,
      name,
      status: type
        ? STATUS_DRAFT
        : reviews?.data.attributes.comment
        ? STATUS_PRE_ASSESSMENT
        : STATUS_ASSIGNMENT,

      coInventorsObjectsArray: co_inventors,
      proposed_review_managers,
      proposed_experts,
      surveyId: chooseInventionTypeObject.attributes.draft
        ? chooseInventionTypeObject.attributes.draft.data.attributes.survey.data
            .id
        : null,
      inventionId: match.params.id
    });
  };

  const onSaveDraft = () => {
    if (isEmpty(name)) {
      setUnfilledFields(['name']);
      toast.error(t('fillInName'));
      return;
    }

    prepareRequest('draft');
  };

  const onSave = () => {
    setUnfilledFields([]);

    const doesFormContainErrors = validateContent();

    if (doesFormContainErrors) {
      return;
    }

    prepareRequest();
  };

  return (
    <FormWrapper>
      <FormScrollableSection
        action={setStateField}
        allowCoinventors={allowCoinventors}
        answers={
          chooseInventionTypeObject?.attributes.draft
            ? chooseInventionTypeObject.attributes.draft.data.attributes.survey
                .data.attributes.answers
            : ''
        }
        attachments={
          chooseInventionTypeObject
            ? chooseInventionTypeObject.attributes.attachments
            : []
        }
        coInventors={co_inventors}
        currentSectionIndex={currentSectionIndex}
        experts={experts}
        figure={
          chooseInventionTypeObject?.attributes
            ? chooseInventionTypeObject.attributes.figure_url
            : null
        }
        formModel={formModel}
        inventors={inventors}
        inventionId={parseInt(match.params.id)}
        name={name}
        proposedExperts={proposed_experts}
        proposedReviewManager={proposed_review_managers}
        reviews={reviews}
        reviewManagers={reviewManagers}
        sectionsLength={sections?.length - 1 || 0}
        setCurrentSectionIndex={setCurrentSectionIndexState}
        setStateField={setStateField}
        unfilledFields={unfilledFields}
      />
      <FormButtonsSection
        currentSectionIndex={currentSectionIndex}
        inventionId={parseInt(match.params.id)}
        isDraftBeingSaved={isDraftBeingSaved}
        onSave={onSave}
        onSaveDraft={onSaveDraft}
        sectionsLength={sections?.length - 1 || 0}
        setCurrentSectionIndex={setCurrentSectionIndexState}
      />
    </FormWrapper>
  );
};

FormContainer.propTypes = {
  allowCoinventors: PropTypes.bool,
  chooseInventionTypeObject: PropTypes.object,
  createFullInventionAnswer: PropTypes.func,
  experts: PropTypes.array,
  fetchOrganisationUsers: PropTypes.func,
  fields: PropTypes.array,
  formModel: PropTypes.object,
  inventors: PropTypes.array,
  inventionTypeId: PropTypes.number,
  match: PropTypes.object,
  reviews: PropTypes.object,
  reviewManagers: PropTypes.array
};

const mapStateToProps = state => ({
  fields: getFields(state),
  inventionTypeId: getChooseInventionTypeId(state),
  chooseInventionTypeObject: getChooseInventionTypeObject(state),
  inventors: getUsersForOptions(state, 'inventors'),
  experts: getUsersForOptions(state, 'experts'),
  reviewManagers: getUsersForOptions(state, 'reviewManagers')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createInvention: createInvention,
      createFullInventionAnswer: createFullInventionAnswer,
      fetchOrganisationUsers,
      saveAnwersDraft: createAnswerRoutine.trigger
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormContainer));
