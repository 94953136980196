import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchOrganisationUsers } from 'actions/organisationActions';
import {
  assignReviewManager,
  assignDecisionMaker
} from 'actions/singleInventionActions';
import {
  getAreUsersLoading,
  getUsersForOptions,
  getAreUsersFetched
} from 'store/users/usersSelectors';
import {
  getReviewManager,
  getReviewManagerFullName,
  getDecisionMaker,
  getDecisionMakerFullName
} from 'store/singleInvention/singleInventionSelectors';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';

import { PersonWrapper, IconWrapper } from './styles';

const AssignReviewManagerOrDecisionMakerModal = ({
  reviewManagerModal,
  decisionMakerModal,
  isOpen,
  closeModal,
  fetchOrganisationUsers,
  users,
  reviewManager,
  decisionMaker,
  assign,
  areUsersFetched,
  proposedUsers
}) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    fetchOrganisationUsers();
    // eslint-disable-next-line
  }, []);

  const hasAssignedReviewManager = reviewManagerModal && reviewManager;
  const hasAssignedDecisionMaker = decisionMakerModal && decisionMaker;

  const rednerTitle = () =>
    hasAssignedReviewManager
      ? t('changeReviewManager')
      : hasAssignedDecisionMaker
      ? t('changeDecisionMaker')
      : reviewManagerModal
      ? t('assignReviewManager')
      : t('assignDecisionMaker');

  const renderAssignedPerson = person => (
    <Fragment>
      <PersonWrapper isAssignedPerson>
        <IconWrapper>
          <FontAwesomeIcon icon={faUserCircle} />
        </IconWrapper>
        <span>
          {person.fullName
            ? person.fullName
            : `${person.first_name} ${person.last_name}`}
        </span>
      </PersonWrapper>
    </Fragment>
  );

  const handleUser = option => {
    setSelectedUser(option.value);
  };

  const handleAssign = () => {
    const data = {
      data: {
        type: 'invention',
        attributes: {
          [reviewManagerModal ? 'review_manager' : 'decision_maker']: {
            id: selectedUser,
            type: 'user'
          }
        }
      }
    };
    assign(data);
    closeModal();
  };

  const content = () => (
    <Fragment>
      {proposedUsers && proposedUsers.length > 0 && (
        <>
          {t('inventorProposition')}:
          {proposedUsers.map(el => renderAssignedPerson(el.data.attributes))}
        </>
      )}
      {(hasAssignedReviewManager || hasAssignedDecisionMaker) && (
        <div>{t('currentlyAssigned')}:</div>
      )}
      {hasAssignedReviewManager
        ? renderAssignedPerson(reviewManager)
        : hasAssignedDecisionMaker
        ? renderAssignedPerson(decisionMaker)
        : null}
      {hasAssignedReviewManager && (
        <label className="form-check-label">
          {t('chooseNewReviewManager')}:
        </label>
      )}
      {hasAssignedDecisionMaker && (
        <label className="form-check-label">
          {t('chooseNewDecisionMaker')}:
        </label>
      )}
      <Select
        options={users}
        isLoading={!areUsersFetched}
        onChange={handleUser}
      />
    </Fragment>
  );

  const footer = () => (
    <Fragment>
      <Button value={t('close')} onClick={closeModal} />
      <Button
        value={
          hasAssignedDecisionMaker || hasAssignedReviewManager
            ? t('change')
            : t('assign')
        }
        colorVariant="green"
        disabled={!selectedUser}
        onClick={handleAssign}
      />
    </Fragment>
  );

  return (
    <Modal
      isOpen={isOpen}
      onHide={closeModal}
      title={rednerTitle()}
      content={content()}
      footer={footer()}
      size="sm"
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  users: getUsersForOptions(
    state,
    ownProps.reviewManagerModal ? 'reviewManagers' : 'decisionMakers'
  ),
  areUsersLoading: getAreUsersLoading(state),
  reviewManager: getReviewManager(state),
  reviewManagerFullName: getReviewManagerFullName(state),
  decisionMaker: getDecisionMaker(state),
  decisionMakerFullName: getDecisionMakerFullName(state),
  areUsersFetched: getAreUsersFetched(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrganisationUsers,
      assignReviewManager,
      assignDecisionMaker
    },
    dispatch
  );

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...ownProps,
  users: propsFromState.users,
  areUsersFetched: propsFromState.areUsersFetched,
  areUsersLoading: propsFromState.areUsersLoading,
  fetchOrganisationUsers: propsFromDispatch.fetchOrganisationUsers,
  assign: ownProps.reviewManagerModal
    ? propsFromDispatch.assignReviewManager
    : propsFromDispatch.assignDecisionMaker,
  ...(ownProps.reviewManagerModal &&
    propsFromState.reviewManagerFullName && {
      reviewManager: {
        ...propsFromState.reviewManager,
        fullName: propsFromState.reviewManagerFullName
      }
    }),
  ...(ownProps.decisionMakerModal &&
    propsFromState.decisionMakerFullName && {
      decisionMaker: {
        ...propsFromState.decisionMaker,
        fullName: propsFromState.decisionMakerFullName
      }
    })
});

AssignReviewManagerOrDecisionMakerModal.propTypes = {
  reviewManagerModal: PropTypes.bool,
  decisionMakerModal: PropTypes.bool,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  fetchOrganisationUsers: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  reviewManager: PropTypes.object,
  decisionMaker: PropTypes.object,
  assign: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AssignReviewManagerOrDecisionMakerModal);
