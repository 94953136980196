import React, { useEffect } from 'react';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { getField } from 'store/newInventionForm/newInventionFormSelectors';
import { bindActionCreators } from 'redux';
import { setMultivalueField } from 'actions/newInventionFormActions';
import { connect } from 'react-redux';
import Checkbox from 'Atoms/Checkbox/Checkbox';

import { OptionsWrapper } from './styles';

const CheckboxType = ({ field, fieldState, setMultivalueField, answers }) => {
  useEffect(() => {
    if (fieldState && fieldState.value.length) {
      return;
    }

    answers &&
      answers.data.attributes.value.map(el =>
        setMultivalueField(field.attributes.id, el)
      );
    // eslint-disable-next-line
  }, []);
  return (
    <OptionsWrapper>
      {field.attributes.settings.options.map((option, i) => {
        if (option.default && !fieldState) {
          setMultivalueField(field.attributes.id, option.value);
        }

        return (
          <Checkbox
            key={i}
            name={field.attributes.name}
            value={option.value}
            checked={fieldState && fieldState.value.includes(option.value)}
            onCheck={() =>
              setMultivalueField(field.attributes.id, option.value)
            }
            label={option.label}
          />
        );
      })}
    </OptionsWrapper>
  );
};

const mapStateToProps = createPropsSelector({
  fieldState: getField
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMultivalueField
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxType);
