import styled from 'styled-components';
import { BLACK, BREADCRUMBS_BLUE } from 'constants/colors';

const prepareBreadcrumbsWidth = (isTabletSize, collapsed) => {
  if (isTabletSize && collapsed) return '100%';
  if (collapsed) return 'calc(100% - 100px)';
  return 'calc(100% - 240px)';
};

export const breadcrumbsStyles = (collapsed, isTabletSize, top) => ({
  background: BREADCRUMBS_BLUE,
  padding: '10px 5px 5px 5px',
  marginBottom: '60px',
  zIndex: 1,
  position: 'fixed',
  ...(top && { top: `${top + 50}px` }),
  width: prepareBreadcrumbsWidth(isTabletSize, collapsed),
  color: BLACK
});

export const Title = styled.h3`
  line-height: 28px;
`;
