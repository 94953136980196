import {
  faAlignLeft,
  faCheckCircle,
  faCheckSquare,
  faCaretSquareDown,
  faPollH,
  faPenAlt
} from '@fortawesome/free-solid-svg-icons';

export const TEXT_FIELD_ID = 1;
export const RADIO_FIELD_ID = 2;
export const EXTENDED_FIELD_ID = 3;
export const CHECKBOX_FIELD_ID = 4;
export const SELECT_FIELD_ID = 5;
export const INFORMATION_FIELD_ID = 6;

const convertIdToString = id => id.toString();

export const formTypeFields = t => [
  {
    type: 'text',
    label: t('textarea'),
    icon: faAlignLeft,
    id: convertIdToString(TEXT_FIELD_ID)
  },
  {
    type: 'radio',
    label: t('radioButton'),
    icon: faCheckCircle,
    id: convertIdToString(RADIO_FIELD_ID)
  },
  {
    type: 'extendedRadio',
    label: t('extendedRadioButton'),
    icon: faCaretSquareDown,
    id: convertIdToString(EXTENDED_FIELD_ID)
  },
  {
    type: 'checkbox',
    label: t('checkbox'),
    icon: faCheckSquare,
    id: convertIdToString(CHECKBOX_FIELD_ID)
  },
  {
    type: 'select',
    label: t('selectField'),
    icon: faPollH,
    id: convertIdToString(SELECT_FIELD_ID)
  },
  {
    type: 'text',
    label: t('textField'),
    icon: faPenAlt,
    id: convertIdToString(INFORMATION_FIELD_ID)
  }
];

export const inventionTypeIdCreator = t => [
  { type: 'text', label: t('serialNumber'), id: '1' }
];
