import Section from 'Models/Section';

export default class FormAttributes {
  constructor(rawJSONObject) {
    this._id = rawJSONObject.id;
    this._name = rawJSONObject.name;
    this._sections = rawJSONObject.sections.map(
      rawJSONSectionObject => new Section(rawJSONSectionObject.data)
    );
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get sections() {
    return this._sections;
  }

  set sections(value) {
    this._sections = value;
  }
}
