import styled from 'styled-components';

export const RequiredLabel = styled.span`
  font-size: 12px;
`;

export const MaxLengthBox = styled.div`
  font-size: 12px;
  float: right;
  margin-top: 5px;
`;

export const TextareaLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;
