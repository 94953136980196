import { createRoutine } from 'redux-saga-routines';

export const DOWNLOAD_FILE = 'singleInvention/DOWNLOAD_FILE';
export const CREATE_PDF_FILE = 'singleInvention/createPDFFile';
export const UPDATE_CURRENT_INVENTION_STATUS =
  'singleInvention/UPDATE_CURRENT_INVENTION_STATUS';
export const UPDATE_NOTE = 'singleInvention/UPDATE_NOTE';

export const CLEAR_SINGLE_INVENTIONS_DATA =
  'singleInvention/CLEAR_CURRENT_INVENTION_DATA';

export const SET_ATTACHMENT_FORM_VALUE = 'SET_ATTACHMENT_FORM_VALUE';

export const fetchSingleInvention = createRoutine(
  'singleInvention/FETCH_SINGLE_INVENTION'
);

export const downloadPDFFile = createRoutine(
  'singleInvention/DOWNLOAD_PDF_FILE'
);

export const fetchInventionTimeline = createRoutine(
  'singleInvention/FETCH_INVENTION_TIMELINE'
);

export const fetchInventionNotes = createRoutine(
  'singleInvention/FETCH_INVENTION_NOTES'
);

export const fetchInventionAttachments = createRoutine(
  'singleInvention/FETCH_INVENTION_ATTACHMENTS'
);

export const addNewNote = createRoutine('singleInvention/ADD_NEW_NOTE');

export const assignReviewManager = createRoutine(
  'singleInvention/ASSIGN_REVIEW_MANAGER'
);

export const assignDecisionMaker = createRoutine(
  'singleInvention/ASSIGN_DECISION_MAKER'
);

export const createSurveyReview = createRoutine(
  'singleInvention/CREATE_SURVEY_REVIEW'
);

export const createAnswerReview = createRoutine(
  'singleInvention/CREATE_ANSWER_REVIEW'
);

export const updateReview = createRoutine('singleInvention/UPDATE_REVIEW');

export const addSingleInventionAttachment = createRoutine(
  'singleInvention/ADD_ATTACHMENT'
);

export const removeSingleInventionAttachments = createRoutine(
  'singleInvention/REMOVE_ATTACHMENT'
);
export const updateSingleInventionAttachment = createRoutine(
  'singleInvention/UPDATE_ATTACHMENT'
);
export const changeInventionStatus = createRoutine(
  'singleInvention/CHANGE_INVENTION_STATUS'
);

export const downloadFile = (url, filename) => ({
  type: DOWNLOAD_FILE,
  url,
  filename
});

export const updateCurrentInventionStatus = status => ({
  type: UPDATE_CURRENT_INVENTION_STATUS,
  status
});

export const updateNote = noteId => ({
  type: UPDATE_NOTE,
  noteId
});

export const createPDFFile = inventionId => ({
  type: CREATE_PDF_FILE,
  inventionId
});

export const clearSingleInventionsData = () => ({
  type: CLEAR_SINGLE_INVENTIONS_DATA
});

export const setAttachmentFormValue = data => ({
  type: SET_ATTACHMENT_FORM_VALUE,
  data
});
