import styled from 'styled-components';
import { INPUT_BORDER_GRAY, LIGHT_GRAY } from 'constants/colors';

export const NotesWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
`;

export const SingleNote = styled.div`
  border: 1px solid ${INPUT_BORDER_GRAY};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const NoteTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  word-break: break-all;

  div {
    margin-bottom: 0;
    padding: 0 5px;
    word-break: break-all;
  }
`;

export const Details = styled.div`
  text-align: left;
  font-size: 14px;
  color: ${LIGHT_GRAY};

  & > div {
    display: inline-block;
    width: 50%;

    &:last-child {
      text-align: right;
    }
  }
`;

export const ConfidentialityWrapper = styled.div`
  margin: 10px 0;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  margin: 10px 0;
`;
