import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { getCurrentUserRoles } from 'store/users/usersSelectors';

import Button from 'Atoms/Button';
import TablePageLayout from 'Elements/TablePageLayout';

import { formatDate } from 'helpers/dateHelpers';

import {
  DECISION_MAKER,
  INVENTOR,
  REVIEW_COORDINATOR,
  REVIEW_MANAGER
} from 'constants/userRoles';

import {
  INVENTIONS_PAGE,
  KNOWLEDGE_DATABASE,
  MY_PRE_ASSESSMENTS,
  NOT_ASSIGNED
} from 'constants/routingConstants';

import { statuses, STATUS_DRAFT } from 'constants/inventionStatuses';

const InventionsList = ({
  data,
  displayButtons,
  handleChangePage,
  isLoading,
  limit,
  listType,
  pagination,
  showNewInventionTypeChooseModal,
  userRoles,
  onLimitSelect,
  onSortClick
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isKnowledgeDatabase = history.location.pathname === KNOWLEDGE_DATABASE;

  const exportLocations = [
    INVENTIONS_PAGE,
    KNOWLEDGE_DATABASE,
    MY_PRE_ASSESSMENTS,
    NOT_ASSIGNED
  ];

  const exportRoles = [DECISION_MAKER, REVIEW_COORDINATOR, REVIEW_MANAGER];

  const canExportToExcel =
    exportLocations.some(location => location === listType) &&
    userRoles.some(role => exportRoles.includes(role));

  const buttons = [
    {
      label: t('addNewInvention'),
      action: showNewInventionTypeChooseModal
    }
  ];

  const columnNames = {
    id: t('idNumber'),
    title: t('title'),
    figure: t('representativeFigure'),
    inventors: t('inventors'),
    submissionDate: t('submissionDate'),
    status: t('status'),
    ...(isKnowledgeDatabase && { decisionDate: t('decisionDate') }),
    reviewManager: t('reviewManager'),
    decisionMaker: t('decisionMaker'),
    actions: t('actions')
  };

  const rows =
    data &&
    data.map(el => {
      const status = statuses(t).find(({ id }) => id === el.status);

      return {
        id: el.serial_number || el.id,
        title: el.name,
        figure: () => (
          <>
            {el.figure_url ? (
              <img alt="" src={`${el.figure_url}`} />
            ) : (
              '-'
            )}
          </>
        ),
        inventors:
          !isEmpty(el.inventor) &&
          `${el.inventor.data.attributes.first_name} ${el.inventor.data.attributes.last_name}`,
        submissionDate:
          el.status !== STATUS_DRAFT ||
            (el.status === STATUS_DRAFT &&
              !isEmpty(el.draft) &&
              !isEmpty(el.draft.data.attributes.survey) &&
              el.draft.data.attributes.survey.data.attributes.reviews.length > 0)
            ? el.submitted_at
              ? formatDate(el.submitted_at)
              : '-'
            : '-',
        status: (status && status.name) || t('undefined'),
        ...(isKnowledgeDatabase && {
          decisionDate: el.decision_date ? formatDate(el.decision_date) : '-'
        }),
        reviewManager: el.review_manager
          ? `${el.review_manager.data.attributes.first_name} ${el.review_manager.data.attributes.last_name}`
          : '-',
        decisionMaker: el.decision_maker
          ? `${el.decision_maker.data.attributes.first_name} ${el.decision_maker.data.attributes.last_name}`
          : '-',
        actions: () => (
          <Button
            colorVariant="blue"
            value={t('preview')}
            small
            onClick={() => history.push(`${INVENTIONS_PAGE}/${el.id}`)}
          />
        )
      };
    });

  return (
    <TablePageLayout
      buttons={displayButtons && userRoles.includes(INVENTOR) ? buttons : []}
      canExportToExcel={canExportToExcel}
      columnNames={columnNames}
      data={rows}
      formTypeListMeta={pagination}
      handleChangePage={handleChangePage}
      isFetching={isLoading}
      limit={limit}
      onLimitSelect={onLimitSelect}
      onSortClick={onSortClick}
    />
  );
};

InventionsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  displayButtons: PropTypes.bool,
  handleChangePage: PropTypes.func,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  listType: PropTypes.string,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    totalItems: PropTypes.number
  }),
  showNewInventionTypeChooseModal: PropTypes.func,
  userRoles: PropTypes.arrayOf(PropTypes.string),
  onLimitSelect: PropTypes.func,
  onSortClick: PropTypes.func
};

const mapStateToProps = state => ({
  userRoles: getCurrentUserRoles(state)
});

export default connect(mapStateToProps)(memo(InventionsList));
