import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip-lite';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { Title, TitleWrapper, IconWrapper } from './styles';

const TitleContent = ({ inModal }) => {
  const { t } = useTranslation();
  return (
    <TitleWrapper inModal={inModal}>
      <Title inModal={inModal}>
        {t('chooseField')}
        <Tooltip
          content={t('howItWorks')}
          direction="up"
          useDefaultStyles
          zIndex={1111}
        >
          <IconWrapper>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </IconWrapper>
        </Tooltip>
      </Title>
    </TitleWrapper>
  );
};

export default TitleContent;
