import { all, put, takeEvery, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { t } from 'helpers/i18n';

import {
  getToken,
  getUser,
  authLogout as authLogoutRequest
} from 'helpers/axios/axiosRequests';
import { setTokens, setUserId } from 'store/login/loginReducer';

import { getLoginDetails, authLogout } from 'store/login/routines';

function* getLoginDetailsSaga({ payload }) {
  try {
    const { data } = yield call(getToken, payload);

    if (data) {
      localStorage.removeItem('isLoggedOut');
      yield put(
        setTokens({ token: data.token, refreshToken: data.refresh_token })
      );
      yield put(setUserId(data.user));

      const userData = yield call(getUser, data.user);

      yield put(getLoginDetails.success(userData.data.data));
    }
  } catch (error) {
    yield put(getLoginDetails.failure());
    yield toast.error(t('somethingWentWrong'));
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('isLoggedOut');
  }
}

function* authLogoutSaga({ token }) {
  try {
    yield call(authLogoutRequest);
    yield put(authLogout.success());
  } catch (e) {
    yield toast.error(t('somethingWentWrong'));
    console.error(e);
  }
}

export default function* loginSaga() {
  yield all([
    takeEvery(getLoginDetails.TRIGGER, getLoginDetailsSaga),
    takeEvery(authLogout.TRIGGER, authLogoutSaga)
  ]);
}
