import React from 'react';
import Select from 'react-select';
import Spinner from 'Atoms/Spinner/Spinner';

import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { getInventionTypesData } from 'store/inventionTypes/inventionTypesSelectors';

const Content = ({
  areInventionTypesLoading,
  rawInventionTypes,
  inventionTypes,
  selectedInventionType,
  selectInventionType
}) => (
  <div>
    {areInventionTypesLoading ? (
      <Spinner center />
    ) : (
      <Select
        options={inventionTypes}
        rawInventionTypes={rawInventionTypes}
        defaultValue={1}
        onChange={option => {
          selectInventionType(option);
        }}
        value={selectedInventionType || null}
      />
    )}
  </div>
);

const mapStateToProps = createPropsSelector({
  rawInventionTypes: getInventionTypesData
});

export default connect(mapStateToProps)(Content);
