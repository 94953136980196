import Form from 'Models/Form';

export default class InventionTypeAttributes {
  constructor(rawJSONObject) {
    this._id = rawJSONObject.id;
    this._is_locked = rawJSONObject.is_locked;
    this._allow_coinventors = rawJSONObject.allow_coinventors;
    this._attachments = rawJSONObject.attachments || [];
    this._description = rawJSONObject.description;
    this._name = rawJSONObject.name;
    this._form = new Form(
      rawJSONObject.form
        ? rawJSONObject.form.data
        : rawJSONObject.draft.data.attributes.survey.data.attributes.form.data
    );
    this._answers = rawJSONObject.draft
      ? rawJSONObject.draft.data.attributes.survey.data.attributes.form.answers
      : '';
    this.reviews = rawJSONObject.draft
      ? rawJSONObject.draft.data.attributes.survey.data.attributes.reviews
      : null;
  }

  get form() {
    return this._form;
  }

  set form(value) {
    this._form = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get isLocked() {
    return this._is_locked;
  }

  set isLocked(value) {
    this._is_locked = value;
  }

  get allowCoInventors() {
    return this._allow_coinventors;
  }

  set allowCoInventors(value) {
    this._allow_coinventors = value;
  }

  get attachments() {
    return this._attachments || [];
  }

  set attachments(value) {
    this._attachments = value;
  }

  get description() {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }
}
