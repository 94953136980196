import React, { useEffect } from 'react';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { getField } from 'store/newInventionForm/newInventionFormSelectors';
import { bindActionCreators } from 'redux';
import { setField } from 'actions/newInventionFormActions';
import { connect } from 'react-redux';
import Radio from 'Atoms/Radio';

import { OptionsWrapper } from './styles';

const RadioType = ({ field, fieldState, setField, answers }) => {
  useEffect(() => {
    if (answers) {
      setField(field.attributes.id, answers.data.attributes.value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <OptionsWrapper>
      {field.attributes.settings.options.map((option, i) => {
        if (option.default && !fieldState) {
          setField(field.attributes.id, option.value);
        }

        return (
          <Radio
            key={i}
            value={option.value}
            name={field.attributes.name}
            checked={fieldState && option.value === fieldState.value}
            onCheck={isChecked =>
              setField(field.attributes.id, isChecked ? null : option.value)
            }
            label={option.label}
          />
        );
      })}
    </OptionsWrapper>
  );
};

const mapStateToProps = createPropsSelector({
  fieldState: getField
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setField
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RadioType);
