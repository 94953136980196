import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';

const AdditionalInfoModal = props => {
  const { t } = useTranslation();

  const content = () => (
    <div>
      <div>
        {t('author')}:{' '}
        {props.infoData &&
          `${props.infoData.author.data.attributes.first_name} ${props.infoData.author.data.attributes.last_name}`}
      </div>
      <div>
        {t('comment')}: {props.infoData && props.infoData.comment}
      </div>
    </div>
  );

  const footer = () => <Button value={t('close')} onClick={props.onHide} />;

  return (
    <Modal
      isOpen={props.isOpen}
      onHide={props.onHide}
      title={t('additionalInfo')}
      content={content()}
      footer={footer()}
    />
  );
};

AdditionalInfoModal.propTypes = {
  onHide: PropTypes.func,
  isOpen: PropTypes.bool,
  infoData: PropTypes.object
};

export default AdditionalInfoModal;
