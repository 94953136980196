import React, { Component } from 'react';
import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Input from 'Atoms/Input';
import Textarea from 'Atoms/Textarea';
import Checkbox from 'Atoms/Checkbox';
import { InputsWrapper, SerialNumberWrapper } from './styles';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import {
  createFormTypeFields,
  SERIAL_NUMBER,
  DELIMITER
} from 'constants/createFormTypeFieldsConstants';
import { getCurrentListType } from 'store/formTypeList/formTypeListSelectors';
import { getInventionTypeMetaData } from 'store/inventionTypeForm/inventionTypeFormSelectors';
import { getAssessmentTypeMetaData } from 'store/assessmentTypeForm/assessmentTypeFormSelectors';
import { addNewFormType } from 'store/formType/formTypeReducer';

import { INVENTION_TYPE } from 'constants/formTypesConstants';
import { DEFAULT_RED } from 'constants/colors';

import InventionTypeIdCreator from './CreateInventionTypeID';
import { DragDropContext } from 'react-beautiful-dnd';
import CreateInventionTypeIDFields from './CreateInventionTypeFields';

class AddOrEditInventionTypeModal extends Component {
  state = {
    data: {
      attributes: {
        number_template: {
          delimiter: '-',
          template: [{ [SERIAL_NUMBER]: '' }]
        }
      },
      type: this.props.currentListType
    },
    unfilledFields: []
  };

  componentDidMount() {
    const newAttributtes = {};

    // eslint-disable-next-line
    this.props.fields.map(el => {
      const fieldValues = createFormTypeFields[Object.keys(el)[0]];
      const { label, key } = fieldValues;

      const value = el[label] || fieldValues.value;
      if (value) return (newAttributtes[key] = value);
    });

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: {
          ...newAttributtes,
          ...(this.props.currentListType === INVENTION_TYPE && {
            allow_coinventors: newAttributtes.allow_coinventors || false,
            ...(!this.props.isEdited && {
              number_template: {
                ...this.state.data.attributes.number_template
              }
            })
          })
        }
      }
    });
  }

  setInputValue = (value, type) => {
    this.setState({
      data: {
        ...this.state.data,
        attributes: {
          ...this.state.data.attributes,
          [type]: value
        }
      }
    });
  };

  setInventionTypeIdValues = (value, type) => {
    const currentAttributes = { ...this.state.data.attributes };
    if (type === DELIMITER) {
      currentAttributes.number_template.delimiter = value;
    } else {
      // eslint-disable-next-line array-callback-return
      currentAttributes.number_template.template.find(el => {
        const keyValue = Object.keys(el)[0];
        if (keyValue === type) {
          el[keyValue] = value;
        }
      });
    }
    this.setState({
      data: {
        ...this.state.data,
        attributes: currentAttributes
      }
    });
  };

  removeInventionTypeIdField = keyName => {
    const currentAttributes = { ...this.state.data.attributes };
    let template = currentAttributes.number_template.template;
    template = template.filter(el => Object.keys(el)[0] !== keyName);
    currentAttributes.number_template.template = template;
    this.setState({
      data: {
        ...this.state.data,
        attributes: currentAttributes
      }
    });
  };

  shouldBeDisabled = () => {
    const { attributes } = this.state.data;
    const emptyFields = [];

    !attributes.name && emptyFields.push('name');
    !attributes.description && emptyFields.push('description');

    if (
      this.props.currentListType === 'invention_type' &&
      !this.props.isEdited &&
      attributes.number_template.template.find(
        el =>
          // eslint-disable-next-line eqeqeq
          Object.keys(el)[0] === SERIAL_NUMBER && el[Object.keys(el)[0]] == ''
      )
    ) {
      emptyFields.push(SERIAL_NUMBER);
    }

    this.setState({ unfilledFields: emptyFields });

    return Boolean(emptyFields.length);
  };

  onDragEnd = result => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const newAttributes = { ...this.state.data.attributes };
    let templateArray = [
      ...this.state.data.attributes.number_template.template
    ];
    if (source.droppableId === 'fields') {
      if (
        result.draggableId === 'year' ||
        result.draggableId === 'year_month'
      ) {
        templateArray = templateArray.filter(
          el => Object.keys(el)[0] !== 'date'
        );
        templateArray.splice(destination.index, 0, {
          date: result.draggableId
        });
      } else {
        templateArray.splice(destination.index, 0, {
          [result.draggableId]: ''
        });
      }

      newAttributes.number_template.template = templateArray;
      this.setState({
        data: {
          ...this.state.data,
          attributes: newAttributes
        }
      });
    } else {
      const movedElement =
        this.state.data.attributes.number_template.template[source.index];

      templateArray.splice(source.index, 1);
      templateArray.splice(destination.index, 0, movedElement);
    }
    newAttributes.number_template.template = templateArray;

    this.setState({
      data: {
        ...this.state.data,
        attributes: newAttributes
      }
    });
  };

  prepareContent = () => {
    const { attributes } = this.state.data;

    return (
      <>
        <InputsWrapper>
          {this.props.fields.map((el, index) => {
            const fieldValues = createFormTypeFields[Object.keys(el)[0]];

            const { label, type, key } = fieldValues;

            const value = attributes[key];

            if (type === 'input') {
              return (
                <Input
                  key={index}
                  isRequired
                  label={t(label)}
                  value={value}
                  onChange={e => {
                    this.setInputValue(e, key);
                  }}
                  borderColor={
                    this.state.unfilledFields.includes(label) && DEFAULT_RED
                  }
                />
              );
            } else if (type === 'text') {
              return (
                <Textarea
                  key={index}
                  isRequired
                  label={t(label)}
                  value={value}
                  onChange={e => {
                    this.setInputValue(e, key);
                  }}
                  borderColor={
                    this.state.unfilledFields.includes(label) && DEFAULT_RED
                  }
                  onKeyUp={e => {
                    this.setInputValue(e, key);
                  }}
                />
              );
            } else if (type === 'number') {
              return (
                <Input
                  key={index}
                  isRequired
                  type="number"
                  label={t(label)}
                  value={
                    value || (fieldValues.value ? fieldValues.value : null)
                  }
                  onChange={e => {
                    this.setInputValue(e ? parseInt(e) : '', key);
                  }}
                />
              );
            } else if (type === 'fixed') {
              return (
                this.props.isEdited && (
                  <SerialNumberWrapper>{`${t(
                    'serialNumber'
                  )}: ${value}`}</SerialNumberWrapper>
                )
              );
            } else {
              return (
                <Checkbox
                  key={index}
                  label={t(label)}
                  checked={value}
                  onCheck={e => this.setInputValue(!value, key)}
                />
              );
            }
          })}
        </InputsWrapper>

        {!this.props.isEdited &&
          this.props.currentListType === 'invention_type' && (
            <DragDropContext
              onDragEnd={this.onDragEnd}
              onDragStart={this.onDragStart}
            >
              <div className="row">
                <div className="col-md-9">
                  <InventionTypeIdCreator
                    template={this.state.data.attributes.number_template}
                    setInventionTypeIdValues={this.setInventionTypeIdValues}
                    removeInventionTypeIdField={this.removeInventionTypeIdField}
                    borderColor={
                      this.state.unfilledFields.includes(SERIAL_NUMBER) &&
                      DEFAULT_RED
                    }
                  />
                </div>
                <div className="col-md-3">
                  <CreateInventionTypeIDFields
                    template={this.state.data.attributes.number_template}
                  />
                </div>
              </div>
            </DragDropContext>
          )}
      </>
    );
  };

  handleConfirm = () => {
    this.setState({ unfilledFields: [] });

    if (this.shouldBeDisabled()) {
      toast.error(this.props.t('fillRequiredFields'));
      return;
    }

    this.props.closeModal();
    this.sendFormData();
  };

  prepareFooter = () => (
    <>
      <Button value={t('close')} onClick={() => this.props.closeModal()} />
      <Button
        colorVariant="green"
        value={this.props.isEdited ? t('editTemplate') : t('create')}
        onClick={this.handleConfirm}
      />
    </>
  );

  sendFormData = () =>
    this.props.isEdited
      ? this.props.editFormTypeMetaData(this.props.formTypeId, {
          data: this.state.data
        })
      : this.props.addNewFormType(
          { data: this.state.data },
          this.props.currentListType
        );

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        title={this.props.isEdited ? t('editForm') : t('createNewForm')}
        onHide={this.props.closeModal}
        size="lg"
        content={this.prepareContent()}
        footer={this.prepareFooter()}
      />
    );
  }
}

const mapStateToProps = state => {
  const currentListType = getCurrentListType(state);

  return {
    currentListType: currentListType,
    fields:
      currentListType === INVENTION_TYPE
        ? getInventionTypeMetaData(state)
        : getAssessmentTypeMetaData(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addNewFormType }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('iamip')(AddOrEditInventionTypeModal));
