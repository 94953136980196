import styled from 'styled-components';

export const FileUploadWrapper = styled.div`
  max-width: 100%;
  display: inline-block;
  position: relative;
`;

export const InputWrapper = styled.div`
  opacity: 0;
  position: absolute;
  max-width: 100px;
  cursor: pointer;
  & > input {
    max-width: 100px;
    overflow: hidden;
  }
`;
