import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { formTypeFields } from 'constants/formTypeFieldConstants';
import TitleContent from './TitleContent';

import { Wrapper, FieldContainer, InputTypeContainer, Label } from './styles';

const FormTypeFields = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <TitleContent />
      <Droppable droppableId={'fieldTypes'} isDropDisabled>
        {provided => (
          <FieldContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
          >
            {formTypeFields(t).map((field, index) => (
              <Draggable draggableId={field.id} index={index} key={field.id}>
                {provided => (
                  <>
                    <InputTypeContainer
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <FontAwesomeIcon icon={field.icon} />
                      <Label>{t(field.label)}</Label>
                    </InputTypeContainer>
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </FieldContainer>
        )}
      </Droppable>
    </Wrapper>
  );
};

export default FormTypeFields;
