import { createAnswer } from 'helpers/axios/axiosRequests';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createAnswerRoutine } from 'actions/answersActions';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { push } from 'connected-react-router';
import {
  STATUS_DRAFT,
  STATUS_ASSIGNMENT,
  STATUS_PRE_ASSESSMENT
} from 'constants/inventionStatuses';
import { INVENTIONS_PAGE } from 'constants/routingConstants';

export function* createAnswerPrepSaga(action) {
  try {
    yield put(createAnswerRoutine.request());
    const response = yield call(createAnswer, action.payload);
    yield put(createAnswerRoutine.success(response.data.data));
    const currentWindowPath =
      window.location.pathname.includes('create-invention');
    if (
      action.payload.status === STATUS_ASSIGNMENT ||
      action.payload.status === STATUS_PRE_ASSESSMENT ||
      action.payload.status === STATUS_DRAFT
    ) {
      yield put(push(`${INVENTIONS_PAGE}/${action.payload.inventionId}`));
    } else {
      yield put(push(`${INVENTIONS_PAGE}/${action.payload.inventionId}/edit`));
    }
    switch (action.payload.status) {
      case STATUS_DRAFT:
        if (currentWindowPath) {
          toast.success(i18next.t('draftSuccefullySaved'));
          break;
        }
        toast.success(i18next.t('draftSuccessfullyUpdated'));
        break;
      case STATUS_ASSIGNMENT:
        toast.success(i18next.t('draftSuccessfullySubmited'));
        break;
      default:
        toast.success(i18next.t('formSuccessfullySubmitted'));
        break;
    }
  } catch (exception) {
    console.log(exception);

    toast.error(i18next.t('answerCreationError'));

    yield put(createAnswerRoutine.failure());
  }

  yield put(createAnswerRoutine.fulfill());
}

export default function* createAnswerSaga() {
  yield all([takeEvery(createAnswerRoutine.TRIGGER, createAnswerPrepSaga)]);
}
