import styled from 'styled-components';
import background from 'assets/404-background.jpg';

import {
  BLACK,
  BOX_SHADOW_GRAY,
  LIGHT_GRAY,
  BACKGROUND_GRAY
} from 'constants/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${BLACK};
  background-color: ${LIGHT_GRAY};
`;

export const WrapperLayer = styled.div`
  position: absolute;
  background: url(${background}) center/cover no-repeat;
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

export const Header = styled.h1`
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
`;

export const Paragraph = styled.p`
  max-width: 80%;
  margin: 20px auto;
  font-size: 1rem;
`;

export const ErrorCode = styled.h1`
  font-size: 8rem;
  font-weight: bold;
  line-height: 7rem;
`;

export const ErrorWrapper = styled.div`
  max-width: 80%;
  max-width: 800px;
  text-align: center;
  background-color: ${BACKGROUND_GRAY};
  display: inline-grid;
  padding: 70px;
  border-radius: 10px;
  box-shadow: 0 0 60px ${BOX_SHADOW_GRAY};
  z-index: 1;
  opacity: 0.7;
`;
