import styled from 'styled-components';

import { INPUT_BORDER_GRAY, INPUT_FOCUS_BORDER_BLUE } from 'constants/colors';

export const IconWrapper = styled.div`
  display: flex;
  margin: 0 10px;
  border: ${props =>
    `1px solid ${props.isFocused ? INPUT_FOCUS_BORDER_BLUE : INPUT_BORDER_GRAY}`};
  border-left: none;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  align-items: center;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
`;

export const RequiredLabel = styled.span`
  font-size: 12px;
`;

export const InputLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;
