import {
  INVENTIONS_PAGE,
  NOT_ASSIGNED,
  MY_PRE_ASSESSMENTS,
  MY_ASSESSMENTS,
  ALL_ASSESSMENTS,
  KNOWLEDGE_DATABASE
} from 'constants/routingConstants';

export const INVENTION_OPTIONS = 'inventionOptions';
export const DECISION_OPTIONS = 'decisionOptions';
export const ASSESSMENT_OPTIONS = 'assessmentOptions';

const ID = {
  field: 'id',
  type: 'input',
  column: 1,
  placeholder: 'idPlaceholder',
  key: 'serialNumber'
};
const ASSESSMENT_ID = {
  field: 'assessmentId',
  type: 'input',
  column: 1,
  key: 'id',
  placeholder: 'idPlaceholder'
};
const TITLE = {
  field: 'title',
  type: 'input',
  column: 1,
  placeholder: 'titlePlaceholder',
  key: 'name'
};
const STATUS = {
  field: 'status',
  type: 'select',
  column: 1,
  key: 'status[]'
};
const SUBMISSION_DATE = {
  field: 'submissionDate',
  type: 'date',
  column: 2,
  key: 'submissionDate'
};
const DECISION_DATE = {
  field: 'decisionDate',
  type: 'date',
  column: 2,
  key: 'decisionDate'
};
const DEADLINE = {
  field: 'deadline',
  type: 'date',
  column: 2,
  key: 'assessmentTypeVersion.deadline'
};
const INVENTOR = {
  field: 'inventor',
  type: 'select',
  column: 3,
  options: 'inventorOptions',
  key: 'inventor[]'
};
const REVIEW_MANAGER = {
  field: 'reviewManager',
  type: 'select',
  column: 3,
  options: 'reviewManagerOptions',
  key: 'reviewManager[]'
};
const DECISION_MAKER = {
  field: 'decisionMaker',
  type: 'select',
  column: 3,
  options: 'decisionMakerOptions',
  key: 'decisionMaker[]'
};
const EXPERT = {
  field: 'expert',
  type: 'select',
  column: 3,
  options: 'expertOptions',
  key: 'expert[]'
};

export const getFiltersFields = currentPage => {
  const pageFields = {
    [INVENTIONS_PAGE]: [
      ID,
      TITLE,
      { ...STATUS, options: INVENTION_OPTIONS },
      SUBMISSION_DATE,
      INVENTOR,
      REVIEW_MANAGER,
      DECISION_MAKER
    ],
    [NOT_ASSIGNED]: [
      ID,
      TITLE,
      SUBMISSION_DATE,
      INVENTOR,
      REVIEW_MANAGER,
      DECISION_MAKER
    ],
    [MY_PRE_ASSESSMENTS]: [
      ID,
      TITLE,
      SUBMISSION_DATE,
      REVIEW_MANAGER,
      DECISION_MAKER,
      INVENTOR
    ],
    [MY_ASSESSMENTS]: [
      { ...ID, key: 'invention.serialNumber' },
      ASSESSMENT_ID,
      { ...TITLE, key: 'invention.name' },
      { ...STATUS, options: ASSESSMENT_OPTIONS },
      DEADLINE,
      { ...INVENTOR, key: 'invention.inventor' }
    ],
    [ALL_ASSESSMENTS]: [
      { ...ID, key: 'invention.serialNumber' },
      ASSESSMENT_ID,
      { ...TITLE, key: 'invention.name' },
      { ...STATUS, options: ASSESSMENT_OPTIONS },
      DEADLINE,
      { ...INVENTOR, key: 'invention.inventor' },
      EXPERT
    ],
    [KNOWLEDGE_DATABASE]: [
      ID,
      TITLE,
      { ...STATUS, options: DECISION_OPTIONS },
      SUBMISSION_DATE,
      DECISION_DATE,
      INVENTOR,
      REVIEW_MANAGER,
      DECISION_MAKER
    ]
  };

  return pageFields[currentPage] || [];
};
