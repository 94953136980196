import { DEFAULT_RED, LIGHT_GRAY, MEDIUM_GRAY, WHITE } from 'constants/colors';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  position: relative;
  padding: 20px;
  border: 1px solid ${props => (props.isRequired ? DEFAULT_RED : LIGHT_GRAY)};
  border-radius: 5px;
  margin: 0px 0 10px 0;
  background-color: ${WHITE};
`;

export const AnswerReview = styled.div`
  margin-top: 10px;
  span {
    font-weight: 900;
  }
`;

export const RequiredLabel = styled.span`
  font-size: 12px;
`;

export const FieldName = styled.h5`
  margin: 20px 5px 5px 5px;
  padding: 0;
  font-weight: bold;
`;

export const FieldDescription = styled.div`
  font-style: italic;
  font-size: 14px;
  color: ${MEDIUM_GRAY};
  margin: 0 5px 5px;
`;
