import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { useTranslation } from 'react-i18next';

import {
  getInventionTimeline,
  getIsInventionTimelineLoading
} from 'store/singleInvention/singleInventionSelectors';

import { fetchInventionTimeline } from 'actions/singleInventionActions';
import { fetchOrganisationUsers } from 'actions/organisationActions';

import Timeline from 'Atoms/Timeline';
import Spinner from 'Atoms/Spinner';

import {
  statuses,
  STATUS_DRAFT,
  STATUS_ASSESSMENT,
  STATUS_PENDING_DECISION,
  STATUS_PROPOSE_DELETE,
  decisionStatuses
} from 'constants/inventionStatuses';
import {
  STATUS_IN_PROGRESS,
  STATUS_REVIEW,
  STATUS_COMPLETED
} from 'constants/assessmentStatuses';
import { formatDateWithTime } from 'helpers/dateHelpers';

const InventionTimeline = ({ ...props }) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchInventionTimeline(props.currentInventionId);
  }, []); // eslint-disable-line

  const prepareMessage = (message, details, author) => {
    const {
      reviewManager,
      decisionMaker,
      status,
      expert = {},
      assessment_type_name
    } = details;
    let preparedMessage = message;

    switch (message) {
      case 'draftSaved':
        preparedMessage = t('draftSaved', { author });
        break;
      case 'draftUpdated':
        preparedMessage = t('draftUpdated', { author });
        break;
      case 'newInventionDisclosureStarted':
        preparedMessage = t('newInventionDisclosureStarted', { author });
        break;
      case 'assignedAsReviewManager':
        preparedMessage = t('assignedAsReviewManager', {
          author,
          user: `${reviewManager.first_name} ${reviewManager.last_name}`
        });
        break;
      case 'assignedAsDecisionMaker':
        preparedMessage = t('assignedAsDecisionMaker', {
          author,
          user: `${decisionMaker.first_name} ${decisionMaker.last_name}`
        });
        break;
      case 'inventionStatusChangedTo':
        if (status === STATUS_PENDING_DECISION) {
          preparedMessage = t('movedToDecision', { author });
        } else if (status === STATUS_PROPOSE_DELETE) {
          preparedMessage = t('inventorProposeDelete', { author });
        } else {
          preparedMessage = t(
            decisionStatuses.includes(status)
              ? 'decisionDone'
              : 'inventionStatusChangedTo',
            {
              status: statuses(t).find(e => e.id === status).name,
              author
            }
          );
        }
        break;
      case 'preassessmentDone':
        const additionalMessage =
          status === STATUS_DRAFT
            ? 'movedBackToDraft'
            : STATUS_ASSESSMENT
            ? 'movedToAssessment'
            : 'movedToDecision';

        preparedMessage = t('preassessmentDone', {
          additionalMessage: t(additionalMessage),
          author
        });
        break;
      case 'newAssessmentCreatedFor':
        preparedMessage = t('newAssessmentCreatedFor', {
          author,
          expert: `${expert.first_name} ${expert.last_name}`,
          assessmentType: assessment_type_name
        });
        break;
      case 'assignedAsExpert':
        preparedMessage = t('assignedAsExpert', {
          user: `${expert.first_name} ${expert.last_name}`
        });
        break;
      case 'assessmentStatusChangedTo':
        if (status === STATUS_REVIEW) {
          preparedMessage = t('submittedAssessment', {
            expert: author,
            assessmentType: assessment_type_name
          });
        } else if (status === STATUS_COMPLETED) {
          preparedMessage = t('assessmentAccepted', {
            author,
            assessmentType: assessment_type_name,
            expert: `${expert.first_name} ${expert.last_name}`
          });
        } else if (status === STATUS_IN_PROGRESS) {
          preparedMessage = t('assessmentNeedsMoreFeedback', {
            author,
            assessmentType: assessment_type_name,
            expert: `${expert.first_name} ${expert.last_name}`
          });
        }
        break;
      default:
        break;
    }

    return preparedMessage;
  };

  const prepareTimelineData = () => {
    let timelineElements = [];

    props.data &&
      props.data.forEach(action => {
        const { first_name, last_name } = action.user.data.attributes;
        const author = `${first_name} ${last_name}`;

        timelineElements.push({
          date:
            (action.timestamp && formatDateWithTime(action.timestamp)) || '-',
          description: prepareMessage(
            action.message,
            action.changed_data,
            author
          )
        });
      });

    return timelineElements;
  };

  return (
    <Fragment>
      {props.isTimelineLoading ? (
        <Spinner center />
      ) : (
        <Timeline data={prepareTimelineData()} />
      )}
    </Fragment>
  );
};

const mapStateToProps = createPropsSelector({
  data: getInventionTimeline,
  isTimelineLoading: getIsInventionTimelineLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventionTimeline,
      fetchOrganisationUsers
    },
    dispatch
  );

InventionTimeline.propTypes = {
  fetchInventionTimeline: PropTypes.func,
  data: PropTypes.array,
  isTimelineLoading: PropTypes.bool,
  currentInventionId: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(InventionTimeline);
