import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { getCurrentUserRoles } from 'store/users/usersSelectors';
import {
  getSingleInvention,
  getInventorFullName,
  getReviewManagerFullName,
  getDecisionMakerFullName,
  getIsReviewManagerBeingAssigned,
  getIsDecisionMakerBeingAssigned
} from 'store/singleInvention/singleInventionSelectors';

import { REVIEW_COORDINATOR } from 'constants/userRoles';
import { formatDateWithTime } from 'helpers/dateHelpers';

import AssignReviewManagerOrDecisionMakerModal from './AssignReviewManagerOrDecisionMakerModal';
import { DetailsContainer, ImageWrapper, InventionDetails } from './styles';

import {
  STATUS_ASSIGNMENT,
  STATUS_PRE_ASSESSMENT,
  STATUS_ASSESSMENT,
  STATUS_PENDING_DECISION,
  statuses,
  decisionStatuses
} from 'constants/inventionStatuses';

import { DEFAULT_YELLOW } from 'constants/colors';

import PropTypes from 'prop-types';

import Spinner from 'Atoms/Spinner';

const InventionMetaData = ({
  invention,
  decisionMakerFullName,
  reviewManagerFullName,
  inventorFullName,
  userRoles,
  disableActions,
  isReviewManagerBeingAssigned,
  isDecisionMakerBeingAssigned
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(null);
  const closeModal = () => setModal(null);

  const getCoInventorsNames = () =>
    invention.co_inventors && invention.co_inventors.length > 0
      ? invention.co_inventors
        .map(
          el =>
            `${el.data.attributes.first_name} ${el.data.attributes.last_name}`
        )
        .join(', ')
      : '-';

  const MODALS = {
    decisionMaker: 'decisionMaker',
    reviewManager: 'reviewManager'
  };

  const shouldBeEditable = status =>
    userRoles.includes(REVIEW_COORDINATOR) &&
    [
      STATUS_ASSIGNMENT,
      STATUS_PRE_ASSESSMENT,
      STATUS_ASSESSMENT,
      STATUS_PENDING_DECISION
    ].includes(status);

  const renderIcon = type => {
    const isLoading =
      type === MODALS.reviewManager
        ? isReviewManagerBeingAssigned
        : isDecisionMakerBeingAssigned;

    return isLoading ? (
      <Spinner inline size={'16px'} />
    ) : (
      <FontAwesomeIcon
        style={{
          cursor: 'pointer',
          color: invention.status === STATUS_ASSIGNMENT && DEFAULT_YELLOW
        }}
        onClick={() => setModal(type)}
        icon={faPencilAlt}
      />
    );
  };

  const renderDetails = () => {
    const status = statuses(t).find(({ id }) => id === invention.status);
    const details = [
      {
        label: t('submissionDate'),
        description:
          invention.submitted_at && formatDateWithTime(invention.submitted_at)
      },
      {
        label: t('inventor'),
        description: inventorFullName
      },
      {
        label: t('coInventors'),
        description: invention.allow_coinventors ? getCoInventorsNames() : '-'
      },
      {
        label: t('reviewManager'),
        description: reviewManagerFullName,
        isEditable: shouldBeEditable(invention.status),
        modalType: MODALS.reviewManager
      },
      {
        label: t('decisionMaker'),
        description: decisionMakerFullName,
        isEditable: shouldBeEditable(invention.status),
        modalType: MODALS.decisionMaker
      },
      {
        label: t('decisionDate'),
        description: invention.decision_date
          ? formatDateWithTime(invention.decision_date)
          : '-'
      },
      {
        label: t('decision'),
        description: !decisionStatuses.includes(invention.status)
          ? t('pendingStatus')
          : status && status.name
      }
    ];

    return (
      <InventionDetails>
        {details.map(e => (
          <React.Fragment key={e.label}>
            <div>{e.label}:</div>
            <div>
              {e.description}{' '}
              {e.isEditable && !disableActions && renderIcon(e.modalType)}
            </div>
          </React.Fragment>
        ))}
      </InventionDetails>
    );
  };

  // eslint-disable-next-line consistent-return
  const renderModal = () => {
    switch (modal) {
      case MODALS.reviewManager:
        return (
          <AssignReviewManagerOrDecisionMakerModal
            reviewManagerModal
            isOpen={modal === MODALS.reviewManager}
            proposedUsers={invention.proposed_review_managers}
            closeModal={closeModal}
          />
        );
      case MODALS.decisionMaker:
        return (
          <AssignReviewManagerOrDecisionMakerModal
            decisionMakerModal
            isOpen={modal === MODALS.decisionMaker}
            closeModal={closeModal}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DetailsContainer className="row align-middle">
      <div className="col-8">{renderDetails()}</div>
      <div className="col-4 d-flex flex-row-reverse">
        <ImageWrapper>
          {!invention.figure_url ? (
            <FontAwesomeIcon icon={faImage} size={'10x'} />
          ) : (
            <img alt="" src={`${invention.figure_url}`} />
          )}
        </ImageWrapper>
      </div>
      {modal && renderModal()}
    </DetailsContainer>
  );
};

const mapStateToProps = state => ({
  userRoles: getCurrentUserRoles(state),
  invention: getSingleInvention(state),
  inventorFullName: getInventorFullName(state),
  reviewManagerFullName: getReviewManagerFullName(state),
  decisionMakerFullName: getDecisionMakerFullName(state),
  isReviewManagerBeingAssigned: getIsReviewManagerBeingAssigned(state),
  isDecisionMakerBeingAssigned: getIsDecisionMakerBeingAssigned(state)
});

InventionMetaData.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string),
  invention: PropTypes.object,
  decisionMakerFullName: PropTypes.string,
  reviewManagerFullName: PropTypes.string,
  inventorFullName: PropTypes.string,
  disableActions: PropTypes.bool
};

export default connect(mapStateToProps)(InventionMetaData);
