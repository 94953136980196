import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import { exportInventionsToExcel } from 'actions/inventionsActions';

import Button from 'Atoms/Button';
import Pagination from 'Atoms/Pagination';
import Table from 'Elements/Table';
import SelectPageSize from 'Atoms/SelectPageSize';

import { LayoutWrapper, ButtonWrapper } from './styles';

import { removeSlash } from 'constants/routingConstants';
import { WHITE } from 'constants/colors';

const TablePageLayout = ({
  buttons,
  canExportToExcel,
  columnNames,
  data,
  exportInventionsToExcel,
  formTypeListMeta,
  handleChangePage,
  isFetching,
  limit,
  onLimitSelect,
  onSortClick
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (formTypeListMeta && formTypeListMeta.currentPage) {
      localStorage.setItem('previousPage', formTypeListMeta.currentPage);
    }
  }, [formTypeListMeta]);

  useEffect(() => {
    if (limit) {
      localStorage.setItem('pageSize', limit);
    }
  }, [limit]);

  const pagesCount = formTypeListMeta
    ? Math.ceil(formTypeListMeta.totalItems / formTypeListMeta.itemsPerPage)
    : 0;

  const handleExportButtonClick = () =>
    exportInventionsToExcel(removeSlash(history.location.pathname));

  const excelExportIcon = (
    <FontAwesomeIcon
      className="mr-2"
      icon={faFileDownload}
      style={{ fontSize: '16px' }}
    />
  );

  return (
    <LayoutWrapper hasPagination={pagesCount > 1}>
      <div className="row">
        <div className="d-flex justify-content-end align-items-center w-100">
          {canExportToExcel && (
            <div className="mr-5">
              <Button
                buttonIcon={excelExportIcon}
                colorVariant="exportGreen"
                customTextColor={WHITE}
                disabled={isEmpty(data)}
                value={t('exportToXls')}
                onClick={() => handleExportButtonClick()}
              />
            </div>
          )}
          <SelectPageSize onLimitSelect={onLimitSelect} />
          {buttons &&
            buttons.map(
              (el, index) =>
                el && (
                  <ButtonWrapper key={index} className="sm-3">
                    <Button
                      colorVariant="blue"
                      isFetching={el.isFetching}
                      value={el.label}
                      onClick={el.action}
                    />
                  </ButtonWrapper>
                )
            )}
        </div>
      </div>
      <Table
        columnNames={columnNames}
        isFetching={isFetching}
        limit={limit}
        rows={data}
        onSortClick={onSortClick}
      />
      {pagesCount > 1 && (
        <Pagination
          currentPage={formTypeListMeta.currentPage}
          handleChangePage={handleChangePage}
          pagesCount={pagesCount}
        />
      )}
    </LayoutWrapper>
  );
};

TablePageLayout.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
  canExportToExcel: PropTypes.bool,
  columnNames: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  exportInventionsToExcel: PropTypes.func,
  formTypeListMeta: PropTypes.object,
  handleChangePage: PropTypes.func,
  isFetching: PropTypes.bool,
  limit: PropTypes.number,
  onLimitSelect: PropTypes.func,
  onSortClick: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exportInventionsToExcel }, dispatch);
export default connect(null, mapDispatchToProps)(memo(TablePageLayout));
