import React from 'react';
import TextareaType from 'Pages/CreateInventionPage/Elements/FieldTypes/TextareaType';
import RadioType from 'Pages/CreateInventionPage/Elements/FieldTypes/RadioType';
import AdvancedRadioType from 'Pages/CreateInventionPage/Elements/FieldTypes/AdvancedRadioType';
import CheckboxType from 'Pages/CreateInventionPage/Elements/FieldTypes/CheckboxType';
import SelectType from 'Pages/CreateInventionPage/Elements/FieldTypes/SelectType';
import InformationType from 'Pages/CreateInventionPage/Elements/FieldTypes/InformationType';
import { useTranslation } from 'react-i18next';
import {
  FieldWrapper,
  AnswerReview,
  RequiredLabel,
  FieldName,
  FieldDescription
} from 'Pages/CreateInventionPage/Elements/Field/styles';
import Checkbox from 'Atoms/Checkbox';
import { INFORMATION_FIELD_ID } from 'constants/formTypeFieldConstants';

const Field = ({ field, answers, review, unfilledFields }) => {
  const { t } = useTranslation();
  const FieldType = () => {
    switch (field.attributes.type) {
      case 1:
        return <TextareaType field={field} answers={answers} />;
      case 2:
        return <RadioType field={field} answers={answers} />;
      case 3:
        return <AdvancedRadioType field={field} answers={answers} />;
      case 4:
        return <CheckboxType field={field} answers={answers} />;
      case 5:
        return <SelectType field={field} answers={answers} />;
      case 6:
        return <InformationType field={field} />;
      default:
        return null;
    }
  };

  return (
    <>
      <FieldName>{field.attributes.name}</FieldName>
      {field.attributes.type !== INFORMATION_FIELD_ID && (
        <FieldDescription>{field.attributes.description}</FieldDescription>
      )}
      <FieldWrapper
        isRequired={
          field.attributes.required && unfilledFields.includes(field.id)
        }
      >
        {FieldType()}
        {field.attributes.required && (
          <RequiredLabel>{t('requiredField')}</RequiredLabel>
        )}
        {review && (
          <>
            <AnswerReview>
              <span>{t('answerReview')}: </span>
              {review.attributes.comment}
            </AnswerReview>
            {review.attributes.critical && (
              <Checkbox label={t('setAsCritical')} disabled checked />
            )}
          </>
        )}
      </FieldWrapper>
    </>
  );
};

export default Field;
