import {
  fetchAssessmentTypes,
  fetchInventionAssessments,
  assignInventionExpert,
  fetchSingleAssessment,
  answerAssessment,
  reviewAssessment,
  fetchAssessmentsCounter,
  fetchPreassessmentsCounter,
  fetchUnassignedIdeasCounter
} from 'actions/inventionAssessmentActions';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  getAssessmentTypeList,
  getInventionAssessments,
  assignExpert,
  getSingleAssessment,
  createAnswer,
  patchAssessment,
  createReview,
  getAssessmentsCounterData,
  getInventionsCounterData
} from 'helpers/axios/axiosRequests';

import { clearInventionsLists } from 'actions/inventionsActions.js';

import { toast } from 'react-toastify';
import { t } from 'helpers/i18n';

import { INVENTIONS_PAGE, MY_ASSESSMENTS } from 'constants/routingConstants';
import {
  STATUS_IN_PROGRESS,
  STATUS_REVIEW
} from 'constants/assessmentStatuses';

import { fetchInventionTimelineSaga } from 'sagas/singleInventionSaga';

function* fetchInventionAssessmentsSaga({ payload }) {
  try {
    const { data } = yield call(getInventionAssessments, payload);
    yield put(fetchInventionAssessments.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAssessmentTypesSaga() {
  try {
    const { data } = yield call(getAssessmentTypeList);
    yield put(fetchAssessmentTypes.success(data.data));
  } catch (e) {
    console.error(e);
  }
}

function* assignExpertSaga({ payload }) {
  try {
    const { data } = yield call(assignExpert, payload);
    yield put(assignInventionExpert.success(data.data));
    yield toast.success(t('assessmentSuccessfullyCreated'));
    yield call(fetchInventionTimelineSaga, {
      payload: payload.data.attributes.invention.id.split('/').pop()
    });
  } catch (e) {
    yield toast.error(t('assignExpertFailure'));
    console.error(e);
  }
}

function* fetchSingleAssessmentSaga({ payload }) {
  try {
    const { data } = yield call(getSingleAssessment, payload);

    yield put(fetchSingleAssessment.success(data.data));
  } catch (error) {
    yield put(fetchSingleAssessment.failure());
    console.error(error);
  }
}

function* answerAssessmentSaga({ payload }) {
  try {
    const { surveyId, fields, assessmentId, status, decisionProposal } =
      payload;

    yield call(createAnswer, { surveyId, fields });

    if (status || decisionProposal) {
      const patchData = {
        data: {
          type: 'assessment',
          attributes: {
            ...(status && { status }),
            ...(decisionProposal && {
              decision_proposal: decisionProposal.explanation,
              decision_proposal_status: decisionProposal.status
            })
          }
        }
      };

      yield call(patchAssessment, assessmentId, patchData);

      if (status === STATUS_IN_PROGRESS) {
        yield toast.success(t('assessmentDraftSaved'));
      } else if (status === STATUS_REVIEW) {
        yield toast.success(t('submitAssessmentSuccess'));
      }
    }
    yield put(clearInventionsLists());
    yield put(push(MY_ASSESSMENTS));
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
  }

  yield put(answerAssessment.fulfill());
}

function* reviewAssessmentSaga({ payload }) {
  try {
    yield call(createReview, payload.data);

    const patchData = {
      data: {
        type: 'assessment',
        attributes: {
          status: payload.assessmentStatus
        }
      }
    };

    yield call(patchAssessment, payload.assessmentId, patchData);

    yield toast.success(t('assessmentReviewSuccess'));

    yield put(push(`${INVENTIONS_PAGE}/${payload.inventionId}`));
  } catch (error) {
    console.error(error);
  }

  yield put(reviewAssessment.fulfill());
}

function* fetchAssessmentsCounterSaga() {
  try {
    const { data } = yield call(getAssessmentsCounterData);
    yield put(fetchAssessmentsCounter.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchPreassessmentsCounterSaga({ payload }) {
  try {
    const { data } = yield call(getInventionsCounterData, payload);
    yield put(fetchPreassessmentsCounter.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUnassignedIdeasCounterSaga({ payload }) {
  try {
    const { data } = yield call(getInventionsCounterData, payload);
    yield put(fetchUnassignedIdeasCounter.success(data));
  } catch (e) {
    console.error(e);
  }
}

export default function* inventionAssessmentsSaga() {
  yield all([
    takeEvery(fetchInventionAssessments.TRIGGER, fetchInventionAssessmentsSaga),
    takeEvery(fetchAssessmentTypes.TRIGGER, fetchAssessmentTypesSaga),
    takeEvery(assignInventionExpert.TRIGGER, assignExpertSaga),
    takeEvery(fetchSingleAssessment.TRIGGER, fetchSingleAssessmentSaga),
    takeEvery(answerAssessment.TRIGGER, answerAssessmentSaga),
    takeEvery(reviewAssessment.TRIGGER, reviewAssessmentSaga),
    takeEvery(fetchAssessmentsCounter.TRIGGER, fetchAssessmentsCounterSaga),
    takeEvery(
      fetchPreassessmentsCounter.TRIGGER,
      fetchPreassessmentsCounterSaga
    ),
    takeEvery(
      fetchUnassignedIdeasCounter.TRIGGER,
      fetchUnassignedIdeasCounterSaga
    )
  ]);
}
