import styled from 'styled-components';
import { WHITE } from 'constants/colors';

export const Wrapper = styled.div`
  background: ${WHITE};
  padding: 20px;
  position: fixed;
  width: ${props => `calc(100% - ${props.collapsed ? 100 : 240}px)`};
  z-index: 111;
`;

export const Header = styled.h3`
  margin-bottom: 15px;
`;

export const Column = styled.div`
  display: inline-grid;
  width: 33%;
  padding: 0 10px;
  grid-template-columns: auto 1fr;
  column-gap: 20px;
  row-gap: 10px;

  div {
    margin: 0;
    align-self: center;
  }
`;

export const ButtonsWrapper = styled.div`
  margin: 15px 0;

  button:last-child {
    float: right;
  }
`;

export const DateRow = styled.div`
  display: inline-block;

  ${props =>
    props.elements === 3
      ? `& > div:first-child {
        display: block;
        padding: 0 5px;
      }
      & > div:not(:first-child) {
        display: inline-block;
        width: 50%;
        padding: 5px;
      }`
      : `& > div {
    display: inline-block;
    &:nth-of-type(1) {
      padding-right: ${props.elements !== 2 ? '0' : '5px'};
    }
    width: calc(100% / ${props.elements});
  }`}
`;
