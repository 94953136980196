import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from 'Atoms/Textarea';
import Radio from 'Atoms/Radio';
import Checkbox from 'Atoms/Checkbox';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { INFORMATION_FIELD_ID } from 'constants/formTypeFieldConstants';

import {
  OptionsWrapper,
  OptionContainer,
  FieldWrapper,
  CommentWrapper,
  DeleteIcon,
  CommentInputWrapper,
  InformationDescription
} from './styles';
import { isEmpty } from 'lodash';

export const InventionPreviewField = ({
  field,
  answer,
  inReview,
  handleAddComment,
  handleRemoveComment,
  answerId,
  review,
  surveyReviewId,
  isInSinglePage
}) => {
  const { t } = useTranslation();
  const { attributes } = field;
  const { options, type } = attributes;
  const [comment, setComment] = useState();
  const [showInput, toggleInput] = useState(false);
  const [isCritical, setIsCritical] = useState(false);

  const toggleField = () => toggleInput(!showInput);
  const isInformationField = field.attributes.type !== INFORMATION_FIELD_ID;

  const handleReviewValues = () => {
    setIsCritical(review.attributes.critical);
    setComment(review.attributes.comment);
  };

  const handleField = () => {
    const preparedField = {
      type: 'answer_review',
      attributes: {
        comment: comment || '',
        critical: isCritical || false,
        survey_review: {
          type: 'survey_review',
          id: surveyReviewId
        },
        answer: {
          type: 'answer',
          id: answerId
        }
      }
    };

    handleAddComment(preparedField, answerId);
  };

  const deleteComment = () => {
    toggleInput();
    handleRemoveComment(answerId);
    setComment();
  };
  useEffect(() => {
    if (review) {
      handleReviewValues();
      if (review.attributes.comment) {
        toggleField();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (review) {
      handleReviewValues();
    }
    // eslint-disable-next-line
  }, [review]);

  useEffect(() => {
    if (comment || isCritical) {
      handleField();
    }
    // eslint-disable-next-line
  }, [comment, isCritical]);

  // eslint-disable-next-line consistent-return
  const renderProperType = () => {
    const prepareSelectData = [];
    if (type === 5) {
      answer &&
        answer.value.map(el => prepareSelectData.push({ label: el.label }));
    }
    switch (type) {
      case 1:
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: (!isEmpty(answer.value) && answer.value) || '-'
            }}
          />
        );

      case 2:
        return (
          <OptionsWrapper>
            {options.fields.map(el => (
              <Radio
                key={el.value}
                label={el.label}
                checked={
                  answer && answer.value ? answer.value === el.label : false
                }
                disabled
              />
            ))}
          </OptionsWrapper>
        );

      case 3:
        return (
          <>
            <OptionsWrapper>
              {options.fields.map((el, index) => (
                <OptionContainer key={index}>
                  <Radio
                    label={el.label}
                    disabled
                    checked={answer ? answer.value === el.label : false}
                  />
                </OptionContainer>
              ))}
            </OptionsWrapper>
            {answer.additional && (
              <Textarea
                disabled
                autoResize
                value={answer.additional}
                label={
                  (
                    field.attributes.options.fields.find(
                      f => answer && answer.value === f.value
                    ) || {}
                  ).expandedLabel
                }
              />
            )}
          </>
        );

      case 4:
        return (
          <OptionsWrapper>
            {options.fields.map((el, index) => (
              <Checkbox
                key={index}
                label={el.label}
                disabled
                checked={answer ? answer.value.includes(el.label) : false}
              />
            ))}
          </OptionsWrapper>
        );

      case 5:
        return (
          <Select
            title={t('chooseOption')}
            isMulti={options.multiselect}
            value={prepareSelectData}
            isDisabled
          />
        );
      case 6:
        return (
          <InformationDescription>
            {field.attributes.description}
          </InformationDescription>
        );

      default:
        break;
    }
  };

  const renderCommentInput = () => (
    <>
      <CommentInputWrapper>
        <Textarea
          onChange={e => setComment(e)}
          placeholder={t('addYourComment')}
          value={comment}
          rows={2}
        />
        <DeleteIcon onClick={deleteComment}>
          <FontAwesomeIcon icon={faTrash} />
        </DeleteIcon>
      </CommentInputWrapper>
      <Checkbox
        label={t('isCritical')}
        onCheck={() => {
          setIsCritical(!isCritical);
        }}
        checked={isCritical}
      />
    </>
  );

  return (
    <FieldWrapper isInSinglePage={isInSinglePage}>
      {renderProperType()}
      {inReview && !showInput && isInformationField && answerId && (
        <CommentWrapper onClick={toggleField}>
          <FontAwesomeIcon icon={faPlusCircle} /> {t('addYourComment')}
        </CommentWrapper>
      )}
      {showInput && isInformationField && renderCommentInput()}
    </FieldWrapper>
  );
};
