import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from 'Atoms/Textarea';
import Radio from 'Atoms/Radio';
import Checkbox from 'Atoms/Checkbox';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from 'Elements/ConfirmationModal';
import {
  FieldDescription,
  FieldName,
  FieldWrapper,
  IconBoxes,
  Label,
  OptionsWrapper,
  OptionContainer
} from './styles';

export const FormTypeField = ({
  field,
  handleEditField,
  handleRemoveField,
  sectionId,
  isFormTypeLocked
}) => {
  const [displayModal, setDisplayModal] = useState();
  const { t } = useTranslation();
  const { attributes } = field;
  const { options, type } = attributes;

  const renderProperType = () => {
    switch (type) {
      case 1:
        return (
          <Textarea
            placeholder={t('addAnswear')}
            maxLength={options['maxLength']}
            onChange={e => {
              console.log(e);
            }}
            disabled
          />
        );

      case 2:
        return (
          <OptionsWrapper>
            {options['fields'].map(el => (
              <Radio
                key={el.value}
                label={el.label}
                checked={el.default || false}
                disabled
              />
            ))}
          </OptionsWrapper>
        );

      case 3: {
        return (
          <OptionsWrapper>
            {options['fields'].map(el => (
              <OptionContainer>
                <Radio
                  label={el.label}
                  disabled
                  checked={el.default || false}
                />
                {el.isExpanded && (
                  <Textarea
                    placeholder={el.expandedLabel}
                    disabled
                    onChange={e => {
                      console.log(e);
                    }}
                  />
                )}
              </OptionContainer>
            ))}
          </OptionsWrapper>
        );
      }

      case 4:
        return (
          <OptionsWrapper>
            {options['fields'].map(el => (
              <Checkbox label={el.label} disabled checked={el.default} />
            ))}
          </OptionsWrapper>
        );

      case 5:
        return (
          <>
            <Select
              title={t('chooseOption')}
              isMulti={options['multiselect']}
              value={options['fields']}
              isDisabled
            />
            <Checkbox
              label={t('multiselect')}
              checked={options['multiselect']}
              disabled
            />
          </>
        );

      default:
        break;
    }
  };
  return (
    <>
      <FieldWrapper>
        {attributes.required && <Label>{t('requiredField')}</Label>}
        {!isFormTypeLocked && (
          <IconBoxes>
            <FontAwesomeIcon
              onClick={() => handleEditField(field, sectionId)}
              icon={faPencilAlt}
            />
            <FontAwesomeIcon
              onClick={() => setDisplayModal(true)}
              icon={faTrash}
            />
          </IconBoxes>
        )}
        <FieldName>{attributes.name}</FieldName>
        <FieldDescription>{attributes.description}</FieldDescription>
        {renderProperType()}
      </FieldWrapper>
      <ConfirmationModal
        isOpen={displayModal}
        onHide={() => setDisplayModal(false)}
        onConfirm={() => handleRemoveField(field.id, sectionId)}
      />
    </>
  );
};
