import React from 'react';
import DefaultTabs from 'Atoms/Tabs';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import { AddSection, Container, IconWrapper, DragMessage } from './styles';
import NewSectionModal from './NewSectionModal';
import EditSectionModal from './EditSectionModal';
import ConfirmationModal from 'Elements/ConfirmationModal';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FormTypeField } from '../FormTypeField/FormTypeField';
import { prepareSectionObjects } from 'helpers/sectionHelpers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import {
  getFormTypeLocked,
  getSections
} from 'store/formType/formTypeSelectors';
import {
  addSection,
  editSection,
  removeField,
  removeSection
} from 'store/formType/formTypeReducer';
import AddNewButton from 'Atoms/AddNewButton';

class FormTypeTabs extends React.Component {
  state = {
    newSectionModal: false,
    editionModal: null
  };

  renderTabContent = id => {
    const sectionToRender = this.props.sections.find(
      el => el.data.attributes.id === id
    );

    return (
      <Droppable droppableId={sectionToRender.data.id} direction="vertical">
        {provided => (
          <Container
            isBeingDragged={this.props.isBeingDragged}
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
          >
            {this.props.isBeingDragged &&
              sectionToRender &&
              sectionToRender.data.attributes.fields.length === 0 && (
                <DragMessage>{this.props.t('dragHere')}</DragMessage>
              )}
            {sectionToRender &&
              sectionToRender.data.attributes.fields.map((el, index) => (
                <Draggable
                  draggableId={el.data.id}
                  index={index}
                  key={el.data.id}
                  isDragDisabled={this.props.isFormTypeLocked}
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FormTypeField
                        key={index}
                        sectionId={sectionToRender.data.id}
                        field={el.data}
                        handleEditField={this.props.handleEditField}
                        handleRemoveField={this.props.removeField}
                        isFormTypeLocked={this.props.isFormTypeLocked}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    );
  };

  displayModal = (value, type) => {
    this.setState({
      [type]: value
    });
  };

  handleEditSection = id => {
    this.setState({
      editedSection: this.props.sections.find(
        // eslint-disable-next-line eqeqeq
        el => el.data.attributes.id == id
      ),
      editionModal: true
    });
  };

  handleRemoveSection = id => {
    const { sections } = this.props;
    // eslint-disable-next-line eqeqeq
    const sectionToRemove = sections.find(el => el.data.id == id);
    if (sectionToRemove.data.attributes.fields.length > 0) {
      this.setState({
        sectionToRemove: sectionToRemove
      });
      return;
    }

    this.props.removeSection(id);
  };

  handleOnConfirm = () => {
    this.props.removeSection(this.state.sectionToRemove.data.id);
  };

  handleOnHide = () => {
    this.setState({ sectionToRemove: null });
  };

  render() {
    const { sections, isFormTypeLocked, isInventionType } = this.props;

    return (
      <>
        {((!isFormTypeLocked && isInventionType && sections.length < 5) ||
          sections.length === 0) && (
          <IconWrapper>
            <AddNewButton
              description={t('addNewSection')}
              onClick={
                sections.length < 5
                  ? () => this.displayModal(true, 'newSectionModal')
                  : undefined
              }
            />
          </IconWrapper>
        )}
        {sections.length > 0 && (
          <DefaultTabs
            isFormTypeLocked={isFormTypeLocked}
            data={prepareSectionObjects(sections)}
            removeSection={
              !isFormTypeLocked && isInventionType
                ? this.handleRemoveSection
                : undefined
            }
            editSection={!isFormTypeLocked ? this.handleEditSection : undefined}
            content={this.renderTabContent}
          />
        )}

        {this.state.newSectionModal && (
          <NewSectionModal
            addSection={this.props.addSection}
            onHide={this.displayModal}
            formId={this.props.formId}
          />
        )}
        {this.state.editionModal && (
          <EditSectionModal
            onHide={this.displayModal}
            section={this.state.editedSection}
            editSection={this.props.editSection}
          />
        )}
        {sections.length === 0 && (
          <AddSection
            onClick={() => this.displayModal(true, 'newSectionModal')}
          >
            {t('clickHereToAddSection')}
          </AddSection>
        )}
        {this.state.sectionToRemove && isInventionType && (
          <ConfirmationModal
            translationKey={'removingSectionConfirmation'}
            isOpen
            onHide={this.handleOnHide}
            onConfirm={this.handleOnConfirm}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createPropsSelector({
  sections: getSections,
  isFormTypeLocked: getFormTypeLocked
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addSection,
      removeSection,
      editSection,
      removeField
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('iamip')(FormTypeTabs));
