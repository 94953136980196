import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const DefaultModal = ({ isOpen, size, onHide, title, content, footer }) => (
  <Modal show={isOpen} size={size} centered onHide={onHide} backdrop="static">
    <Modal.Header closeButton>{title}</Modal.Header>
    <Modal.Body>{content}</Modal.Body>
    <Modal.Footer>{footer}</Modal.Footer>
  </Modal>
);

DefaultModal.propTypes = {
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  onHide: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.element,
  footer: PropTypes.element
};

export default memo(DefaultModal);
