import React from 'react';
import Textarea from 'Atoms/Textarea';

const InformationType = ({ field }) => (
  <Textarea
    placeholder={field.name}
    value={field.description}
    disabled
    autoResize
  />
);

export default InformationType;
