import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setField, clearAllFields } from 'actions/filterAndSearchActions';
import {
  setShowSearchAndFilterSection,
  setFilterAndSearchHeight
} from 'actions/layoutDetailsActions';
import { fetchOrganisationUsers } from 'actions/organisationActions';
import { fetchInventions } from 'actions/inventionsActions';
import {
  getCurrentUserRoles,
  getUsersForOptions
} from 'store/users/usersSelectors';
import {
  getFilterAndSearchValues,
  getAreFiltersApplied,
  getHasAnyFiltersSaved
} from 'store/filterAndSearch/filterAndSearchSelectors';
import { getInventionsLimit } from 'store/inventions/inventionsSelectors';

import { pathTranslations } from 'constants/routingConstants';
import {
  inventionStatusesOptions,
  decisionStatusesOptions
} from 'constants/inventionStatuses';
import { assessmentStatuses } from 'constants/assessmentStatuses';
import Input from 'Atoms/Input';
import Button from 'Atoms/Button';

import { Wrapper, Header, Column, ButtonsWrapper } from './styles';
import DateFiltersRow from './DateFiltersRow';

import {
  getFiltersFields,
  DECISION_OPTIONS,
  ASSESSMENT_OPTIONS,
  INVENTION_OPTIONS
} from './filtersSettings';

const FilterAndSearch = props => {
  const { t } = useTranslation();
  const ref = useRef();
  const [fields, setFields] = useState([]);
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);

  const setFieldForMultiSelect = (fieldId, options) => {
    const newState = multiSelectOptions;
    newState[fieldId] = options;
    setMultiSelectOptions(newState);
  };

  const getOptionsIndexByKey = fieldKey =>
    fields
      .filter(field => field.type === 'select')
      .findIndex(field => field.key === fieldKey);

  useEffect(() => {
    props.fetchOrganisationUsers();

    setFields(getFiltersFields(props.pathname));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.setFilterAndSearchHeight(
      (ref.current && ref.current.clientHeight) || 0
    );

    const loadState = fields
      .filter(field => field.type === 'select')
      .map(field => props.values[field.key]);
    setMultiSelectOptions(loadState);
    // eslint-disable-next-line
  }, [fields]);

  const handleFilter = () => {
    props.fetchInventions({ type: props.pathname, itemsPerPage: props.limit });
    props.setShowSearchAndFilterSection(false);
  };

  const handleClear = () => {
    setMultiSelectOptions(
      new Array(fields.filter(field => field.type === 'select').length).fill([])
    );
    props.clearAllFields();
    props.areFiltersApplied &&
      props.fetchInventions({
        type: props.pathname,
        itemsPerPage: props.limit
      });
  };

  const onKeyUp = e => {
    if (e.key === 'Enter' && props.hasAnyValues) {
      handleFilter();
    }
  };

  const prepareSelectOptions = type =>
    type === DECISION_OPTIONS
      ? decisionStatusesOptions(t)
      : type === ASSESSMENT_OPTIONS
      ? assessmentStatuses(t).map(o => ({ value: o.id, label: o.label }))
      : type === INVENTION_OPTIONS
      ? inventionStatusesOptions(t, props.userRoles)
      : props[type];

  const handleSelect = (fieldKey, event) => {
    setFieldForMultiSelect(getOptionsIndexByKey(fieldKey), event);
    props.setField(
      fieldKey,
      multiSelectOptions[getOptionsIndexByKey(fieldKey)]
    );
  };

  const renderSingleField = field => (
    <React.Fragment key={field.field}>
      <div>{t(field.field)}</div>
      {field.type === 'input' ? (
        <Input
          placeholder={t(field.placeholder)}
          value={props.values[field.key]}
          onChange={e => props.setField(field.key, e)}
        />
      ) : field.type === 'select' ? (
        <Select
          options={prepareSelectOptions(field.options)}
          value={multiSelectOptions[getOptionsIndexByKey(field.key)]}
          isClearable
          isMulti
          onChange={event => handleSelect(field.key, event)}
        />
      ) : (
        <DateFiltersRow
          field={field.key}
          setField={props.setField}
          values={props.values}
        />
      )}
    </React.Fragment>
  );

  const renderFields = () =>
    [1, 2, 3].map(columnNumber => (
      <Column key={`columnNumber-${columnNumber}`}>
        {fields
          .filter(field => field.column === columnNumber)
          .map(field => renderSingleField(field))}
      </Column>
    ));

  return (
    <Wrapper ref={ref} collapsed={props.collapsed} onKeyUp={onKeyUp}>
      <Header>
        {t('filterIn', {
          name: pathTranslations(t).find(
            el => el.path === props.pathname.slice(1)
          )
            ? pathTranslations(t).find(
                el => el.path === props.pathname.slice(1)
              ).translation
            : ''
        })}
      </Header>
      {renderFields()}
      <ButtonsWrapper>
        <Button
          colorVariant="red"
          disabled={!props.hasAnyValues}
          value={t('clearAllFields')}
          onClick={handleClear}
        />
        <Button
          colorVariant="blue"
          value={t('filter')}
          onClick={handleFilter}
          // disabled={!props.hasAnyValues}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};

FilterAndSearch.propTypes = {
  areFiltersApplied: PropTypes.bool,
  clearAllFields: PropTypes.func,
  collapsed: PropTypes.bool,
  fetchInventions: PropTypes.func,
  fetchOrganisationUsers: PropTypes.func,
  hasAnyValues: PropTypes.bool,
  limit: PropTypes.number,
  pathname: PropTypes.string,
  setField: PropTypes.func,
  setFilterAndSearchHeight: PropTypes.func,
  setShowSearchAndFilterSection: PropTypes.func,
  userRoles: PropTypes.array,
  values: PropTypes.object
};

const mapStateToProps = state => ({
  userRoles: getCurrentUserRoles(state),
  inventorOptions: getUsersForOptions(state, 'inventors', true),
  reviewManagerOptions: getUsersForOptions(state, 'reviewManagers'),
  decisionMakerOptions: getUsersForOptions(state, 'decisionMakers'),
  expertOptions: getUsersForOptions(state, 'experts'),
  values: getFilterAndSearchValues(state),
  areFiltersApplied: getAreFiltersApplied(state),
  hasAnyValues: getHasAnyFiltersSaved(state),
  limit: getInventionsLimit(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setField,
      fetchOrganisationUsers,
      clearAllFields,
      fetchInventions,
      setShowSearchAndFilterSection,
      setFilterAndSearchHeight
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FilterAndSearch);
