export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

// grays
export const BACKGROUND_GRAY = '#F4F5F6';
export const BOX_SHADOW_GRAY = '#A5A4A4';
export const DARK_GRAY = '#275075';
export const INPUT_BORDER_GRAY = '#CED4DA';
export const LIGHT_GRAY = '#D2D6DA';
export const LIGHT_GRAY_SECONDARY = '#F4F6F9';
export const MEDIUM_GRAY = '#808080';
export const SILVER_GRAY = '#858E9B';
export const VERY_DARK_GRAY = '#343A40';
export const VERY_LIGHT_GRAY = '#F9F9F9';

// blues
export const ACTIVE_BLUE_PRIMARY = '#0E95D5';
export const ACTIVE_BLUE_SECONDARY = '#239AD2';
export const BREADCRUMBS_BLUE = '#CEE5F3';
export const DARK_BLUE = '#3994D0';
export const DEFAULT_BLUE = '#59A5D8';
export const ICON_BG_BLUE = '#2196F3';
export const INPUT_FOCUS_BORDER_BLUE = '#80BDFF';

// greens
export const DEFAULT_GREEN = '#3FD485';
export const SUCCESS_GREEN_PRIMARY = '#44CF6C';
export const SUCCESS_GREEN_SECONDARY = '#2AA44D';
export const EXCEL_EXPORT_GREEN = '#3EB678';

// reds
export const DEFAULT_RED = '#FF0000';
export const ERROR_RED_PRIMARY = '#FE5F55';
export const ERROR_RED_SECONDARY = '#D10F01';
export const LIGHT_RED = '#D7345B';

// yellows
export const DEFAULT_YELLOW = '#FEBC34';
export const LIGHT_YELLOW = '#FFF200';

export const BUTTON_COLORS = {
  green: 'btn-success',
  red: 'btn-danger',
  orange: 'btn-warning',
  blue: 'btn-info',
  darkBlue: 'btn-primary'
};
