import typeToReducer from 'type-to-reducer';
import {
  CLEAR_SELECTED_INVENTION_TYPE,
  HIDE_MODAL,
  SET_CHOOSE_INVENTION_TYPE_ID,
  SET_CHOOSE_INVENTION_TYPE_OBJECT,
  SHOW_MODAL
} from 'actions/newInventionTypeChooseModalActions';

import {
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENT,
  UPDATE_ATTACHMENT,
  UPDATE_INVENTION_FIGURE
} from 'actions/newInventionFormActions';

import { deleteTemporaryAttachment } from 'actions/attachmentsActions';

export const SET_CHOOSE_INVENTION_FORM_OBJECT =
  'invention/SET_CHOOSE-iNVENTION_FORM_OBJECT';

const defaultState = {
  isVisible: false,
  chooseInventionTypeId: null,
  chooseInventionTypeObject: null
};

export default typeToReducer(
  {
    [SHOW_MODAL]: state => ({
      ...state,
      isVisible: true
    }),
    [HIDE_MODAL]: state => ({
      ...state,
      isVisible: false
    }),
    [SET_CHOOSE_INVENTION_TYPE_ID]: (state, { payload }) => ({
      ...state,
      chooseInventionTypeId: payload
    }),
    [SET_CHOOSE_INVENTION_TYPE_OBJECT]: (state, { payload }) => ({
      ...state,
      chooseInventionTypeObject: payload
    }),
    [CLEAR_SELECTED_INVENTION_TYPE]: state => ({
      ...state,
      chooseInventionTypeObject: null,
      chooseInventionTypeId: null
    }),
    [SET_CHOOSE_INVENTION_FORM_OBJECT]: (state, { payload }) => ({
      ...state,
      chooseInventionTypeObject: payload
    }),
    [ADD_ATTACHMENT]: (state, { data }) => {
      const attributes = { ...state.chooseInventionTypeObject.attributes };
      return {
        ...state,
        chooseInventionTypeObject: {
          ...state.chooseInventionTypeObject,
          attributes: {
            ...state.chooseInventionTypeObject.attributes,
            attachments: attributes.attachments
              ? attributes.attachments.concat(data)
              : [].concat(data)
          }
        }
      };
    },
    [deleteTemporaryAttachment]: (state, { payload }) => {
      if (payload.type !== 'draft') {
        return state;
      }

      return {
        ...state,
        chooseInventionTypeObject: {
          ...state.chooseInventionTypeObject,
          attributes: {
            ...state.chooseInventionTypeObject.attributes,
            attachments:
              state.chooseInventionTypeObject.attributes.attachments.filter(
                (el, index) => index !== payload.index
              )
          }
        }
      };
    },
    [UPDATE_ATTACHMENT]: (state, { data }) => ({
      ...state,
      chooseInventionTypeObject: {
        ...state.chooseInventionTypeObject,
        attributes: {
          ...state.chooseInventionTypeObject.attributes,

          attachments:
            state.chooseInventionTypeObject.attributes.attachments.map(el => {
              if (
                el.data.attributes.file_name === data.data.attributes.file_name
              ) {
                el = { ...data };
              }
              return el;
            })
        }
      }
    }),

    [REMOVE_ATTACHMENT]: (state, { id }) => ({
      ...state,
      chooseInventionTypeObject: {
        ...state.chooseInventionTypeObject,
        attributes: {
          ...state.chooseInventionTypeObject.attributes,
          attachments:
            state.chooseInventionTypeObject.attributes.attachments.filter(
              el => el.data.id !== id
            )
        }
      }
    }),
    [UPDATE_INVENTION_FIGURE]: (state, { figureUrl }) => ({
      ...state,
      chooseInventionTypeObject: {
        ...state.chooseInventionTypeObject,
        attributes: {
          ...state.chooseInventionTypeObject.attributes,
          figure_url: figureUrl
        }
      }
    })
  },
  defaultState
);

export const setInventionFormObject = payload => ({
  type: SET_CHOOSE_INVENTION_FORM_OBJECT,
  payload
});
