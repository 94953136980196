import styled from 'styled-components';
import {
  LIGHT_GRAY,
  LIGHT_RED,
  DEFAULT_GREEN,
  LIGHT_YELLOW
} from 'constants/colors';

import {
  STATUS_PENDING,
  STATUS_IN_PROGRESS,
  STATUS_REVIEW
} from 'constants/assessmentStatuses';

export const Action = styled.div`
  margin: 20px;
`;

export const IconWrapper = styled.span`
  font-size: 21px;
  margin-right: 10px;
  color: ${({ assessmentStatus }) =>
    assessmentStatus === STATUS_PENDING
      ? LIGHT_RED
      : [STATUS_IN_PROGRESS, STATUS_REVIEW].includes(assessmentStatus)
      ? LIGHT_YELLOW
      : DEFAULT_GREEN};
`;

export const AddNewButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: right;
`;

export const DecisionWrapper = styled.div`
  margin-top: 10px;
`;

export const UploadFileWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${LIGHT_GRAY};
  text-align: center;
`;

export const FormWrapper = styled.div`
  height: 60vh;
  overflow: auto;
`;

export const SelectWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ExpertAssessmentHeader = styled.div`
  font-size: 18px;
  margin: 0 auto;
`;

export const ExpertWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ExpertName = styled.span`
  flex-direction: column;
  float: left;
  flex: 1 40%;
`;

export const ExpertDecision = styled.span`
  flex-direction: column;
  margin: 5px;
  flex: 1 40%;
`;
