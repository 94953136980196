import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Locize)
  .init({
    fallbackLng: 'en',
    ns: ['iamip'],
    saveMissing: false,
    defaultNS: 'iamip',
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
      useSuspense: false
    },
    debug: false,
    initImmediate: true,
    backend: {
      projectId: '5f9f51fc-3570-4aa2-b5f2-e331028a4f18',
      apiKey: '8e9d8ece-f025-4bd2-97a4-721867d2dacd',
      referenceLng: 'en-US'
    }
  });

i18n.on('languageChanged', language => moment.locale(language));
export default i18n;
