import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import {
  getCurrentUserFullName,
  getCurrentUserRoles
} from 'store/users/usersSelectors';
import {
  ADMIN,
  REVIEW_COORDINATOR,
  REVIEW_MANAGER,
  EXPERT
} from 'constants/userRoles';
import {
  INVENTION_TYPE,
  NOT_ASSIGNED,
  MY_ASSESSMENTS,
  MY_PRE_ASSESSMENTS,
  INVENTIONS_PAGE
} from 'constants/routingConstants';

import { NameWrapper } from './styles';

const HomePage = ({ currentUser, roles }) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const routeToPass =
      roles.length === 1 && (roles[0] === ADMIN || roles[0] === EXPERT)
        ? roles[0] === ADMIN
          ? INVENTION_TYPE
          : MY_ASSESSMENTS
        : isEqual(roles.sort(), [ADMIN, EXPERT])
        ? MY_ASSESSMENTS
        : roles.includes(REVIEW_COORDINATOR)
        ? NOT_ASSIGNED
        : roles.includes(REVIEW_MANAGER)
        ? MY_PRE_ASSESSMENTS
        : INVENTIONS_PAGE;

    history.push(routeToPass);
    // eslint-disable-next-line
  }, []);

  return (
    <NameWrapper>{`${currentUser}, ${t('welcomeToPlatform')}`}</NameWrapper>
  );
};

const mapStateToProps = createPropsSelector({
  currentUser: getCurrentUserFullName,
  roles: getCurrentUserRoles
});

export default connect(mapStateToProps)(HomePage);
