import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import PropTypes from 'prop-types';

import Calendar from 'Atoms/Calendar';
import { formatDate, prepareDateValue } from 'helpers/dateHelpers';

import { DateRow } from './styles';

const DateFilters = ({ field, setField, values }) => {
  const { t } = useTranslation();
  const [type, setType] = useState(values[field + 'Type']);

  const options = [
    { value: 'inactive', label: t('inactive') },
    { value: 'on', label: t('on') },
    { value: 'earlierThan', label: t('earlierThan') },
    { value: 'laterThan', label: t('laterThan') },
    { value: 'between', label: t('between') }
  ];

  const currentDate = new Date();

  const getFieldName = type =>
    type === 'earlierThan'
      ? `${field}[before]`
      : type === 'laterThan' || type === 'between'
      ? `${field}[after]`
      : field;

  const handleSelect = e => {
    setType(e.value);
    setField(field + 'Type', e.value);
    setField(getFieldName(e.value), values[getFieldName(type)]);
  };

  const handleDateChange = date =>
    setField(getFieldName(type), formatDate(date || new Date()));

  const firstDate = prepareDateValue(values[getFieldName(type)]);
  const secondDate = prepareDateValue(values[field + `[before]`]);

  return (
    <DateRow
      elements={
        type && type === 'between' ? 3 : type && type !== 'inactive' ? 2 : 1
      }
    >
      <Select
        value={
          options.find(o => o.value === values[field + `Type`]) || options[0]
        }
        defaultValue={options[0]}
        options={options}
        onChange={handleSelect}
      />
      {type && type !== 'inactive' && (
        <Calendar
          maxDate={field !== 'deadline' ? currentDate : null}
          value={firstDate}
          onChange={handleDateChange}
        />
      )}
      {type === 'between' && (
        <Calendar
          maxDate={field !== 'deadline' ? currentDate : null}
          minDate={new Date(values[field + `[after]`])}
          value={secondDate}
          onChange={date => setField(field + '[before]', formatDate(date))}
        />
      )}
    </DateRow>
  );
};

DateFilters.propTypes = {
  field: PropTypes.string,
  setField: PropTypes.func,
  values: PropTypes.object
};

export default DateFilters;
