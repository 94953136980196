import React from 'react';
import { withTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';
import { t } from 'helpers/i18n';
import FormTypeFields from 'Elements/FormTypeFields';
import FormTypeDescription from 'Elements/FormTypeDescription';
import NewInputTypeModal from './NewInputTypeModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createPropsSelector } from 'reselect-immutable-helpers';
import {
  editFormTypeMetaData,
  addNewField,
  editField
} from 'store/formType/formTypeReducer';
import {
  getFormTypeId,
  getNumberOfSections,
  getFormId,
  getSections,
  getFormTypeLocked,
  getIsLoading
} from 'store/formType/formTypeSelectors';

import FormTypeContainer from 'Elements/FormTypeContainer/FormTypeContainer';
import { toast } from 'react-toastify';

class FormTypeCreator extends React.Component {
  state = {
    showModal: false,
    isBeingDragged: false
  };

  onDragEnd = result => {
    this.setState({ isBeingDragged: false });

    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const targetSection = this.props.sections.find(
      // eslint-disable-next-line eqeqeq
      el => el.data.id == destination.droppableId
    );

    if (
      targetSection.data.attributes.fields &&
      targetSection.data.attributes.fields.length >= 50 &&
      source.droppableId !== destination.droppableId
    ) {
      toast.error(t('cannotAddField'));
      return;
    }

    if (destination.droppableId && source.droppableId === 'fieldTypes') {
      this.displayModal(true, source.index + 1, destination);
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index !== source.index
    ) {
      this.handleChangeFieldPosition(
        destination,
        source.index,
        result.draggableId
      );
    }
  };

  onDragStart = () => {
    this.setState({ isBeingDragged: true });
  };

  displayModal = (value, type, destination, field, sectionId, isEdited) => {
    if (destination) {
      this.setState({
        showModal: value,
        modalType: type,
        destinationIndex: destination.index,
        sectionId: destination.droppableId || sectionId
      });
    } else {
      this.setState({
        showModal: value,
        modalType: type,
        field: field,
        sectionId: sectionId,
        isEdited: isEdited
      });
    }
  };

  handleNewField = field => {
    this.props.addNewField(field);
  };

  handleEditField = (field, sectionId) => {
    this.displayModal(
      true,
      field.attributes.type,
      null,
      field,
      sectionId,
      true
    );
  };

  handleChangeFieldPosition = (destination, sourceIndex, fieldId) => {
    const fieldToMove = this.props.sections.find(
      // eslint-disable-next-line eqeqeq
      el => el.data.id == destination.droppableId
    ).data.attributes.fields[sourceIndex];
    const dataToSend = {
      data: {
        type: 'field',
        attributes: {
          ...fieldToMove.data.attributes,
          position: destination.index
        }
      }
    };

    this.props.editField(
      fieldId,
      dataToSend,
      sourceIndex,
      destination.droppableId
    );
  };

  render() {
    const { formTypeId, formId, isFormTypeLocked, isLoading } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-md-9">
            {!isLoading && (
              <FormTypeDescription
                editFormTypeMetaData={this.props.editFormTypeMetaData}
                formTypeId={formTypeId}
                formTypeMetaData={this.props.formTypeMetaData}
                isFormTypeLocked={isFormTypeLocked}
                isLoading={isLoading}
                isInventionType={this.props.isInventionType}
              />
            )}
          </div>
        </div>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <div className="row">
            <div className="col-md-9">
              <FormTypeContainer
                isBeingDragged={this.state.isBeingDragged}
                formId={formId}
                handleEditField={this.handleEditField}
                isInventionType={this.props.isInventionType}
              />
            </div>
            <div className="col-md-3">
              {this.props.numberOfSections > 0 &&
                !isFormTypeLocked &&
                !isLoading && (
                  <FormTypeFields isBeingDragged={this.state.isBeingDragged} />
                )}
            </div>
          </div>
        </DragDropContext>
        {this.state.showModal && (
          <NewInputTypeModal
            onHide={this.displayModal}
            handleNewField={this.handleNewField}
            type={parseInt(this.state.modalType)}
            sectionId={this.state.sectionId}
            field={this.state.field}
            handleEditField={this.props.editField}
            isEdited={this.state.isEdited}
            position={this.state.destinationIndex}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createPropsSelector({
  formTypeId: getFormTypeId,
  numberOfSections: getNumberOfSections,
  formId: getFormId,
  sections: getSections,
  isFormTypeLocked: getFormTypeLocked,
  isLoading: getIsLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editFormTypeMetaData: editFormTypeMetaData,
      addNewField,
      editField
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('iamip')(withRouter(FormTypeCreator)));
