import styled from 'styled-components';
import { DEFAULT_BLUE } from 'constants/colors';

export const Action = styled.div`
  margin: 20px;
`;

export const AddNewButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: right;
`;

export const PersonWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 5fr;
  align-items: center;
  grid-row-margin: 10px;
`;

export const IconWrapper = styled.div`
  font-size: 21px;
  margin-right: 10px;
  color: ${DEFAULT_BLUE};
`;
export const ProposalWrapper = styled.div`
  margin: 20px;
`;
