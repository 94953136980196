/* eslint-disable array-callback-return */
import React from 'react';
import Fields from 'Pages/CreateInventionPage/Elements/Fields';

import Tabs from 'Atoms/Tabs';

import { SectionsWrapper } from './styles';

const Sections = ({
  sections,
  answers,
  reviews,
  unfilledFields,
  currentSectionIndex,
  setCurrentSectionIndex
}) => {
  sections.map(el => (el.containsError = false));
  unfilledFields.map(el => {
    sections.map(section => {
      if (section.attributes.fields.find(field => field.id === el)) {
        section.containsError = true;
      }
    });
  });

  const data = sections.map(section => ({
    id: section.id,
    name: section.attributes.name,
    description: section.attributes.description,
    containsError: section.containsError
  }));

  const renderTabContent = id => {
    const section = sections.find(e => e.id === id);

    return (
      <Fields
        fields={section.attributes.fields}
        answers={answers}
        reviews={reviews}
        unfilledFields={unfilledFields}
      />
    );
  };

  return (
    <SectionsWrapper>
      <Tabs
        data={data}
        content={renderTabContent}
        isCreateInvention
        currentSectionIndex={currentSectionIndex}
        setCurrentSectionIndex={setCurrentSectionIndex}
      />
    </SectionsWrapper>
  );
};

export default Sections;
