export const getInventions = state => state.inventions.data || [];
export const getAreInventionsLoading = state => state.inventions.isFetching;
export const getInventionsPagination = state => state.inventions.pagination;
export const getInventionsLimit = state => state.inventions.limit;
export const getInventionsSortingOrder = state => state.inventions.sortingOrder;

export const getIsInventionsSortingApplied = state =>
  Boolean(
    Object.values(state.inventions.sortingOrder).reduce(
      (acc, el) => acc || el,
      false
    )
  );
