import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import {
  UserAvatar,
  UserBox,
  UserFooter,
  UserMenuDropdown,
  UserMenuToggler,
  UserName,
  UserNameDropdown,
  UserSettings
} from './styles';
import Button from 'Atoms/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getCurrentUserAvatar,
  getCurrentUserFullName
} from 'store/users/usersSelectors';
import { authLogout } from 'store/login/routines';
import env from 'helpers/env';

class UserMenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserMenuOpened: false
    };
  }

  toggleUserMenu = value => {
    this.setState({
      isUserMenuOpened: value
    });
  };

  handleClickOutside = evt => {
    this.toggleUserMenu(false);
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    const { avatar, userFullName } = this.props;

    return (
      <UserSettings>
        <UserMenuToggler
          onClick={() => this.toggleUserMenu(!this.state.isUserMenuOpened)}
        >
          <FontAwesomeIcon icon={faUserCircle} />
          <UserName>{userFullName}</UserName>
        </UserMenuToggler>

        <UserMenuDropdown isOpen={this.state.isUserMenuOpened}>
          <UserBox>
            {avatar ? (
              <UserAvatar src={avatar} />
            ) : (
              <FontAwesomeIcon icon={faUserCircle} size="6x" />
            )}
            <UserNameDropdown>{userFullName}</UserNameDropdown>
          </UserBox>
          <UserFooter>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${env('AUTH_URL')}/user/profile`}
            >
              <Button value={t('profile')} />
            </a>
            <Button value={t('logout')} onClick={this.logout} />
          </UserFooter>
        </UserMenuDropdown>
      </UserSettings>
    );
  }
}

const mapStateToProps = state => ({
  userFullName: getCurrentUserFullName(state),
  avatar: getCurrentUserAvatar(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: authLogout
    },
    dispatch
  );

UserMenuComponent.propTypes = {
  userFullName: PropTypes.string,
  avatar: PropTypes.string,
  logout: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('iamip')(onClickOutside(UserMenuComponent)));
