import React from 'react';
import FormTypeCreator from 'Elements/FormTypeCreator';
import { connect } from 'react-redux';

import { getAssessmentTypeMetaData } from 'store/assessmentTypeForm/assessmentTypeFormSelectors';

const AssessmentTypeCreatorPage = ({ formTypeMetaData }) => (
  <FormTypeCreator formTypeMetaData={formTypeMetaData} />
);

const mapStateToProps = state => ({
  formTypeMetaData: getAssessmentTypeMetaData(state)
});

export default connect(mapStateToProps)(AssessmentTypeCreatorPage);
