import React, { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';

const ConfirmationModal = ({
  isOpen,
  onHide,
  onConfirm,
  onReject,
  translationKey
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={t('confirm')}
      onHide={onHide}
      content={<div>{t(translationKey || 'areYouSure')}</div>}
      footer={
        <Fragment>
          {onReject ? (
            <Button
              colorVariant="red"
              value={t('reject')}
              onClick={() => {
                onReject();
                onHide();
              }}
            />
          ) : (
            <Button colorVariant="red" value={t('cancel')} onClick={onHide} />
          )}
          <Button
            colorVariant="green"
            value={t('confirm')}
            onClick={() => {
              onConfirm();
              onHide();
            }}
          />
        </Fragment>
      }
    />
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  translationKey: PropTypes.string
};

export default memo(ConfirmationModal);
