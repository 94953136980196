import styled from 'styled-components';
import { MEDIUM_GRAY } from 'constants/colors';

export const SelectWrapper = styled.div`
  display: inline-block;
  width: 70px;
  margin: 20px 10px;
  span {
    display: none;
  }
`;

export const SelectLabel = styled.label`
  color: ${MEDIUM_GRAY};
  font-size: 13px;
  margin-bottom: 0;
`;

export const CustomSelect = {
  option: styles => ({
    ...styles,
    cursor: 'pointer'
  }),
  control: styles => ({
    ...styles,
    cursor: 'pointer'
  })
};
