const SET_FIELD = 'SET_FIELD';
const SET_MULTIVALUE_FIELD = 'SET_MULTIVALUE_FIELD';
const CLEAR_FIELDS = 'CLEAR_FIELDS';
const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';
const ADD_FIGURE_TO_INVENTION = 'ADD_FIGURE_TO_INVENTION';
const UPDATE_INVENTION_FIGURE = 'UPDATE_INVENTION_FIGURE';

const setField = (id, value, additionalValue = null) => ({
  type: SET_FIELD,
  payload: { id, value, additionalValue }
});

const setMultivalueField = (id, value) => ({
  type: SET_MULTIVALUE_FIELD,
  payload: { id, value }
});

const clearFields = () => ({
  type: CLEAR_FIELDS
});

const addAttachment = data => ({
  type: ADD_ATTACHMENT,
  data
});

const removeAttachment = id => ({
  type: REMOVE_ATTACHMENT,
  id
});

const updateAttachment = data => ({
  type: UPDATE_ATTACHMENT,
  data
});
const addFigureToInvention = (file, inventionId) => ({
  type: ADD_FIGURE_TO_INVENTION,
  file,
  inventionId
});
const updateInventionFigure = figureUrl => ({
  type: UPDATE_INVENTION_FIGURE,
  figureUrl
});

export {
  SET_FIELD,
  SET_MULTIVALUE_FIELD,
  CLEAR_FIELDS,
  ADD_ATTACHMENT,
  REMOVE_ATTACHMENT,
  UPDATE_ATTACHMENT,
  ADD_FIGURE_TO_INVENTION,
  UPDATE_INVENTION_FIGURE,
  setField,
  setMultivalueField,
  clearFields,
  addAttachment,
  removeAttachment,
  updateAttachment,
  addFigureToInvention,
  updateInventionFigure
};
