import { createSelector } from 'reselect';

const getIsVisible = state => state.newInventionTypeChooseModal.isVisible;
const getChooseInventionTypeId = state =>
  state.newInventionTypeChooseModal.chooseInventionTypeId;
const getChooseInventionTypeObject = state =>
  state.newInventionTypeChooseModal.chooseInventionTypeObject;

const getInventionTypeReviewManger = createSelector(
  state => getChooseInventionTypeObject(state),
  chooseInventionTypeObject =>
    (chooseInventionTypeObject.attributes.review_manager &&
      chooseInventionTypeObject.attributes.review_manager.data) ||
    null
);

const getInventionTypeDecisionMaker = createSelector(
  state => getChooseInventionTypeObject(state),
  chooseInventionTypeObject =>
    (chooseInventionTypeObject.attributes.decision_maker &&
      chooseInventionTypeObject.attributes.decision_maker.data) ||
    null
);

const getInventionTypeAttachments = createSelector(
  state => getChooseInventionTypeObject(state),
  chooseInventionTypeObject =>
    (chooseInventionTypeObject.attributes.attachments &&
      chooseInventionTypeObject.attributes.attachments) ||
    null
);

export {
  getIsVisible,
  getChooseInventionTypeId,
  getChooseInventionTypeObject,
  getInventionTypeReviewManger,
  getInventionTypeDecisionMaker,
  getInventionTypeAttachments
};
