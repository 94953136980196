import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LIGHT_RED, WHITE } from 'constants/colors';

const NotificationCounter = ({ notificationNumber, left, top }) => (
  <Counter left={left} top={top}>
    {notificationNumber <= 9 ? notificationNumber : '9+'}
  </Counter>
);

NotificationCounter.propTypes = {
  notificationNumber: PropTypes.number,
  left: PropTypes.string,
  top: PropTypes.string
};

const Counter = styled.div`
  position: absolute;
  z-index: 1;
  top: ${props => (props.top ? props.top : 'calc(-50%)')};
  left: ${props => (props.left ? props.left : 'calc(110%)')};
  transform: translateX(-50%);
  display: flex;
  user-select: none;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  background: ${LIGHT_RED};
  border-radius: 50%;
  color: ${WHITE};
  font-size: 12px;
  font-weight: 700;
`;

export default NotificationCounter;
