import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

const Pagination = ({ handleChangePage, currentPage, pagesCount }) => {
  const onChange = page => {
    handleChangePage && handleChangePage(page['selected']);
  };

  return (
    <>
      <ReactPaginate
        pageCount={pagesCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={onChange}
        containerClassName={'pagination justify-content-center'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        activeClassName={'active-item'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        forcePage={currentPage - 1 || 0}
      />
    </>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  pagesCount: PropTypes.number
};

export default Pagination;
