import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAssessmentName } from 'store/inventionAssessments/inventionAssessmentsSelectors';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';

import SingleAssessmentForm from 'Elements/SingleAssessmentForm';

import { FormWrapper } from './styles';

const AssessmentPreviewModal = props => {
  const { t } = useTranslation();

  const content = () => (
    <FormWrapper>
      <SingleAssessmentForm
        disabled
        assessmentId={props.assessmentId}
        emptyForm={props.emptyForm}
      />
    </FormWrapper>
  );

  const footer = () => <Button value={t('close')} onClick={props.closeModal} />;

  return (
    <Modal
      title={`${t('assessment')}: ${props.assessmentName}`}
      size="lg"
      isOpen={props.isOpen}
      onHide={props.closeModal}
      content={content()}
      footer={footer()}
    />
  );
};

AssessmentPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  assessmentId: PropTypes.number,
  assesmentName: PropTypes.string,
  emptyForm: PropTypes.bool
};

const mapStateToProps = state => ({
  assessmentName: getAssessmentName(state)
});

export default connect(mapStateToProps)(AssessmentPreviewModal);
