import InventionTypeAttributes from 'Models/InventionTypeAttributes';

export default class InventionType {
  constructor(rawJSONObject) {
    this._id = rawJSONObject.id;
    this._type = rawJSONObject.type;
    this._attributes = new InventionTypeAttributes(rawJSONObject.attributes);
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  get attributes() {
    return this._attributes;
  }

  set attributes(value) {
    this._attributes = value;
  }
}
