import {
  createInvention as createInventionRequest,
  updateInvention
} from 'helpers/axios/axiosRequests';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { createInvention } from 'actions/inventionsActions';
import { getInventionTypeAttachments } from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';
import {
  uploadAttachmentsSaga,
  addFigureToInventionSaga
} from 'sagas/attachmentsSaga.js';

export function* createInventionPrepSaga(action) {
  try {
    yield put(createInvention.request());
    const response = !action.payload.inventionId
      ? yield call(createInventionRequest, action.payload)
      : yield call(updateInvention, action.payload);

    if (!action.payload.inventionId) {
      if (localStorage.getItem('figureImage')) {
        const payload = {
          file: localStorage.getItem('figureImage'),
          inventionId: response.data.data.attributes.id
        };
        yield call(addFigureToInventionSaga, payload);
      }
      let attachments = yield select(getInventionTypeAttachments);
      if (attachments) {
        const action = {
          payload: {
            attachments: attachments,
            responseData: response.data.data
          }
        };

        yield call(uploadAttachmentsSaga, action);
      }
    }
    yield put(createInvention.success(response.data.data));
  } catch (exception) {
    yield put(createInvention.failure());
  }

  yield put(createInvention.fulfill());
}

export default function* createInventionSaga() {
  yield all([takeEvery(createInvention.TRIGGER, createInventionPrepSaga)]);
}
