import { LIGHT_GRAY, DEFAULT_GREEN } from 'constants/colors';
import styled from 'styled-components';

export const InventionName = styled.div`
  font-size: 22px;
  padding-bottom: 10px;
`;

export const InventionDescription = styled.div`
  max-width: 650px;
  padding-bottom: 10px;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  padding-left: 5px;
`;

export const Label = styled.span``;

export const InputBox = styled.div`
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 30px 5px 5px;
  margin-bottom: 20px;
`;

export const AddIconWrapper = styled.div`
  cursor: pointer;
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  top: 10px;
  right: 30px;
  font-size: 20px;
  color: ${DEFAULT_GREEN};
`;

export const InputWrapper = styled.div`
  flex: 1 50%;
  margin: 5px 0;
  position: relative;
  max-width: 50%;

  & > div {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
  }
`;

export const InputBoxLabel = styled.label`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0.25px;
`;

export const TrashWrapper = styled.div`
  position: absolute;
  width: 60px;
  top: 5px;
  right: ${props => (props.type === 'select' ? '65px' : '40px')};
  & > div {
    display: inline-block;
  }
  & svg {
    margin: 0 5px;
    cursor: pointer;
  }
`;
export const SelectWrapper = styled.div`
  padding: 5px;
  position: relative;
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const SelectContainer = styled.div`
  width: 80%;
  margin-bottom: 20px;
`;

export const AddField = styled.div`
  width: 50%;
  min-height: 80px;
  margin: 0 auto 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dotted ${LIGHT_GRAY};
`;

export const ExpandedInputWrapper = styled.div`
  margin-left: 28px;
  margin-top: -10px;
  max-width: 230px;
`;
