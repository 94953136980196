import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Wrapper,
  Header,
  ErrorCode,
  Paragraph,
  ErrorWrapper,
  WrapperLayer
} from './styles';

import { HOME_PAGE } from 'constants/routingConstants';

import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';

export class ErrorPage extends Component {
  render() {
    return (
      <Wrapper>
        <WrapperLayer />
        {this.props.location.state && this.props.location.state.accessDenied ? (
          <>
            <ErrorWrapper>
              <ErrorCode>403</ErrorCode>
              <Header>{t('accessDenied')}</Header>
              <Paragraph>{t('noPermission')}</Paragraph>
              <Paragraph>{t('notFoundErrorMessage')}</Paragraph>
              <Link onClick={() => this.props.history.goBack()}>
                {t('goBack')}
              </Link>
            </ErrorWrapper>
          </>
        ) : (
          <>
            <ErrorWrapper>
              <ErrorCode>404</ErrorCode>
              <Header>{t('pageNotFound')}</Header>
              <Paragraph>{t('notFoundErrorMessage')}</Paragraph>
              <Link to={HOME_PAGE}>{t('goToHomepage')}</Link>
            </ErrorWrapper>
          </>
        )}
      </Wrapper>
    );
  }
}

export default withRouter(withTranslation('iamip')(ErrorPage));
