import styled from 'styled-components';
import { DEFAULT_BLUE, LIGHT_GRAY, WHITE } from 'constants/colors';

export const FormWrapper = styled.div`
  height: 83vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(min-content, max-content);
`;

export const ScrollableSection = styled.div`
  overflow: auto;
  background: white;
  padding: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContributorsWrapper = styled.div`
  flex: 1 49%;
`;
export const AddContributorsWrapper = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  max-width: 90%;
  flex: 1;
`;

export const IconWrapper = styled.span`
  font-size: 21px;
  margin-right: 10px;
  color: ${DEFAULT_BLUE};
`;

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AttachmentsContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 30px;
`;

export const Comment = styled.div`
  margin-bottom: 20px;
  span {
    font-weight: 900;
  }
`;

export const FigureWrapper = styled.div`
  position: relative;
  padding: 10px 0;
  margin: 20px 0;
  background-color: ${WHITE};
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  padding: 10px;

  img {
    width: 100px;
    max-height: 100px;
  }
  input[type='file'] {
    max-width: 100px;
    overflow: hidden;
    opacity: 0;
    z-index: 1;
  }
  button {
    position: absolute;
    left: 130px;
  }
`;

export const FigureIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Label = styled.p`
  margin-bottom: 10px;
`;
export const UsersWrapper = styled.div`
  flex: 1;
  max-width: 49%;
`;
