import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SelectType = ({ field, onChange, disabled }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();

  useEffect(() => {
    if (field.value) {
      setValue(field.value);
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (fieldId, value) => {
    let options = '';
    if (Array.isArray(value)) {
      options = value.map(el => ({ label: el.value, value: el.value }));
    } else {
      options = value !== null ? [value] : [];
    }
    setValue(options);
    onChange(fieldId, options);
  };

  return (
    <Select
      title={t('chooseOption')}
      options={field.options.fields}
      isMulti={field.options.multiselect}
      onChange={value => handleSelect(field.id, value)}
      isDisabled={disabled}
      value={value}
      menuContainerStyle={{ zIndex: 999 }}
    />
  );
};

SelectType.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default SelectType;
