import React, { useState } from 'react';
import FileBase64 from 'Elements/Attachments/FileBase64';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addFigureToInvention } from 'actions/newInventionFormActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

import Button from 'Atoms/Button';
import { useTranslation } from 'react-i18next';

import { FigureWrapper, Label, FigureIconWrapper } from './styles';

const UploadFigure = ({ addFigureToInvention, inventionId, figure }) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState(null);
  const addFigure = file => {
    addFigureToInvention(file.base64, inventionId);
  };

  const handleChange = file => {
    setImageUrl(URL.createObjectURL(file.file));
    localStorage.setItem('figureImage', file.base64);
  };

  return (
    <FigureWrapper>
      <Label>{t('representativeFigure')}</Label>

      <FigureIconWrapper>
        {figure || imageUrl ? (
          <img alt="" src={figure ? `${figure}` : imageUrl} />
        ) : (
          <FontAwesomeIcon icon={faImage} size={'6x'} />
        )}
        <FileBase64 onDone={!isNaN(inventionId) ? addFigure : handleChange} allow={["image/png", "image/jpeg", "image/bmp"]} />

        <Button colorVariant="green" value={t('uploadFile')} />
      </FigureIconWrapper>
    </FigureWrapper>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addFigureToInvention }, dispatch);

export default connect(null, mapDispatchToProps)(UploadFigure);
