import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { setField } from 'actions/newInventionFormActions';
import { getField } from 'store/newInventionForm/newInventionFormSelectors';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { connect } from 'react-redux';

const SelectType = ({ field, fieldState, setField, answers }) => {
  const { t } = useTranslation();
  const [selectValues, setSelectValues] = useState([]);

  let newValues = [];

  const setFunction = values => {
    if (values && Array.isArray(values)) {
      newValues = values.map(valueObject => ({
        value: valueObject.value ? valueObject.value : valueObject.label,
        label: valueObject.value ? valueObject.value : valueObject.label
      }));
    } else {
      newValues = newValues.concat(values);
    }
    setSelectValues(newValues);
    setField(field.attributes.id, newValues);
  };

  useEffect(() => {
    if (answers) {
      if (Array.isArray(answers.data.attributes.value)) {
        // eslint-disable-next-line
        newValues = answers.data.attributes.value.map(el => ({
          value: el.label,
          label: el.label
        }));
      }
      setSelectValues(newValues);
      setField(field.attributes.id, newValues);
    }
    if (fieldState) {
      setFunction(fieldState.value);
    }
  }, []);

  return (
    <div>
      <FormGroup>
        <Select
          title={t('chooseOption')}
          options={field.attributes.settings.options}
          isMulti={field.attributes.settings.multiselect}
          onChange={setFunction}
          value={selectValues}
        />
      </FormGroup>
    </div>
  );
};

const mapStateToProps = createPropsSelector({
  fieldState: getField
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setField
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectType);
