import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Modal from 'Atoms/Modal';
import RichText from 'Atoms/RichText';
import Checkbox from 'Atoms/Checkbox';
import Button from 'Atoms/Button';

import { ConfidentialityWrapper } from './styles';

class AddNewNoteModal extends Component {
  state = {
    isConfidential: false,
    note: null
  };

  handleTextChange = e => this.setState({ note: e });

  handleIsConfidential = () =>
    this.setState(prevState => ({ isConfidential: !prevState.isConfidential }));

  handleAdd = () => {
    let payload = {
      note: this.state.note,
      isConfidential: this.state.isConfidential
    };
    if (this.props.noteId) {
      payload.parent = {
        id: this.props.noteId,
        type: 'note'
      };
      payload.recipient = {
        id: this.props.authorId,
        type: 'user'
      };
    }

    this.props.addNewNote(payload);

    this.props.onCancel();
  };

  renderContent = () => {
    const { t } = this.props;

    return (
      <Fragment>
        <RichText onChange={this.handleTextChange} />
        <ConfidentialityWrapper>
          <Checkbox
            label={t('shouldBeConfidential')}
            checked={this.state.isConfidential}
            onCheck={this.handleIsConfidential}
          />
        </ConfidentialityWrapper>
      </Fragment>
    );
  };

  renderFooter = () => (
    <Fragment>
      <Button value={this.props.t('cancel')} onClick={this.props.onCancel} />
      <Button
        colorVariant="green"
        disabled={!this.state.note}
        value={this.props.noteId ? this.props.t('reply') : this.props.t('save')}
        onClick={this.handleAdd}
      />
    </Fragment>
  );

  render() {
    const { t } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        title={this.props.noteId ? t('replyNote') : t('addNewNote')}
        onHide={this.props.onCancel}
        content={this.renderContent()}
        footer={this.renderFooter()}
      />
    );
  }
}

AddNewNoteModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  addNewNote: PropTypes.func,
  noteId: PropTypes.string,
  authorId: PropTypes.string
};

export default withTranslation('iamip')(AddNewNoteModal);
