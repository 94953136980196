import typeToReducer from 'type-to-reducer';

export const FETCH_FORM_TYPE_DATA = 'formType/FETCH_FORM_TYPE_DATA';

export const FETCH_FORM_TYPE_DATA_SUCCESS =
  'formType/FETCH_FORM_TYPE_DATA_SUCCESS';

export const UNLOCK_FORM_TYPE = 'formType/UNLOCK_FORM_TYPE';

export const ADD_NEW_SECTION = 'formType/ADD_NEW_SECTION';
export const ADD_NEW_SECTION_SUCCESS = 'formType/ADD_NEW_SECTION_SUCCESS';

export const REMOVE_SECTION = 'formType/REMOVE_SECTION';
export const REMOVE_SECTION_SUCCESS = 'formType/REMOVE_SECTION_SUCCESS';

export const EDIT_SECTION = 'formType/EDIT_SECTION';
export const EDIT_FORM_TYPE_META_DATA = 'formType/EDIT_FORM_TYPE_META_DATA';
export const EDIT_FORM_TYPE_META_DATA_SUCCESS =
  'formType/EDIT_FORM_TYPE_META_DATA_SUCCESS';

export const EDIT_SECTION_SUCCESS = 'formType/EDIT_SECTION_SUCCESS';

export const ADD_NEW_FORM_TYPE = 'formType/ADD_NEW_FORM_TYPE';

export const ADD_NEW_FIELD = 'formType/ADD_NEW_FIELD';
export const ADD_NEW_FIELD_SUCCESS = 'formType/ADD_NEW_FIELD_SUCCESS';

export const EDIT_FIELD = 'formType/EDIT_NEW_FIELD';
export const EDIT_FIELD_SUCCESS = 'formType/EDIT_FIELD_SUCCESS';

export const REMOVE_FIELD = 'formType/REMOVE_FIELD';
export const REMOVE_FIELD_SUCCESS = 'formType/REMOVE_FIELD_SUCCESS';

export const CLEAR_FORM_TYPE_DATA = 'formType/CLEAR_FROM_TYPE_DATA';
export const ADD_NEW_FORM_TYPE_SUCCESS = 'formType/ADD_NEW_FORM_TYPE_SUCCESS';
export const ADD_NEW_FORM_TYPE_FAILURE = 'formType/ADD_NEW_FORM_TYPE_FAILURE';

const defaultState = {
  data: {
    id: '',
    type: 'FORM_TYPE',
    attributes: {
      name: '',
      form: {
        data: {
          id: '',
          type: 'form',
          attributes: {
            name: '',
            sections: []
          }
        }
      },
      creator: {
        data: {
          id: '',
          type: 'User',
          attributes: {
            email: '',
            first_name: '',
            last_name: '',
            avatar_url: '',
            language: 'en',
            language_preference: []
          }
        }
      },
      is_locked: true,
      locked_by: {
        data: {
          id: '',
          type: '',
          attributes: {
            email: '',
            first_name: null,
            last_name: null,
            avatar_url: null,
            language: null,
            language_preference: null
          }
        }
      },
      locked_until: ''
    }
  },

  isLoading: false,
  isFormCreating: false
};

export default typeToReducer(
  {
    [FETCH_FORM_TYPE_DATA]: state => ({
      ...state,
      data: {
        ...state.data
      },
      isLoading: true
    }),
    [FETCH_FORM_TYPE_DATA_SUCCESS]: (state, { formType }) => ({
      ...state,
      data: formType,
      isLoading: false
    }),
    [ADD_NEW_SECTION_SUCCESS]: (state, { section }) => {
      const { data } = state;
      const attributes = { ...state.data.attributes };
      attributes.form.data.attributes.sections =
        attributes.form.data.attributes.sections.concat(section);

      return {
        ...state,
        data: {
          ...data,
          attributes: attributes
        }
      };
    },
    [EDIT_SECTION_SUCCESS]: (state, { section }) => {
      const { data } = state;
      const attributes = { ...state.data.attributes };
      attributes.form.data.attributes.sections =
        attributes.form.data.attributes.sections.map(el =>
          el.data.id === section.data.id ? section : el
        );
      return {
        ...state,
        data: {
          ...data,
          attributes: attributes
        }
      };
    },
    [REMOVE_SECTION_SUCCESS]: (state, { sectionId }) => {
      const { data } = state;
      const attributes = { ...state.data.attributes };
      attributes.form.data.attributes.sections =
        attributes.form.data.attributes.sections.filter(
          section => section.data.id !== sectionId
        );
      return {
        ...state,
        data: {
          ...data,
          attributes: attributes
        }
      };
    },
    [EDIT_FORM_TYPE_META_DATA_SUCCESS]: (state, { formType }) => ({
      ...state,
      data: formType.data
    }),

    [ADD_NEW_FIELD_SUCCESS]: (state, { field, sectionId }) => {
      const { data } = state;
      const sections = [...data.attributes.form.data.attributes.sections];

      sections.map(el => {
        if (el.data.id === sectionId) {
          el.data.attributes.fields.splice(
            field.data.attributes.position,
            0,
            field
          );
        }
        return el;
      });

      return {
        ...state,
        data: {
          ...data,
          attributes: {
            ...data.attributes,
            form: {
              ...data.attributes.form,
              data: {
                ...data.attributes.form.data,
                attributes: {
                  ...data.attributes.form.data.attributes,
                  sections: sections
                }
              }
            }
          }
        }
      };
    },
    [EDIT_FIELD_SUCCESS]: (state, { field, sectionId, sourceIndex }) => {
      const { data } = state;
      const editedFieldPosition = field.data.attributes.position;
      const sections = [...data.attributes.form.data.attributes.sections];
      const editedSection = sections.find(el => el.data.id === sectionId);
      let fields = [...editedSection.data.attributes.fields];

      fields = fields.map(el => {
        if (
          el.data.id === field.data.id &&
          el.data.attributes.position === editedFieldPosition
        ) {
          return field;
        }
        return el;
      });

      if (sourceIndex !== null) {
        const deletedElement = fields.splice(sourceIndex, 1)[0];
        fields.splice(editedFieldPosition, 0, deletedElement);

        // eslint-disable-next-line array-callback-return
        fields.map((el, index) => {
          el.data.attributes.position = index;
        });
      }

      editedSection.data.attributes.fields = fields;

      sections.map(el => (el.data.id === sectionId ? editedSection : el));

      return {
        ...state,
        data: {
          ...data,
          attributes: {
            ...data.attributes,
            form: {
              ...data.attributes.form,
              data: {
                ...data.attributes.form.data,
                attributes: {
                  ...data.attributes.form.data.attributes,
                  sections: sections
                }
              }
            }
          }
        }
      };
    },
    [REMOVE_FIELD_SUCCESS]: (state, { fieldId, sectionId }) => {
      const { data } = state;
      const sections = [...data.attributes.form.data.attributes.sections];

      sections.map(el => {
        if (el.data.id === sectionId) {
          el.data.attributes.fields = el.data.attributes.fields.filter(
            field => field.data.id !== fieldId
          );
        }
        return el;
      });

      return {
        ...state,
        data: {
          ...data,
          attributes: {
            ...data.attributes,
            form: {
              ...data.attributes.form,
              data: {
                ...data.attributes.form.data,
                attributes: {
                  ...data.attributes.form.data.attributes,
                  sections: sections
                }
              }
            }
          }
        }
      };
    },
    [CLEAR_FORM_TYPE_DATA]: state => ({
      ...state,
      data: defaultState.data,
      isLoading: true
    }),
    [ADD_NEW_FORM_TYPE]: state => ({
      ...state,
      isFormCreating: true
    }),
    [ADD_NEW_FORM_TYPE_SUCCESS]: state => ({
      ...state,
      isFormCreating: false
    }),
    [ADD_NEW_FORM_TYPE_FAILURE]: state => ({
      ...state,
      isFormCreating: false
    })
  },
  defaultState
);

export const addSection = section => ({
  type: ADD_NEW_SECTION,
  section
});

export const addSectionSuccess = section => ({
  type: ADD_NEW_SECTION_SUCCESS,
  section
});

export const editSection = (section, sectionId) => ({
  type: EDIT_SECTION,
  section,
  sectionId
});

export const editSectionSuccess = section => ({
  type: EDIT_SECTION_SUCCESS,
  section
});
export const removeSection = sectionId => ({
  type: REMOVE_SECTION,
  sectionId
});

export const removeSectionSuccess = sectionId => ({
  type: REMOVE_SECTION_SUCCESS,
  sectionId
});

export const editFormTypeMetaData = (formTypeData, formTypeId) => ({
  type: EDIT_FORM_TYPE_META_DATA,
  formTypeData,
  formTypeId
});
export const editFormTypeMetaDataSuccess = formType => ({
  type: EDIT_FORM_TYPE_META_DATA_SUCCESS,
  formType
});

export const addNewFormType = (newFormType, formType) => ({
  type: ADD_NEW_FORM_TYPE,
  newFormType,
  formType
});

export const addNewFormTypeSuccess = () => ({
  type: ADD_NEW_FORM_TYPE_SUCCESS
});

export const addNewFormTypeFailure = () => ({
  type: ADD_NEW_FORM_TYPE_FAILURE
});

export const addNewField = field => ({
  type: ADD_NEW_FIELD,
  field
});

export const addNewFieldSuccess = (field, sectionId) => ({
  type: ADD_NEW_FIELD_SUCCESS,
  field,
  sectionId
});

export const editField = (fieldId, field, sourceIndex, sectionId) => ({
  type: EDIT_FIELD,
  fieldId,
  field,
  sourceIndex,
  sectionId
});

export const editFieldSuccess = (field, sectionId, sourceIndex) => ({
  type: EDIT_FIELD_SUCCESS,
  field,
  sectionId,
  sourceIndex
});

export const removeField = (fieldId, sectionId) => ({
  type: REMOVE_FIELD,
  fieldId,
  sectionId
});
export const removeFieldSuccess = (fieldId, sectionId) => ({
  type: REMOVE_FIELD_SUCCESS,
  fieldId,
  sectionId
});

export const fetchFormTypeData = (formTypeId, isInventionType) => ({
  type: FETCH_FORM_TYPE_DATA,
  formTypeId,
  isInventionType
});

export const fetchFormTypeDataSuccess = formType => ({
  type: FETCH_FORM_TYPE_DATA_SUCCESS,
  formType
});

export const unlockFormType = (formTypeId, isInventionType) => ({
  type: UNLOCK_FORM_TYPE,
  formTypeId,
  isInventionType
});

export const clearFormTypeData = () => ({
  type: CLEAR_FORM_TYPE_DATA
});
