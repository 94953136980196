import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { getInventionAttachments } from 'store/singleInvention/singleInventionSelectors';

export const getIsFetching = state => state.inventionAssessments.isFetching;

export const getIsSingleAssessmentFetching = state =>
  state.inventionAssessments.isSingleAssessmentFetching;

export const getInventionAssessments = createSelector(
  state => state.inventionAssessments.assessments,
  assessments => assessments || {}
);

export const getAssessmentTemplates = state =>
  state.inventionAssessments.templates;

export const getIsFetchingTemplates = state =>
  state.inventionAssessments.isFetchingTemplates;

export const getIsExpertAssigning = state =>
  state.inventionAssessments.isAssigningExpert;

export const getAssessment = state =>
  state.inventionAssessments.singleAssessment;

export const getAssessmentFields = createSelector(
  state => getAssessment(state),
  assessment =>
    (!isEmpty(assessment) &&
      !isEmpty(
        assessment.survey.data.attributes.form.data.attributes.sections
      ) &&
      assessment.survey.data.attributes.form.data.attributes.sections[0].data
        .attributes.fields) ||
    []
);

export const getAssessmentExpertId = createSelector(
  state => getAssessment(state),
  assessment => (!isEmpty(assessment) && assessment.expert.data.id) || ''
);

export const getAssessmentReviewManagerId = createSelector(
  state => getAssessment(state),
  assessment =>
    (!isEmpty(assessment) &&
      !isEmpty(assessment.invention.data.attributes.review_manager) &&
      assessment.invention.data.attributes.review_manager.data.id) ||
    null
);

export const getAssessmentLatestAnswers = createSelector(
  state => getAssessment(state),
  assessment =>
    (!isEmpty(assessment) && assessment.survey.data.attributes.answers) || {}
);

export const getAssessmentName = createSelector(
  state => getAssessment(state),
  assessment =>
    (!isEmpty(assessment) &&
      assessment.survey.data.attributes.form.data.attributes.name) ||
    ''
);

export const getAssessmentSurveyId = createSelector(
  state => getAssessment(state),
  assessment => (!isEmpty(assessment) && assessment.survey.data.id) || null
);

export const getAssessmentSurveyName = createSelector(
  state => getAssessment(state),
  assessment =>
    !isEmpty(assessment) &&
    assessment.survey.data.attributes.form.data.attributes.name
);

export const getAssessmentStatus = createSelector(
  state => getAssessment(state),
  assessment => (!isEmpty(assessment) && assessment.status) || null
);

export const getAssessmentReview = createSelector(
  state => getAssessment(state),
  assessment =>
    (!isEmpty(assessment) &&
      !isEmpty(assessment.survey.data.attributes.reviews) &&
      assessment.survey.data.attributes.reviews[0].data.attributes.comment) ||
    ''
);

export const getAssessmentProposalData = createSelector(
  state => getAssessment(state),
  assessment =>
    !isEmpty(assessment) && {
      status: assessment.decision_proposal_status,
      explanation: assessment.decision_proposal
    }
);

export const getAssessmentAttachments = createSelector(
  state => getAssessment(state),
  state => getInventionAttachments(state),
  (assessment, attachments) => {
    const assessmentId = !isEmpty(assessment) && assessment.id.split('/');

    return attachments?.filter(
      attachment =>
        (attachment.data.attributes.relation === 'assessment' &&
          attachment.data.attributes.relation_id ===
            Number(assessmentId[assessmentId.length - 1])) ||
        attachment.data.attributes.relation === 'invention'
    );
  }
);

export const getIsAssessmentBeingAnswered = state =>
  state.inventionAssessments.isAssessmentBeingAnswered;

export const getAssessmentsCounter = state =>
  state.inventionAssessments.assessmentsCounter;

export const getPreassessmentsCounter = state =>
  state.inventionAssessments.preassessmentsCounter;

export const getUnassignedIdeasCounter = state =>
  state.inventionAssessments.unassignedIdeasCounter;
