import styled from 'styled-components';
import { LIGHT_GRAY, DARK_GRAY } from 'constants/colors';

export const asideStyle = collapsed => ({
  top: '50px',
  minHeight: '100%',
  width: collapsed ? '100px' : '240px',
  marginLeft: 0,
  overflow: 'hidden',
  boxShadow: '0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)',
  zIndex: 0,
  backgroundColor: `${DARK_GRAY}`
});

export const contentWrapperStyle = (collapsed, isTabletSize) => ({
  minHeight: 'calc(100vh - 50px)',
  overflow: 'auto',
  marginLeft: isTabletSize
    ? collapsed
      ? '0px'
      : '240px'
    : collapsed
    ? '100px'
    : '240px',
  borderTop: `1px solid ${LIGHT_GRAY}`
});

export const Content = styled.div`
  ${props =>
    props.isListPage
      ? `margin: ${props.top ? props.top + 80 : 80}px 20px 20px 20px`
      : `margin-top: 80px`};
`;
