import typeToReducer from 'type-to-reducer';
import { getInventionTypesRoutine } from 'actions/inventionTypesActions';

const defaultState = {
  data: [],
  pagination: null,
  meta: null,
  loading: false
};

export default typeToReducer(
  {
    [getInventionTypesRoutine.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [getInventionTypesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      pagination: payload.pagination,
      meta: payload.meta,
      loading: false
    }),
    [getInventionTypesRoutine.FAILURE]: state => ({ ...state }),
    [getInventionTypesRoutine.FULFILL]: state => ({
      ...state,
      loading: false
    })
  },
  defaultState
);
