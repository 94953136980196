const getInventionTypesData = state => state.inventionTypes.data;
const getInventionTypesPagination = state => state.inventionTypes.pagination;
const getInventionTypesMeta = state => state.inventionTypes.meta;
const getInventionTypesLoading = state => state.inventionTypes.loading;

export {
  getInventionTypesData,
  getInventionTypesMeta,
  getInventionTypesPagination,
  getInventionTypesLoading
};
