import { createGlobalStyle } from 'styled-components';

import {
  ACTIVE_BLUE_PRIMARY,
  ACTIVE_BLUE_SECONDARY,
  BLACK,
  DEFAULT_BLUE,
  DEFAULT_RED,
  ERROR_RED_PRIMARY,
  ERROR_RED_SECONDARY,
  INPUT_BORDER_GRAY,
  LIGHT_GRAY_SECONDARY,
  SILVER_GRAY,
  SUCCESS_GREEN_PRIMARY,
  SUCCESS_GREEN_SECONDARY,
  WHITE
} from './constants/colors';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Lato', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${BLACK} !important;
  }

  code {
    font-family: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  button {
    font-family: 'Lato', sans-serif;
  }

  li > a {
    color: ${BLACK} !important;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${DEFAULT_BLUE};
    border-radius: 15px;
  }

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }

  .active-item > a, .active-item:hover > a {
    background-color: ${ACTIVE_BLUE_SECONDARY};
    color: ${WHITE} !important;
  }

  .card-body {
    overflow-x: auto;
  }

  .modal-header {
    font-weight: 600 !important;
    letter-spacing: 0.25px;
  }

  // navigation tabs
  .nav-tabs {
    margin-bottom: 30px;

    .icon-wrapper {
      padding: 0 10px;
    }
  }

  .nav-item {
    color: ${BLACK};
    display: flex;
    align-items: center;
  }

  .nav-item svg {
    margin-left: 5px;
  }

  .nav-item.active {
    background-color: ${SILVER_GRAY} !important;
    color: ${WHITE} !important;
  }

  .nav-item.active:hover {
    background-color: ${ACTIVE_BLUE_PRIMARY} !important;
    color: ${WHITE} !important;
  }

  .nav-item:hover {
    color: ${ACTIVE_BLUE_PRIMARY};
  }

  .no-margin {
    margin-bottom: 0 !important;
  }

  // calendar & datepicker
  .react-date-picker {
    line-height: 1.5;
    height: 38px;
    width: 100%;
  }

  .react-date-picker__wrapper {
    border: 1px solid ${INPUT_BORDER_GRAY};
    border-radius: .25rem;
    line-height: 1.5;
    padding: 2px 8px;
    height: 38px;
  }

  .react-calendar__tile--now {
    background: ${ACTIVE_BLUE_PRIMARY};
  }

  .react-calendar__tile--now:enabled:hover, 
  .react-calendar__tile--now:enabled:focus {
    background: ${LIGHT_GRAY_SECONDARY};
  }

  .react-date-picker__button__icon {
    stroke: ${INPUT_BORDER_GRAY};
  }

  .react-date-picker__required {
    border: 1px solid ${DEFAULT_RED};
  }

  // toast alerts
  .Toastify__toast--success {
    background: ${SUCCESS_GREEN_PRIMARY} !important;
  }

  .Toastify__progress-bar--success {
    background: ${SUCCESS_GREEN_SECONDARY} !important;
  }

  .Toastify__toast--error {
    background: ${ERROR_RED_PRIMARY} !important;
  }

  .Toastify__progress-bar--error {
    background: ${ERROR_RED_SECONDARY} !important;
  }

  // timeline
  .vertical-timeline.vertical-timeline-custom-line::before {
    background: ${LIGHT_GRAY_SECONDARY};
  }

  .vertical-timeline-element-icon {
    background: ${ACTIVE_BLUE_SECONDARY} !important;
  }
`;

export default GlobalStyle;
