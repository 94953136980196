import typeToReducer from 'type-to-reducer';

import { authLogout, getLoginDetails } from 'store/login/routines';
import { fetchOrganisationUsers } from 'actions/organisationActions';
import { setCookie, ORGANISATION_ID } from 'helpers/cookies';

import { fetchUserData } from './routines';

const defaultState = {
  currentUser: {},
  experts: [],
  inventors: [],
  decisionMakers: [],
  reviewManagers: [],
  reviewCoordinators: [],
  isUserLoading: false,
  isLoading: false,
  areUsersFetched: false
};

const reduceUsers = users => {
  const usersToReduce = Array.isArray(users) ? users : Object.values(users);

  return usersToReduce.reduce(
    (acc, user) => [...acc, { ...user.data.attributes, id: user.data.id }],
    []
  );
};

export default typeToReducer(
  {
    [getLoginDetails.SUCCESS]: (state, { payload }) => {
      setCookie(
        ORGANISATION_ID,
        payload.attributes.organisation.data.attributes.id
      );
      return {
        ...state,
        currentUser: payload
      };
    },

    [fetchUserData.TRIGGER]: state => ({ ...state, isUserLoading: true }),
    [fetchUserData.SUCCESS]: (state, { payload }) => ({
      ...state,
      isUserLoading: false,
      currentUser: payload
    }),
    [fetchUserData.FAILURE]: state => ({ ...state, isUserLoading: false }),
    [fetchOrganisationUsers.TRIGGER]: state => ({ ...state, isLoading: true }),
    [fetchOrganisationUsers.SUCCESS]: (state, { payload }) => {
      if (!payload) {
        return { ...state, isLoading: false, areUsersFetched: true };
      }

      const { attributes } = payload;

      return {
        ...state,
        isLoading: false,
        areUsersFetched: true,
        experts: reduceUsers(attributes.experts),
        inventors: reduceUsers(attributes.inventors),
        decisionMakers: reduceUsers(attributes.decision_makers),
        reviewManagers: reduceUsers(attributes.review_managers),
        reviewCoordinators: reduceUsers(attributes.review_coordinators)
      };
    },
    [fetchOrganisationUsers.FAILURE]: state => ({ ...state, isLoading: false }),
    [authLogout.SUCCESS]: () => defaultState
  },
  defaultState
);
