import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { IconWrapper, RequiredLabel, InputLabel } from './styles';

const Input = ({
  type,
  label,
  placeholder,
  width,
  onChange,
  value,
  disabled,
  delay,
  isFocused,
  icon,
  isRequired,
  borderColor,
  maxLength,
  minValue,
  maxValue
}) => {
  const { t } = useTranslation();
  const styles = {
    ...(width && { width: width }),
    ...(icon && { display: 'flex', 'align-items': 'center' })
  };
  const onChangeHandler = useCallback(debounce(onChange, 500), []);
  const handleChange = e => {
    onChange && delay
      ? onChangeHandler(e.target.value)
      : onChange(e.target.value);
  };
  return (
    <div className="form-group" style={styles}>
      {label && <InputLabel className="form-check-label">{label}</InputLabel>}
      <input
        type={type || 'text'}
        pattern={type === 'number' ? 'd*' : ''}
        className="form-control"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        readOnly={disabled}
        maxLength={maxLength}
        min={minValue}
        max={maxValue}
        style={{
          ...(icon && {
            'border-right': 'none',
            'border-top-right-radius': 0,
            'border-bottom-right-radius': 0
          }),
          ...(borderColor && { borderColor })
        }}
      />
      {icon && <IconWrapper isFocused={isFocused}>{icon}</IconWrapper>}
      {isRequired && <RequiredLabel>{t('requiredField')}</RequiredLabel>}
    </div>
  );
};
Input.propTypes = {
  type: PropTypes.oneOf(['email', 'password', 'number']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  delay: PropTypes.bool,
  borderColor: PropTypes.any,
  maxLength: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number
};
export default Input;
