import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Input from 'Atoms/Input';
import UsersProposalSelect from '../UsersProposalSelect';
import Attachments from 'Elements/Attachments';
import UploadFigure from './UploadFigure';
import Sections from 'Pages/CreateInventionPage/Elements/Sections';

import { STATUS_DRAFT } from 'constants/inventionStatuses';
import { DEFAULT_RED } from 'constants/colors';

import {
  ScrollableSection,
  AddContributorsWrapper,
  AdditionalInfo,
  AttachmentsContainer,
  Comment,
  UsersWrapper
} from 'Pages/CreateInventionPage/Elements/Form/styles';

const FormScrollableSection = ({
  action,
  allowCoinventors,
  answers,
  attachments,
  coInventors,
  currentSectionIndex,
  experts,
  figure,
  formModel,
  inventors,
  inventionId,
  name,
  proposedExperts,
  proposedReviewManager,
  reviews,
  reviewManagers,
  sectionsLength,
  setCurrentSectionIndex,
  setStateField,
  unfilledFields
}) => {
  const { t } = useTranslation();

  const renderProposalsOptions = [
    {
      usersGroup: inventors,
      addedUsers: coInventors,
      title: 'addOtherContributors',
      type: 'co_inventors',
      shouldDisplay: allowCoinventors
    },
    {
      usersGroup: reviewManagers,
      addedUsers: proposedReviewManager,
      title: 'proposeReviewManager',
      type: 'proposed_review_managers',
      shouldDisplay: true
    },
    {
      usersGroup: experts,
      addedUsers: proposedExperts,
      title: 'proposeExperts',
      type: 'proposed_experts',
      shouldDisplay: true
    }
  ];

  return (
    <ScrollableSection>
      <>
        <Input
          placeholder={t('name')}
          label={t('name')}
          value={name}
          onChange={value => setStateField('name', value)}
          isRequired
          borderColor={unfilledFields.includes('name') && DEFAULT_RED}
        />
        <UploadFigure inventionId={inventionId} figure={figure} />
      </>
      {reviews.data.attributes.comment && (
        <Comment>
          <span>{t('generalReviewComment')}: </span>
          {reviews.data.attributes.comment}
        </Comment>
      )}
      <Sections
        sections={formModel.attributes.sections}
        answers={answers}
        reviews={reviews}
        currentSectionIndex={currentSectionIndex}
        setCurrentSectionIndex={setCurrentSectionIndex}
        unfilledFields={unfilledFields}
      />
      <>
        <AttachmentsContainer>
          <Attachments
            attachments={attachments}
            isEdited
            type={STATUS_DRAFT}
            inventionId={inventionId}
          />
        </AttachmentsContainer>
        {currentSectionIndex === sectionsLength && (
          <AdditionalInfo>
            <UsersWrapper>
              {renderProposalsOptions.map(
                el =>
                  el.shouldDisplay && (
                    <AddContributorsWrapper>
                      <UsersProposalSelect
                        usersGroup={el.usersGroup}
                        action={action}
                        addedUsers={el.addedUsers}
                        title={el.title}
                        type={el.type}
                      />
                    </AddContributorsWrapper>
                  )
              )}
            </UsersWrapper>
          </AdditionalInfo>
        )}
      </>
    </ScrollableSection>
  );
};

FormScrollableSection.propTypes = {
  action: PropTypes.func,
  allowCoinventors: PropTypes.bool,
  answers: PropTypes.string,
  attachments: PropTypes.array,
  coInventors: PropTypes.array
};

export default FormScrollableSection;
