import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { changeInventionStatus } from 'actions/singleInventionActions';
import { getExperts } from 'store/singleInvention/singleInventionSelectors';

import {
  STATUS_DROP,
  STATUS_PATENT,
  STATUS_KEEP_SECRET,
  STATUS_PUBLISH,
  STATUS_ON_HOLD
} from 'constants/inventionStatuses';
import { decisionProposalStatuses } from 'constants/assessmentStatuses';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Textarea from 'Atoms/Textarea';
import {
  SelectWrapper,
  ExpertAssessmentHeader,
  ExpertDecision,
  IconWrapper,
  ExpertWrapper,
  ExpertName
} from './styles';

const MakeADecisionModal = ({
  isOpen,
  onHide,
  changeInventionStatus,
  inventionId,
  ...props
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState();
  const [decisionStatus, setDecisionStatus] = useState();

  const options = [
    { label: t('drop'), value: STATUS_DROP },
    { label: t('patent'), value: STATUS_PATENT },
    { label: t('keepSecret'), value: STATUS_KEEP_SECRET },
    { label: t('publish'), value: STATUS_PUBLISH },
    { label: t('onHold'), value: STATUS_ON_HOLD }
  ];

  const handleStatusChange = () => {
    const data = {
      data: {
        type: 'invention',
        attributes: {
          status: decisionStatus,
          comment
        }
      }
    };

    changeInventionStatus({ id: inventionId, data });
    onHide();
  };

  const renderExpertAssessment = () => (
    <Fragment>
      <ExpertAssessmentHeader>
        <p>{t('expertAssessment')}</p>
      </ExpertAssessmentHeader>
      {props.experts.length ? (
        props.experts.map((el, index) => (
          <ExpertWrapper key={index}>
            <IconWrapper assessmentStatus={el.assessmentStatus}>
              <FontAwesomeIcon icon={faUserCircle} />
            </IconWrapper>
            <ExpertName>
              <span>{el.fullName}</span>
            </ExpertName>
            <ExpertDecision>
              {el.decisionProposalStatus ? (
                decisionProposalStatuses(t).find(
                  status => status.value === el.decisionProposalStatus
                ).label
              ) : (
                <span>-</span>
              )}
            </ExpertDecision>
          </ExpertWrapper>
        ))
      ) : (
        <span>-</span>
      )}
    </Fragment>
  );

  const renderContent = () => (
    <Fragment>
      <SelectWrapper>
        <Select
          options={options}
          placeholder={t('selectStatus')}
          onChange={e => setDecisionStatus(e.value)}
        />
      </SelectWrapper>
      <Textarea
        label={t('commentYourChange')}
        onChange={setComment}
        isRequired
      />
      {renderExpertAssessment()}
    </Fragment>
  );

  const renderFooter = () => (
    <Fragment>
      <Button value={t('close')} onClick={onHide} />
      <Button
        colorVariant="green"
        disabled={!comment || !decisionStatus}
        value={t('confirm')}
        onClick={handleStatusChange}
      />
    </Fragment>
  );

  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      title={t('makeDecision')}
      content={renderContent()}
      footer={renderFooter()}
    />
  );
};

MakeADecisionModal.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func,
  changeInventionStatus: PropTypes.func,
  inventionId: PropTypes.string,
  experts: PropTypes.array
};

const mapStateToProps = state => ({
  experts: getExperts(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeInventionStatus
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MakeADecisionModal);
