import React, { Component } from 'react';
import { connect } from 'react-redux';
import NewInventionTypeChooseModal from './NewInventionTypeChooseModal';
import { bindActionCreators } from 'redux';
import { getInventionTypesRoutine } from 'actions/inventionTypesActions';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { getInventionTypesData } from 'store/inventionTypes/inventionTypesSelectors';
import { getChooseInventionTypeId } from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';
import { withRouter } from 'react-router-dom';
import { CREATE_INVENTION } from 'constants/routingConstants';
import {
  clearSelectedInventionType,
  hideNewInventionTypeChooseModal
} from 'actions/newInventionTypeChooseModalActions';
import { withTranslation } from 'react-i18next';

class NewInventionTypeChooseModalContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      processedInventionTypes: []
    };
  }

  componentDidMount() {
    this.props.getInventionTypes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.inventionTypes !== this.props.inventionTypes) {
      this.prepareInventionTypesOptions();
    }
  }

  prepareInventionTypesOptions = () => {
    const inventionTypes = this.props.inventionTypes.map(inventionType => ({
      value: inventionType.attributes.id,
      label: inventionType.attributes.name
    }));

    this.setState({
      processedInventionTypes: inventionTypes
    });
  };

  approveInventionType = () => {
    this.props.history.push(CREATE_INVENTION);
    this.props.hideNewInventionTypeChooseModal();
  };

  closeModal = () => {
    this.props.hideNewInventionTypeChooseModal();
    this.props.clearSelectedInventionType();
  };

  render() {
    return (
      <NewInventionTypeChooseModal
        inventionTypes={this.state.processedInventionTypes}
        approveInventionType={this.approveInventionType}
        closeModal={this.closeModal}
      />
    );
  }
}

const mapStateToProps = createPropsSelector({
  inventionTypes: getInventionTypesData,
  chooseInventionTypeId: getChooseInventionTypeId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInventionTypes: getInventionTypesRoutine,
      hideNewInventionTypeChooseModal,
      clearSelectedInventionType
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(NewInventionTypeChooseModalContainer)));
