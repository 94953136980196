import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { createFullInventionAnswer } from 'actions/inventionsActions';

import { createInventionPrepSaga } from 'sagas/createInventionSaga';
import { createAnswerPrepSaga } from 'sagas/createAnswerSaga';
import { toast } from 'react-toastify';
import i18next from 'i18next';

function* createFullInventionAnswerPrepSaga(action) {
  try {
    yield put(createFullInventionAnswer.request());

    yield call(createInventionPrepSaga, action);

    let state = yield select();

    const createdInvention = state.inventions.createInvention;

    if (createdInvention !== null) {
      // adding survey id to action payload for the next request

      action.payload.surveyId =
        createdInvention.attributes.draft.data.attributes.survey.data.id;
      action.payload.inventionId = createdInvention.attributes.id;

      yield call(createAnswerPrepSaga, action);

      state = yield select();

      const createdAnswer = state.createAnswer.data;

      if (createdAnswer !== null) {
        yield put(createFullInventionAnswer.success());
      } else {
        yield call(createAnswerPrepSaga, action);
      }
    }
  } catch (exception) {
    console.log(exception);

    toast.error(i18next.t('fullInventionAnswerCreationError'));

    yield put(createFullInventionAnswer.failure());
  }

  yield put(createFullInventionAnswer.fulfill());
}

export default function* createFullInventionAnswerSaga() {
  yield all([
    takeEvery(
      createFullInventionAnswer.TRIGGER,
      createFullInventionAnswerPrepSaga
    )
  ]);
}
