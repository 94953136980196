import { v4 as uuidv4 } from 'uuid';

import env from './env';

const authParams = {
  client_id: env('CLIENT_ID'),
  redirect_uri: env('REDIRECT_URI'),
  response_type: 'code',
  scope: '',
  state: uuidv4(),
  product_id: 2
};

export const getLoginData = () => {
  let params = '?';
  // eslint-disable-next-line array-callback-return
  Object.entries(authParams).map(([key, value]) => {
    params = params + `${key}=${value}&`;
  });
  const url = `${env('AUTH_URL')}/oauth/authorize${params}`;
  return {
    url,
    state: authParams.state
  };
};

export const changeOrganisationUrl = `${env(
  'AUTH_URL'
)}/organization/switch?client_id=${env('CLIENT_ID')}&redirect_uri=${env(
  'REDIRECT_URI'
)}`;
