import { combineReducers } from 'redux';
import formType from 'store/formType/formTypeReducer';
import loginReducer from 'store/login/loginReducer';
import inventionsReducer from 'store/inventions/inventionsReducer';
import newInventionTypeChooseModalReducer from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalReducer';
import inventionTypesReducer from 'store/inventionTypes/inventionTypesReducer';
import usersReducer from 'store/users/usersReducer';
import singleInventionReducer from 'store/singleInvention/singleInventionReducer';
import formTypeListReducer from 'store/formTypeList/formTypeListReducer';
import organisationReducer from 'store/organisation/organisationReducer';
import newInventionFormReducer from 'store/newInventionForm/newInventionFormReducer';
import createAnswerReducer from 'store/createAnswer/createAnswerReducer';
import inventionAssessmentsReducer from 'store/inventionAssessments/inventionAssessmentsReducer';
import layoutDetailsReducer from 'store/layoutDetails/layoutDetailsReducer';
import filterAndSearchReducer from 'store/filterAndSearch/filterAndSearchReducer';
import { connectRouter } from 'connected-react-router';

export default history =>
  combineReducers({
    router: connectRouter(history), // !!! must be first
    login: loginReducer,
    formType: formType,
    newInventionTypeChooseModal: newInventionTypeChooseModalReducer,
    inventionTypes: inventionTypesReducer,
    inventions: inventionsReducer,
    singleInvention: singleInventionReducer,
    organisation: organisationReducer,
    newInventionForm: newInventionFormReducer,
    formTypeList: formTypeListReducer,
    users: usersReducer,
    createAnswer: createAnswerReducer,
    inventionAssessments: inventionAssessmentsReducer,
    layoutDetails: layoutDetailsReducer,
    filterAndSearch: filterAndSearchReducer
  });
