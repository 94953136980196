import { createSelector } from 'reselect';

export const getAssessmentTypeMetaData = createSelector(
  state => state.formType.data.attributes,
  attributes => [
    { name: attributes.name },
    {
      description: attributes.description
    },
    {
      deadline: attributes.deadline
    }
  ]
);
