import { ADMIN, SUPERVISOR } from 'constants/userRoles';

export const prepareUsers = users =>
  users &&
  users.map(el => ({
    id: el.value,
    type: 'user'
  }));

export const isOnlySupervisor = currentUserRoles =>
  currentUserRoles.every(role => [SUPERVISOR, ADMIN].includes(role));
