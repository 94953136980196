import { createSelector } from 'reselect';
import { getCurrentUserId } from 'store/users/usersSelectors';

export const getSections = createSelector(
  state => state.formType.data,
  data => data.attributes.form.data.attributes.sections
);

export const getNumberOfSections = createSelector(
  state => state.formType.data,
  data => data.attributes.form.data.attributes.sections.length
);

export const getFormTypeId = createSelector(
  state => state.formType.data,
  data => data.id
);

export const getFormTypeName = createSelector(
  state => state.formType.data,
  data => data.attributes.name
);

export const getFormTypePredefinedId = createSelector(
  state => state.formType.data,
  data => data.attributes.number_template_example
);

export const getFormId = createSelector(
  state => state.formType.data,
  data => data.attributes.form.data.id
);
export const getFormTypeLocked = createSelector(
  [state => state.formType.data, getCurrentUserId],
  (data, currentUserId) =>
    (data.attributes.is_locked &&
      data.attributes.locked_by &&
      data.attributes.locked_by.data.id !== currentUserId) ||
    false
);

export const getLockedUntil = createSelector(
  state => state.formType.data,
  data => data.attributes.locked_until
);

export const getIsLoading = state => state.formType.isLoading;

export const getIsFormCreating = state => state.formType.isFormCreating;
