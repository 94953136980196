import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { getLoginData } from 'helpers/auth';
import { HOME_PAGE, LOGIN_PAGE } from 'constants/routingConstants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getLoginState,
  getIsAuth,
  getTryToLoginAgain
} from 'store/login/loginSelectors';
import { saveLoginState } from 'store/login/loginReducer';
import { getLoginDetails } from 'store/login/routines';

import LoadingPage from 'Pages/LoadingPage';

import { getToken, getUserId } from 'helpers/cookies';
import { getUser } from '../../helpers/axios/axiosRequests';

const LoginPage = ({
  saveLoginState,
  getLoginDetails,
  loginState,
  isAuth,
  tryToLoginAgain
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const login = () => {
    const data = getLoginData();
    saveLoginState(data.state);
    window.location.replace(data.url);
  };

  useEffect(() => {
    if (
      !window.location.href.includes('code') &&
      !isAuth &&
      !localStorage.getItem('isLoggedIn')
    ) {
      // eslint-disable-next-line
      const fetchUser = async () => {
        try {
          const userId = getUserId();

          if (!userId) {
            throw Error('User id now found');
          }

          await getUser(userId);
        } catch (err) {
          login();
        }
      };

      fetchUser();
    }
    // eslint-disable-next-line
  }, []);

  const getPreviousPath = () => {
    if (localStorage.getItem('isLoggedOut')) {
      localStorage.removeItem('isLoggedOut');
      return LOGIN_PAGE;
    }

    const previousPath = localStorage.getItem('previousPath');
    return previousPath === LOGIN_PAGE ? HOME_PAGE : previousPath || HOME_PAGE;
  };

  useEffect(() => {
    if (isAuth && getToken()) {
      history.push(getPreviousPath());
    }
    // eslint-disable-next-line
  }, [isAuth]);

  if (window.location.href.includes('code')) {
    const urlParams = new URL(window.location.href).searchParams;

    if (urlParams.get('state') === loginState || !loginState) {
      localStorage.setItem('isLoggedIn', true);
      getLoginDetails(urlParams.get('code'));
    } else {
      toast.error(t('somethingWentWrong'));
    }
  }

  return <LoadingPage login={login} tryToLoginAgain={tryToLoginAgain} />;
};

LoginPage.propTypes = {
  getLoginDetails: PropTypes.func,
  isAuth: PropTypes.bool,
  loginState: PropTypes.string,
  saveLoginState: PropTypes.func,
  tryToLoginAgain: PropTypes.bool
};

const mapStateToProps = state => ({
  loginState: getLoginState(state),
  isAuth: getIsAuth(state),
  tryToLoginAgain: getTryToLoginAgain(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveLoginState,
      getLoginDetails
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
