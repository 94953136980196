import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import ISvgWrapper from 'Atoms/ISvgWrapper';
import logo from 'assets/logo-dark.svg';
import smallLogo from 'assets/logo-small.svg';
import {
  HOME_PAGE,
  INVENTIONS_PAGE,
  NOT_ASSIGNED,
  MY_PRE_ASSESSMENTS,
  KNOWLEDGE_DATABASE,
  MY_ASSESSMENTS,
  ASSESSMENTS_PAGE,
  ALL_ASSESSMENTS
} from 'constants/routingConstants';
import UserMenu from 'Elements/UserMenu';

import {
  ToggleIcon,
  LogoWrapper,
  MainHeaderStyle,
  NavBarStyle
} from './styles';
import Organisation from './Organisation';
import FilterAndSearchNavigation from './FilterAndSearchNavigation';

const Navigation = props => {
  const history = useHistory();
  const displayFilterAndSearchNavigation = [
    INVENTIONS_PAGE,
    NOT_ASSIGNED,
    MY_PRE_ASSESSMENTS,
    KNOWLEDGE_DATABASE,
    MY_ASSESSMENTS,
    ASSESSMENTS_PAGE,
    ALL_ASSESSMENTS
  ].includes(history.location.pathname);

  return (
    <header className="main-header" style={MainHeaderStyle}>
      <LogoWrapper isCollapsed={props.shouldBeCollapsed || props.isTabletSize}>
        <Link to={HOME_PAGE}>
          {props.shouldBeCollapsed || props.isTabletSize ? (
            <ISvgWrapper src={smallLogo} />
          ) : (
            <ISvgWrapper src={logo} />
          )}
        </Link>
      </LogoWrapper>
      <nav
        className="navbar navbar-static-top"
        style={NavBarStyle(props.shouldBeCollapsed)}
      >
        <ToggleIcon onClick={props.collapseClick}>
          <FontAwesomeIcon icon={faBars} />
        </ToggleIcon>
        {displayFilterAndSearchNavigation && <FilterAndSearchNavigation />}
        <Organisation />
        <UserMenu />
      </nav>
    </header>
  );
};

Navigation.propTypes = {
  collapseClick: PropTypes.func,
  shouldBeCollapsed: PropTypes.bool,
  isTabletSize: PropTypes.bool
};

export default Navigation;
