import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from 'Pages/CreateInventionPage/Elements/Form';

const CreateInventionPage = ({
  allowCoinventors,
  attachments,
  formModel,
  reviews
}) => (
  <div>
    <FormContainer
      formModel={formModel}
      allowCoinventors={allowCoinventors}
      attachments={attachments}
      reviews={reviews}
    />
  </div>
);

CreateInventionPage.propTypes = {
  allowCoinventors: PropTypes.bool,
  attachments: PropTypes.array,
  formModel: PropTypes.object,
  reviews: PropTypes.object
};

export default CreateInventionPage;
