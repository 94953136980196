import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTrash, faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  downloadFile,
  deleteAttachment,
  uploadAttachments,
  deleteTemporaryAttachment
} from 'actions/attachmentsActions';

import { EDIT_INVENTION } from 'constants/routingConstants';
import { STATUS_DRAFT } from 'constants/inventionStatuses';
import { BLACK, DEFAULT_BLUE } from 'constants/colors';
import Button from 'Atoms/Button';
import Checkbox from 'Atoms/Checkbox';
import Input from 'Atoms/Input';
import Spinner from 'Atoms/Spinner';
import {
  FileContainer,
  IconWrapper,
  FileName,
  Settings,
  FileNameWrapper,
  RemoveIcon
} from './styles';
import Tooltip from 'react-tooltip-lite';

import {
  getCurrentUserId,
  getCurrentUserFullName
} from 'store/users/usersSelectors';
import {
  getDecisionMaker,
  getReviewManager,
  getIsAttachmentBeingUploaded,
  getIsAttachmentBeingDeleted
} from 'store/singleInvention/singleInventionSelectors';

import {
  getInventionTypeReviewManger,
  getInventionTypeDecisionMaker
} from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';

const Attachment = ({
  downloadFile,
  deleteAttachment,
  uploadAttachments,
  isEdited,
  type,
  name,
  id,
  url,
  toUpload,
  setIsConfidential,
  setDescription,
  index,
  file,
  confidential,
  owner,
  currentUserId,
  reviewManager,
  decisionMaker,
  isAssessmentAttachment,
  currentUserFullName,
  disabled,
  inventionId,
  deleteTemporaryAttachment,
  description,
  isAttachmentBeingUploaded,
  isAttachmentBeingDeleted
}) => {
  const { t } = useTranslation();

  const isInventionId = isNaN(inventionId);

  const sendFile = () => {
    uploadAttachments({ data: file, type: type });
  };

  const removeAttachment = (e, id, type) => {
    e.stopPropagation();
    deleteAttachment({
      id: id,
      type: type,
      isAssessmentAttachment
    });
  };

  return (
    <FileContainer>
      <FileName
        canBeDownloaded={!isEdited && !toUpload}
        onClick={
          !isEdited && !toUpload
            ? () => downloadFile(url, file.data.attributes.file_name)
            : undefined
        }
      >
        <FileNameWrapper>
          <IconWrapper>
            <FontAwesomeIcon color={DEFAULT_BLUE} icon={faFileAlt} />
          </IconWrapper>
          <FileName>
            <p>{name}</p>
            <p>
              {t('owner')}:{' '}
              {owner
                ? `${owner.data.attributes.first_name} 
               ${owner.data.attributes.last_name}`
                : currentUserFullName}
            </p>
            {description && !toUpload && (
              <p>
                {t('description')}: {description}
              </p>
            )}
          </FileName>
          {confidential && !toUpload && (
            <Tooltip
              content={
                <div style={{ maxWidth: 800 }}>
                  {t('confidentialAttachment')}
                </div>
              }
              direction="up"
              useDefaultStyles
            >
              <FontAwesomeIcon icon={faLock} />
            </Tooltip>
          )}
        </FileNameWrapper>
        {!disabled && (
          <>
            {(toUpload
              ? true
              : isInventionId ||
                (owner && owner.data.id === currentUserId) ||
                (reviewManager && reviewManager.id === currentUserId) ||
                (decisionMaker && decisionMaker.id === currentUserId)) && (
              <RemoveIcon>
                {isAttachmentBeingDeleted ? (
                  <Spinner size="14px" color={BLACK} inline center />
                ) : (
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={e =>
                      !isInventionId && !toUpload
                        ? removeAttachment(e, id, type)
                        : deleteTemporaryAttachment({
                            file,
                            index,
                            type:
                              type === STATUS_DRAFT
                                ? 'draft'
                                : isAssessmentAttachment
                                ? 'assessment'
                                : 'invention'
                          })
                    }
                  />
                )}
              </RemoveIcon>
            )}
          </>
        )}
      </FileName>
      {toUpload && (
        <>
          <Input
            label={`${t('description')}:`}
            onChange={e => setDescription(e)}
          />
          <Settings>
            <Checkbox label={t('confidential')} onCheck={setIsConfidential} />
            {!isInventionId && (
              <Button
                colorVariant="green"
                isFetching={isAttachmentBeingUploaded}
                value={t('uploadFile')}
                onClick={sendFile}
              />
            )}
          </Settings>
        </>
      )}
    </FileContainer>
  );
};

Attachment.propTypes = {
  confidential: PropTypes.bool,
  currentUserFullName: PropTypes.string,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decisionMaker: PropTypes.object,
  deleteAttachment: PropTypes.func,
  deleteTemporaryAttachment: PropTypes.func,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  downloadFile: PropTypes.func,
  file: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  inventionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAssessmentAttachment: PropTypes.bool,
  isAttachmentBeingDeleted: PropTypes.bool,
  isAttachmentBeingUploaded: PropTypes.bool,
  isEdited: PropTypes.bool,
  name: PropTypes.string,
  owner: PropTypes.object,
  reviewManager: PropTypes.object,
  setDescription: PropTypes.func,
  setIsConfidential: PropTypes.func,
  toUpload: PropTypes.bool,
  type: PropTypes.number,
  uploadAttachments: PropTypes.func,
  url: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const isEdited = ownProps.location.pathname.includes(EDIT_INVENTION);
  return {
    currentUserFullName: getCurrentUserFullName(state),
    currentUserId: getCurrentUserId(state),
    reviewManager: !isEdited
      ? getReviewManager(state)
      : getInventionTypeReviewManger(state),
    decisionMaker: !isEdited
      ? getDecisionMaker(state)
      : getInventionTypeDecisionMaker(state),
    isAttachmentBeingUploaded: getIsAttachmentBeingUploaded(state),
    isAttachmentBeingDeleted: getIsAttachmentBeingDeleted(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      downloadFile,
      uploadAttachments,
      deleteAttachment,
      deleteTemporaryAttachment
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attachment)
);
