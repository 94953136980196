import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { bindActionCreators } from 'redux';

import {
  fetchSingleAssessment,
  clearSingleAssessment
} from 'actions/inventionAssessmentActions';
import {
  getAssessmentReviewManagerId,
  getAssessmentExpertId,
  getAssessmentStatus,
  getAssessmentAttachments,
  getIsSingleAssessmentFetching
} from 'store/inventionAssessments/inventionAssessmentsSelectors';
import { getCurrentUserId } from 'store/users/usersSelectors';
import { getCurrentInventionStatus } from 'store/singleInvention/singleInventionSelectors';

import { STATUS_ASSESSMENT } from 'constants/attachmentStatuses';
import {
  STATUS_REVIEW,
  STATUS_IN_PROGRESS,
  STATUS_PENDING,
  STATUS_COMPLETED
} from 'constants/assessmentStatuses';
import { STATUS_ASSESSMENT as INVENTION_STATUS_ASSESSMENT } from 'constants/inventionStatuses';

import Spinner from 'Atoms/Spinner';

import SingleAssessmentFields from './SingleAssessmentFields';
import SingleAssessmentDecisionProposal from './SingleAssessmentDecisionProposal';
import Attachments from 'Elements/Attachments';

const SingleAssessmentForm = props => {
  useEffect(() => {
    props.fetchSingleAssessment(props.assessmentId);

    return () => {
      props.clearSingleAssessment();
    };
    // eslint-disable-next-line
  }, []);

  const isNotInventionAssessment =
    props.currentInventionStatus !== INVENTION_STATUS_ASSESSMENT;

  const shouldDisableFormFields =
    [STATUS_REVIEW, STATUS_COMPLETED].includes(props.assessmentStatus) ||
    isNotInventionAssessment ||
    props.currentUserId !== props.assessmentExpertId;

  return props.isLoading ? (
    <Spinner center />
  ) : (
    <Fragment>
      <SingleAssessmentFields
        disabled={
          props.disabled !== undefined
            ? props.disabled
            : shouldDisableFormFields
        }
        isReviewManagerView={
          props.currentUserId === props.reviewManagerId &&
          props.assessmentStatus === STATUS_REVIEW &&
          props.currentInventionStatus === INVENTION_STATUS_ASSESSMENT
        }
        assessmentStatus={props.assessmentStatus}
        emptyForm={props.emptyForm}
        handleFields={props.handleFields}
        setReview={props.setReview}
        unfilledFields={props.unfilledFields}
      >
        <SingleAssessmentDecisionProposal
          disabled={
            props.disabled !== undefined
              ? props.disabled
              : shouldDisableFormFields
          }
          hasRequiredFields={
            props.unfilledFields
              ? !props.unfilledFields.includes('decisionProposal')
              : true
          }
          decisionProposal={props.decisionProposal}
          emptyForm={props.emptyForm}
          handleDecisionProposal={props.setDecisionProposal}
        />
      </SingleAssessmentFields>
      <Attachments
        disabled={
          props.disabled !== undefined
            ? props.disabled
            : ![STATUS_IN_PROGRESS, STATUS_PENDING].includes(
                props.assessmentStatus
              ) ||
              isNotInventionAssessment ||
              props.currentUserId !== props.assessmentExpertId
        }
        assessmentId={props.assessmentId}
        attachments={props.assessmentAttachments}
        inventionId={props.inventionId}
        status={STATUS_ASSESSMENT}
        isAssessmentAttachment
      />
    </Fragment>
  );
};

const mapStateToProps = createPropsSelector({
  currentUserId: getCurrentUserId,
  reviewManagerId: getAssessmentReviewManagerId,
  assessmentExpertId: getAssessmentExpertId,
  assessmentStatus: getAssessmentStatus,
  assessmentAttachments: getAssessmentAttachments,
  currentInventionStatus: getCurrentInventionStatus,
  isLoading: getIsSingleAssessmentFetching
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSingleAssessment,
      clearSingleAssessment
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleAssessmentForm);
