import moment from 'moment';

import { t } from 'helpers/i18n';
import env from 'helpers/env';

import {
  INVENTIONS_PAGE,
  KNOWLEDGE_DATABASE,
  MY_PRE_ASSESSMENTS,
  NOT_ASSIGNED,
  pathTranslations,
  removeSlash
} from 'constants/routingConstants';

import {
  ALL_INVENTION_STATUSES,
  ALL_KNOWLEDGE_DATABASE_STATUSES,
  API_PREFIX
} from 'constants/requestsContants';

import {
  STATUS_ASSIGNMENT,
  STATUS_PRE_ASSESSMENT
} from 'constants/inventionStatuses';

export const checkForStatusFiltering = filters =>
  filters ? Boolean(filters['status[]']) : false;

export const getExportApiUrl = (
  currentUserId,
  exportLocation,
  filterSortParams,
  filters
) => {
  let statusPrefix = '';
  const hasStatusFilteringApplied = checkForStatusFiltering(filters);

  if (hasStatusFilteringApplied) {
    const statusFilters = Object.values(filters?.['status[]']);

    statusPrefix = `?status[]=${statusFilters[0].value}${
      statusFilters.length > 1
        ? statusFilters
            .slice(1)
            .map(status => `&status[]=${status.value}`)
            .join('')
        : ''
    }`;
  } else {
    switch (exportLocation) {
      case removeSlash(INVENTIONS_PAGE):
        statusPrefix = `?${ALL_INVENTION_STATUSES}`;
        break;
      case removeSlash(NOT_ASSIGNED):
        statusPrefix = `?status=${STATUS_ASSIGNMENT}`;
        break;
      case removeSlash(MY_PRE_ASSESSMENTS):
        statusPrefix = `?status=${STATUS_PRE_ASSESSMENT}&reviewManager=${currentUserId}`;
        break;
      case removeSlash(KNOWLEDGE_DATABASE):
        statusPrefix = `?${ALL_KNOWLEDGE_DATABASE_STATUSES}`;
        break;
      default:
        break;
    }
  }

  return `${env(
    'API_URL'
  )}${API_PREFIX}${INVENTIONS_PAGE}${statusPrefix}${filterSortParams}&export_of=${exportLocation}&pagination=false`;
};

export const getExportFilename = exportLocation => {
  const locationName = pathTranslations(t).find(
    p => p.path === removeSlash(exportLocation)
  ).translation;

  return `${t('ideaDatabase')} ${locationName} ${t('export')}-${moment().format(
    'YYYY-MM-DD HH-mm'
  )}.xlsx`;
};
