import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';

const Calendar = ({
  onChange,
  label,
  minDate,
  maxDate,
  disabled,
  borderColor,
  value
}) => {
  const [date, setDate] = useState(value);

  const handleChange = e => {
    setDate(e);
    onChange && onChange(e);
  };

  return (
    <>
      {label && <label className="form-check-label">{label}</label>}
      <DatePicker
        calendarIcon={null}
        className={borderColor && 'react-date-picker__required'}
        clearIcon={null}
        disabled={disabled}
        format="y-MM-dd"
        maxDate={maxDate}
        minDate={minDate}
        value={date}
        onChange={handleChange}
      />
    </>
  );
};

Calendar.propTypes = {
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
};

export default Calendar;
