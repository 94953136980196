import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';

import { getAssessmentProposalData } from 'store/inventionAssessments/inventionAssessmentsSelectors';

import Select from 'react-select';
import Textarea from 'Atoms/Textarea';

import { decisionProposalStatuses } from 'constants/assessmentStatuses';

import { DecisionProposalSection, Label } from './styles';

const SingleAssessmentDecisionProposal = props => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(
    (props.decisionProposal && props.decisionProposal.status) || null
  );
  const [explanation, setExplanation] = useState(
    (props.decisionProposal && props.decisionProposal.status) || null
  );

  useEffect(() => {
    status !== null &&
      explanation !== null &&
      props.handleDecisionProposal &&
      props.handleDecisionProposal({
        status,
        explanation
      });
    // eslint-disable-next-line
  }, [status, explanation]);

  useEffect(() => {
    if (props.emptyForm) {
      return;
    }

    setStatus(props.decisionProposalData.status);
    setExplanation(props.decisionProposalData.explanation);
    // eslint-disable-next-line
  }, [props.decisionProposalData]);

  return (
    <DecisionProposalSection
      hasRequiredFields={props.hasRequiredFields}
      disabled={props.disabled}
    >
      <Label className="form-check-label">{t('decisionProposal')}:</Label>
      <Select
        options={decisionProposalStatuses(t)}
        onChange={e => setStatus(e.value)}
        value={decisionProposalStatuses(t).find(o => o.value === status)}
        isDisabled={props.disabled}
      />
      <Label className="form-check-label">{t('explanation')}:</Label>
      <Textarea
        isRequired={!props.disabled}
        onChange={setExplanation}
        value={explanation}
        disabled={props.disabled}
        autoResize={props.disabled}
      />
    </DecisionProposalSection>
  );
};

const mapStateToProps = createPropsSelector({
  decisionProposalData: getAssessmentProposalData
});

export default connect(mapStateToProps)(SingleAssessmentDecisionProposal);
