import React, { useEffect } from 'react';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { getField } from 'store/newInventionForm/newInventionFormSelectors';
import { bindActionCreators } from 'redux';
import { setField } from 'actions/newInventionFormActions';
import { connect } from 'react-redux';
import RichText from 'Atoms/RichText';

const TextareaType = ({ field, fieldState, setField, answers }) => {
  useEffect(() => {
    if (answers) {
      setField(field.attributes.id, answers.data.attributes.value);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <RichText
        placeholder={field.attributes.name}
        value={
          (answers && answers.data.attributes.value) ||
          (fieldState && fieldState.value)
        }
        onChange={value => setField(field.attributes.id, value)}
        maxLength={
          field.attributes.settings && field.attributes.settings.maxLength
        }
      />
    </div>
  );
};

const mapStateToProps = createPropsSelector({
  fieldState: getField
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setField
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TextareaType);
