import { Cookies } from 'react-cookie';
import env from 'helpers/env';

const cookies = new Cookies();

export const LOGIN_STATE = 'loginState';
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const USER_ID = 'userId';
export const ORGANISATION_ID = 'organisationId';
export const RELOAD_LOGIN_PAGE = 'reloadLoginPage';

export const setCookie = (name, value) =>
  cookies.set(name, value, {
    path: '/',
    ...([LOGIN_STATE, TOKEN, REFRESH_TOKEN].includes(name) && {
      sameSite: 'strict',
      secure: env('SECURE_COOKIES') === true
    })
  });

export const removeCookie = name => cookies.remove(name);

export const getLoginState = () => cookies.get(LOGIN_STATE);
export const getToken = () => cookies.get(TOKEN);
export const getRefreshToken = () => cookies.get(REFRESH_TOKEN);
export const getUserId = () => cookies.get(USER_ID);
export const getOrganisationId = () => cookies.get(ORGANISATION_ID);
export const getReloadLoginPage = () => cookies.get(RELOAD_LOGIN_PAGE);

export const clearSessionCookies = () => {
  [
    LOGIN_STATE,
    TOKEN,
    REFRESH_TOKEN,
    USER_ID,
    ORGANISATION_ID,
    RELOAD_LOGIN_PAGE
  ].forEach(cookie => removeCookie(cookie));
};
