import React from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import DefaultModal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Input from 'Atoms/Input';
import Textarea from 'Atoms/Textarea';

class EditSectionModal extends React.Component {
  state = {
    data: {
      type: 'section',
      attributes: {
        name: this.props.section.data.attributes.name,
        description: this.props.section.data.attributes.description
      }
    }
  };

  changeSectionAttributes = (type, value) => {
    let attributes = { ...this.state.data.attributes };
    attributes[type] = value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: attributes
      }
    });
  };

  render() {
    const { onHide, section } = this.props;

    const {
      data: { attributes }
    } = this.state;

    const content = (
      <>
        <Input
          isRequired
          label={t('editName')}
          onChange={e => this.changeSectionAttributes('name', e)}
          value={attributes.name}
        />
        <Textarea
          isRequired
          label={t('editDescription')}
          onChange={e => this.changeSectionAttributes('description', e)}
          value={attributes.description}
        />
      </>
    );
    const footer = (
      <>
        <Button
          value={t('cancel')}
          onClick={() => onHide(false, 'editionModal')}
        />
        <Button
          disabled={
            !(
              attributes.name !== section.data.attributes.name ||
              attributes.description !== section.data.attributes.description
            )
          }
          colorVariant="green"
          value={t('editTemplate')}
          onClick={() => {
            onHide(false, 'editionModal');
            this.props.editSection(this.state, section.data.id);
          }}
        />
      </>
    );
    return (
      <>
        <DefaultModal
          isOpen
          size="sm"
          onHide={() => onHide(false, 'editionModal')}
          content={content}
          footer={footer}
          title={t('editSection')}
        />
      </>
    );
  }
}

export default withTranslation('iamip')(EditSectionModal);
