import typeToReducer from 'type-to-reducer';
import {
  setCookie,
  clearSessionCookies,
  LOGIN_STATE,
  TOKEN,
  REFRESH_TOKEN,
  USER_ID
} from 'helpers/cookies';

import { getLoginDetails, authLogout } from './routines';

export const CONFIRM_AUTH = 'login/CONFIRM_AUTH';
export const SAVE_LOGIN_STATE = 'login/SAVE_LOGIN_STATE';
export const SET_TOKENS = 'login/SET_TOKENS';
export const SET_USER_ID = 'login/SET_USER_ID';

const defaultState = {
  isAuth: false,
  token: null,
  refreshToken: null,
  loginState: null,
  isLoading: false,
  tryToLoginAgain: false
};

export default typeToReducer(
  {
    [CONFIRM_AUTH]: state => ({
      ...state,
      isAuth: true
    }),
    [SAVE_LOGIN_STATE]: (state, { loginState }) => {
      setCookie(LOGIN_STATE, loginState);

      return {
        ...state,
        loginState: loginState,
        isLoading: true
      };
    },
    [SET_TOKENS]: (state, { tokens }) => {
      setCookie(TOKEN, tokens.token);
      setCookie(REFRESH_TOKEN, tokens.refreshToken);

      return {
        ...state,
        token: tokens.token,
        refreshToken: tokens.refreshToken
      };
    },
    [SET_USER_ID]: (state, { id }) => {
      setCookie(USER_ID, id);

      return state;
    },
    [getLoginDetails.TRIGGER]: state => ({ ...state, isLoading: true }),
    [getLoginDetails.SUCCESS]: state => {
      localStorage.removeItem('isLoggedIn');

      return {
        ...state,
        isAuth: true,
        isLoading: false
      };
    },
    [getLoginDetails.FAILURE]: state => {
      clearSessionCookies();

      return {
        ...state,
        isLoading: false,
        isAuth: false,
        tryToLoginAgain: true
      };
    },
    [authLogout.SUCCESS]: state => {
      clearSessionCookies();
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('previousPath');
      localStorage.setItem('isLoggedOut', true);

      return defaultState;
    }
  },
  defaultState
);

export const confirmAuth = () => ({
  type: CONFIRM_AUTH
});

export const saveLoginState = loginState => ({
  type: SAVE_LOGIN_STATE,
  loginState
});

export const setTokens = tokens => ({
  type: SET_TOKENS,
  tokens
});

export const setUserId = id => ({
  type: SET_USER_ID,
  id
});
