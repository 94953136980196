import typeToReducer from 'type-to-reducer';

import { fetchFormTypeList } from 'actions/formTypeListActions';

export const SET_CURRENT_LIST_TYPE = 'formTypeList/SET_CURRENT_TYPE_LIST';
export const SET_FORM_LIMIT = 'formTypeList/SET_FORM_LIMIT';

const defaultState = {
  isFetching: false,
  data: [],
  currentListType: '',
  limit: null
};

export default typeToReducer(
  {
    [fetchFormTypeList.TRIGGER]: state => ({
      ...state,
      isFetching: true,
      data: {}
    }),
    [fetchFormTypeList.SUCCESS]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      data: payload
    }),
    [SET_CURRENT_LIST_TYPE]: (state, { currentListType }) => ({
      ...state,
      currentListType: currentListType
    }),
    [SET_FORM_LIMIT]: (state, { limit }) => ({
      ...state,
      limit: limit
    })
  },
  defaultState
);

export const setCurrentListType = currentListType => ({
  type: SET_CURRENT_LIST_TYPE,
  currentListType
});

export const setFormLimit = limit => ({
  type: SET_FORM_LIMIT,
  limit
});
