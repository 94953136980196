import { all, put, takeEvery, call, select, delay } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  EDIT_SECTION,
  EDIT_FORM_TYPE_META_DATA,
  ADD_NEW_FORM_TYPE,
  FETCH_FORM_TYPE_DATA,
  UNLOCK_FORM_TYPE,
  ADD_NEW_SECTION,
  REMOVE_SECTION,
  ADD_NEW_FIELD,
  EDIT_FIELD,
  REMOVE_FIELD,
  addSectionSuccess,
  removeSectionSuccess,
  editSectionSuccess,
  editFormTypeMetaDataSuccess,
  addNewFieldSuccess,
  editFieldSuccess,
  fetchFormTypeDataSuccess,
  removeFieldSuccess,
  addNewFormTypeSuccess,
  addNewFormTypeFailure
} from 'store/formType/formTypeReducer';

import {
  createNewSection,
  editSection,
  removeSection,
  editFormTypeMetaData,
  addNewInventionType,
  addNewAssessmentType,
  lockFormType,
  addNewField,
  editField,
  removeField,
  unlockFormType
} from 'helpers/axios/axiosRequests';

import { toast } from 'react-toastify';
import { t } from 'helpers/i18n';

import { INVENTION_TYPE, ASSESSMENT_TYPE } from 'constants/routingConstants';
import { setCurrentListType } from 'store/formTypeList/formTypeListReducer';
import { getIsAuth } from 'store/login/loginSelectors';

function* fetchFormTypeDataSaga({ formTypeId, isInventionType }) {
  try {
    const { data } = yield call(lockFormType, formTypeId, isInventionType);
    yield put(fetchFormTypeDataSuccess(data.data));
    yield put(setCurrentListType(data.data.type));
  } catch (error) {
    console.error(error);
    yield toast.error(t('somethingWentWrong'));
  }
}

function* addSectionSaga({ section }) {
  try {
    const { data } = yield call(createNewSection, section);
    yield put(addSectionSuccess(data));
    yield toast.success(t('sectionSuccessfullyAdded'), {
      position: 'top-center',
      autoClose: 5000
    });
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    console.error(error);
  }
}

function* editSectionSaga({ section, sectionId }) {
  try {
    const { data } = yield call(editSection, sectionId, section);

    yield put(editSectionSuccess(data));
    yield toast.success(t('sectionSuccessfullyEdited'), {
      position: 'top-center',
      autoClose: 5000
    });
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    console.error(error);
  }
}

function* removeSectionSaga({ sectionId }) {
  try {
    yield call(removeSection, sectionId);
    yield toast.success(t('sectionSuccessfullyRemoved'), {
      position: 'top-center',
      autoClose: 5000
    });
    yield put(removeSectionSuccess(sectionId));
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    console.error(error);
  }
}

function* editFormTypeMetaDataSaga({ formTypeData, formTypeId }) {
  try {
    const { data } = yield call(editFormTypeMetaData, formTypeData, formTypeId);

    yield put(editFormTypeMetaDataSuccess(data));
    yield toast.success(t('formTypeUpdated'), {
      position: 'top-center',
      autoClose: 5000
    });
  } catch (error) {
    console.error(error);

    toast.error(
      error.response.data.detail.includes('description')
        ? t('formDescriptionError')
        : t('somethingWentWrong')
    );
  }
}

function* addNewFormTypeSaga({ newFormType, formType }) {
  try {
    const { data } = yield call(
      formType === 'invention_type'
        ? addNewInventionType
        : addNewAssessmentType,
      newFormType
    );

    yield put(addNewFormTypeSuccess());

    yield put(
      push(
        `${formType === 'invention_type' ? INVENTION_TYPE : ASSESSMENT_TYPE}/${
          data.data.attributes.id
        }`
      )
    );
  } catch (error) {
    console.error(error);

    yield put(addNewFormTypeFailure());

    toast.error(
      error.response.data.detail.includes('description')
        ? t('formDescriptionError')
        : t('somethingWentWrong')
    );
  }
}

function* addNewFieldSaga({ field }) {
  try {
    const { data } = yield call(addNewField, field);
    yield put(addNewFieldSuccess(data, field.data.attributes.section.id));
    yield toast.success(t('newFieldSuccessfullyAdded'));
  } catch (error) {
    console.error(error);
    toast.error(t('somethingWentWrong'));
  }
}

function* removeFieldSaga({ fieldId, sectionId }) {
  try {
    yield call(removeField, fieldId);
    yield put(removeFieldSuccess(fieldId, sectionId));
    yield toast.success(t('fieldSuccessfullyRemoved'));
  } catch (error) {
    console.error(error);
    toast.error(t('somethingWentWrong'));
  }
}

function* editFieldSaga({ fieldId, field, sourceIndex, sectionId }) {
  try {
    const { data } = yield call(editField, fieldId, field);
    yield put(editFieldSuccess(data, sectionId, sourceIndex));
    yield toast.success(t('fieldSuccessfullyEdited'));
  } catch (error) {
    console.error(error);
    toast.error(t('somethingWentWrong'));
  }
}

function* unlockFormTypeSaga({ formTypeId, isInventionType }) {
  try {
    const isAuth = yield select(getIsAuth);
    yield delay(2000);
    if (isAuth) {
      yield call(unlockFormType, formTypeId, isInventionType);
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* formTypeSaga() {
  yield all([
    takeEvery(ADD_NEW_SECTION, addSectionSaga),
    takeEvery(REMOVE_SECTION, removeSectionSaga),
    takeEvery(EDIT_SECTION, editSectionSaga),
    takeEvery(EDIT_FORM_TYPE_META_DATA, editFormTypeMetaDataSaga),
    takeEvery(ADD_NEW_FORM_TYPE, addNewFormTypeSaga),
    takeEvery(ADD_NEW_FIELD, addNewFieldSaga),
    takeEvery(FETCH_FORM_TYPE_DATA, fetchFormTypeDataSaga),
    takeEvery(REMOVE_FIELD, removeFieldSaga),
    takeEvery(EDIT_FIELD, editFieldSaga),
    takeEvery(UNLOCK_FORM_TYPE, unlockFormTypeSaga)
  ]);
}
