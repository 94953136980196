/* eslint-disable eqeqeq */
import React, { memo, useState, useEffect, useRef } from 'react';
import { Tabs, Tab, TabContainer, TabContent } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencilAlt,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'react-tooltip-lite';
import usePrevious from 'helpers/hooks/usePrevious';
import { isEmpty } from 'lodash';

import NotificationCounter from 'Atoms/NotificationCounter';

import { TabsContainer } from './styles';

const DefaultTabs = ({
  activeTab,
  data,
  removeSection,
  editSection,
  content,
  isFormTypeLocked,
  isCreateInvention,
  currentSectionIndex,
  setCurrentSectionIndex
}) => {
  const [key, setKey] = useState(
    activeTab || (data.length && data[currentSectionIndex || 0].id)
  );

  const [removedId, setRemovedId] = useState(null);
  const [indexOfRemovedTab, setIndexOfRemovedTab] = useState('');
  const previousData = usePrevious(data);
  const previousIndex = usePrevious(currentSectionIndex) || 0;
  const tabsRef = useRef();

  useEffect(() => {
    if (
      isCreateInvention &&
      previousData &&
      JSON.stringify(previousData) !== JSON.stringify(data)
    ) {
      setKey(data[currentSectionIndex || 0].id);
      return;
    }

    if (previousData && previousData.length > data.length) {
      if (removedId !== key) {
        return;
      }
      setKey(data[indexOfRemovedTab - 1 <= 0 ? 0 : indexOfRemovedTab - 1].id);
    }

    if (previousData && previousData.length < data.length) {
      setKey(data[data.length - 1].id);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!isNaN(currentSectionIndex) && previousIndex !== currentSectionIndex) {
      tabsRef && tabsRef.current.scrollIntoView();
    }

    if (!isEmpty(data) && currentSectionIndex !== undefined) {
      setKey(data[currentSectionIndex].id);
    }
    // eslint-disable-next-line
  }, [currentSectionIndex]);

  const handleRemoveSection = (e, tabId) => {
    e.stopPropagation();
    setRemovedId(tabId);
    const indexOfRemovedTab = data.findIndex(({ id }) => id === tabId);
    setIndexOfRemovedTab(indexOfRemovedTab);
    removeSection(`/v1/sections/${tabId}`);
  };

  const selectActiveTab = k => {
    setKey(k);

    if (setCurrentSectionIndex) {
      const tabIndex = data.findIndex(el => el.id == k);
      setCurrentSectionIndex(tabIndex);
    }
  };

  return (
    <TabsContainer ref={tabsRef}>
      <Tabs activeKey={key} onSelect={k => selectActiveTab(k)}>
        {data.map((tab, index) => (
          <Tab
            className="my-tab"
            key={tab.id}
            eventKey={tab.id}
            index={index}
            title={
              <>
                <Tooltip
                  content={
                    <div style={{ maxWidth: 800 }}>{tab.description}</div>
                  }
                  direction="up"
                  useDefaultStyles
                >
                  <>{tab.name}</>
                  {Boolean(tab.cotentLength) && (
                    <NotificationCounter
                      notificationNumber={tab.cotentLength}
                    />
                  )}
                </Tooltip>
                {tab.containsError && (
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                )}
                {!isFormTypeLocked && (
                  <>
                    {editSection && (
                      <div className="icon-wrapper">
                        <FontAwesomeIcon
                          onClick={e => {
                            editSection(tab.id);
                          }}
                          icon={faPencilAlt}
                        />
                      </div>
                    )}
                    {removeSection && data.length > 1 && (
                      <FontAwesomeIcon
                        onClick={e => handleRemoveSection(e, tab.id)}
                        icon={faTrash}
                      />
                    )}
                  </>
                )}
              </>
            }
            disabled={tab.disabled}
          >
            <TabContainer mountOnEnter unmountOnExit>
              <TabContent>{key == tab.id && content(tab.id)}</TabContent>
            </TabContainer>
          </Tab>
        ))}
      </Tabs>
    </TabsContainer>
  );
};

DefaultTabs.propTypes = {
  activeTab: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      fields: PropTypes.array,
      disabled: PropTypes.bool
    })
  ),
  removeSection: PropTypes.func,
  editSection: PropTypes.func,
  content: PropTypes.func,
  isCreateInvention: PropTypes.bool,
  currentSectionIndex: PropTypes.number
};

export default memo(DefaultTabs);
