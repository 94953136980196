export const STATUS_PENDING = 1;
export const STATUS_IN_PROGRESS = 2;
export const STATUS_REVIEW = 3;
export const STATUS_COMPLETED = 4;

export const DECISION_PROPOSAL_STATUS_PATENT = 1;
export const DECISION_PROPOSAL_STATUS_DROP = 2;
export const DECISION_PROPOSAL_STATUS_KEEP_SECRET = 3;
export const DECISION_PROPOSAL_STATUS_PUBLISH = 4;
export const DECISION_PROPOSAL_STATUS_ON_HOLD = 5;
export const DECISION_PROPOSAL_STATUS_DONT_KNOW = 6;

export const assessmentStatuses = t => [
  { id: STATUS_PENDING, label: t('pending') },
  { id: STATUS_IN_PROGRESS, label: t('inProgress') },
  { id: STATUS_REVIEW, label: t('review') },
  { id: STATUS_COMPLETED, label: t('completed') }
];

export const decisionProposalStatuses = t => [
  { value: DECISION_PROPOSAL_STATUS_PATENT, label: t('patent') },
  { value: DECISION_PROPOSAL_STATUS_DROP, label: t('drop') },
  { value: DECISION_PROPOSAL_STATUS_KEEP_SECRET, label: t('keepSecret') },
  { value: DECISION_PROPOSAL_STATUS_PUBLISH, label: t('publish') },
  { value: DECISION_PROPOSAL_STATUS_ON_HOLD, label: t('onHold') },
  { value: DECISION_PROPOSAL_STATUS_DONT_KNOW, label: t('dontKnow') }
];
