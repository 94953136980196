import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import InventionFormPreview from 'Elements/InventionFormPreview';

import {
  createSurveyReview,
  updateReview
} from 'actions/singleInventionActions';

import {
  STATUS_SEND_FOR_REVIEW,
  STATUS_MOVE_TO_DECISION,
  STATUS_BACK_TO_INVENTOR
} from 'constants/surveyReviewStatuses';

import { STATUS_OPEN, STATUS_SUBMITTED } from 'constants/surveyStatuses';

import {
  STATUS_ASSESSMENT,
  STATUS_PENDING_DECISION,
  STATUS_DRAFT
} from 'constants/inventionStatuses';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Radio from 'Atoms/Radio';
import Textarea from 'Atoms/Textarea';
import { connect } from 'react-redux';

import { DecisionWrapper, UploadFileWrapper, FormWrapper } from './styles';
import { DEFAULT_RED } from 'constants/colors';

import { bindActionCreators } from 'redux';
import Attachments from 'Elements/Attachments';
import { STATUS_PRE_ASSESSMENT } from 'constants/attachmentStatuses';

import { getInventionReviews } from 'store/singleInvention/singleInventionSelectors';
import TabsButtons from 'Elements/TabsButtons';

const PreAssessmentModal = ({
  isOpen,
  closeModal,
  surveyId,
  createSurveyReview,
  reviews,
  updateReview,
  inventionId,
  attachments,
  sectionsLength
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(
    reviews.data ? reviews.data.attributes.comment : ''
  );
  const [isEmpty, setIsEmpty] = useState(false);

  const [data, setData] = useState([]);
  const [currentReviews, setCurrentReviews] = useState(reviews);
  const surveyReviewId = reviews.data ? reviews.data.id : null;
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  // TODO: Change values to value: STATUS_... when backend will handle stauts changes

  const decisionRadio = [
    {
      label: t('sendForReview'),
      value: {
        surveyReviewStatus: STATUS_SEND_FOR_REVIEW,
        inventionStatus: STATUS_ASSESSMENT
      }
    },
    {
      label: t('moveForDecision'),
      value: {
        surveyReviewStatus: STATUS_MOVE_TO_DECISION,
        inventionStatus: STATUS_PENDING_DECISION
      }
    },
    {
      label: t('backToInventor'),
      value: {
        surveyReviewStatus: STATUS_BACK_TO_INVENTOR,
        inventionStatus: STATUS_DRAFT
      }
    }
  ];

  const [status, setStatus] = useState(
    reviews &&
      reviews.data &&
      reviews.data.attributes.review_status &&
      decisionRadio.find(
        el =>
          el.value.surveyReviewStatus === reviews.data.attributes.review_status
      )
      ? decisionRadio.find(
          el =>
            el.value.surveyReviewStatus ===
            reviews.data.attributes.review_status
        ).value
      : decisionRadio[0].value
  );

  useEffect(() => {
    if (!isRequiredField) {
      setIsEmpty(false);
    }
    // eslint-disable-next-line
  }, [status]);

  const isRequiredField = [
    STATUS_MOVE_TO_DECISION,
    STATUS_BACK_TO_INVENTOR
  ].includes(status.surveyReviewStatus);

  const handleAddComment = (field, answerId) => {
    const newData = reviews.data.attributes.answer_reviews;

    const fieldIndex = newData.findIndex(el => {
      const currentAnswerId = el.attributes.answer.data
        ? el.attributes.answer.data.id
        : el.attributes.answer.id;

      return currentAnswerId === answerId;
    });

    if (fieldIndex === -1) {
      newData.push(field);
    } else {
      newData[fieldIndex] = field;
    }

    const temporaryReviews = { ...currentReviews };
    currentReviews.data.attributes.answer_reviews = [...newData];
    setCurrentReviews(temporaryReviews);
    setData(newData);
  };

  const handleRemoveComment = answerId => {
    const newData = data.filter(el => {
      if (el.attributes.answer.id === answerId) {
        el.attributes.comment = '';
      }
      return el;
    });
    setData(newData);
  };

  const changeStatus = value => setStatus(value);

  const handleUpdateReview = draft => {
    setIsEmpty(false);

    if (isRequiredField && !comment) {
      setIsEmpty(true);
      toast.error(t('fillRequiredFields'));
      return;
    }

    const reviewData = {
      data: {
        type: 'survey_review',
        attributes: {
          status: draft ? STATUS_OPEN : STATUS_SUBMITTED,
          review_status: status.surveyReviewStatus,
          comment
        }
      }
    };

    updateReview({
      reviewData,
      data: { data },
      surveyReviewId,
      ...(!draft && { inventionStatus: status.inventionStatus })
    });
    closeModal();
  };

  const handleCreateReview = draft => {
    setIsEmpty(false);

    if (isRequiredField && !comment) {
      setIsEmpty(true);
      toast.error(t('fillRequiredFields'));
      return;
    }

    const reviewData = {
      data: {
        type: 'survey_review',
        attributes: {
          status: draft ? STATUS_OPEN : STATUS_SUBMITTED,
          review_status: status.surveyReviewStatus,
          comment: comment || '',
          survey: {
            id: surveyId,
            type: 'form'
          }
        }
      }
    };

    createSurveyReview({
      reviewData,
      data: { data },
      ...(!draft && { inventionStatus: status.inventionStatus })
    });
    closeModal();
  };

  const renderContent = () => (
    <FormWrapper>
      <InventionFormPreview
        inReview
        handleAddComment={handleAddComment}
        handleRemoveComment={handleRemoveComment}
        reviewData={currentReviews}
        surveyReviewId={surveyReviewId}
        sectionIndex={currentSectionIndex}
        setSectionIndex={setCurrentSectionIndex}
      />

      <UploadFileWrapper>
        <Attachments
          attachments={attachments}
          isEdited
          status={STATUS_PRE_ASSESSMENT}
          inventionId={inventionId}
          isPreAssessment
        />
      </UploadFileWrapper>
      {currentSectionIndex === sectionsLength && (
        <DecisionWrapper>
          {decisionRadio.map(el => (
            <Radio
              key={el.label}
              label={el.label}
              onCheck={() => changeStatus(el.value)}
              checked={
                status.surveyReviewStatus === el.value.surveyReviewStatus
              }
            />
          ))}
          <Textarea
            placeholder={t('addYourComment')}
            onChange={e => setComment(e)}
            value={comment || (reviews.data && reviews.data.attributes.comment)}
            isRequired={isRequiredField}
            borderColor={isEmpty && DEFAULT_RED}
          />
        </DecisionWrapper>
      )}
    </FormWrapper>
  );

  const footer = () => (
    <>
      <Button value={t('close')} onClick={closeModal} />
      {sectionsLength > 0 && (
        <TabsButtons
          sectionsLength={sectionsLength}
          onTabChange={setCurrentSectionIndex}
          currentIndex={currentSectionIndex}
        />
      )}
      <>
        <Button
          colorVariant="darkBlue"
          value={t('saveDraft')}
          onClick={() =>
            surveyReviewId
              ? handleUpdateReview('draft')
              : handleCreateReview('draft')
          }
        />
        {currentSectionIndex === sectionsLength && (
          <Button
            colorVariant="green"
            value={t('save')}
            onClick={() =>
              surveyReviewId ? handleUpdateReview() : handleCreateReview()
            }
          />
        )}
      </>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onHide={closeModal}
      title={t('preassessmentModal')}
      content={renderContent()}
      footer={footer()}
      size="lg"
    />
  );
};

PreAssessmentModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

const mapStateToProps = state => ({
  reviews: getInventionReviews(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createSurveyReview,
      updateReview
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreAssessmentModal);
