import React from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FieldButton, FieldsContainer } from './styles';
import TitleContent from 'Elements/FormTypeFields/TitleContent';

const CreateInventionTypeIDFields = ({ template }) => {
  const { t } = useTranslation();
  const buttons = [
    { type: 'date', name: 'year_month', value: 'year_month' },
    { type: 'date', name: 'year', value: 'year' },
    { name: 'fixed', value: 'fixed' }
  ];

  const renderButtons = () => {
    const currentTemplate = template.template;
    return buttons.filter(
      el =>
        !currentTemplate.find(
          currentElement =>
            currentElement[Object.keys(currentElement)[0]] === el.value ||
            Object.keys(currentElement)[0] === el.value
        )
    );
  };

  return (
    <FieldsContainer>
      <TitleContent inModal />
      <Droppable droppableId={'fields'}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
          >
            {renderButtons().map((el, index) => (
              <Draggable draggableId={el.value} index={index} key={el.value}>
                {provided => (
                  <>
                    <FieldButton
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <>{t(el.value)}</>
                    </FieldButton>
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </FieldsContainer>
  );
};

export default CreateInventionTypeIDFields;
