import React from 'react';
import Textarea from 'Atoms/Textarea';

const InformationType = ({ field }) => (
  <Textarea
    autoResize
    placeholder={field.attributes.name}
    value={field.attributes.description}
    disabled
  />
);

export default InformationType;
