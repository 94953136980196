export const flattenObject = (obj, skippedField) => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      key !== skippedField
    ) {
      Object.assign(flattened, flattenObject(obj[key], skippedField));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};
