import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import SingleField from './SingleField';
import {
  Label,
  DescriptionWrapper,
  FieldWrapper,
  RequiredLabel
} from './styles';
import { INFORMATION_FIELD_ID } from 'constants/formTypeFieldConstants';

const Fields = ({ formFields, handleFields, disabled, unfilledFields }) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (formFields && isEmpty(fields)) {
      const fieldsWithValues =
        formFields &&
        formFields.reduce((acc, field) => {
          const { attributes, id } = field.data;

          const defaultValue =
            !isEmpty(attributes.options) &&
            attributes.options.fields.find(option => option.default);

          if (!attributes.value && !defaultValue) {
            return acc;
          }

          const value = attributes.value
            ? attributes.value
            : defaultValue
            ? defaultValue.value
            : null;
          const additionalValue = attributes.additionalValue
            ? attributes.additionalValue
            : defaultValue
            ? defaultValue.additional
            : null;

          return [
            ...acc,
            {
              fieldId: id,
              ...(value && { value }),
              ...(additionalValue && { additionalValue })
            }
          ];
        }, []);

      setFields(fieldsWithValues);
    }
    // eslint-disable-next-line
  }, [formFields]);

  useEffect(() => {
    handleFields && handleFields(fields);
    // eslint-disable-next-line
  }, [fields]);

  const reducedFields =
    formFields &&
    formFields.reduce(
      (acc, field) => [...acc, { ...field.data.attributes, id: field.data.id }],
      []
    );

  const handleChange = changedField => {
    const isFieldAssigned = fields.find(
      ({ fieldId }) => fieldId === changedField.fieldId
    );

    if (isFieldAssigned) {
      setFields([
        ...fields.filter(({ fieldId }) => fieldId !== changedField.fieldId),
        changedField
      ]);
    } else {
      setFields([...fields, changedField]);
    }
  };

  return (
    <Fragment>
      {reducedFields &&
        reducedFields.map(field => (
          <React.Fragment key={field.id}>
            <Label>{field.name}</Label>
            {field.type !== INFORMATION_FIELD_ID && (
              <DescriptionWrapper>{field.description}</DescriptionWrapper>
            )}
            <FieldWrapper
              disabled={disabled}
              key={field.id}
              required={
                field.required &&
                unfilledFields &&
                unfilledFields.includes(field.id)
              }
            >
              <SingleField
                field={field}
                onChange={handleChange}
                disabled={disabled}
              />
              {field.required && !disabled && (
                <RequiredLabel>{t('requiredField')}</RequiredLabel>
              )}
            </FieldWrapper>
          </React.Fragment>
        ))}
    </Fragment>
  );
};

Fields.propTypes = {
  formFields: PropTypes.array,
  handleFields: PropTypes.func,
  disabled: PropTypes.bool,
  unfilledFields: PropTypes.array
};

export default Fields;
