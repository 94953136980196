import { createRoutine } from 'redux-saga-routines';

export const fetchInventions = createRoutine('inventions/FETCH_INVENTIONS');
export const createInvention = createRoutine('inventions/CREATE_INVENTION');
export const createFullInventionAnswer = createRoutine(
  'inventions/CREATE_FULL_INVENTION_ANSWER'
);

export const CLEAR_INVENTIONS_LIST = 'CLEAR_INVENTIONS_LIST';
export const clearInventionsLists = () => ({
  type: CLEAR_INVENTIONS_LIST
});

export const SET_INVENTIONS_LIMIT = 'SET_INVENTIONS_LIMIT';
export const setInventionsLimit = limit => ({
  type: SET_INVENTIONS_LIMIT,
  limit
});

export const EXPORT_TO_EXCEL = 'inventions/EXPORT_TO_EXCEL';
export const exportInventionsToExcel = exportLocation => ({
  type: EXPORT_TO_EXCEL,
  exportLocation
});

export const CLEAR_SORTING_ORDER = 'inventions/CLEAR_SORTING_ORDER';
export const clearInventionsSortingOrder = () => ({
  type: CLEAR_SORTING_ORDER
});

export const SET_SORTING_ORDER = 'inventions/SET_SORTING_ORDER';
export const setInventionsSortingOrder = sortingOrder => ({
  type: SET_SORTING_ORDER,
  sortingOrder
});
