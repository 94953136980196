import { all, put, call, takeEvery } from 'redux-saga/effects';

import { fetchFormTypeList } from 'actions/formTypeListActions';

import {
  getInventionTypes,
  getAssessmentTypeList
} from 'helpers/axios/axiosRequests';

const prepareParams = payload => {
  let params = '';

  if (payload.page) {
    params = params + `&page=${payload.page}`;
  }
  if (payload.itemsPerPage) {
    params = params + `&itemsPerPage=${payload.itemsPerPage}`;
  }
  if (payload.sort) {
    params = params + `&order[${payload.sort.field}]=${payload.sort.order}`;
  }
  return params;
};

function* fetchFormTypeListSaga({ payload }) {
  try {
    const params = prepareParams(payload);

    const { data } = yield call(
      payload.currentListType === 'invention_type'
        ? getInventionTypes
        : getAssessmentTypeList,
      payload.page,
      true,
      params
    );
    yield put(fetchFormTypeList.success(data));
  } catch (error) {
    console.error(error);
  }
}

export default function* formTypeListSaga() {
  yield all([takeEvery(fetchFormTypeList.TRIGGER, fetchFormTypeListSaga)]);
}
