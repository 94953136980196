import styled from 'styled-components';

import { DARK_GRAY } from 'constants/colors';

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorContent = styled.div`
  color: ${DARK_GRAY};
  text-align: center;
`;

export const ErrorMessage = styled.div`
  padding-top: 40px;
  font-size: 2rem;
  font-weight: bold;
`;
