import { createSelector } from 'reselect';
import { getUserId } from 'helpers/cookies';

export const getCurrentUserData = state => state.users.currentUser;
export const getIsUserLoading = state => state.users.isUserLoading;
export const getAreUsersLoading = state => state.users.isLoading;

export const getCurrentUserFullName = createSelector(
  state => state.users.currentUser,
  user =>
    (user.attributes &&
      `${user.attributes.first_name} ${user.attributes.last_name}`) ||
    ''
);

export const getCurrentUserAvatar = createSelector(
  state => getCurrentUserData(state),
  user => (user.attributes && user.attributes.avatar_url) || null
);

export const getCurrentValidUserId = createSelector(
  state => getCurrentUserData(state),
  user => user.id
);

export const getCurrentUserId = createSelector(
  getCurrentValidUserId,
  userId => userId || getUserId()
);

export const getAreUsersFetched = state => state.users.areUsersFetched;
export const getExperts = state => state.users.experts;
export const getInventors = state => state.users.inventors;
export const getReviewManagers = state => state.users.reviewManagers;
export const getDecisionMakers = state => state.users.decisionMakers;
export const getReviewCoordinators = state => state.users.reviewCoordinators;

export const getUsersForOptions = createSelector(
  (state, usersType, showAll) => {
    const currentUserId = getCurrentUserId(state);
    const optionUsers = state.users[usersType];
    return usersType !== 'inventors' || showAll
      ? optionUsers
      : optionUsers.filter(inventor => inventor.id !== currentUserId);
  },
  users =>
    users.map(el => ({
      label: `${el.first_name} ${el.last_name}`,
      value: el.id
    }))
);

export const getCurrentUserRoles = createSelector(
  state => getCurrentUserData(state),
  user => (user.attributes && user.attributes.roles) || []
);
