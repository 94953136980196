import axios from 'axios';
import {
  getToken,
  getRefreshToken,
  getOrganisationId,
  setCookie,
  clearSessionCookies,
  TOKEN,
  REFRESH_TOKEN,
  RELOAD_LOGIN_PAGE
} from 'helpers/cookies';
import env from 'helpers/env';
import { LOGIN_PAGE, ACCESS_DENIED } from 'constants/routingConstants';
import { history } from 'store/createStore';
import { toast } from 'react-toastify';
import { t } from 'helpers/i18n';
import { authLogout } from 'helpers/axios/axiosRequests';
import { utcFormat } from 'helpers/dateHelpers';

const URL = env('API_URL');

export const fetch = axios.create({
  baseURL: `${URL}/`,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/vnd.api+json'
  }
});

const logout = message => {
  localStorage.setItem('forceShowButton', 'true');
  localStorage.removeItem('isLoggedIn');
  localStorage.setItem('isLoggedOut', 'true');
  authLogout();
  clearSessionCookies();
  history.push(LOGIN_PAGE);
  if (message) {
    toast.error(message);
  }
};

fetch.interceptors.response.use(
  response => {
    if (
      response.status === 201 ||
      response.status === 200 ||
      response.status === 204
    ) {
      return response;
    }
    return Promise.reject(response);
  },
  error => {
    if (error) {
      const refreshToken = getRefreshToken();

      if (
        error.response &&
        error.response.status === 401 &&
        (error.config.url.includes('auth/logout') ||
          error.config.url.includes('token/refresh'))
      ) {
        setCookie(RELOAD_LOGIN_PAGE, true);
      }

      const retryFailed =
        error.config.isRetryRequest !== undefined &&
        error.response?.status !== 200;
      const isRefreshRequest =
        refreshToken && error.config.url.includes('token/refresh');
      const isLogoutRequest = error.config.url.includes('auth/logout');
      const isUnathorized = error.response?.status === 401;

      if (
        (isUnathorized && !isLogoutRequest && !isRefreshRequest) ||
        retryFailed
      ) {
        logout();
      }

      if (error.response && error.response.status === 423) {
        toast.error(
          t('lockedItem', {
            name: error.response.data.locked_by,
            date: utcFormat(error.response.data.locked_until.date)
          })
        );
      }

      if (error.response && error.response.status === 403) {
        history.push(ACCESS_DENIED);
      }
    }

    return Promise.reject(error);
  }
);

fetch.interceptors.request.use(config => {
  const accessToken = getToken();

  config.params = {
    ...config.params,
    ...config.appendParams
  };

  if (accessToken) {
    return {
      ...config,

      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/vnd.api+json'
      }
    };
  }

  return config;
});

fetch.refreshToken = () => {
  const refreshToken = getRefreshToken();
  const organisationId = getOrganisationId();
  const bodyFormData = new FormData();
  bodyFormData.append('refresh_token', refreshToken);

  if (!organisationId) {
    logout(t('tokenExpired'));
    return;
  }

  // eslint-disable-next-line consistent-return
  return fetch
    .post(
      `${URL}/token/refresh?selected_organisation=${organisationId}`,
      bodyFormData
    )
    .then(response => {
      setCookie(TOKEN, response.data.token);
      setCookie(REFRESH_TOKEN, response.data.refresh_token);

      return response;
    });
};

fetch.interceptors.response.use(
  response => response,
  error => {
    const refreshToken = getRefreshToken();

    if (
      error.response &&
      error.response.data &&
      error.response.data.message === 'Unauthorized'
    ) {
      throw error;
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.config.isRetryRequest === undefined &&
      !error.config.url.includes('token/refresh') &&
      refreshToken
    ) {
      return fetch
        .refreshToken()
        .then(() => fetch({ ...error.config, isRetryRequest: true }));
    }
    throw error;
  }
);
