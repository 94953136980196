import { fetch } from './interceptors';
import { prepareUsers } from 'helpers/usersHelpers';

import {
  ALL_INVENTION_STATUSES,
  ALL_KNOWLEDGE_DATABASE_STATUSES,
  ANSWERS_PREFIX,
  API_PREFIX,
  FIELDS_PREFIX,
  INVENTION_TYPES_PREFIX,
  SUPERVISOR_STATUSES
} from 'constants/requestsContants';

import {
  STATUS_ASSIGNMENT,
  STATUS_PRE_ASSESSMENT
} from 'constants/inventionStatuses';
import { INVENTIONS_PAGE } from 'constants/routingConstants';

export const getToken = code => fetch.post('token/issue', { code });
export const refreshToken = (bodyForm, masterId) =>
  fetch.post(
    `token/refresh${masterId ? `?master_id=${masterId}` : ``}`,
    bodyForm
  );
export const getUser = user => fetch.get(user);
export const authLogout = () => fetch.post('auth/logout');

// ANSWERS REQUESTS
/**
 * fields should has form {id: [integer id value], value: [value of field]}
 * if you give createSurvey=false then you must pass surveyId which you created before, if createSurvey=true
 * then survey will be created during request but you must pass formId
 *
 * @param surveyId
 * @param formId
 * @param fields
 */

export const createAnswer = ({ surveyId, fields = [] }) => {
  const preparedFields = fields.map(field => ({
    type: 'answer',
    attributes: {
      additional: field.additionalValue || '',

      value: field.value,
      field: {
        id:
          isNaN(field.id) && field.id.includes('fields')
            ? field.id
            : API_PREFIX + FIELDS_PREFIX + '/' + field.id,
        type: 'field'
      },
      survey: {
        id: surveyId,
        type: 'survey'
      }
    }
  }));

  return fetch.post(API_PREFIX + ANSWERS_PREFIX, { data: preparedFields });
};

// INVENTIONS REQUESTS
export const createInvention = ({
  inventionTypeId,
  name,
  status,
  coInventorsObjectsArray = [],
  proposed_experts,
  proposed_review_managers
}) =>
  fetch.post(API_PREFIX + INVENTIONS_PAGE, {
    data: {
      type: 'invention',
      attributes: {
        invention_type: {
          id: API_PREFIX + INVENTION_TYPES_PREFIX + '/' + inventionTypeId,
          type: 'invention_type'
        },
        status: status,
        name,
        proposed_review_managers: prepareUsers(proposed_review_managers),
        proposed_experts: prepareUsers(proposed_experts),
        co_inventors: prepareUsers(coInventorsObjectsArray)
      }
    }
  });

export const updateInvention = ({
  name,
  status,
  coInventorsObjectsArray,
  inventionId,
  file,
  proposed_experts,
  proposed_review_managers
}) =>
  fetch.patch(`${API_PREFIX}${INVENTIONS_PAGE}/${inventionId}`, {
    data: {
      type: 'invention',
      attributes: {
        status: status,
        figure: file,
        name,
        proposed_review_managers: prepareUsers(proposed_review_managers),
        proposed_experts: prepareUsers(proposed_experts),
        co_inventors: prepareUsers(coInventorsObjectsArray)
      }
    }
  });

// SECTION REQUESTS
export const createNewSection = section =>
  fetch.post(`${API_PREFIX}/sections`, section);
export const editSection = (url, section) => fetch.patch(url, section);
export const removeSection = url => fetch.delete(url);

//  FORM TYPES REQUESTS
export const addNewInventionType = data =>
  fetch.post(`${API_PREFIX}/invention_types`, data);
export const addNewAssessmentType = data =>
  fetch.post(`${API_PREFIX}/assessment_types`, data);
export const editFormTypeMetaData = (url, data) => fetch.patch(url, data);
export const lockFormType = (id, isInventionType) =>
  fetch(
    `${API_PREFIX}/${
      isInventionType ? `invention_types` : `assessment_types`
    }/${id}`,
    {
      method: 'lock',
      headers: {
        'Content-type': 'application/vnd.api+json'
      },
      data: {}
    }
  );

export const unlockFormType = (id, isInventionType) => {
  fetch(
    `${API_PREFIX}/${
      isInventionType ? `invention_types` : `assessment_types`
    }/${id}`,
    {
      method: 'unlock',
      headers: {
        'Content-type': 'application/vnd.api+json'
      },
      data: {}
    }
  );
};

export const fetchFormType = (id, isInventionType) =>
  fetch.get(
    `${API_PREFIX}/${
      isInventionType ? 'invention_types' : 'assessment_types'
    }/${id}`
  );

//  FIELDS REQUESTS
export const addNewField = data => fetch.post(`${API_PREFIX}/fields`, data);
export const editField = (url, data) => fetch.patch(url, data);
export const removeField = url => fetch.delete(url);

// INVENTION TYPE REQUESTS
export const getInventionTypes = (page = false, pagination = 'true', params) =>
  fetch.get(
    API_PREFIX +
      INVENTION_TYPES_PREFIX +
      ('?pagination=' + pagination) +
      (page ? '&page=' + page : '') +
      (params || '')
  );

export const getInventionTypeList = page =>
  fetch.get(`${API_PREFIX}/invention_types${page ? `?page=${page}` : ''}`);

// ASSESSMENT TYPE REQUESTS
export const getAssessmentTypeList = page =>
  fetch.get(`${API_PREFIX}/assessment_types${page ? `?page=${page}` : ''}`);

// ORGANISATION REQUESTS
export const getGroupedOrganisationUsers = id =>
  fetch.get(`${API_PREFIX}/organisations/${id}/users/grouped`);

export const getOrganisationInventions = (
  organisationId,
  payload,
  hasStatusFiltersApplied,
  isSupervisor
) => {
  const apiStatuses = isSupervisor
    ? SUPERVISOR_STATUSES
    : ALL_INVENTION_STATUSES;

  return fetch.get(
    `${API_PREFIX}/organisations/${organisationId}/inventions?${
      !hasStatusFiltersApplied ? `${apiStatuses}${payload}` : payload
    }`
  );
};

export const getUnassignedInventions = payload =>
  fetch.get(
    `${API_PREFIX}/inventions?status=${STATUS_ASSIGNMENT}${payload || ''}`
  );

export const getMyPreAssessments = payload =>
  fetch.get(
    `${API_PREFIX}/inventions?status=${STATUS_PRE_ASSESSMENT}&reviewManager=${
      payload.currentUserId
    }${payload.params || ''}`
  );

export const getMyAssessments = payload =>
  fetch.get(
    `${API_PREFIX}/assessments?expert=${payload.currentUserId}${
      payload.params || ''
    }`
  );

export const getAllAssessments = payload =>
  fetch.get(`${API_PREFIX}/assessments${payload || ''}`);

export const getKnowledgeDatabase = (
  organisationId,
  params,
  hasStatusFiltersApplied
) =>
  fetch.get(
    `${API_PREFIX}/organisations/${organisationId}/inventions?${
      !hasStatusFiltersApplied
        ? `${ALL_KNOWLEDGE_DATABASE_STATUSES}${params}`
        : params
    }`
  );

// SINGLE INVENTION
export const getInvention = id => fetch.get(`${API_PREFIX}/inventions/${id}`);

export const patchInvention = (id, data) =>
  fetch.patch(`${API_PREFIX}/inventions/${id}`, data);

export const getInventionTimeline = id =>
  fetch.get(`${API_PREFIX}/inventions/${id}/timelines?pagination=false`);

export const getInventionNotes = id =>
  fetch.get(`${API_PREFIX}/inventions/${id}/notes`);

export const getInventionAttachments = id =>
  fetch.get(`${API_PREFIX}/inventions/${id}/attachments`);

export const postNote = payload => fetch.post(`${API_PREFIX}/notes`, payload);

export const getInventionAssessments = payload =>
  fetch.get(
    `${API_PREFIX}/inventions/${payload.id}/assessments${
      payload.page ? `?page=${payload.page}` : ''
    }`
  );

export const getSingleAssessment = id =>
  fetch.get(`${API_PREFIX}/assessments/${id}`);

export const patchAssessment = (id, payload) =>
  fetch.patch(`${API_PREFIX}/assessments/${id}`, payload);

export const assignExpert = data =>
  fetch.post(`${API_PREFIX}/assessments`, data);

export const createAnswersForReview = data =>
  fetch.post(`${API_PREFIX}/answer_reviews`, data);

export const createReview = data =>
  fetch.post(`${API_PREFIX}/survey_reviews`, data);

export const updateReview = (id, data) => fetch.patch(`${id}`, data);

export const uploadAttachment = data =>
  fetch.post(`${API_PREFIX}/attachments`, data);
export const deleteAttachment = url => fetch.delete(url);

export const generatePDF = inventionId =>
  fetch.post(`${API_PREFIX}/inventions/${inventionId}/export`, { type: 'pdf' });

export const getPDFUrl = url => fetch.get(url);

export const getAssessmentsCounterData = () =>
  fetch.get(`${API_PREFIX}/assessments/count`);

export const getInventionsCounterData = payload =>
  fetch.get(`${API_PREFIX}/inventions/count${payload}`);
