import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ center, inline, size, color }) => (
  <div className={`${center && `text-center`} ${inline && `d-inline`}`}>
    <div
      className={`spinner-border ${!color && `text-primary`}`}
      style={{
        ...(size && { height: size, width: size }),
        ...(color && { color })
      }}
    />
  </div>
);

Spinner.propTypes = {
  center: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string
};

export default memo(Spinner);
