import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { STATUS_DRAFT } from 'constants/inventionStatuses';

import {
  getInventionAttachments,
  getAreAttachmentsLoading
} from 'store/singleInvention/singleInventionSelectors';

import {
  fetchInventionAttachments,
  setAttachmentFormValue
} from 'actions/singleInventionActions';

import Spinner from 'Atoms/Spinner';
import Dropzone from 'Elements/Dropzone';
import Attachment from './Attachment';
import { isOnlySupervisor } from 'helpers/usersHelpers';
import { getCurrentUserRoles } from 'store/users/usersSelectors';

const Attachments = ({
  attachments,
  currentUserRoles,
  data,
  isEdited,
  type,
  inventionId,
  status,
  disabled,
  fetchInventionAttachments,
  setAttachmentFormValue,
  assessmentId,
  isPreAssessment,
  isAssessmentAttachment,
  isLoading
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    fetchInventionAttachments();
    // eslint-disable-next-line
  }, []);

  const setIsConfidential = index => {
    const attachment = data[index];
    attachment.data.attributes.confidential = !attachment.confidential;
    setAttachmentFormValue(attachment);
  };

  const setDescription = (index, description) => {
    const attachment = data[index];
    attachment.data.attributes.description = description;
    setAttachmentFormValue(attachment);
  };

  const hasOnlySupervisorRole = isOnlySupervisor(currentUserRoles);

  return (
    <Fragment>
      {isLoading ? (
        <Spinner center />
      ) : (
        <>
          {data && data.length
            ? data.map((el, index) => (
              <Attachment
                  key={index}
                  setDescription={description =>
                    setDescription(index, description)
                  }
                  confidential={el.data.attributes.confidential}
                  description={el.data.attributes.description}
                  disabled={disabled || hasOnlySupervisorRole}
                  file={el}
                  id={el.data.id}
                  index={index}
                  inventionId={inventionId}
                  isAssessmentAttachment={isAssessmentAttachment}
                  isEdited={isEdited}
                  name={el.data.attributes.file_name}
                  owner={el.data.attributes.owner}
                  setIsConfidential={() => setIsConfidential(index)}
                  toUpload={el.data.toUpload}
                  type={type}
                  url={el.data.attributes.url}
                />
              ))
            : !disabled && <div>{t('noAttachments')}</div>}
          {!disabled && !hasOnlySupervisorRole && (
            <Dropzone
              assessmentId={assessmentId}
              attachments={data}
              inventionId={inventionId}
              isPreAssessment={isPreAssessment}
              status={status}
              type={type}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  currentUserRoles: getCurrentUserRoles(state),
  data:
    ownProps.type === STATUS_DRAFT || ownProps.isAssessmentAttachment
      ? ownProps.attachments
      : getInventionAttachments(state),
  isLoading: getAreAttachmentsLoading(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventionAttachments,
      setAttachmentFormValue
    },
    dispatch
  );

Attachments.propTypes = {
  assessmentId: PropTypes.number,
  attachments: PropTypes.arrayOf(PropTypes.object),
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  fetchInventionAttachments: PropTypes.func,
  inventionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAssessmentAttachment: PropTypes.bool,
  isEdited: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPreAssessment: PropTypes.bool,
  setAttachmentFormValue: PropTypes.func,
  status: PropTypes.number,
  type: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
