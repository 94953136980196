import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { t } from 'helpers/i18n';
import DefaultModal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Input from 'Atoms/Input';
import Textarea from 'Atoms/Textarea';
import { DEFAULT_RED } from 'constants/colors';

class NewSectionModal extends React.Component {
  state = {
    data: {
      type: 'section',
      attributes: {
        name: '',
        description: '',
        form: {
          id: `${this.props.formId}`,
          type: 'form'
        }
      }
    },
    unfilledFields: []
  };

  displayModal = (value, type) => {
    this.setState({
      [type]: value
    });
  };

  changeSectionAttributes = (type, value) => {
    let attributes = { ...this.state.data.attributes };
    attributes[type] = value;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: attributes
      }
    });
  };

  handleClose = () => this.props.onHide(false, 'newSectionModal');

  handleConfirm = () => {
    this.setState({ unfilledFields: [] });
    const { attributes } = this.state.data;
    let emptyFields = [];
    !attributes.name && emptyFields.push('name');
    !attributes.description && emptyFields.push('description');

    if (emptyFields.length) {
      this.setState({ unfilledFields: emptyFields });
      toast.error(this.props.t('fillRequiredFields'));
      return;
    }

    this.handleClose();
    this.props.addSection({ data: this.state.data });
  };

  render() {
    const content = (
      <>
        <Input
          borderColor={
            this.state.unfilledFields.includes('name') && DEFAULT_RED
          }
          label={t('addName')}
          isRequired
          onChange={e => this.changeSectionAttributes('name', e)}
        />
        <Textarea
          isRequired
          label={t('addDescription')}
          onChange={e => this.changeSectionAttributes('description', e)}
          borderColor={
            this.state.unfilledFields.includes('description') && DEFAULT_RED
          }
        />
      </>
    );
    const footer = (
      <>
        <Button value={t('cancel')} onClick={this.handleClose} />
        <Button
          colorVariant="green"
          value={t('add')}
          onClick={this.handleConfirm}
        />
      </>
    );
    return (
      <DefaultModal
        isOpen
        size="sm"
        onHide={this.handleClose}
        content={content}
        footer={footer}
        title={t('addNewSection')}
      />
    );
  }
}

export default withTranslation('iamip')(NewSectionModal);
