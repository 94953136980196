/* eslint-disable array-callback-return */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import DefaultModal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import Input from 'Atoms/Input';
import Textarea from 'Atoms/Textarea';
import Checkbox from 'Atoms/Checkbox';
import Radio from 'Atoms/Radio';
import Select from 'react-select';
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {
  faCaretSquareDown,
  faPlusCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {
  AddIconWrapper,
  ExpandedInputWrapper,
  InputBox,
  InputBoxLabel,
  InputWrapper,
  SelectContainer,
  TrashWrapper
} from './styles';

import {
  INFORMATION_FIELD_ID,
  TEXT_FIELD_ID,
  CHECKBOX_FIELD_ID,
  SELECT_FIELD_ID,
  EXTENDED_FIELD_ID
} from 'constants/formTypeFieldConstants';

import { DEFAULT_RED } from 'constants/colors';

class NewInputTypeModal extends React.Component {
  state = {
    data: {
      id: '',
      type: '',
      attributes: {
        name: '',
        description: '',
        options: { fields: [{}, {}] },
        required: false,
        position: this.props.position,
        type: this.props.type
      }
    },
    unfilledFields: [],
    duplicatedOptions: []
  };

  componentDidMount() {
    const { field } = this.props;

    if (field) {
      this.setState({
        data: field
      });
    }

    if ((field && field.attributes.options.fields.length === 0) || !field) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          attributes: {
            ...this.state.data.attributes,
            options: { fields: [{}, {}] }
          }
        }
      });
    }
  }

  prepareDataToSend = () => {
    const dataToSend = {
      data: {
        type: 'field',
        attributes: {
          ...this.state.data.attributes,
          type: this.props.type,
          section: {
            id: this.props.sectionId,
            type: 'section'
          }
        }
      }
    };

    if (this.props.field) {
      this.props.handleEditField(
        this.props.field.id,
        dataToSend,
        null,
        this.props.sectionId
      );
    } else {
      this.props.handleNewField(dataToSend);
    }
  };

  setAttributeValue = (type, value) => {
    const options = { ...this.state.data.attributes.options };

    options[type] = value;

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: {
          ...this.state.data.attributes,
          options: options
        }
      }
    });
  };

  setIsRequired = () => {
    this.setState(prevState => ({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: {
          ...this.state.data.attributes,
          required: !prevState.data.attributes.required
        }
      }
    }));
  };

  setInputValue = (type, value) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: {
          ...this.state.data.attributes,
          [type]: value
        }
      }
    });
  };

  onOptionsActions = (actionType, params) => {
    const options = { ...this.state.data.attributes.options };
    let fields = [...options.fields];

    if (actionType === 'add') {
      fields = fields.concat({ label: '', value: '' });
    }
    if (actionType === 'edit') {
      fields[params.index].label = params.e;
      fields[params.index].value = params.e;
    }
    if (actionType === 'remove') {
      fields.splice(params.index, 1);
    }

    if (actionType === 'setDefault') {
      fields.map((el, index) => {
        if (params.index === index) {
          el.default = true;
          return;
        }
        delete el.default;
      });
    }
    options.fields = fields;
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: {
          ...this.state.data.attributes,
          options: options
        }
      }
    });
  };

  expandRadioButton = (index, isExpanded, value) => {
    const options = { ...this.state.data.attributes.options };
    if (!value) {
      options.fields[index].isExpanded = !isExpanded;
    }

    options.fields[index].expandedLabel = value;

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        attributes: {
          ...this.state.data.attributes,
          options: options
        }
      }
    });
  };

  shouldBeDisabled = () => {
    this.setState({ unfilledFields: [] });
    const {
      data: { attributes }
    } = this.state;

    const emptyFields = [];

    !attributes.name && emptyFields.push('name');
    !attributes.description && emptyFields.push('description');

    if (
      this.props.type !== TEXT_FIELD_ID &&
      this.props.type !== INFORMATION_FIELD_ID &&
      attributes.options.fields.length > 1
    ) {
      attributes.options.fields.forEach((el, index) => {
        if (!el.value) {
          emptyFields.push(index);
        }
      });
    }

    this.setState({ unfilledFields: emptyFields });

    return Boolean(emptyFields.length);
  };

  setLimitInputCharacter = e => {
    const intValue = parseInt(e);
    if (!isNaN(intValue) && intValue > 0) {
      this.setAttributeValue('maxLength', intValue);
    } else {
      this.setAttributeValue('maxLength', '');
    }
  };

  renderTextInputContent = type => {
    const { attributes } = this.state.data;
    return (
      <>
        <Input
          borderColor={
            this.state.unfilledFields.includes('name') && DEFAULT_RED
          }
          label={t('addInputName')}
          value={attributes.name}
          isRequired
          onChange={e => this.setInputValue('name', e)}
        />
        <Textarea
          borderColor={
            this.state.unfilledFields.includes('description') && DEFAULT_RED
          }
          label={t('addInputDescription')}
          value={attributes.description}
          isRequired
          onChange={e => this.setInputValue('description', e)}
        />
        {type === 1 && (
          <Input
            label={t('limitInputCharacters')}
            onChange={e => this.setLimitInputCharacter(e)}
            value={attributes.options.maxLength}
          />
        )}
      </>
    );
  };

  renderSelectContent = () => {
    const { attributes } = this.state.data;

    const isMultiSelect = attributes.options.multiselect;

    return (
      <>
        {attributes.options.fields.length > 0 && (
          <SelectContainer>
            <Select
              options={attributes.options.fields}
              title={t('chooseOption')}
              isMulti={isMultiSelect}
            />
            <Checkbox
              onCheck={() =>
                this.setAttributeValue('multiselect', !isMultiSelect)
              }
              label={t('multiselect')}
              checked={isMultiSelect}
            />
          </SelectContainer>
        )}
      </>
    );
  };

  renderOptionalCheckbox = () => (
    <Checkbox
      onCheck={() => this.setIsRequired()}
      label={t('optionalOrMandatory')}
      checked={this.state.data.attributes.required}
    />
  );

  renderEmptyInputBox = () => (
    <>
      {this.state.data.attributes.options.fields.length < 10 && (
        <>
          <AddIconWrapper absolute>
            <Tooltip
              content={t('addNewInput')}
              direction="up"
              useDefaultStyles
              zIndex={1500000}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                onClick={() => this.onOptionsActions('add')}
              />
            </Tooltip>
          </AddIconWrapper>
        </>
      )}
    </>
  );

  renderOptions = type => (
    <>
      {this.state.data.attributes.options.fields.map((el, index) => (
        <InputWrapper key={index}>
          {type !== SELECT_FIELD_ID &&
            (type === CHECKBOX_FIELD_ID ? (
              <Checkbox
                checked={el.default}
                onCheck={() => this.onOptionsActions('setDefault', { index })}
              />
            ) : (
              <Radio
                checked={el.default || false}
                onCheck={() => this.onOptionsActions('setDefault', { index })}
              />
            ))}
          <Input
            value={el.value}
            onChange={e => this.onOptionsActions('edit', { index, e })}
            placeholder={
              type === SELECT_FIELD_ID ? t('addOption') : t('addLabel')
            }
            isRequired
            borderColor={
              this.state.unfilledFields.includes(index) ||
              this.state.duplicatedOptions.includes(el.value)
                ? DEFAULT_RED
                : null
            }
          />
          <TrashWrapper type={type}>
            {type === EXTENDED_FIELD_ID && (
              <Tooltip
                content={t('expandInput')}
                direction="up"
                useDefaultStyles
                zIndex={1500000}
              >
                <FontAwesomeIcon
                  icon={faCaretSquareDown}
                  onClick={() => this.expandRadioButton(index, el.isExpanded)}
                />
              </Tooltip>
            )}
            {index > 1 && (
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => this.onOptionsActions('remove', { index })}
              />
            )}
          </TrashWrapper>
          {el.isExpanded && (
            <ExpandedInputWrapper>
              <Textarea
                placeholder={t('addAdditionalQuestion')}
                value={el.expandedLabel}
                onChange={e => {
                  this.expandRadioButton(index, null, e);
                }}
              />
            </ExpandedInputWrapper>
          )}
        </InputWrapper>
      ))}
    </>
  );

  renderInputBox = type => (
    <>
      <InputBoxLabel>{t('defineYourFields')}</InputBoxLabel>
      <InputBox>
        <>
          {this.renderEmptyInputBox()}
          {type === SELECT_FIELD_ID && this.renderSelectContent()}
          {type !== INFORMATION_FIELD_ID && this.renderOptions(type)}
        </>
      </InputBox>
    </>
  );

  handleCancel = () => {
    this.props.onHide(false);
  };

  hasUniqueValues = () => {
    this.setState({ duplicatedOptions: [] });
    const { fields } = this.state.data.attributes.options;

    const duplicatedOptions = fields
      .map(f => f.label)
      .filter((field, index, array) => field && array.indexOf(field) !== index);

    if (duplicatedOptions.length) {
      this.setState({ duplicatedOptions });
      toast.error(
        this.props.t('duplicatedFields', {
          fields: duplicatedOptions.join(', ')
        })
      );
    }

    return !duplicatedOptions.length;
  };

  handleConfirm = typeToRender => {
    if (this.shouldBeDisabled()) {
      toast.error(this.props.t('fillRequiredFields'));
      return;
    }

    if (!this.hasUniqueValues()) {
      return;
    }

    this.prepareDataToSend(typeToRender);
    this.handleCancel();
  };

  render() {
    const { type } = this.props;

    const typeToRender = this.props.field
      ? this.props.field.attributes.type
      : type;

    const content = (
      <>
        {this.renderTextInputContent(typeToRender)}
        {typeToRender !== TEXT_FIELD_ID &&
          typeToRender !== INFORMATION_FIELD_ID &&
          this.renderInputBox(typeToRender)}
        {typeToRender !== INFORMATION_FIELD_ID && this.renderOptionalCheckbox()}
      </>
    );
    const footer = (
      <>
        <Button value={t('cancel')} onClick={this.handleCancel} />
        <Button
          colorVariant="green"
          value={this.props.isEdited ? t('update') : t('add')}
          onClick={() => this.handleConfirm(typeToRender)}
        />
      </>
    );
    return (
      <DefaultModal
        isOpen
        size="lg"
        onHide={this.handleCancel}
        content={content}
        footer={footer}
        title={t('saveYourInput')}
      />
    );
  }
}

export default withTranslation('iamip')(NewInputTypeModal);
