import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  SERIAL_NUMBER,
  DELIMITER
} from 'constants/createFormTypeFieldsConstants';

import {
  FieldContainer,
  InputTypeContainer,
  FieldsWrapper,
  DelimiterWrapper,
  Title,
  DraggableWrappper,
  SerialNumberLabel,
  SelectWrapper,
  RequiredLabel
} from './styles';
import { useTranslation } from 'react-i18next';
import Input from 'Atoms/Input';
import Select from 'react-select';

const InventionTypeIdCreator = ({
  template,
  setInventionTypeIdValues,
  removeInventionTypeIdField,
  borderColor
}) => {
  const { t } = useTranslation();

  const options = [
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 }
  ];

  const getFieldValue = type =>
    Object.values(
      template.template.find(el => Object.keys(el)[0] === type) || []
    )[0];

  return (
    <FieldsWrapper>
      <Title>{t('defineFormId')}</Title>
      <InputTypeContainer isDelimiter>
        <Input
          label={t('delimiter')}
          onChange={e => {
            setInventionTypeIdValues(e, DELIMITER);
          }}
          value={template.delimiter}
          maxLength={1}
        />
      </InputTypeContainer>
      <Droppable droppableId={'inventionIdCreator'} direction="horizontal">
        {provided => (
          <FieldContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dragHandleProps}
          >
            {template.template.map((el, index) => {
              const keyName = Object.keys(el)[0];
              const serialNumberValue = getFieldValue(SERIAL_NUMBER);
              const fixedValue = getFieldValue('fixed');

              return (
                <DraggableWrappper>
                  <Draggable
                    draggableId={`${keyName}-${index}`}
                    index={index}
                    key={keyName}
                  >
                    {provided => (
                      <>
                        <InputTypeContainer
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {keyName !== SERIAL_NUMBER && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() =>
                                removeInventionTypeIdField(keyName)
                              }
                            />
                          )}
                          {keyName === SERIAL_NUMBER ? (
                            <>
                              <SerialNumberLabel>
                                {t('serial_number')}
                              </SerialNumberLabel>
                              <SelectWrapper border={borderColor}>
                                <Select
                                  options={options}
                                  onChange={e => {
                                    setInventionTypeIdValues(
                                      e.value,
                                      SERIAL_NUMBER
                                    );
                                  }}
                                  value={
                                    serialNumberValue && {
                                      value: serialNumberValue,
                                      label: serialNumberValue
                                    }
                                  }
                                />
                              </SelectWrapper>
                              <RequiredLabel>
                                {t('requiredField')}
                              </RequiredLabel>
                            </>
                          ) : (
                            <Input
                              key={index}
                              type="text"
                              label={t(keyName)}
                              value={el.date ? t(el.date) : fixedValue || ''}
                              disabled={keyName === 'date'}
                              maxLength={keyName === 'fixed' ? 5 : null}
                              onChange={e => {
                                setInventionTypeIdValues(e, keyName);
                              }}
                              placeholder={t('addValue')}
                            />
                          )}
                        </InputTypeContainer>
                        {index < template.template.length - 1 && (
                          <DelimiterWrapper>
                            {template.delimiter}
                          </DelimiterWrapper>
                        )}
                      </>
                    )}
                  </Draggable>
                </DraggableWrappper>
              );
            })}

            {provided.placeholder}
          </FieldContainer>
        )}
      </Droppable>
    </FieldsWrapper>
  );
};

export default InventionTypeIdCreator;
