import React, { useState, useEffect } from 'react';
import Textarea from 'Atoms/Textarea';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getField } from 'store/newInventionForm/newInventionFormSelectors';
import { setField } from 'actions/newInventionFormActions';
import Radio from 'Atoms/Radio';

import { OptionsWrapper } from './styles';

const AdvancedRadioType = ({ field, fieldState, setField, answers }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedLabel, setExpandedLabel] = useState();
  const [additionalValue, setAdditionalValue] = useState();

  const setDefaultField = () => {
    if (fieldState) {
      const selectedOptionSettings = field.attributes.settings.options.find(
        o => fieldState.value === o.value
      );
      setIsExpanded(
        selectedOptionSettings && selectedOptionSettings.isExpanded
      );
      return;
    }

    const defaultField = field.attributes.settings.options.find(
      option => option.default
    );

    if (defaultField && !fieldState) {
      setField(field.attributes.id, defaultField.value);

      if (defaultField.isExpanded) {
        setIsExpanded(true);
        setExpandedLabel(defaultField.expandedLabel);
      }
    }

    if (answers) {
      const attributes = { ...answers.data.attributes };
      setField(field.attributes.id, answers.data.attributes.value);

      if (attributes.additional) {
        setIsExpanded(true);
        setAdditionalValue(attributes.additional);
        setField(field.attributes.id, attributes.value, attributes.additional);
        setExpandedLabel(
          field.attributes.settings.options.find(
            el => el.value === answers.data.attributes.value
          )?.expandedLabel
        );
      }
    }
  };

  useEffect(() => {
    setDefaultField();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fieldState &&
      fieldState.additionalValue &&
      setAdditionalValue(fieldState.additionalValue);
  }, [fieldState]);

  const onCheck = (option, isNotChecked) => {
    setField(
      field.attributes.id,
      isNotChecked ? null : option.value,
      option.isExpanded && additionalValue
    );

    setIsExpanded(!isNotChecked && option.isExpanded);
    setExpandedLabel(option.isExpanded && option.expandedLabel);

    if (isNotChecked) {
      setAdditionalValue(null);
      setField(fieldState.id, null, null);
    }
  };

  const handleAdditionalValueChange = value => {
    setAdditionalValue(value);
    setField(fieldState.id, fieldState.value, value);
  };

  return (
    <>
      <OptionsWrapper marginBottom>
        {field.attributes.settings.options.map((option, i) => (
          <Radio
            key={i}
            value={option.value}
            name={field.attributes.name}
            checked={fieldState && option.value === fieldState.value}
            onCheck={isChecked => onCheck(option, isChecked)}
            label={option.label}
          />
        ))}
      </OptionsWrapper>
      {isExpanded && fieldState && (
        <Textarea
          onChange={handleAdditionalValueChange}
          value={(fieldState && fieldState.additionalValue) || additionalValue}
          label={expandedLabel}
        />
      )}
    </>
  );
};

const mapStateToProps = createPropsSelector({
  fieldState: getField
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setField
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedRadioType);
