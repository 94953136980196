export const getFilterAndSearchValues = state => state.filterAndSearch.filters;
export const getHasAnyFiltersSaved = state =>
  Boolean(
    Object.values(state.filterAndSearch.filters).reduce(
      (acc, el) => acc || el,
      false
    )
  );
export const getAreFiltersApplied = state =>
  state.filterAndSearch.areFiltersApplied;
