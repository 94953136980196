import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Tabs from 'Atoms/Tabs';
import InventionTimeline from 'Elements/InventionTimeline';
import Attachments from 'Elements/Attachments';
import Notes from 'Elements/Notes';

const InventionPreviewDetailsTabs = ({ ...props }) => {
  const { t } = useTranslation();

  const filterSavedAttachments = () =>
    props.attachments &&
    props.attachments.filter(attachment => !attachment.data.toUpload).length;

  const data = [
    {
      id: '1',
      name: t('timeline'),
      description: t('timeline'),
      content: () => (
        <InventionTimeline
          currentInventionId={props.currentInventionId}
          data={props.data}
        />
      )
    },
    {
      id: '2',
      name: t('attachments'),
      description: t('attachments'),
      cotentLength: filterSavedAttachments(),
      content: () => <Attachments inventionId={props.currentInventionId} />
    },
    {
      id: '3',
      name: t('notes'),
      description: t('notes'),
      cotentLength: props.notes?.length,
      content: () => <Notes />
    }
  ];

  const renderContent = id => {
    const details = data.find(e => e.id === id);

    return details.content();
  };

  return <Tabs content={renderContent} data={data} />;
};

InventionPreviewDetailsTabs.propTypes = {
  attachments: PropTypes.array,
  currentInventionId: PropTypes.string,
  data: PropTypes.object,
  notes: PropTypes.array
};

export default InventionPreviewDetailsTabs;
