import {
  getLoginState as loginState,
  getRefreshToken as refreshToken
} from 'helpers/cookies';

export const getIsLoading = state => state.login.isLoading;
export const getIsAuth = state => state.login.isAuth;
export const getLoginState = state => state.login.loginState || loginState();
export const getRefreshToken = state =>
  state.login.refreshToken || refreshToken();
export const getTryToLoginAgain = state => state.login.tryToLoginAgain;
