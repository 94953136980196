import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { t } from 'helpers/i18n';

import {
  getGroupedOrganisationUsers,
  refreshToken
} from 'helpers/axios/axiosRequests';

import {
  fetchOrganisationUsers,
  changeOrganisation
} from 'actions/organisationActions';
import { fetchUserData } from 'store/users/routines';

import { getAreUsersFetched } from 'store/users/usersSelectors';
import { getOrganisationId } from 'store/organisation/organisationSelectors';

import {
  getRefreshToken,
  setCookie,
  TOKEN,
  REFRESH_TOKEN
} from 'helpers/cookies';

import { LOGIN_PAGE } from 'constants/routingConstants';
import { history } from 'store/createStore';

function* fetchOrganisationUsersSaga() {
  try {
    const areUsersFetched = yield select(getAreUsersFetched);

    if (areUsersFetched) {
      yield put(fetchOrganisationUsers.success());
      return;
    }

    const id = yield select(getOrganisationId);

    const { data } = yield call(getGroupedOrganisationUsers, id);

    yield put(fetchOrganisationUsers.success(data.data));
  } catch (error) {
    yield put(fetchOrganisationUsers.failure());
    yield toast.error(t('getOrganisationUsersFailure'));
    console.error(error);
  }
}

function* changeOrganisationSaga({ payload }) {
  try {
    const token = getRefreshToken();
    let bodyFormData = new FormData();
    bodyFormData.append('refresh_token', token);

    const { data } = yield call(refreshToken, bodyFormData, payload);
    setCookie(TOKEN, data.token);
    setCookie(REFRESH_TOKEN, data.refresh_token);
    yield put(fetchUserData());
  } catch (e) {
    history.push(LOGIN_PAGE);
    console.error(e);
  }
}

export default function* organisationSaga() {
  yield all([
    takeEvery(fetchOrganisationUsers.TRIGGER, fetchOrganisationUsersSaga),
    takeEvery(changeOrganisation.TRIGGER, changeOrganisationSaga)
  ]);
}
