import typeToReducer from 'type-to-reducer';

import {
  fetchInventionAssessments,
  fetchAssessmentTypes,
  assignInventionExpert,
  fetchSingleAssessment,
  CLEAR_SINGLE_ASSESSMENT,
  answerAssessment,
  reviewAssessment,
  fetchAssessmentsCounter,
  fetchPreassessmentsCounter,
  fetchUnassignedIdeasCounter
} from 'actions/inventionAssessmentActions';

import { deleteTemporaryAttachment } from 'actions/attachmentsActions';

const defaultState = {
  assessments: {},
  templates: [],
  isFetching: false,
  isSingleAssessmentFetching: false,
  isFetchingTemplates: false,
  isAssigningExpert: false,
  singleAssessment: {},
  isAssessmentBeingAnswered: false,
  isFetchingAssessmentsCounter: false,
  assessmentsCounter: 0,
  isFetchingPreassessmentsCounter: false,
  preassessmentsCounter: 0,
  isFetchingUnassignedIdeasCounter: false,
  unassignedIdeasCounter: 0
};

export default typeToReducer(
  {
    [fetchInventionAssessments.TRIGGER]: state => ({
      ...state,
      isFetching: true
    }),
    [fetchInventionAssessments.SUCCESS]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      assessments: payload
    }),
    [fetchAssessmentTypes.TRIGGER]: state => ({
      ...state,
      isFetchingTemplates: true
    }),
    [fetchAssessmentTypes.SUCCESS]: (state, { payload }) => {
      const dataToSave = payload.map(el => ({
        value: el.id,
        label: el.attributes.name,
        deadline: el.attributes.deadline
      }));

      return {
        ...state,
        templates: dataToSave,
        isFetchingTemplates: false
      };
    },
    [assignInventionExpert.TRIGGER]: state => ({
      ...state,
      isAssigningExpert: true
    }),
    [assignInventionExpert.SUCCESS]: state => ({
      ...state,
      isAssigningExpert: false
    }),
    [assignInventionExpert.FAILURE]: state => ({
      ...state,
      isAssigningExpert: false
    }),
    [fetchSingleAssessment.TRIGGER]: state => ({
      ...state,
      isSingleAssessmentFetching: true
    }),
    [fetchSingleAssessment.SUCCESS]: (state, { payload }) => ({
      ...state,
      isSingleAssessmentFetching: false,
      singleAssessment: {
        ...payload.attributes,
        id: payload.id,
        attachments: payload.attributes.invention.data.attributes.attachments
      }
    }),
    [fetchSingleAssessment.FAILURE]: state => ({
      ...state,
      isSingleAssessmentFetching: false
    }),
    [CLEAR_SINGLE_ASSESSMENT]: state => ({ ...state, singleAssessment: {} }),
    [deleteTemporaryAttachment]: (state, { payload }) => {
      if (payload.type !== 'assessment') {
        return state;
      }

      return {
        ...state,
        singleAssessment: {
          ...state.singleAssessment,
          attachments: state.singleAssessment.attachments.filter(
            el =>
              el.data.attributes.file_name !==
              payload.file.data.attributes.file_name
          )
        }
      };
    },
    [answerAssessment.TRIGGER]: state => ({
      ...state,
      isAssessmentBeingAnswered: true
    }),
    [answerAssessment.FULFILL]: state => ({
      ...state,
      isAssessmentBeingAnswered: false
    }),
    [reviewAssessment.TRIGGER]: state => ({
      ...state,
      isAssessmentBeingAnswered: true
    }),
    [reviewAssessment.FULFILL]: state => ({
      ...state,
      isAssessmentBeingAnswered: false
    }),
    [fetchAssessmentsCounter.TRIGGER]: state => ({
      ...state,
      isFetchingAssessmentsCounter: true
    }),
    [fetchAssessmentsCounter.SUCCESS]: (state, { payload }) => ({
      ...state,
      isFetchingAssessmentsCounter: false,
      assessmentsCounter: payload.total_items
    }),
    [fetchPreassessmentsCounter.TRIGGER]: state => ({
      ...state,
      isFetchingPreassessmentsCounter: true
    }),
    [fetchPreassessmentsCounter.SUCCESS]: (state, { payload }) => ({
      ...state,
      isFetchingPreassessmentsCounter: false,
      preassessmentsCounter: payload.total_items
    }),
    [fetchUnassignedIdeasCounter.TRIGGER]: state => ({
      ...state,
      isFetchingUnassignedIdeasCounter: true
    }),
    [fetchUnassignedIdeasCounter.SUCCESS]: (state, { payload }) => ({
      ...state,
      isFetchingUnassignedIdeasCounter: false,
      unassignedIdeasCounter: payload.total_items
    })
  },
  defaultState
);
