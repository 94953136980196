import styled from 'styled-components';

import { LIGHT_GRAY, DEFAULT_GREEN, WHITE } from 'constants/colors';

export const InventionCreatorForm = styled.div`
  width: 100%;
  padding: 40px;
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  background-color: ${WHITE};
  min-height: 70vh;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 60vh;
  margin-top: 10px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 21px;
  cursor: pointer;
  color: ${DEFAULT_GREEN};
`;

export const SpinnerWrapper = styled.div`
  min-height: 70vh;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;
