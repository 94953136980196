import typeToReducer from 'type-to-reducer';
import { createAnswerRoutine } from 'actions/answersActions';

const defaultState = {
  data: null,
  loading: false
};

export default typeToReducer(
  {
    [createAnswerRoutine.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [createAnswerRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload
    }),
    [createAnswerRoutine.FAILURE]: state => ({ ...state }),
    [createAnswerRoutine.FULFILL]: state => ({
      ...state,
      loading: false
    })
  },
  defaultState
);
