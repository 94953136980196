import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'Atoms/Modal';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { getIsVisible } from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';
import { bindActionCreators } from 'redux';
import Footer from 'Elements/NewInventionTypeChooseModal/Elements/Footer';
import { getInventionTypesLoading } from 'store/inventionTypes/inventionTypesSelectors';
import Content from 'Elements/NewInventionTypeChooseModal/Elements/Content';

const NewInventionTypeChooseModal = ({
  isVisible,
  closeModal,
  areInventionTypesLoading,
  inventionTypes,
  approveInventionType
}) => {
  const { t } = useTranslation();

  const [selectedInventionType, setSelectedInventionType] = useState('');

  useEffect(() => {
    if (inventionTypes.length === 1) {
      setSelectedInventionType(inventionTypes[0]);
    }
  }, [inventionTypes]);

  const selectInventionType = inventionType => {
    setSelectedInventionType(inventionType);
  };

  return (
    <Modal
      isOpen={isVisible}
      title={t('chooseTemplate')}
      onHide={closeModal}
      size="sm"
      content={
        <Content
          areInventionTypesLoading={areInventionTypesLoading}
          inventionTypes={inventionTypes}
          selectInventionType={selectInventionType}
          selectedInventionType={selectedInventionType}
        />
      }
      footer={
        <Footer
          closeModal={closeModal}
          approveInventionType={approveInventionType}
          selectedInventionType={selectedInventionType.value}
        />
      }
    />
  );
};

const mapStateToProps = createPropsSelector({
  isVisible: getIsVisible,
  areInventionTypesLoading: getInventionTypesLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewInventionTypeChooseModal);
