import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { IconWrapper } from './styles';

const AddNewButton = ({ description, onClick, label }) => (
  <IconWrapper>
    <Tooltip content={description || label} direction="up" useDefaultStyles>
      <FontAwesomeIcon icon={faPlusCircle} onClick={onClick} />
      {label && (
        <label className="form-check-label" onClick={onClick}>
          {label}
        </label>
      )}
    </Tooltip>
  </IconWrapper>
);

AddNewButton.propTypes = {
  description: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string
};

export default AddNewButton;
