import styled from 'styled-components';
import { DEFAULT_BLUE, DARK_BLUE, WHITE } from 'constants/colors';

export const ProSidebarWrapper = styled.div`
  height: calc(100vh - 50px);
  background-color: ${DEFAULT_BLUE};
  padding-top: 52px;
`;

export const ItemWrapper = styled.div``;

export const SideBarItem = styled.p`
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.div`
  ${props =>
    props.isCollapsed
      ? `text-align: center`
      : `min-width: 270px; display: flex; align-items: center;`};
  padding: 15px 25px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${DARK_BLUE};
  }
  ${props => props.active && `background-color: ${DARK_BLUE}`};
`;

export const MenuLabel = styled.span`
  padding-left: 10px;
  color: ${WHITE};
  & > a {
    color: ${WHITE};
  }
`;
