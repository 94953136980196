import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getReviewManager,
  getDecisionMaker,
  getInventor
} from 'store/singleInvention/singleInventionSelectors';

import { addNewNote } from 'actions/singleInventionActions';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';
import RichText from 'Atoms/RichText';
import Calendar from 'Atoms/Calendar';
import Radio from 'Atoms/Radio';

import { FieldWrapper } from './styles';
import { DEFAULT_RED } from 'constants/colors';

const FurtherQuestionsModal = props => {
  const { t } = useTranslation();
  const [person, setPerson] = useState();
  const [questions, setQuestions] = useState();
  const [deadline, setDeadline] = useState();
  const [areFieldsValid, setAreFieldsValid] = useState(true);

  const prepareName = person =>
    `${person.attributes.first_name} ${person.attributes.last_name}`;

  const users = [
    {
      id: 1,
      name: prepareName(props.reviewManager),
      value: props.reviewManager.id
    },
    {
      id: 2,
      name: prepareName(props.decisionMaker),
      value: props.decisionMaker.id
    },
    { id: 3, name: prepareName(props.inventor), value: props.inventor.id }
  ].reduce((acc, user) => {
    if (acc.find(u => u.value === user.value)) {
      return acc;
    } else {
      return [...acc, user];
    }
  }, []);

  const prepareUsers = () =>
    users.map(u => (
      <Radio
        label={u.name}
        value={u.id}
        inline
        checked={person === u.id}
        onCheck={setPerson}
      />
    ));

  const content = () => (
    <Fragment>
      <FieldWrapper>
        <RichText
          onChange={setQuestions}
          borderColor={!areFieldsValid && DEFAULT_RED}
        />
      </FieldWrapper>
      <FieldWrapper>
        <div>{t('whoDoYouWantToAsk')}?</div>
        {prepareUsers()}
      </FieldWrapper>
      <FieldWrapper>
        <Calendar
          minDate={new Date()}
          label={t('questionsDeadline')}
          onChange={setDeadline}
          borderColor={!areFieldsValid && DEFAULT_RED}
        />
      </FieldWrapper>
    </Fragment>
  );

  const handleConfirm = () => {
    setAreFieldsValid(true);

    if (!person || !questions || !deadline) {
      setAreFieldsValid(false);
      return;
    }

    const selectedPersonId = users.find(u => u.id === person).value;

    props.addNewNote({
      note: questions,
      isConfidential: person !== 3,
      recipient: {
        id: selectedPersonId,
        type: 'user'
      },
      response_deadline: deadline
    });

    props.closeModal();
  };

  const footer = () => (
    <Fragment>
      <Button value={t('close')} onClick={props.closeModal} />
      <Button
        colorVariant="green"
        value={t('confirm')}
        onClick={handleConfirm}
      />
    </Fragment>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      title={t('askFurtherQuestions')}
      onHide={props.closeModal}
      content={content()}
      footer={footer()}
    />
  );
};

const mapStateToProps = state => ({
  reviewManager: getReviewManager(state),
  decisionMaker: getDecisionMaker(state),
  inventor: getInventor(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addNewNote }, dispatch);

FurtherQuestionsModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  addNewNote: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FurtherQuestionsModal);
