import styled from 'styled-components';
import { DEFAULT_BLUE, DEFAULT_RED, LIGHT_GRAY, WHITE } from 'constants/colors';

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div:nth-of-type(1),
  div:nth-of-type(2),
  div:last-of-type {
    max-width: 100%;
    flex: 1 100%;
  }

  div:nth-of-type(3),
  div:nth-of-type(4),
  div:nth-of-type(5) {
    width: 32%;
  }
`;

export const FieldContainer = styled.div``;
export const InputTypeContainer = styled.div`
  margin-bottom: ${props => (props.isDelimiter ? '0px' : '20px')};
  position: relative;
  display: ${props => (props.isDelimiter ? 'block' : 'inline-block')};
  max-width: ${props => (props.isDelimiter ? '80px' : 'unset')};
  & > svg {
    position: absolute;
    right: 7px;
    top: 17px;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 20px;
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  & > div {
    display: flex;
  }
`;

export const DraggableWrappper = styled.div`
  flex: 1;
  max-width: 24% !important;
  margin: 0 19px 0 0;
  position: relative;
`;

export const DelimiterWrapper = styled.p`
  position: absolute;
  top: 29%;
  right: -12px;
`;

export const Title = styled.h6`
  font-weight: 600;
  letter-spacing: 0.25px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  & > button {
    margin-right: 5px;
  }
`;

export const SerialNumberWrapper = styled.div`
  margin: 10px 0;
`;

export const SerialNumberLabel = styled.label`
  font-size: 13px;
  font-weight: 600 !important;
  margin-bottom: 0;
  letter-spacing: -0.5px;
`;

export const SelectWrapper = styled.div`
  position: relative;
  & > div {
    z-index: 1;
    border: ${props =>
      props.border ? `1px solid ${DEFAULT_RED}` : '1px solid transparent'};
  }
`;
export const RequiredLabel = styled.span`
  font-size: 12px;
`;

export const FieldButton = styled.div`
  margin: 2px 0;
  border: 1px solid ${LIGHT_GRAY};
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${DEFAULT_BLUE};
  color: ${WHITE};
`;

export const FieldsContainer = styled.div`
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  min-height: 223px;
`;
