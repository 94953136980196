import React from 'react';
import Field from 'Pages/CreateInventionPage/Elements/Field';

const Fields = ({ fields, answers, reviews, unfilledFields }) =>
  fields.map(field => {
    const answer =
      answers &&
      answers.find(el => el.data.attributes.field.data.id === field.id);
    let review = null;
    if (answer && reviews.data) {
      // eslint-disable-next-line array-callback-return
      reviews.data.attributes.answer_reviews.map(el => {
        if (el.attributes.answer.id === answer.data.id) {
          review = { ...el };
        }
      });
    }

    return (
      <Field
        key={field.id}
        field={field}
        answers={answer}
        review={review}
        unfilledFields={unfilledFields}
      />
    );
  });

export default Fields;
