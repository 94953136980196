import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Spinner from 'Atoms/Spinner';
import { DEFAULT_BLUE, WHITE } from 'constants/colors';
import {
  CardWrapper,
  EmptyTableInfo,
  SortWrapper,
  TableCell,
  TableHeader,
  TableHeaderText
} from './styles';

import Tooltip from 'react-tooltip-lite';
import { useHistory } from 'react-router';
import {
  INVENTIONS_PAGE,
  KNOWLEDGE_DATABASE,
  MY_PRE_ASSESSMENTS,
  NOT_ASSIGNED
} from 'constants/routingConstants';

const Table = ({
  tableTitle,
  columnNames,
  rows,
  isFetching,
  defaultSort,
  onSortClick,
  limit
}) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState(
    (defaultSort && defaultSort.field) || null
  );
  const [order, setOrder] = useState(
    (defaultSort && defaultSort.order) || 'asc'
  );
  const [pageSize, setPageSize] = useState(limit);
  const history = useHistory();

  const columnNamesEntries = Object.entries(columnNames);

  const isInvention = [
    INVENTIONS_PAGE,
    NOT_ASSIGNED,
    MY_PRE_ASSESSMENTS,
    KNOWLEDGE_DATABASE
  ].includes(history.location.pathname);

  const sortableFields = {
    id: isInvention ? 'serialNumber' : 'id',
    inventionId: 'invention.serialNumber',
    assessmentInventors: [
      'invention.inventor.firstName',
      'invention.inventor.lastName'
    ],
    inventors: ['inventor.firstName', 'inventor.lastName'],
    expert: ['expert.firstName', 'expert.lastName'],
    reviewManager: ['reviewManager.firstName', 'reviewManager.lastName'],
    decisionMaker: ['decisionMaker.firstName', 'decisionMaker.lastName'],
    submissionDate: 'submissionDate',
    decisionDate: 'decisionDate',
    deadline: 'assessmentTypeVersion.deadline',
    status: 'status',
    title: 'name',
    inventionTitle: 'invention.name'
  };

  const isSortable = id => Object.keys(sortableFields).includes(id);

  const prepareIcon = id =>
    sortField === id ? (order === 'asc' ? faSortUp : faSortDown) : faSort;

  const handleSort = id => {
    setSortField(id);
    setOrder(order === 'desc' ? 'asc' : 'desc');
    setPageSize(localStorage.getItem('pageSize') || limit);
    onSortClick && onSortClick({ field: sortableFields[id], order, pageSize });
  };

  const prepareTitle = title => {
    let cutTitle = '';
    if (title && title.length > 40) {
      cutTitle = (
        <Tooltip
          content={title}
          direction="up"
          zIndex={1500000}
          useDefaultStyles
        >
          {`${title.slice(0, 35)}...`}
        </Tooltip>
      );
    }
    return cutTitle || title;
  };

  const renderRows = () =>
    rows &&
    rows.map((row, index) => (
      <tr key={index} role="row">
        {Object.values(row).map((cell, index) => {
          if (typeof cell === 'object') {
            // eslint-disable-next-line array-callback-return
            return;
          }

          if (typeof cell === 'function') {
            // eslint-disable-next-line consistent-return
            return (
              <TableCell key={index} rowsCount={columnNamesEntries.length}>
                {cell()}
              </TableCell>
            );
          }

          // eslint-disable-next-line consistent-return
          return (
            <TableCell
              key={index}
              isTitle={Object.keys(row)[index] === 'title'}
              rowsCount={columnNamesEntries.length}
            >
              {Object.keys(row)[index] === 'title' ? prepareTitle(cell) : cell}
            </TableCell>
          );
        })}
      </tr>
    ));

  return (
    <CardWrapper className="card">
      {tableTitle && (
        <div className="card-header">
          <h3 className="card-title">{tableTitle}</h3>
        </div>
      )}
      <div className="card-body">
        <div className="dataTables_wrapper dt-bootstrap4">
          <div className="row">
            <div className="col-sm-12">
              {(isFetching || !isEmpty(rows)) && (
                <table
                  className="table table-striped table-hover dataTable"
                  style={{ tableLayout: 'fixed' }}
                >
                  <thead>
                    <tr role="row">
                      {columnNamesEntries.map(([id, name]) => {
                        const canSort = isSortable(id);

                        return (
                          <TableHeader
                            key={name}
                            style={{
                              ...(canSort && { cursor: 'pointer' }),
                              width:
                                id === 'id'
                                  ? '130px'
                                  : id === 'title' || id === 'inventionTitle'
                                  ? '340px'
                                  : id === 'actions'
                                  ? '140px'
                                  : 'auto'
                            }}
                            onClick={() => canSort && handleSort(id)}
                          >
                            <TableHeaderText>{name}</TableHeaderText>
                            {canSort && (
                              <SortWrapper>
                                <FontAwesomeIcon
                                  color={DEFAULT_BLUE}
                                  icon={prepareIcon(id)}
                                />
                              </SortWrapper>
                            )}
                          </TableHeader>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {isFetching ? (
                      <tr style={{ background: WHITE }}>
                        <td colSpan={columnNamesEntries.length}>
                          <Spinner center />
                        </td>
                      </tr>
                    ) : (
                      renderRows()
                    )}
                  </tbody>
                </table>
              )}
              {isEmpty(rows) && !isFetching && (
                <EmptyTableInfo>{t('emptyListInfo')}</EmptyTableInfo>
              )}
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

Table.propTypes = {
  columnNames: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  isFetching: PropTypes.bool,
  limit: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.object),
  tableTitle: PropTypes.string,
  onSortClick: PropTypes.func
};

export default Table;
