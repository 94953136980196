import styled from 'styled-components';

import {
  BACKGROUND_GRAY,
  DARK_GRAY,
  DEFAULT_BLUE,
  LIGHT_GRAY,
  WHITE
} from 'constants/colors';

export const FieldsContainer = styled.div`
  margin-bottom: 10px;
`;

export const FieldWrapper = styled.div`
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background: ${props => (props.isInSinglePage ? WHITE : BACKGROUND_GRAY)};
`;

export const OptionsWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 25%;
    padding: 10px;
  }
  & > div:nth-child(n + 4) {
    flex-grow: 0;
  }
`;

export const OptionContainer = styled.div`
  flex: 1;
`;

export const CommentWrapper = styled.div`
  position: relative;
  padding: 10px 0;
  &:hover {
    cursor: pointer;
    color: ${DEFAULT_BLUE};
  }
`;

export const CommentInputWrapper = styled.div`
  position: relative;
`;
export const DeleteIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: -11px;
  right: 10px;
  padding: 4px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${DARK_GRAY};
  font-size: 13px;
  background-color: white;
`;

export const FieldName = styled.h6`
  margin: 20px 5px 5px 5px;
  padding: 0;
  font-weight: bold;
  font-size: 1.1rem;
`;

export const FieldDescription = styled.div`
  margin: 5px;
  color: gray;
  font-style: italic;
  white-space: pre-wrap;
`;

export const ButtonsWrapper = styled.div`
  text-align: center;
`;
export const InformationDescription = styled.p`
  white-space: pre-wrap;
`;
