import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  BUTTON_COLORS,
  DEFAULT_BLUE,
  DEFAULT_GREEN,
  EXCEL_EXPORT_GREEN
} from 'constants/colors';

const Button = ({
  buttonIcon,
  disabled,
  value,
  onClick,
  colorVariant,
  customTextColor,
  isFetching,
  width,
  small,
  height,
  margin
}) => {
  const buttonRef = useRef();
  const [buttonWidth, setButtonWidth] = useState(null);
  const [customColor, setCustomColor] = useState(null);

  const prepareColorVariant = color =>
    ({
      blue: DEFAULT_BLUE,
      green: DEFAULT_GREEN,
      exportGreen: EXCEL_EXPORT_GREEN
    }[color]);

  useEffect(() => {
    setCustomColor(prepareColorVariant(colorVariant));
  }, [colorVariant]);

  useEffect(() => {
    const { current } = buttonRef;

    current && setButtonWidth(current.offsetWidth);
    // eslint-disable-next-line
  }, [buttonRef.current]);

  const prepareClassName = () => {
    let defaultClassName = colorVariant
      ? `btn ${BUTTON_COLORS[colorVariant]}`
      : `btn btn-default`;

    if (disabled || isFetching) {
      defaultClassName = `${defaultClassName} disabled`;
    }

    if (small) {
      defaultClassName = `${defaultClassName} btn-sm`;
    }

    return defaultClassName;
  };

  return (
    <button
      ref={buttonRef}
      style={{
        ...(height && { height }),
        ...(margin && { margin }),
        ...(customColor && {
          background: customColor,
          borderColor: customColor,
          width: width || (isFetching ? `${buttonWidth}px` : undefined)
        }),
        ...(customTextColor && {
          color: customTextColor
        })
      }}
      className={prepareClassName()}
      onClick={disabled || isFetching ? undefined : onClick}
    >
      {buttonIcon || null}
      {isFetching ? (
        <span className="spinner-border spinner-border-sm" />
      ) : (
        <span>{value}</span>
      )}
    </button>
  );
};

Button.propTypes = {
  buttonIcon: PropTypes.element,
  colorVariant: PropTypes.string,
  customTextColor: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  isFetching: PropTypes.bool,
  margin: PropTypes.string,
  small: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
