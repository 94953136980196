import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';

import {
  getInventions,
  getAreInventionsLoading,
  getInventionsPagination,
  getInventionsLimit,
  getInventionsSortingOrder
} from 'store/inventions/inventionsSelectors';

import {
  getCurrentUserId,
  getCurrentUserRoles
} from 'store/users/usersSelectors';

import {
  clearInventionsSortingOrder,
  fetchInventions,
  setInventionsLimit,
  setInventionsSortingOrder
} from 'actions/inventionsActions';

import { showNewInventionTypeChooseModal } from 'actions/newInventionTypeChooseModalActions';

import {
  INVENTIONS_PAGE,
  MY_ASSESSMENTS,
  MY_PRE_ASSESSMENTS,
  NOT_ASSIGNED,
  ALL_ASSESSMENTS,
  KNOWLEDGE_DATABASE
} from 'constants/routingConstants';

import InventionsList from './InventionsList';
import AssessmentsList from './AssessmentsList';

const ListPage = ({
  clearInventionsSortingOrder,
  currentUserId,
  currentUserRoles,
  data,
  fetchInventions,
  isLoading,
  limit,
  pagination,
  setInventionsLimit,
  setInventionsSortingOrder,
  showNewInventionTypeChooseModal,
  sortingOrder
}) => {
  const history = useHistory();
  const [listType, setListType] = useState();
  const previousPage = localStorage.getItem('previousPage');
  const previousSort = JSON.parse(localStorage.getItem('previousSort'));
  const pageSize = localStorage.getItem('pageSize');

  const inventionsListsTypes = [
    INVENTIONS_PAGE,
    NOT_ASSIGNED,
    MY_PRE_ASSESSMENTS,
    MY_ASSESSMENTS,
    ALL_ASSESSMENTS,
    KNOWLEDGE_DATABASE
  ];

  useEffect(() => {
    clearInventionsSortingOrder();

    if (history.location.pathname !== listType) {
      const type = inventionsListsTypes.find(
        el => el === history.location.pathname
      );
      setListType(type);
      fetchInventions({
        type: type,
        currentUserId,
        ...(previousPage &&
          Number(previousPage) !== 1 && { page: previousPage }),
        ...(previousSort && { sort: previousSort }),
        ...(pageSize && { itemsPerPage: pageSize })
      });
    }
    setInventionsLimit(Number(pageSize) || 10);
    // eslint-disable-next-line
  }, []);

  const handleChangePage = page =>
    fetchInventions({
      page: page + 1,
      type: listType,
      currentUserId,
      sort: sortingOrder,
      itemsPerPage: limit
    });

  const onLimitSelect = limitValue => {
    setInventionsLimit(limitValue);
    const pageLimit = Math.ceil(pagination.totalItems / limitValue);
    fetchInventions({
      page:
        pagination.currentPage > pageLimit ? pageLimit : pagination.currentPage,
      type: listType,
      currentUserId,
      sort: sortingOrder,
      itemsPerPage: limitValue
    });
  };

  const onSortClick = sort => {
    setInventionsSortingOrder(sort);
    localStorage.setItem('previousSort', JSON.stringify(sort));
    fetchInventions({
      type: listType,
      currentUserId,
      sort,
      itemsPerPage: limit
    });
  };

  const renderLists = () =>
    listType === ALL_ASSESSMENTS || listType === MY_ASSESSMENTS ? (
      <AssessmentsList
        currentUserId={currentUserId}
        currentUserRoles={currentUserRoles}
        data={data}
        handleChangePage={handleChangePage}
        isAllAssessments={listType === ALL_ASSESSMENTS}
        isLoading={isLoading}
        limit={limit}
        pagination={pagination}
        showNewInventionTypeChooseModal={showNewInventionTypeChooseModal}
        onLimitSelect={onLimitSelect}
        onSortClick={onSortClick}
      />
    ) : (
      <InventionsList
        data={data}
        displayButtons={listType === INVENTIONS_PAGE}
        handleChangePage={handleChangePage}
        isLoading={isLoading}
        limit={limit}
        listType={listType}
        pagination={pagination}
        showNewInventionTypeChooseModal={showNewInventionTypeChooseModal}
        onLimitSelect={onLimitSelect}
        onSortClick={onSortClick}
      />
    );

  return renderLists();
};

ListPage.propTypes = {
  clearInventionsSortingOrder: PropTypes.func,
  currentUserId: PropTypes.string,
  currentUserRoles: PropTypes.array,
  data: PropTypes.array,
  fetchInventions: PropTypes.func,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  pagination: PropTypes.object,
  setInventionsLimit: PropTypes.func,
  setInventionsSortingOrder: PropTypes.func,
  sortingOrder: PropTypes.shape({
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  })
};

const mapStateToProps = createPropsSelector({
  data: getInventions,
  isLoading: getAreInventionsLoading,
  pagination: getInventionsPagination,
  currentUserRoles: getCurrentUserRoles,
  currentUserId: getCurrentUserId,
  limit: getInventionsLimit,
  sortingOrder: getInventionsSortingOrder
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearInventionsSortingOrder,
      fetchInventions,
      showNewInventionTypeChooseModal,
      setInventionsLimit,
      setInventionsSortingOrder
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(memo(ListPage));
