import React, { Fragment } from 'react';
import Button from 'Atoms/Button';
import { useTranslation } from 'react-i18next';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { bindActionCreators } from 'redux';

import { getInventionTypesData } from 'store/inventionTypes/inventionTypesSelectors';

import {
  setChooseInventionTypeId,
  setChooseInventionTypeObject
} from 'actions/newInventionTypeChooseModalActions';

import { connect } from 'react-redux';
import { clearFields } from 'actions/newInventionFormActions';

const Footer = ({
  closeModal,
  approveInventionType,
  selectedInventionType,
  rawInventionTypes,
  setChooseInventionTypeId,
  setChooseInventionTypeObject,
  clearFields
}) => {
  const { t } = useTranslation();

  const handleApproveButton = () => {
    clearFields();
    setChooseInventionTypeId(selectedInventionType);
    setChooseInventionTypeObject(
      rawInventionTypes.find(
        type => type.attributes.id === selectedInventionType
      )
    );
    approveInventionType();
  };

  return (
    <Fragment>
      <Button value={t('close')} onClick={closeModal} />
      <Button
        colorVariant="green"
        disabled={!selectedInventionType}
        value={t('choose')}
        onClick={handleApproveButton}
      />
    </Fragment>
  );
};

const mapStateToProps = createPropsSelector({
  rawInventionTypes: getInventionTypesData
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setChooseInventionTypeId,
      setChooseInventionTypeObject,
      clearFields
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
