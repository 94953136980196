import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Radio from 'Atoms/Radio';

import { OptionsWrapper } from './styles';

const RadioType = ({ field, onChange, disabled }) => {
  const [radioValue, setRadioValue] = useState(null);

  useEffect(() => {
    if (field.value) {
      setRadioValue(field.value);
    }

    const defaultField = field.options.fields.find(field => field.default);

    if (defaultField && !field.value) {
      setRadioValue(defaultField.value);
      onChange(field.id, defaultField.value);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (fieldId, value) => {
    setRadioValue(value);
    onChange(fieldId, value);
  };

  return (
    <OptionsWrapper>
      {field.options.fields.map((option, i) => (
        <Radio
          key={i}
          value={option.value}
          name={field.name}
          checked={option.value === radioValue}
          onCheck={() => handleChange(field.id, option.value)}
          label={option.label}
          disabled={disabled}
        />
      ))}
    </OptionsWrapper>
  );
};

RadioType.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default RadioType;
