import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'Atoms/Checkbox';

import { OptionsWrapper } from './styles';

const CheckboxType = ({ field, onChange, disabled }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (field.value) {
      setValues(field.value);
    }

    const defaultField = field.options.fields.find(field => field.default);

    if (defaultField && !field.value) {
      setValues(defaultField.value);
      handleChange(field.id, defaultField.value);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (fieldId, value) => {
    let newValues = [...values];
    if (newValues.includes(value)) {
      newValues = newValues.filter(v => v !== value);
    } else {
      newValues = [...newValues, value];
    }

    setValues(newValues);
    onChange(fieldId, newValues);
  };

  return (
    <OptionsWrapper>
      {field.options.fields.map((option, i) => (
        <Checkbox
          key={i}
          name={field.name}
          value={option.value}
          checked={values && values.includes(option.value)}
          onCheck={() => handleChange(field.id, option.value)}
          label={option.label}
          disabled={disabled}
        />
      ))}
    </OptionsWrapper>
  );
};

CheckboxType.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default CheckboxType;
