export const IS_TABLET_SIZE = 'layoutDetails/IS_TABLET_SIZE';
export const SHOW_SEARCH_AND_FILTER_SECTION =
  'layoutDetails/SHOW_SEARCH_AND_FILTER_SECTION';
export const SET_FILTER_AND_SEARCH_HEIGHT =
  'layoutDetails/SET_FILTER_AND_SEARCH_HEIGHT';

export const setIsTabletSize = isTabletSize => ({
  type: IS_TABLET_SIZE,
  isTabletSize
});

export const setShowSearchAndFilterSection = show => ({
  type: SHOW_SEARCH_AND_FILTER_SECTION,
  show
});

export const setFilterAndSearchHeight = height => ({
  type: SET_FILTER_AND_SEARCH_HEIGHT,
  height
});
