import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${props => props.marginBottom && `margin-bottom: 20px;`}

  & > div {
    flex: 25%;
    padding: 10px;
  }
  & > div:nth-child(n + 4) {
    flex-grow: 0;
  }
`;
