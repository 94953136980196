import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from 'Atoms/Spinner';
import ISvgWrapper from 'Atoms/ISvgWrapper/ISvgWrapper';
import Button from 'Atoms/Button';
import logo from 'assets/logo-small.svg';

import { PageWrapper, ContentWrapper } from './styles';

const LoadingPage = ({ tryToLoginAgain, login }) => {
  const { t } = useTranslation();

  const forceShowButton = localStorage.getItem('forceShowButton') !== null;
  useEffect(() => {
    localStorage.removeItem('forceShowButton');
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <ISvgWrapper height={100} src={logo} width={200} />
        {!tryToLoginAgain && !forceShowButton && <Spinner center />}
        <div>{t('inventionDisclosure')}</div>
        {tryToLoginAgain || forceShowButton ? (
          <Button
            colorVariant="blue"
            value={t('tryToLoginAgain')}
            onClick={login}
          />
        ) : (
          <span>{t('loading')}...</span>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default LoadingPage;
