import moment from 'moment';

export const formatDateWithTime = date =>
  moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');
export const formatDate = date =>
  moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
export const utcFormat = date => moment(date).format('YYYY-MM-DD HH:mm');

export const prepareDateValue = date =>
  typeof date === 'string' ? new Date(date) : moment().toDate();
