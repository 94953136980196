import React, { useEffect, useState } from 'react';

import AddOrEditInventionTypeModal from 'Elements/AddOrEditInventionTypeModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import usePrevious from 'helpers/hooks/usePrevious';
import PropTypes from 'prop-types';

import {
  getFormTypeListData,
  getFormTypeListMeta,
  getCurrentListType,
  getIsFetching,
  getFormLimit
} from 'store/formTypeList/formTypeListSelectors';
import {
  setCurrentListType,
  setFormLimit
} from 'store/formTypeList/formTypeListReducer';
import { fetchFormTypeList } from 'actions/formTypeListActions';
import TablePageLayout from 'Elements/TablePageLayout';
import Button from 'Atoms/Button';

import { INVENTION_TYPE, ASSESSMENT_TYPE } from 'constants/formTypesConstants';
import { getIsFormCreating } from 'store/formType/formTypeSelectors';

const FormTypeList = ({
  fetchFormTypeList,
  data,
  formTypeListMeta,
  setCurrentListType,
  currentListType,
  isFetching,
  isFormCreating,
  limit,
  setFormLimit
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [displayModal, setDisplayModal] = useState();

  const handleNewFormType = () => setDisplayModal(true);

  const columnNames = {
    title: t('name'),
    description: t('description'),
    creator: t('creator'),
    createdAt: t('created_at'),
    modifiedAt: t('modified_at'),
    actions: t('actions')
  };

  const buttons = [
    {
      label:
        currentListType === INVENTION_TYPE
          ? t('addNewInventionType')
          : t('addNewAssessmentType'),
      action: handleNewFormType,
      isFetching: isFormCreating
    }
  ];
  const previousListType = usePrevious(currentListType);
  const previousIsFormCreating = usePrevious(isFormCreating);
  const previousPage = localStorage.getItem('previousPage');
  const pageSize = localStorage.getItem('pageSize');

  const rows =
    data &&
    data.map(el => ({
      ...el,
      actions: () => (
        <Button
          colorVariant="blue"
          value={t('edit')}
          small
          onClick={() => history.push(el.link.link)}
        />
      )
    }));

  const fetchingFormTypeList = () =>
    fetchFormTypeList({
      page: previousPage || 1,
      currentListType: history.location.pathname.includes('invention')
        ? INVENTION_TYPE
        : ASSESSMENT_TYPE,
      itemsPerPage: pageSize || 10
    });

  useEffect(() => {
    setCurrentListType(
      history.location.pathname.includes('invention')
        ? INVENTION_TYPE
        : ASSESSMENT_TYPE
    );
    setFormLimit(Number(pageSize) || 10);
    if (previousListType !== currentListType) {
      fetchingFormTypeList();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      previousIsFormCreating &&
      !isFormCreating &&
      formTypeListMeta.currentPage === 1
    ) {
      fetchingFormTypeList();
    }
    // eslint-disable-next-line
  }, [isFormCreating]);

  const handleChangePage = page => {
    fetchFormTypeList({
      page: page + 1,
      currentListType,
      itemsPerPage: limit
    });
  };

  const onSortClick = sort => {
    fetchFormTypeList({ currentListType, sort, itemsPerPage: limit });
  };

  const onLimitSelect = limitValue => {
    setFormLimit(limitValue);
    const pageLimit = Math.ceil(formTypeListMeta.totalItems / limitValue);
    fetchFormTypeList({
      page:
        formTypeListMeta.currentPage > pageLimit
          ? pageLimit
          : formTypeListMeta.currentPage,
      currentListType,
      itemsPerPage: limitValue
    });
  };

  return (
    <>
      <TablePageLayout
        data={rows}
        columnNames={columnNames}
        buttons={buttons}
        formTypeListMeta={formTypeListMeta || {}}
        handleChangePage={handleChangePage}
        isFetching={isFetching}
        onSortClick={onSortClick}
        limit={limit}
        onLimitSelect={onLimitSelect}
      />
      {displayModal && (
        <AddOrEditInventionTypeModal
          isOpen
          closeModal={() => setDisplayModal(false)}
        />
      )}
    </>
  );
};

FormTypeList.propTypes = {
  fetchFormTypeList: PropTypes.func,
  data: PropTypes.array,
  formTypeListMeta: PropTypes.object,
  setCurrentListType: PropTypes.func,
  isFetching: PropTypes.bool,
  limit: PropTypes.number,
  setFormLimit: PropTypes.func
};

const mapStateToProps = createPropsSelector({
  data: getFormTypeListData,
  formTypeListMeta: getFormTypeListMeta,
  currentListType: getCurrentListType,
  isFetching: getIsFetching,
  isFormCreating: getIsFormCreating,
  limit: getFormLimit
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFormTypeList: fetchFormTypeList.trigger,
      setCurrentListType,
      setFormLimit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormTypeList);
