import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from 'Atoms/Button';
import { setShowSearchAndFilterSection } from 'actions/layoutDetailsActions';
import { clearAllFields } from 'actions/filterAndSearchActions';
import { fetchInventions } from 'actions/inventionsActions';
import { getHasAnyFiltersSaved } from 'store/filterAndSearch/filterAndSearchSelectors';
import { getShowSearchAndFilterSection } from 'store/layoutDetails/layoutDetailsSelectors';
import { getInventionsLimit } from 'store/inventions/inventionsSelectors';

import { FilterAndSearch, RemoveFilters } from './styles';

const FilterAndSearchNavigation = props => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleFilterAndSearch = () => {
    props.setShowSearchAndFilterSection(!props.isSectionVisible);
  };

  const handleRemoveFiltering = () => {
    props.clearAllFields();
    props.fetchInventions({
      type: history.location.pathname,
      itemsPerPage: props.limit
    });
  };

  return (
    <FilterAndSearch>
      <Button
        value={
          props.isSectionVisible
            ? t('hideFilterAndSearch')
            : t('showFilterAndSearch')
        }
        colorVariant="blue"
        small
        onClick={handleFilterAndSearch}
      />
      {!props.isSectionVisible && props.hasAnyFiltersApplied && (
        <RemoveFilters>
          <Button
            small
            value={t('removeFiltering')}
            onClick={handleRemoveFiltering}
          />
        </RemoveFilters>
      )}
    </FilterAndSearch>
  );
};

FilterAndSearchNavigation.propTypes = {
  setShowSearchAndFilterSection: PropTypes.func,
  hasAnyFiltersApplied: PropTypes.bool,
  isSectionVisible: PropTypes.bool,
  clearAllFields: PropTypes.func,
  fetchInventions: PropTypes.func,
  limit: PropTypes.number
};

const mapStateToProps = state => ({
  hasAnyFiltersApplied: getHasAnyFiltersSaved(state),
  isSectionVisible: getShowSearchAndFilterSection(state),
  limit: getInventionsLimit(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { setShowSearchAndFilterSection, clearAllFields, fetchInventions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterAndSearchNavigation);
