import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Tooltip from 'react-tooltip-lite';
import { useHistory } from 'react-router-dom';

import { fetchInventionAssessments } from 'actions/inventionAssessmentActions';
import { getExperts } from 'store/singleInvention/singleInventionSelectors';
import { getCurrentUserId } from 'store/users/usersSelectors';
import {
  getIsFetching,
  getIsExpertAssigning
} from 'store/inventionAssessments/inventionAssessmentsSelectors';

import {
  STATUS_PENDING_DECISION,
  STATUS_ASSESSMENT
} from 'constants/inventionStatuses';
import {
  assessmentStatuses,
  decisionProposalStatuses,
  STATUS_COMPLETED
} from 'constants/assessmentStatuses';
import { INVENTIONS_PAGE, ASSESSMENTS_PAGE } from 'constants/routingConstants';

import Button from 'Atoms/Button';
import Spinner from 'Atoms/Spinner';

import ExpertsModal from 'Elements/ExpertsModal';
import AssessmentPreviewModal from './Modals/AssessmentPreviewModal';

import { AssessmentSection, Expert } from './styles';
import { IconWrapper } from './Modals/styles';

const MODALS = {
  assignExpert: 'assignExpert',
  showAssessmentPreview: 'showAssessmentPreview'
};

const InventionAssessmentsSection = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [shouldFormBeEmpty, setShouldFormBeEmpty] = useState(false);

  useEffect(() => {
    props.fetchInventionAssessments({ id: props.currentInventionId });
    // eslint-disable-next-line
  }, []);

  const isAssignedReviewManager =
    !isEmpty(props.reviewManager) &&
    props.reviewManager.data.id === props.currentUserId;

  const isDecisionMakerView =
    !isEmpty(props.decisionMaker) &&
    props.decisionMaker.data.id === props.currentUserId &&
    props.inventionStatus === STATUS_PENDING_DECISION;

  const closeModal = () => setModal(null);

  const shouldDisplaySection = () => {
    const assignedExpertsIds =
      (!isEmpty(props.experts) && props.experts.map(expert => expert.id)) || [];

    return [
      ...assignedExpertsIds,
      !isEmpty(props.decisionMaker) && props.decisionMaker.data.id,
      !isEmpty(props.reviewManager) && props.reviewManager.data.id
    ].includes(props.currentUserId);
  };

  const handleExpertClick = expert => {
    setShouldFormBeEmpty(false);

    if (
      expert.assessmentStatus === STATUS_COMPLETED ||
      props.inventionStatus !== STATUS_ASSESSMENT
    ) {
      setSelectedAssessment(expert.assessmentId);
      setModal(MODALS.showAssessmentPreview);
    } else {
      if (expert.id === props.currentUserId || isAssignedReviewManager) {
        history.push(
          `${INVENTIONS_PAGE}/${props.currentInventionId}${ASSESSMENTS_PAGE}/${expert.assessmentId}`
        );
      } else {
        setShouldFormBeEmpty(true);
        setSelectedAssessment(expert.assessmentId);
        setModal(MODALS.showAssessmentPreview);
      }
    }
  };

  const renderExperts = () =>
    props.experts.map((el, index) => (
      <Tooltip
        key={index}
        useDefaultStyles
        content={
          isDecisionMakerView
            ? `${t('decisionProposal')}: ${
                el.decisionProposalStatus
                  ? decisionProposalStatuses(t).find(
                      status => status.value === el.decisionProposalStatus
                    ).label
                  : '-'
              }`
            : `${t('status')}: ${
                assessmentStatuses(t).find(
                  status => status.id === el.assessmentStatus
                ).label
              }`
        }
      >
        <Expert key={index} onClick={() => handleExpertClick(el)}>
          <IconWrapper assessmentStatus={el.assessmentStatus}>
            <FontAwesomeIcon icon={faUserCircle} />
          </IconWrapper>
          <span>{el.fullName}</span>
        </Expert>
      </Tooltip>
    ));

  return (
    shouldDisplaySection() && (
      <AssessmentSection>
        {modal === MODALS.assignExpert && (
          <ExpertsModal
            isOpen={modal === MODALS.assignExpert}
            closeModal={closeModal}
            inventionId={parseInt(props.currentInventionId)}
          />
        )}
        {modal === MODALS.showAssessmentPreview && (
          <AssessmentPreviewModal
            isOpen={modal === MODALS.showAssessmentPreview}
            closeModal={closeModal}
            assessmentId={parseInt(selectedAssessment)}
            emptyForm={shouldFormBeEmpty}
          />
        )}
        <div>{t('assessments')}:</div>
        <div>{props.isFetching ? <Spinner center /> : renderExperts()}</div>
        {isAssignedReviewManager &&
          props.inventionStatus === STATUS_ASSESSMENT && (
            <Button
              colorVariant="green"
              height={'38px'}
              isFetching={props.isExpertBeingAssigned}
              value={t('assignExpert')}
              onClick={() => setModal(MODALS.assignExpert)}
            />
          )}
      </AssessmentSection>
    )
  );
};

const mapStateToProps = state => ({
  experts: getExperts(state),
  currentUserId: getCurrentUserId(state),
  isFetching: getIsFetching(state),
  isExpertBeingAssigned: getIsExpertAssigning(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInventionAssessments
    },
    dispatch
  );

InventionAssessmentsSection.propTypes = {
  fetchInventionAssessments: PropTypes.func,
  experts: PropTypes.array,
  currentInventionId: PropTypes.any,
  inventionStatus: PropTypes.number,
  decisionMaker: PropTypes.object,
  currentUserId: PropTypes.string,
  reviewManager: PropTypes.object,
  isFetching: PropTypes.bool,
  isExpertBeingAssigned: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventionAssessmentsSection);
