import { LIGHT_GRAY, VERY_DARK_GRAY, WHITE } from 'constants/colors';
import styled from 'styled-components';

export const FileContainer = styled.div`
  border: 1px solid ${LIGHT_GRAY};
  position: relative;
  padding: 10px;
  border-radius: 5px;
  margin: 14px 0;
  & > button {
    margin-left: auto;
  }
  svg {
    cursor: pointer;
  }
`;

export const IconWrapper = styled.span`
  font-size: 30px;
  svg {
    cursor: unset;
  }
`;

export const FileName = styled.div`
  cursor: ${props => (props.canBeDownloaded ? 'pointer' : 'unset')};
  max-width: 90%;
  justify-content: space-between;
  p {
    margin-bottom: 0;
    padding: 0 5px;
    word-wrap: break-word;
    &:not(:first-child) {
      font-size: 12px;
      text-align: left;
    }
  }
`;

export const Settings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RemoveIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: -11px;
  right: 5px;
  padding: 4px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${VERY_DARK_GRAY};
  font-size: 13px;
  background-color: ${WHITE};
`;
