import typeToReducer from 'type-to-reducer';

import {
  fetchInventions,
  createInvention,
  CLEAR_INVENTIONS_LIST,
  SET_INVENTIONS_LIMIT,
  SET_SORTING_ORDER,
  CLEAR_SORTING_ORDER
} from 'actions/inventionsActions';

const defaultSortingOrder = {
  field: null,
  order: null,
  pageSize: null
};

const defaultState = {
  data: [],
  pagination: {
    totalItems: null,
    itemsPerPage: null,
    currentPage: null
  },
  sortingOrder: defaultSortingOrder,
  isFetching: false,
  loading: false,
  createInvention: null,
  limit: null
};

export default typeToReducer(
  {
    [fetchInventions.TRIGGER]: state => ({
      ...state,
      isFetching: true,
      data: []
    }),
    [fetchInventions.SUCCESS]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      data: payload.data.reduce(
        (acc, row) => [...acc, { id: row.id, ...row.attributes }],
        []
      ),
      pagination: payload.pagination
    }),
    [fetchInventions.FAILURE]: state => ({
      ...state,
      isFetching: false
    }),
    [createInvention.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [createInvention.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      createInvention: payload
    }),
    [createInvention.FAILURE]: state => ({ ...state }),
    [createInvention.FULFILL]: state => ({
      ...state,
      loading: false
    }),
    [CLEAR_INVENTIONS_LIST]: () => ({ defaultState }),
    [SET_INVENTIONS_LIMIT]: (state, { limit }) => ({
      ...state,
      limit: limit
    }),
    [CLEAR_SORTING_ORDER]: state => ({
      ...state,
      sortingOrder: defaultSortingOrder
    }),
    [SET_SORTING_ORDER]: (state, { sortingOrder }) => ({
      ...state,
      sortingOrder: sortingOrder
    })
  },
  defaultState
);
