import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { useTranslation } from 'react-i18next';

import { getOrganisationName } from 'store/organisation/organisationSelectors';

import { changeOrganisationUrl } from 'helpers/auth';

import { OrganisationWrapper } from './styles';
import { removeCookie } from '../../helpers/cookies';

const Organisation = props => {
  const { t } = useTranslation();

  const redirectToChangePage = () => {
    removeCookie('token');
    removeCookie('refreshToken');
    window.location.replace(changeOrganisationUrl);
  };

  return (
    <OrganisationWrapper onClick={redirectToChangePage}>
      <Tooltip
        content={t('changeOrganisationDescription')}
        direction="down"
        zIndex={9999}
        arrow
        useDefaultStyles
      >
        {props.organisationName}
      </Tooltip>
    </OrganisationWrapper>
  );
};

const mapStateToProps = state => ({
  organisationName: getOrganisationName(state)
});

export default connect(mapStateToProps)(Organisation);
