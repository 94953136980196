import styled from 'styled-components';
import { BACKGROUND_GRAY, DEFAULT_RED, INPUT_BORDER_GRAY } from 'constants/colors';

const BaseStyles = styled.div`
  padding: 20px;
  border-radius: 5px;
`;

export const ReviewManagerComment = styled(BaseStyles)`
  background: ${BACKGROUND_GRAY};

  & > p {
    font-weight: bold;
  }
`;

export const DecisionProposalSection = styled(BaseStyles)`
  margin: 20px 0;
  border: ${props =>
    `1px solid ${props.hasRequiredFields ? INPUT_BORDER_GRAY : DEFAULT_RED}`};
  background: ${props => (props.disabled ? BACKGROUND_GRAY : 'unset')};
`;

export const Label = styled.label`
  margin: 10px 0;
`;
