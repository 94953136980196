import { all } from 'redux-saga/effects';
import loginSaga from './loginSaga';
import formTypeSaga from './formTypeSaga';
import inventionsSaga from './inventionsSaga';
import getInventionTypesSaga from 'sagas/inventionTypes/getInventionTypesSaga';
import usersSaga from './usersSaga';
import singleInventionSaga from './singleInventionSaga';
import formTypeListSaga from './formTypeListSaga';
import organisationSaga from './organisationSaga';
import createInventionSaga from 'sagas/createInventionSaga';
import createAnswerSaga from 'sagas/createAnswerSaga';
import createFullInventionAnswerSaga from 'sagas/createFullInventionAnswerSaga';
import inventionAssessmentsSaga from 'sagas/inventionAssessmentsSaga';
import attachmentsSaga from 'sagas/attachmentsSaga';

export default function* mainSaga() {
  yield all([
    loginSaga(),
    formTypeSaga(),
    inventionsSaga(),
    singleInventionSaga(),
    formTypeListSaga(),
    getInventionTypesSaga(),
    usersSaga(),
    organisationSaga(),
    inventionAssessmentsSaga(),
    createInventionSaga(),
    createAnswerSaga(),
    createFullInventionAnswerSaga(),
    attachmentsSaga()
  ]);
}
