import styled from 'styled-components';
import { DEFAULT_BLUE, LIGHT_GRAY, WHITE } from 'constants/colors';

export const Wrapper = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  display: block;
  background-color: ${WHITE};
`;
export const FieldContainer = styled.div``;

export const Label = styled.span`
  padding-left: 20px;
`;
export const TitleWrapper = styled.div`
  text-align: center;
  padding: ${props => (props.inModal ? '5px 8px 10px' : '15px')};
`;
export const Title = styled.h5`
  padding-right: 5px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  ${props => (props.inModal ? 'font-size: 17px' : '20px')};
  font-weight: 600 !important;
  letter-spacing: 0.25px;
`;

export const IconWrapper = styled.div`
  width: 20px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-left: 5px;
`;
export const InputTypeContainer = styled.div`
  margin: 5px 10px;
  border: 1px solid ${LIGHT_GRAY};
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${DEFAULT_BLUE};
  color: ${WHITE};

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 35px;
    background-color: ${LIGHT_GRAY};
    top: 0;
  }

  &:hover {
    opacity: 0.7;
  }
`;
