import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  label,
  checked,
  onCheck,
  disabled,
  inline,
  name,
  value
}) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  useEffect(() => setIsChecked(checked), [checked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onCheck && onCheck(value);
  };

  const pointerStyle = { cursor: 'pointer' };

  return (
    <div className={inline ? 'form-check form-check-inline' : 'form-check'}>
      <input
        className="form-check-input"
        type="checkbox"
        value={value}
        name={name}
        checked={isChecked}
        onChange={!disabled ? handleChange : undefined}
        disabled={disabled}
        style={!disabled ? pointerStyle : null}
      />
      <label
        className="form-check-label"
        onClick={!disabled ? handleChange : undefined}
        style={!disabled ? pointerStyle : null}
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string
};

export default memo(Checkbox);
