import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${props =>
    props.hasPagination
      ? `minmax(min-content, max-content) auto minmax(
      min-content,
      max-content
    )`
      : `minmax(min-content, max-content) minmax(min-content, max-content)`};
`;

export const ButtonWrapper = styled.div`
  margin: 20px 10px;
`;
