import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { createPropsSelector } from 'reselect-immutable-helpers/index';
import { useIntercom } from 'react-use-intercom';

import Navigation from 'Elements/Navigation/Navigation';
import LeftMenu from 'Elements/LeftMenu/LeftMenu';
import Breadcrumbs from 'Elements/Breadcrumbs/Breadcrumbs';
import NewInventionTypeChooseModalContainer from 'Elements/NewInventionTypeChooseModal/NewInventionTypeChooseModalContainer';
import FilterAndSearch from 'Elements/FilterAndSearch';

import { asideStyle, contentWrapperStyle, Content } from './styles';

import {
  NOT_FOUND,
  INVENTION_TYPE,
  ASSESSMENT_TYPE,
  CREATE_INVENTION,
  EDIT_INVENTION,
  ACCESS_DENIED
} from 'constants/routingConstants';

import { setIsTabletSize } from 'actions/layoutDetailsActions';
import { getIsAuth } from 'store/login/loginSelectors';
import { getIsVisible } from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';
import { getCurrentUserData } from 'store/users/usersSelectors';
import { getIsIntercomActive } from 'store/organisation/organisationSelectors';
import {
  getShowSearchAndFilterSection,
  getFilterAndSearchHeight
} from 'store/layoutDetails/layoutDetailsSelectors';

import PreloadHOC from 'HOC/PreloadHOC/PreloadHOC';
import { getToken } from 'helpers/cookies';

import { useMedia, useInterval } from 'helpers/hooks';
import env from 'helpers/env';

const Layout = ({
  isNewInventionTypeChooseModalVisible,
  isAuth,
  location,
  children,
  userData,
  isIntercomActive,
  showFilterAndSearch,
  top
}) => {
  const isTabletSize = useMedia('(max-width: 768px)');
  const [collapsed, setCollapsed] = useState(true);

  const is404Page = location.pathname === NOT_FOUND;
  const is403Page =
    location.pathname === ACCESS_DENIED ||
    (location.state && location.state.accessDenied);

  const regexp = new RegExp(
    `${INVENTION_TYPE}/\\d{1,}|${ASSESSMENT_TYPE}/\\d{1,}|${CREATE_INVENTION}|${EDIT_INVENTION}`,
    'gi'
  );

  const isListPage = !location.pathname.match(regexp);

  const { boot, shutdown, update } = useIntercom();
  const isIntercom =
    env('USE_INTERCOM') && env('USE_INTERCOM') === 'true' && isIntercomActive;

  useEffect(() => {
    if (!isIntercom) {
      return;
    }

    if (isAuth && getToken()) {
      const { attributes } = userData;

      boot({
        name: attributes.first_name,
        email: attributes.email,
        customAttributes: {
          inventions: true
          // user_id: attributes.id
        }
      });
    } else {
      shutdown();
    }
    // eslint-disable-next-line
  }, [userData]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isIntercom) {
      return () => shutdown();
    }
    // eslint-disable-next-line
  }, []);

  useInterval(() => {
    isIntercom && update();
  }, 90000);

  useEffect(() => {
    isTabletSize && setCollapsed(true);
  }, [isTabletSize]);

  const onCollapseClick = () => {
    setCollapsed(!collapsed);
  };

  const hideMenu = () => (isTabletSize && setCollapsed(true)) || undefined;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isAuth && getToken() && !is404Page && !is403Page ? (
        <div className="wrapper">
          {isNewInventionTypeChooseModalVisible && (
            <NewInventionTypeChooseModalContainer />
          )}
          <Navigation
            collapseClick={onCollapseClick}
            shouldBeCollapsed={collapsed}
            isTabletSize={isTabletSize}
          />
          {(!isTabletSize || !collapsed) && (
            <aside
              className="main-sidebar position-fixed"
              style={asideStyle(collapsed)}
            >
              <LeftMenu shouldBeCollapsed={collapsed} hideMenu={hideMenu} />
            </aside>
          )}
          <div
            className="content-wrapper"
            style={contentWrapperStyle(collapsed, isTabletSize, top)}
          >
            {showFilterAndSearch && (
              <FilterAndSearch
                pathname={location.pathname}
                collapsed={collapsed}
              />
            )}
            <Breadcrumbs
              collapsed={collapsed}
              isTabletSize={isTabletSize}
              top={top}
              isFilterSectionVisible={showFilterAndSearch}
            />
            <Content
              className={!isListPage && 'container'}
              isListPage={isListPage}
              top={showFilterAndSearch && top}
            >
              {children}
            </Content>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

const mapStateToProps = createPropsSelector({
  isAuth: getIsAuth,
  isNewInventionTypeChooseModalVisible: getIsVisible,
  userData: getCurrentUserData,
  isIntercomActive: getIsIntercomActive,
  showFilterAndSearch: getShowSearchAndFilterSection,
  top: getFilterAndSearchHeight
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setIsTabletSize }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreloadHOC(Layout));
