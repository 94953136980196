import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from 'Atoms/Button';
import { ButtonsWrapper } from './styles';

const TabsButtons = ({ sectionsLength, onTabChange, currentIndex }) => {
  const { t } = useTranslation();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(
    currentIndex || 0
  );

  const changeCurrentTab = tabIndex => {
    setCurrentSectionIndex(tabIndex);
    onTabChange(tabIndex);
  };

  useEffect(() => {
    if (currentIndex >= 0) {
      setCurrentSectionIndex(currentIndex);
    }
  }, [currentIndex]);

  return (
    <ButtonsWrapper>
      {currentSectionIndex > 0 && (
        <Button
          colorVariant="blue"
          margin="20px 5px"
          value={t('previous')}
          onClick={() => changeCurrentTab(currentSectionIndex - 1)}
        />
      )}
      {currentSectionIndex !== sectionsLength && (
        <Button
          colorVariant="blue"
          value={t('next')}
          onClick={() => changeCurrentTab(currentSectionIndex + 1)}
        />
      )}
    </ButtonsWrapper>
  );
};

TabsButtons.propTypes = {
  sectionsLength: PropTypes.number,
  onTabChange: PropTypes.func,
  currentIndex: PropTypes.number
};

export default TabsButtons;
