import typeToReducer from 'type-to-reducer';

import {
  fetchSingleInvention,
  fetchInventionTimeline,
  fetchInventionNotes,
  fetchInventionAttachments,
  addNewNote,
  assignReviewManager,
  assignDecisionMaker,
  updateReview,
  createAnswerReview,
  addSingleInventionAttachment,
  removeSingleInventionAttachments,
  updateSingleInventionAttachment,
  changeInventionStatus,
  UPDATE_CURRENT_INVENTION_STATUS,
  SET_ATTACHMENT_FORM_VALUE,
  UPDATE_NOTE,
  CLEAR_SINGLE_INVENTIONS_DATA,
  createSurveyReview
} from 'actions/singleInventionActions';

import {
  fetchInventionAssessments,
  assignInventionExpert
} from 'actions/inventionAssessmentActions';

import { STATUS_PENDING } from 'constants/assessmentStatuses';

import {
  uploadAttachments,
  deleteAttachment,
  deleteTemporaryAttachment
} from 'actions/attachmentsActions';

import { createFullInventionAnswer } from 'actions/inventionsActions';

const defaultReviews = { data: { attributes: { answer_reviews: [] } } };

const defaultState = {
  data: {},
  timeline: [],
  currentInventionId: null,
  isFetching: false,
  isTimelineFetching: false,
  areNotesFetching: false,
  areAttachmentsFetching: false,
  experts: [],
  reviews: defaultReviews,
  isInventionBeingCreated: false,
  isReviewManagerBeingAssigned: false,
  isDecisionMakerBeingAssigned: false,
  isStatusBeingChanged: false,
  isPreAssessmentBeingDone: false,
  isAttachmentBeingUploaded: false,
  isAttachmentBeingDeleted: false
};

export default typeToReducer(
  {
    [fetchSingleInvention.TRIGGER]: (state, { payload }) => ({
      ...state,
      currentInventionId: payload,
      isFetching: true,
      reviews: defaultReviews
    }),
    [fetchSingleInvention.SUCCESS]: (state, { payload }) => {
      const reviews =
        payload.attributes.draft.data.attributes.survey.data.attributes.reviews;
      const preparedReviews = reviews[reviews.length - 1];
      if (preparedReviews) {
        preparedReviews.data.attributes.answer_reviews =
          preparedReviews.data.attributes.answer_reviews.map(el => ({
            type: 'answer_review',
            attributes: {
              comment: el.data.attributes.comment,
              critical: el.data.attributes.critical,
              survey_review: {
                type: 'survey_review',
                id: preparedReviews.data.id
              },
              answer: {
                type: 'answer',
                id: el.data.attributes.answer.data.id
              }
            }
          }));

        payload.attributes.draft.data.attributes.survey.data.attributes.reviews =
          preparedReviews;
      }

      return {
        ...state,
        isFetching: false,
        data: payload.attributes,
        ...(preparedReviews && {
          reviews:
            payload.attributes.draft.data.attributes.survey.data.attributes
              .reviews
        })
      };
    },
    [updateReview.TRIGGER]: state => ({
      ...state,
      isPreAssessmentBeingDone: true
    }),
    [updateReview.SUCCESS]: (state, { payload }) => {
      payload.data.attributes.answer_reviews =
        payload.data.attributes.answer_reviews.reduce(
          (acc, element) => [...acc, element.data],
          []
        );

      return {
        ...state,
        isPreAssessmentBeingDone: false,
        reviews: payload,
        data: {
          ...state.data,
          draft: {
            ...state.data.draft,
            data: {
              ...state.data.draft.data,
              attributes: {
                ...state.data.draft.data.attributes,
                survey: {
                  ...state.data.draft.data.attributes.survey,
                  data: {
                    ...state.data.draft.data.attributes.survey.data,
                    attributes: {
                      ...state.data.draft.data.attributes.survey.data
                        .attributes,
                      reviews: payload
                    }
                  }
                }
              }
            }
          }
        }
      };
    },
    [updateReview.FAILURE]: state => ({
      ...state,
      isPreAssessmentBeingDone: false
    }),
    [createAnswerReview.SUCCESS]: (state, { payload }) => {
      const { reviewData } = payload;
      let reviewsToUpdate = { ...state.reviews };
      reviewsToUpdate = {
        data: {
          id: payload.surveyReviewId,
          type: 'survey_review',
          attributes: {
            comment: reviewData.data.attributes.comment,
            review_status: reviewData.data.attributes.review_status,
            answer_reviews: [].concat(payload.data.data)
          }
        }
      };

      return {
        ...state,
        reviews: reviewsToUpdate
      };
    },
    [fetchSingleInvention.FAILURE]: state => ({
      ...state,
      isFetching: false
    }),
    [fetchInventionTimeline.TRIGGER]: state => ({
      ...state,
      isTimelineFetching: true
    }),
    [fetchInventionTimeline.SUCCESS]: (state, { payload }) => ({
      ...state,
      isTimelineFetching: false,
      timeline: payload.reduce(
        (acc, row) => [...acc, { ...row.attributes, id: row.id }],
        []
      )
    }),
    [fetchInventionTimeline.FAILURE]: state => ({
      ...state,
      isTimelineFetching: false
    }),
    [fetchInventionNotes.TRIGGER]: state => ({
      ...state,
      areNotesFetching: true
    }),
    [fetchInventionNotes.SUCCESS]: (state, { payload }) => ({
      ...state,
      areNotesFetching: false,
      data: {
        ...state.data,
        notes: payload
      }
    }),
    [fetchInventionNotes.FAILURE]: state => ({
      ...state,
      areNotesFetching: false
    }),
    [fetchInventionAttachments.TRIGGER]: state => ({
      ...state,
      areAttachmentsFetching: true
    }),
    [fetchInventionAttachments.SUCCESS]: (state, { payload }) => ({
      ...state,
      areAttachmentsFetching: false,
      data: {
        ...state.data,
        attachments: payload
      }
    }),
    [fetchInventionAttachments.FAILURE]: state => ({
      ...state,
      areAttachmentsFetching: false
    }),
    [SET_ATTACHMENT_FORM_VALUE]: (state, { data }) => ({
      ...state,
      data: {
        ...state.data,
        attachments: state.data.attachments.map(attachment => {
          if (
            attachment.data.attributes.file_name ===
            data.data.attributes.file_name
          ) {
            return data;
          }
          return attachment;
        })
      }
    }),
    [addNewNote.TRIGGER]: state => ({
      ...state,
      areNotesFetching: true
    }),
    [addNewNote.SUCCESS]: (state, { payload }) => ({
      ...state,
      areNotesFetching: false,
      data: {
        ...state.data,
        notes: [...state.data.notes, payload]
      }
    }),
    [addNewNote.FAILURE]: state => ({ ...state, areNotesFetching: false }),
    [addSingleInventionAttachment.TRIGGER]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        attachments: state.data.attachments.concat(payload)
      }
    }),
    [removeSingleInventionAttachments.TRIGGER]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        attachments: state.data.attachments.filter(el => el.data.id !== payload)
      }
    }),
    [deleteAttachment.TRIGGER]: state => ({
      ...state,
      isAttachmentBeingDeleted: true
    }),
    [deleteAttachment.FULFILL]: state => ({
      ...state,
      isAttachmentBeingDeleted: false
    }),
    [deleteTemporaryAttachment]: (state, { payload }) => {
      if (payload.type !== 'invention') {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          attachments: state.data.attachments.filter(
            (el, index) => index !== payload.index
          )
        }
      };
    },
    [updateSingleInventionAttachment]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        attachments: state.data.attachments.map(el =>
          el.data.attributes.file_name === payload.data.attributes.file_name
            ? payload
            : el
        )
      }
    }),
    [assignInventionExpert.SUCCESS]: (state, { payload }) => ({
      ...state,
      experts: [
        ...state.experts,
        {
          expert: payload.attributes.expert.data,
          status: STATUS_PENDING,
          assessmentId: payload.id
        }
      ]
    }),
    [fetchInventionAssessments.SUCCESS]: (state, { payload }) => ({
      ...state,
      experts: payload.data.map(assessment => ({
        expert: assessment.attributes.expert.data,
        status: assessment.attributes.status,
        decisionProposalStatus: assessment.attributes.decision_proposal_status,
        assessmentId: assessment.id
      }))
    }),
    [assignReviewManager.TRIGGER]: state => ({
      ...state,
      isReviewManagerBeingAssigned: true
    }),
    [assignReviewManager.SUCCESS]: (state, { payload }) => ({
      ...state,
      isReviewManagerBeingAssigned: false,
      data: {
        ...state.data,
        review_manager: payload.reviewManager,
        status: payload.status
      }
    }),
    [assignReviewManager.FAILURE]: state => ({
      ...state,
      isReviewManagerBeingAssigned: false
    }),
    [assignDecisionMaker.TRIGGER]: state => ({
      ...state,
      isDecisionMakerBeingAssigned: true
    }),
    [assignDecisionMaker.SUCCESS]: (state, { payload }) => ({
      ...state,
      isDecisionMakerBeingAssigned: false,
      data: {
        ...state.data,
        decision_maker: payload.decisionMaker,
        status: payload.status
      }
    }),
    [assignDecisionMaker.FAILURE]: state => ({
      ...state,
      isDecisionMakerBeingAssigned: false
    }),
    [changeInventionStatus.TRIGGER]: state => ({
      ...state,
      isStatusBeingChanged: true
    }),
    [changeInventionStatus.SUCCESS]: (state, { payload }) => ({
      ...state,
      isStatusBeingChanged: false,
      data: {
        ...state.data,
        status: payload.status,
        comments: payload.comments
      }
    }),
    [changeInventionStatus.FAILURE]: state => ({
      ...state,
      isStatusBeingChanged: false
    }),
    [UPDATE_CURRENT_INVENTION_STATUS]: (state, { status }) => ({
      ...state,
      data: {
        ...state.data,
        status: status
      }
    }),
    [UPDATE_NOTE]: (state, { noteId }) => ({
      ...state,
      data: {
        ...state.data,
        notes: state.data.notes.map(note => {
          if (note.id === noteId) {
            return {
              ...note,
              replied: true
            };
          }
          return note;
        })
      }
    }),
    [CLEAR_SINGLE_INVENTIONS_DATA]: state => defaultState,
    [createFullInventionAnswer.TRIGGER]: state => ({
      ...state,
      isInventionBeingCreated: true
    }),
    [createFullInventionAnswer.FULFILL]: state => ({
      ...state,
      isInventionBeingCreated: false
    }),
    [createSurveyReview.TRIGGER]: state => ({
      ...state,
      isPreAssessmentBeingDone: true
    }),
    [createSurveyReview.FULFILL]: state => ({
      ...state,
      isPreAssessmentBeingDone: false
    }),
    [uploadAttachments.TRIGGER]: state => ({
      ...state,
      isAttachmentBeingUploaded: true
    }),
    [uploadAttachments.FULFILL]: state => ({
      ...state,
      isAttachmentBeingUploaded: false
    })
  },
  defaultState
);
