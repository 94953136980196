import styled from 'styled-components';

export const CardWrapper = styled.div`
  overflow: auto;
`;

export const EmptyTableInfo = styled.div`
  text-align: center;
  padding: 20px;
`;

export const TableCell = styled.td`
  vertical-align: middle !important;
  width: inherit;
  overflow: hidden;

  img {
    max-width: 130px;
    height: auto;
  }
`;

export const TableHeader = styled.th`
  position: relative;
`;

export const SortWrapper = styled.div`
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
`;

export const TableHeaderText = styled.span`
  display: inline-block;
  font-size: 13px;
  margin-right: 12px;
  word-break: break-word;
`;
