import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';

import {
  getAssessmentLatestAnswers,
  getAssessmentReview,
  getAssessmentFields
} from 'store/inventionAssessments/inventionAssessmentsSelectors';

import { STATUS_REVIEW } from 'constants/assessmentStatuses';

import Fields from 'Elements/Fields';
import Textarea from 'Atoms/Textarea';

import { ReviewManagerComment } from './styles';

const SingleAssessmentFields = props => {
  const { t } = useTranslation();
  const [answeredFields, setAnsweredFields] = useState(null);

  useEffect(() => {
    if (!isEmpty(props.fields) && !isEmpty(props.latestAnswers)) {
      const newFields = props.fields.map(field => {
        const answer = props.latestAnswers.find(
          el => el.data.attributes.field.data.id === field.data.id
        );

        return {
          data: {
            ...field.data,
            attributes: {
              ...field.data.attributes,
              value:
                (!props.emptyForm && answer && answer.data.attributes.value) ||
                null,
              ...(!props.emptyForm &&
                answer &&
                answer.data.attributes.additional && {
                  additionalValue: answer.data.attributes.additional
                })
            }
          }
        };
      });

      setAnsweredFields(newFields);
    }
    // eslint-disable-next-line
  }, [props.fields, props.latestAnswers]);

  return (
    <Fragment>
      {props.assessmentReview && !props.emptyForm && (
        <ReviewManagerComment>
          <p>{t('commentFromReviewManager')}:</p> {props.assessmentReview}
        </ReviewManagerComment>
      )}
      <Fields
        disabled={props.disabled}
        formFields={props.latestAnswers.length ? answeredFields : props.fields}
        handleFields={props.handleFields}
        unfilledFields={props.unfilledFields}
      />
      {props.children}
      {props.isReviewManagerView &&
        props.assessmentStatus === STATUS_REVIEW && (
          <Textarea
            label={t('reviewAssessment')}
            onChange={value => props.setReview(value)}
            value={props.assessmentReview}
          />
        )}
    </Fragment>
  );
};

const mapStateToProps = state =>
  createPropsSelector({
    latestAnswers: getAssessmentLatestAnswers,
    assessmentReview: getAssessmentReview,
    fields: getAssessmentFields
  });

export default connect(mapStateToProps)(SingleAssessmentFields);
