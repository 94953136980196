import { all, put, takeEvery, call } from 'redux-saga/effects';
import JsFileDownloader from 'js-file-downloader';
import { t } from 'helpers/i18n';
import env from 'helpers/env';
import {
  uploadAttachments,
  DOWNLOAD_FILE,
  deleteAttachment
} from 'actions/attachmentsActions';

import {
  uploadAttachment,
  deleteAttachment as deleteAttachmentRequest,
  updateInvention,
  generatePDF
} from 'helpers/axios/axiosRequests';

import {
  updateAttachment,
  removeAttachment,
  ADD_FIGURE_TO_INVENTION,
  updateInventionFigure
} from 'actions/newInventionFormActions';

import {
  removeSingleInventionAttachments,
  updateSingleInventionAttachment,
  fetchInventionAttachments,
  CREATE_PDF_FILE
} from 'actions/singleInventionActions';

import { getToken } from 'helpers/cookies';

import { STATUS_DRAFT } from 'constants/inventionStatuses';

import { toast } from 'react-toastify';

export function* uploadAttachmentsSaga({ payload }) {
  try {
    if (payload.attachments) {
      yield all(
        payload.attachments.map(el => {
          el.data.attributes.invention.id = payload.responseData.id;
          el.data.attributes.relation_id = payload.responseData.attributes.id;
          const action = {
            payload: {
              data: el,
              type: STATUS_DRAFT
            }
          };

          return call(uploadAttachmentsSaga, action);
        })
      );
    } else {
      const { data } = yield call(uploadAttachment, payload.data);

      if (payload.type === STATUS_DRAFT) {
        yield put(updateAttachment(data));
      } else {
        yield put(updateSingleInventionAttachment(data));
        yield put(fetchInventionAttachments());
      }
    }
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    console.error(error);
  }

  yield put(uploadAttachments.fulfill());
}

function* downloadFileSaga({ url, filename }) {
  yield new JsFileDownloader({
    url: `${env('API_URL')}${url}`,

    method: 'GET',
    headers: [
      {
        name: 'Authorization',
        value: `Bearer ${getToken()}`
      }
    ],
    filename
  }).catch(error => {
    console.error(error);
  });
}

function* deleteAttachmentSaga({ payload }) {
  try {
    yield call(deleteAttachmentRequest, payload.id);

    if (payload.type === STATUS_DRAFT) {
      yield put(removeAttachment(payload.id));
    } else {
      yield put(removeSingleInventionAttachments(payload.id));
    }
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    console.error(error);
  }

  yield put(deleteAttachment.fulfill());
}

export function* addFigureToInventionSaga({ file, inventionId }) {
  try {
    const { data } = yield call(updateInvention, {
      file: file,
      inventionId: inventionId
    });

    yield put(updateInventionFigure(data.data.attributes.figure_url));
    if (localStorage.getItem('figureImage')) {
      localStorage.removeItem('figureImage');
    }
  } catch (e) {
    yield toast.error(t('somethingWentWrong'));
    console.error(e);
  }
}

export function* createPDFFileSaga({ inventionId }) {
  try {
    yield call(generatePDF, inventionId);
    yield toast.success(t('exportHasBeenQueued'));
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    yield console.error(error);
  }
}

export default function* attachmentsSaga() {
  yield all([
    takeEvery(uploadAttachments.TRIGGER, uploadAttachmentsSaga),
    takeEvery(DOWNLOAD_FILE, downloadFileSaga),
    takeEvery(deleteAttachment, deleteAttachmentSaga),
    takeEvery(ADD_FIGURE_TO_INVENTION, addFigureToInventionSaga),
    takeEvery(CREATE_PDF_FILE, createPDFFileSaga)
  ]);
}
