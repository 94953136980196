import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  STATUS_DRAFT,
  STATUS_PENDING_DECISION,
  STATUS_ASSESSMENT,
  STATUS_PRE_ASSESSMENT
} from 'constants/inventionStatuses';
import {
  STATUS_BACK_TO_INVENTOR,
  STATUS_SEND_FOR_REVIEW,
  STATUS_MOVE_TO_DECISION
} from 'constants/surveyReviewStatuses';
import AdditionalInfoModal from './Modals/AdditionalInfoModal';

import { Fixed, ExclamationMark } from './styles';

const InventionPreviewHeader = props => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => setShowModal(!showModal);

  const prepareInfoObject = () => {
    const reviewStatuses = {
      [STATUS_DRAFT]: STATUS_BACK_TO_INVENTOR,
      [STATUS_ASSESSMENT]: STATUS_SEND_FOR_REVIEW,
      [STATUS_PENDING_DECISION]: STATUS_MOVE_TO_DECISION
    };

    if (
      !isEmpty(props.reviews) &&
      reviewStatuses[props.inventionStatus] ===
      props.reviews.data.attributes.review_status
    ) {
      return {
        comment: props.reviews.data.attributes.comment,
        author: props.reviews.data.attributes.user
      };
    }

    if (!isEmpty(props.comments)) {
      const comment = props.comments.find(
        e => e.data.attributes.status === props.inventionStatus
      );
      return comment
        ? {
          comment: comment.data.attributes.comment,
          author: comment.data.attributes.author
        }
        : {};
    }
    return 0;
  };

  const info = prepareInfoObject();

  return (
    <Fixed>
      {showModal && (
        <AdditionalInfoModal
          infoData={info}
          isOpen={showModal}
          onHide={handleModal}
        />
      )}
      <div>
        <div>
          {t('inventionDisclosure')} [{props.serialNumber || props.inventionId}]
        </div>
        <div>{props.inventionName}</div>
      </div>
      {props.inventionStatus !== STATUS_PRE_ASSESSMENT &&
        !isEmpty(info) &&
        info.comment && (
          <ExclamationMark onClick={handleModal}>
            <Tooltip content={t('showInfo')} useDefaultStyles>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Tooltip>
          </ExclamationMark>
        )}
      {props.children}
    </Fixed>
  );
};

InventionPreviewHeader.propTypes = {
  children: PropTypes.object,
  comments: PropTypes.array,
  inventionId: PropTypes.any,
  inventionName: PropTypes.string,
  inventionStatus: PropTypes.number,
  reviews: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  serialNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default InventionPreviewHeader;
