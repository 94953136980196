import styled from 'styled-components';

import { INPUT_BORDER_GRAY, BACKGROUND_GRAY } from 'constants/colors';

export const FieldWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid ${INPUT_BORDER_GRAY};
  border-radius: 5px;

  label:first-child {
    margin-bottom: 10px;
  }
`;

export const SectionsWrapper = styled.div`
  background: ${BACKGROUND_GRAY};
  padding: 20px;
`;
