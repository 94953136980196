import typeToReducer from 'type-to-reducer';

import {
  IS_TABLET_SIZE,
  SHOW_SEARCH_AND_FILTER_SECTION,
  SET_FILTER_AND_SEARCH_HEIGHT
} from 'actions/layoutDetailsActions';

const defaultState = {
  isTabletSize: false,
  showSearchAndFilterSection: false,
  filterAndSearchHeight: null
};

export default typeToReducer(
  {
    [IS_TABLET_SIZE]: (state, { isTabletSize }) => ({
      ...state,
      isTabletSize
    }),
    [SHOW_SEARCH_AND_FILTER_SECTION]: (state, { show }) => ({
      ...state,
      showSearchAndFilterSection: show
    }),
    [SET_FILTER_AND_SEARCH_HEIGHT]: (state, { height }) => ({
      ...state,
      filterAndSearchHeight: height
    })
  },
  defaultState
);
