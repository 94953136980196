import React from 'react';
import { withTranslation } from 'react-i18next';
import { InventionCreatorForm, SpinnerWrapper } from './styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createPropsSelector } from 'reselect-immutable-helpers';
import {
  getSections,
  getIsLoading,
  getLockedUntil
} from 'store/formType/formTypeSelectors';
import { getCurrentUserId } from 'store/users/usersSelectors';
import {
  fetchFormTypeData,
  unlockFormType,
  clearFormTypeData
} from 'store/formType/formTypeReducer';
import FormTypeTabs from './FormTypeTabs/FormTypeTabs';
import Spinner from 'Atoms/Spinner';

class FormTypeContainer extends React.Component {
  componentDidMount() {
    this.props.fetchFormTypeData(
      this.props.match.params.id,
      this.props.isInventionType
    );
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.currentUserId && this.props.currentUserId) {
      this.props.fetchFormTypeData(
        this.props.match.params.id,
        this.props.isInventionType
      );
    }
    if (
      prevProps.lockedUntil !== this.props.lockedUntil &&
      this.props.lockedUntil
    ) {
      const formattedLockedUntil = new Date(this.props.lockedUntil);
      const requestRefreshInterval = setInterval(() => {
        const currentDate = new Date();
        if (currentDate >= formattedLockedUntil) {
          this.props.fetchFormTypeData(
            this.props.match.params.id,
            this.props.isInventionType
          );
          clearInterval(requestRefreshInterval);
        }
      }, 3000);
    }
  }
  componentWillUnmount() {
    this.props.clearFormTypeData();
    this.props.unlockFormType(
      this.props.match.params.id,
      this.props.isInventionType
    );
  }
  render() {
    return (
      <>
        <InventionCreatorForm>
          {this.props.isLoading ? (
            <SpinnerWrapper>
              <Spinner center />
            </SpinnerWrapper>
          ) : (
            <FormTypeTabs
              formId={this.props.formId}
              handleEditField={this.props.handleEditField}
              isInventionType={this.props.isInventionType}
              isBeingDragged={this.props.isBeingDragged}
            />
          )}
        </InventionCreatorForm>
      </>
    );
  }
}

const mapStateToProps = createPropsSelector({
  sections: getSections,
  isLoading: getIsLoading,
  lockedUntil: getLockedUntil,
  currentUserId: getCurrentUserId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFormTypeData,
      unlockFormType,
      clearFormTypeData
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation('iamip')(FormTypeContainer))
);
