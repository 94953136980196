import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { formatDateWithTime } from 'helpers/dateHelpers';

export const getSingleInvention = state => state.singleInvention.data;
export const getCurrentInventionId = state =>
  state.singleInvention.currentInventionId;
export const getCurrentInventionStatus = createSelector(
  state => getSingleInvention(state),
  invention => invention.status
);
export const getCurrentInventionSerialNumber = createSelector(
  state => getSingleInvention(state),
  invention => invention.serial_number || null
);
export const getCurrentInventionName = createSelector(
  state => getSingleInvention(state),
  invention => invention.name || null
);
export const isSingleInventionLoading = state =>
  state.singleInvention.isFetching;
export const getInventionTimeline = state =>
  state.singleInvention.timeline.reverse();
export const getIsInventionTimelineLoading = state =>
  state.singleInvention.isTimelineFetching;

export const getInventionNotes = state => {
  const replies = state.singleInvention.data.notes.filter(note => note.parent);

  const mapElement = el => {
    const notePath = el.data.attributes;
    return {
      author: `${notePath.author.data.attributes.first_name} ${notePath.author.data.attributes.last_name}`,
      authorId: notePath.author.data.id,
      note: notePath.comment,
      createdAt: formatDateWithTime(notePath.created_at).toLocaleString(),
      isConfidential: notePath.is_confidential,
      confidentialFor: notePath.confidential_for,
      id: el.data.id,
      recipientId: notePath.recipient && notePath.recipient.data.id,
      replied: notePath.replied,
      replies: replies
        .filter(reply => reply.data.attributes.parent.data.id === el.data.id)
        .map(reply => mapElement(reply))
    };
  };

  return state.singleInvention.data.notes
    .filter(note => !note.parent)
    .map(el => {
      const note = mapElement(el);
      let allReplies = [];

      const mapReplies = replies => {
        // eslint-disable-next-line array-callback-return
        replies.map(e => {
          allReplies = [...allReplies, { ...e, replies: [] }];

          if (e.replies && e.replies.length) {
            mapReplies(e.replies);
          }
        });

        return allReplies;
      };

      if (note.replies && note.replies.length) {
        return {
          ...note,
          replies: mapReplies(note.replies)
        };
      }
      return note;
    });
};

export const getInventionAttachments = state =>
  state.singleInvention.data.attachments;

export const getAreAttachmentsLoading = state =>
  state.singleInvention.areAttachmentsFetching;

export const getAreNotesLoading = state =>
  state.singleInvention.areNotesFetching;

export const getExperts = state =>
  state.singleInvention.experts &&
  state.singleInvention.experts.reduce(
    (acc, data) => [
      ...acc,
      {
        id: data.expert.id,
        email: data.expert.attributes.email,
        fullName: `${data.expert.attributes.first_name} ${data.expert.attributes.last_name}`,
        assessmentStatus: data.status,
        decisionProposalStatus: data.decisionProposalStatus,
        assessmentId: data.assessmentId && data.assessmentId.split('/').pop()
      }
    ],
    []
  );

export const getInventor = createSelector(
  state => getSingleInvention(state),
  invention => invention && invention.inventor.data
);

export const getInventorFullName = createSelector(
  state => getSingleInvention(state),
  invention => {
    if (isEmpty(invention) || !invention.inventor) {
      return '';
    }
    const { attributes } = invention.inventor && invention.inventor.data;

    return attributes && `${attributes.first_name} ${attributes.last_name}`;
  }
);

export const getDecisionMaker = createSelector(
  state => getSingleInvention(state),
  invention =>
    (invention.decision_maker && invention.decision_maker.data) || null
);

export const getDecisionMakerFullName = createSelector(
  state => getDecisionMaker(state),
  decisionMaker =>
    (decisionMaker &&
      `${decisionMaker.attributes.first_name} ${decisionMaker.attributes.last_name}`) ||
    null
);

export const getProposedExperts = createSelector(
  state => getSingleInvention(state),
  invention => (invention && invention.proposed_experts) || []
);

export const getReviewManager = createSelector(
  state => getSingleInvention(state),
  invention =>
    (invention.review_manager && invention.review_manager.data) || null
);

export const getReviewManagerFullName = createSelector(
  state => getReviewManager(state),
  reviewManager =>
    (reviewManager &&
      `${reviewManager.attributes.first_name} ${reviewManager.attributes.last_name}`) ||
    null
);

export const getSingleInventionForm = createSelector(
  state => getSingleInvention(state),
  invention =>
    (!isEmpty(invention) &&
      !isEmpty(invention.draft) &&
      invention.draft.data.attributes.survey.data.attributes) ||
    {}
);

export const getSurveyId = createSelector(
  state => getSingleInvention(state),
  invention =>
    invention.draft ? invention.draft.data.attributes.survey.data.id : ''
);

export const getInventionReviews = createSelector(
  state => state.singleInvention.reviews,
  reviews => {
    reviews.data &&
      reviews.data.attributes.answer_reviews.map(el => ({
        type: 'answer_review',
        attributes: {
          comment: el.data ? el.data.attributes.comment : el.attributes.comment,
          critical: el.data
            ? el.data.attributes.critical
            : el.attributes.critical,
          survey_review: {
            type: 'survey_review',
            id: reviews.data.id
          },
          answer: {
            type: 'answer',
            id: el.data ? el.data.attributes.answer.id : el.attributes.answer.id
          }
        }
      }));
    return reviews;
  }
);

export const getIsInventionBeingCreated = state =>
  state.singleInvention.isInventionBeingCreated;

export const getIsReviewManagerBeingAssigned = state =>
  state.singleInvention.isReviewManagerBeingAssigned;

export const getIsDecisionMakerBeingAssigned = state =>
  state.singleInvention.isDecisionMakerBeingAssigned;

export const getIsStatusBeingChanged = state =>
  state.singleInvention.isStatusBeingChanged;

export const getIsPreAssessmentBeingDone = state =>
  state.singleInvention.isPreAssessmentBeingDone;

export const getIsAttachmentBeingUploaded = state =>
  state.singleInvention.isAttachmentBeingUploaded;

export const getIsAttachmentBeingDeleted = state =>
  state.singleInvention.isAttachmentBeingDeleted;
