import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';

import { Dropdown } from 'react-bootstrap';

import Spinner from 'Atoms/Spinner';

import {
  getCurrentUserRoles,
  getCurrentUserId
} from 'store/users/usersSelectors';

import {
  getSurveyId,
  getIsStatusBeingChanged,
  getIsPreAssessmentBeingDone
} from 'store/singleInvention/singleInventionSelectors';

import {
  STATUS_DRAFT,
  STATUS_PRE_ASSESSMENT,
  STATUS_ASSESSMENT,
  STATUS_PENDING_DECISION,
  STATUS_DELETED
} from 'constants/inventionStatuses';

import {
  INVENTOR,
  REVIEW_MANAGER,
  DECISION_MAKER,
  REVIEW_COORDINATOR
} from 'constants/userRoles';
import { DEFAULT_YELLOW, WHITE } from 'constants/colors';

import { INVENTIONS_PAGE, EDIT_INVENTION } from 'constants/routingConstants';

import { ActionsContainer } from './styles';
import PreAssessmentModal from './Modals/PreassessmentModal';
import ConfirmationModal from 'Elements/ConfirmationModal';
import MoveToDecisionModal from './Modals/MoveToDecisionModal';
import MakeADecisionModal from './Modals/MakeADecisionModal';

import {
  createPDFFile,
  changeInventionStatus
} from 'actions/singleInventionActions';

const MODALS = {
  preAssessment: 'preAssessment',
  moveForDecision: 'moveForDecision',
  makeDecision: 'makeDecision',
  deleteInvention: 'deleteInvention'
};

const InventionPreviewActions = ({
  reviewManager,
  decisionMaker,
  match,
  history,
  status,
  userRoles,
  currentUserId,
  currentInventionId,
  surveyId,
  attachments,
  inventor,
  sectionsLength,
  isStatusBeingChanged,
  isPreAssessmentBeingDone,
  createPDFFile,
  changeInventionStatus,
  coInventors
}) => {
  const { t } = useTranslation();
  const actionsRef = useRef();
  const [modal, setModal] = useState(null);
  const [buttonWidth, setButtonWidth] = useState(null);

  useEffect(() => {
    const { current } = actionsRef;

    current &&
      buttonWidth < current.offsetWidth &&
      setButtonWidth(current.offsetWidth);
    // eslint-disable-next-line
  }, [actionsRef.current]);

  const assignedReviewManagerId =
    !isEmpty(reviewManager) && reviewManager.data.id;
  const assignedDecisionMakerId =
    !isEmpty(decisionMaker) && decisionMaker.data.id;

  const inventorId = !isEmpty(inventor) && inventor.data.id;
  const isCurrentUserAnInventor =
    userRoles.includes(INVENTOR) && inventorId === currentUserId;
  const isCurrentUserAReviewManager =
    userRoles.includes(REVIEW_MANAGER) &&
    assignedReviewManagerId === currentUserId;
  const isCurrentUserADecisionMaker =
    userRoles.includes(DECISION_MAKER) &&
    assignedDecisionMakerId === currentUserId;

  const actionButtons = [];
  const renderPDFAction = () => ({
    value: 'createPDF',
    action: () => createPDFFile(match.params.id)
  });

  const deleteInvention = () => {
    const data = {
      data: {
        type: 'invention',
        attributes: {
          status: STATUS_DELETED
        }
      }
    };

    changeInventionStatus({ id: currentInventionId, data });
  };

  // eslint-disable-next-line consistent-return
  const renderActions = () => {
    if (
      isCurrentUserAnInventor ||
      isCurrentUserAReviewManager ||
      userRoles.includes(REVIEW_COORDINATOR) ||
      isCurrentUserADecisionMaker ||
      (coInventors && coInventors.find(el => el.data.id === currentUserId))
    ) {
      actionButtons.push(renderPDFAction());
    }
    switch (status) {
      case STATUS_DRAFT:
        if (isCurrentUserAnInventor) {
          if (isEmpty(reviewManager)) {
            actionButtons.push({
              value: 'deleteInvention',
              action: () => setModal(MODALS.deleteInvention)
            });
          }

          actionButtons.push({
            value: 'edit',
            action: () =>
              history.push(
                `${INVENTIONS_PAGE}/${currentInventionId}${EDIT_INVENTION}`
              )
          });
        }
        break;
      case STATUS_PRE_ASSESSMENT:
        if (isCurrentUserAReviewManager) {
          actionButtons.push({
            value: 'preassessment',
            action: () => setModal(MODALS.preAssessment)
          });
        }
        break;
      case STATUS_ASSESSMENT:
        if (isCurrentUserAReviewManager) {
          actionButtons.push({
            value: 'moveForDecision',
            action: () => setModal(MODALS.moveForDecision)
          });
        }
        break;
      case STATUS_PENDING_DECISION:
        if (isCurrentUserADecisionMaker) {
          actionButtons.push({
            value: 'makeDecision',
            action: () => setModal(MODALS.makeDecision)
          });
        }
        break;
      default:
        return null;
    }
  };

  const closeModal = () => setModal(null);

  // eslint-disable-next-line consistent-return
  const renderModal = () => {
    switch (modal) {
      case MODALS.preAssessment:
        return (
          <PreAssessmentModal
            isOpen={modal === MODALS.preAssessment}
            closeModal={closeModal}
            surveyId={surveyId}
            inventionId={parseInt(match.params.id)}
            attachments={attachments}
            sectionsLength={sectionsLength}
          />
        );
      case MODALS.moveForDecision:
        return (
          <MoveToDecisionModal
            isOpen={modal === MODALS.moveForDecision}
            onHide={closeModal}
            inventionId={currentInventionId}
          />
        );
      case MODALS.makeDecision:
        return (
          <MakeADecisionModal
            isOpen={modal === MODALS.makeDecision}
            onHide={closeModal}
            inventionId={currentInventionId}
          />
        );
      case MODALS.deleteInvention:
        return (
          <ConfirmationModal
            isOpen={modal === MODALS.deleteInvention}
            onHide={closeModal}
            onConfirm={deleteInvention}
            inventionId={currentInventionId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {modal && renderModal()}
      <ActionsContainer>
        {renderActions()}
        {actionButtons.length > 0 && (
          <Dropdown ref={actionsRef}>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{
                background: DEFAULT_YELLOW,
                borderColor: DEFAULT_YELLOW,
                width: `${buttonWidth}px`
              }}
            >
              {isStatusBeingChanged || isPreAssessmentBeingDone ? (
                <Spinner color={WHITE} size={'16px'} center inline />
              ) : (
                t('actions')
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actionButtons.map((el, index) => (
                <React.Fragment key={el.value}>
                  <Dropdown.Item onClick={el.action}>
                    {t(el.value)}
                  </Dropdown.Item>
                  {index !== actionButtons.length - 1 && <Dropdown.Divider />}
                </React.Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </ActionsContainer>
    </>
  );
};

const mapStateToProps = state => ({
  userRoles: getCurrentUserRoles(state),
  currentUserId: getCurrentUserId(state),
  surveyId: getSurveyId(state),
  isStatusBeingChanged: getIsStatusBeingChanged(state),
  isPreAssessmentBeingDone: getIsPreAssessmentBeingDone(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPDFFile,
      changeInventionStatus
    },
    dispatch
  );

InventionPreviewActions.propTypes = {
  decisionMaker: PropTypes.object,
  reviewManager: PropTypes.object,
  match: PropTypes.object,
  status: PropTypes.number,
  userRoles: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.object,
  inventor: PropTypes.object,
  sectionsLength: PropTypes.number,
  createPDFFile: PropTypes.func,
  changeInventionStatus: PropTypes.func,
  coInventors: PropTypes.arrayOf(PropTypes.object)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InventionPreviewActions));
