import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchAssessmentTypes,
  assignInventionExpert
} from 'actions/inventionAssessmentActions';

import { fetchOrganisationUsers } from 'actions/organisationActions';
import { getProposedExperts } from 'store/singleInvention/singleInventionSelectors';
import {
  getAreUsersLoading,
  getUsersForOptions
} from 'store/users/usersSelectors';
import {
  getAssessmentTemplates,
  getIsFetchingTemplates
} from 'store/inventionAssessments/inventionAssessmentsSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import Modal from 'Atoms/Modal';
import Button from 'Atoms/Button';

import { Action, PersonWrapper, IconWrapper, ProposalWrapper } from './styles';
import Spinner from 'Atoms/Spinner';

const ExpertsModal = ({
  isOpen,
  closeModal,
  experts,
  assignExpert,
  getOrganisationUsers,
  areUsersLoading,
  fetchAssessmentTypes,
  assessmentTemplates,
  inventionId,
  isFetchingTemplates,
  proposedUsers
}) => {
  const { t } = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedExpert, setSelectedExpert] = useState();

  useEffect(() => {
    if (assessmentTemplates.length === 1) {
      setSelectedTemplate(assessmentTemplates[0]);
    }
  }, [assessmentTemplates]);

  const renderProposedUsers = user => (
    <PersonWrapper>
      <IconWrapper>
        <FontAwesomeIcon icon={faUserCircle} />
      </IconWrapper>
      <span>{`${user.first_name} ${user.last_name}`}</span>
    </PersonWrapper>
  );

  const handleAssignExpert = () => {
    const data = {
      data: {
        type: 'assessment',
        attributes: {
          status: 1,
          expert: {
            id: selectedExpert,
            type: 'user'
          },
          assessment_type: {
            id: selectedTemplate.value,
            type: 'assessment_type'
          },
          invention: {
            id: `/v1/inventions/${inventionId}`,
            type: 'invention'
          }
        }
      }
    };
    assignExpert(data);
    closeModal();
  };

  useEffect(() => {
    getOrganisationUsers();
    fetchAssessmentTypes();
    // eslint-disable-next-line
  }, []);

  const content = () => (
    <Fragment>
      {!isFetchingTemplates ? (
        <>
          <ProposalWrapper>
            {proposedUsers.length > 0 && (
              <>
                {t('inventorProposition')}:
                {proposedUsers.map(el =>
                  renderProposedUsers(el.data.attributes)
                )}
              </>
            )}
          </ProposalWrapper>

          <Action>
            <label className="form-check-label">
              {t('selectAssessmentType')}
            </label>
            <Select
              options={assessmentTemplates}
              placeholder={`${t('select')}...`}
              onChange={e => setSelectedTemplate(e)}
              value={selectedTemplate || null}
            />
          </Action>
          <Action>
            <label className="form-check-label">{t('chooseExpert')}</label>
            <Select
              onChange={e => setSelectedExpert(e.value)}
              options={experts}
              placeholder={`${t('select')}...`}
              isLoading={areUsersLoading}
            />
          </Action>
        </>
      ) : (
        <Spinner center />
      )}
    </Fragment>
  );

  const footer = () => (
    <Fragment>
      <Button value={t('close')} onClick={closeModal} />
      <Button
        colorVariant="green"
        disabled={!(selectedTemplate && selectedExpert)}
        value={t('add')}
        onClick={handleAssignExpert}
      />
    </Fragment>
  );

  return (
    <Modal
      isOpen={isOpen}
      onHide={closeModal}
      title={t('assignExperts')}
      content={content()}
      footer={footer()}
    />
  );
};

const mapStateToProps = state => ({
  experts: getUsersForOptions(state, 'experts'),
  areUsersLoading: getAreUsersLoading(state),
  assessmentTemplates: getAssessmentTemplates(state),
  isFetchingTemplates: getIsFetchingTemplates(state),
  proposedUsers: getProposedExperts(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrganisationUsers: fetchOrganisationUsers,
      fetchAssessmentTypes: fetchAssessmentTypes.trigger,
      assignExpert: assignInventionExpert.trigger
    },
    dispatch
  );

ExpertsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  experts: PropTypes.arrayOf(PropTypes.object),
  assignExpert: PropTypes.func,
  isFetchingTemplates: PropTypes.bool,
  proposedUsers: PropTypes.arrayOf(PropTypes.object)
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsModal);
