import styled from 'styled-components';

import {
  DARK_GRAY,
  DEFAULT_RED,
  INPUT_BORDER_GRAY,
  LIGHT_GRAY_SECONDARY,
  WHITE
} from 'constants/colors';

export const FieldWrapper = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${LIGHT_GRAY_SECONDARY};
  border: 1px solid ${INPUT_BORDER_GRAY};
  margin: 25px 0;
`;

export const Label = styled.div`
  position: absolute;
  top: -17px;
  background-color: ${WHITE};
  display: inline-block;
  padding: 5.5px;
  color: red;
  border-radius: 5px;
  border: 1px solid ${DEFAULT_RED};
  font-size: 13px;
  right: 65px;
`;
export const FieldName = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
`;

export const FieldDescription = styled.p`
  font-size: 15px;
  white-space: pre-wrap;
`;

export const IconBoxes = styled.div`
  position: absolute;
  top: -17px;
  right: 6px;
  padding: 3px 0;
  border: 1px solid ${DARK_GRAY};
  border-radius: 5px;
  background-color: ${WHITE};
  svg {
    cursor: pointer;
    margin: 0 5px;
  }
`;
export const OptionsWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;

  & > div {
    flex: 25%;
    padding: 10px;
  }
  & > div:nth-child(n + 4) {
    flex-grow: 0;
  }
`;
export const OptionContainer = styled.div``;
