import styled from 'styled-components';

const BaseStyles = styled.div`
  padding: 20px;
  border-radius: 5px;
`;

export const Title = styled.div`
  font-size: 24px;
  text-align: center;
  padding: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ActionsWrapper = styled.div`
  text-align: right;
  margin-top: 20px;

  & > button {
    margin-left: 10px;
  }
`;

export const Column = styled(BaseStyles)`
  background: white;
  height: 82vh;
  padding: 0;
`;

export const ScrollableContent = styled.div`
  overflow: ${props => (props.padding ? `auto` : `hidden`)};
  ${props => props.padding && `padding: 0 20px;`}
  height: calc(100% - 75px);
`;

export const FieldWrapper = styled.div`
  margin-bottom: 20px;
`;
