import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  answerAssessment,
  reviewAssessment
} from 'actions/inventionAssessmentActions';
import {
  getAssessmentFields,
  getAssessmentName,
  getAssessmentSurveyId,
  getAssessmentReviewManagerId,
  getAssessmentExpertId,
  getAssessmentStatus,
  getIsAssessmentBeingAnswered
} from 'store/inventionAssessments/inventionAssessmentsSelectors';
import { getCurrentUserId } from 'store/users/usersSelectors';
import {
  getCurrentInventionStatus,
  getCurrentInventionName
} from 'store/singleInvention/singleInventionSelectors';

import {
  STATUS_PENDING,
  STATUS_REVIEW,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED
} from 'constants/assessmentStatuses';
import { STATUS_OPEN, STATUS_SUBMITTED } from 'constants/surveyStatuses';
import { STATUS_ASSESSMENT as INVENTION_STATUS_ASSESSMENT } from 'constants/inventionStatuses';
import { validateForms } from 'helpers/validationHelper';

import Button from 'Atoms/Button';

import { Title, ActionsWrapper, Column, ScrollableContent } from './styles';

import FurtherQuestionsModal from './FurtherQuestionsModal';
import SingleAssessmentForm from 'Elements/SingleAssessmentForm';
import SingleInventionPage from 'Pages/SingleInventionPage';

const SingleAssessmentPage = props => {
  const { t } = useTranslation();
  const [answers, setAnswers] = useState([]);
  const [review, setReview] = useState(null);
  const [unfilledFields, setUnfilledFields] = useState([]);
  const [decisionProposal, setDecisionProposal] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isDraftBeingSaved, setIsDraftBeingSaved] = useState(false);
  const [isAssessmentAccepted, setIsAssessmentAccepted] = useState(false);

  const handleFields = formFields => setAnswers(formFields);

  const validateFields = () => {
    const requiredFields = props.fields.filter(
      el => el.data.attributes.required
    );

    let emptyFields = [];

    emptyFields = validateForms(requiredFields, answers);

    if (!decisionProposal.status || !decisionProposal.explanation) {
      emptyFields.push('decisionProposal');
    }
    setUnfilledFields(emptyFields);

    return (
      !emptyFields.length &&
      decisionProposal.status &&
      decisionProposal.explanation
    );
  };

  const sendRequest = (status, validate = false) => {
    setUnfilledFields([]);

    setIsDraftBeingSaved(status === STATUS_IN_PROGRESS);

    if (validate) {
      const canContinue = validateFields();

      if (!canContinue) {
        toast.error(t('fillRequiredFields'));
        return;
      }
    }

    const payload = answers.map(field => ({
      id: field.fieldId,
      value: field.value,
      ...(field.additionalValue && { additionalValue: field.additionalValue })
    }));

    props.answerAssessment({
      surveyId: props.surveyId,
      fields: payload,
      assessmentId: props.match.params.id,
      status: status,
      inventionId: props.match.params.inventionId,
      decisionProposal
    });
  };

  const handleDraft = () => {
    sendRequest(STATUS_IN_PROGRESS);
  };

  const handleConfirm = () => {
    sendRequest(STATUS_REVIEW, true);
  };

  const handleReviewManagerAction = isAccepted => {
    setIsAssessmentAccepted(isAccepted);

    const payload = {
      data: {
        type: 'survey_review',
        attributes: {
          status: isAccepted ? STATUS_SUBMITTED : STATUS_OPEN,
          comment: review,
          survey: {
            id: props.surveyId,
            type: 'form'
          }
        }
      }
    };

    props.reviewAssessment({
      data: payload,
      inventionId: props.match.params.inventionId,
      assessmentStatus: isAccepted ? STATUS_COMPLETED : STATUS_IN_PROGRESS,
      assessmentId: props.match.params.id
    });
  };

  const isNotInventionAssessment =
    props.currentInventionStatus !== INVENTION_STATUS_ASSESSMENT;

  const prepareActions = () => {
    if (
      props.assessmentStatus === STATUS_COMPLETED ||
      isNotInventionAssessment
    ) {
      return null;
    }

    if (
      props.assessmentStatus === STATUS_REVIEW &&
      props.reviewManagerId === props.currentUserId
    ) {
      return (
        <ActionsWrapper>
          <Button
            isFetching={
              !isAssessmentAccepted && props.isAssessmentBeingAnswered
            }
            colorVariant="blue"
            value={t('moveBackToExpert')}
            onClick={() => handleReviewManagerAction(false)}
          />
          <Button
            colorVariant="green"
            isFetching={isAssessmentAccepted && props.isAssessmentBeingAnswered}
            value={t('accept')}
            onClick={() => handleReviewManagerAction(true)}
          />
        </ActionsWrapper>
      );
    }

    if (
      [STATUS_PENDING, STATUS_IN_PROGRESS].includes(props.assessmentStatus) &&
      props.currentUserId === props.assessmentExpertId
    ) {
      return (
        <ActionsWrapper>
          <Button
            colorVariant="blue"
            isFetching={isDraftBeingSaved && props.isAssessmentBeingAnswered}
            value={t('saveDraft')}
            onClick={handleDraft}
          />
          <Button
            colorVariant="green"
            isFetching={!isDraftBeingSaved && props.isAssessmentBeingAnswered}
            value={t('confirm')}
            onClick={handleConfirm}
          />
        </ActionsWrapper>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-6">
        <Column>
          <Title>
            {t('inventionPreview')}: {props.inventionName}
          </Title>
          <ScrollableContent>
            <SingleInventionPage
              inventionId={props.match.params.inventionId}
              assessmentPreview
              collapsed
            />
          </ScrollableContent>
        </Column>
      </div>
      <div className="col-6">
        {showModal && (
          <FurtherQuestionsModal
            closeModal={() => setShowModal(false)}
            isOpen={showModal}
          />
        )}
        <Column>
          <Title>
            {t('assessment')}: {props.assessmentName}
          </Title>
          <ScrollableContent padding>
            <SingleAssessmentForm
              assessmentId={props.match.params.id}
              decisionProposal={decisionProposal}
              handleFields={handleFields}
              inventionId={props.match.params.inventionId}
              setDecisionProposal={setDecisionProposal}
              setReview={setReview}
              unfilledFields={unfilledFields}
            />
            {prepareActions()}
          </ScrollableContent>
        </Column>
      </div>
    </div>
  );
};

const mapStateToProps = createPropsSelector({
  currentUserId: getCurrentUserId,
  fields: getAssessmentFields,
  reviewManagerId: getAssessmentReviewManagerId,
  assessmentName: getAssessmentName,
  surveyId: getAssessmentSurveyId,
  assessmentExpertId: getAssessmentExpertId,
  assessmentStatus: getAssessmentStatus,
  currentInventionStatus: getCurrentInventionStatus,
  isAssessmentBeingAnswered: getIsAssessmentBeingAnswered,
  inventionName: getCurrentInventionName
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      answerAssessment,
      reviewAssessment
    },
    dispatch
  );

SingleAssessmentPage.propTypes = {
  match: PropTypes.object,
  answerAssessment: PropTypes.func,
  currentUserId: PropTypes.string,
  fields: PropTypes.array,
  reviewManagerId: PropTypes.string,
  assessmentName: PropTypes.string,
  surveyId: PropTypes.string,
  assessmentExpertId: PropTypes.string,
  assessmentStatus: PropTypes.number,
  reviewAssessment: PropTypes.func,
  currentInventionStatus: PropTypes.number,
  isAssessmentBeingAnswered: PropTypes.bool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SingleAssessmentPage));
