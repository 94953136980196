import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import RichText from 'Atoms/RichText';

import RadioType from './SingleFields/RadioType';
import AdvancedRadioType from './SingleFields/AdvancedRadioType';
import CheckboxType from './SingleFields/CheckboxType';
import SelectType from './SingleFields/SelectType';
import InformationType from './SingleFields/InformationType';

const SingleField = ({ field, onChange, disabled }) => {
  const handleChange = (fieldId, value, additionalValue) => {
    onChange({
      fieldId,
      value,
      ...(additionalValue && { additionalValue })
    });
  };

  const FieldType = () => {
    switch (field.type) {
      case 1:
        return (
          <RichText
            placeholder={field.description}
            onChange={value => handleChange(field.id, value)}
            disabled={disabled}
            value={!disabled ? field.value : field.value || '-'}
            maxLength={field.options.maxLength}
          />
        );
      case 2:
        return (
          <RadioType
            field={field}
            onChange={handleChange}
            disabled={disabled}
          />
        );
      case 3:
        return (
          <AdvancedRadioType
            field={field}
            onChange={handleChange}
            disabled={disabled}
          />
        );
      case 4:
        return (
          <CheckboxType
            field={field}
            onChange={handleChange}
            disabled={disabled}
          />
        );
      case 5:
        return (
          <SelectType
            field={field}
            onChange={handleChange}
            disabled={disabled}
          />
        );
      case 6:
        return <InformationType field={field} />;
      default:
        return null;
    }
  };

  return <Fragment>{FieldType()}</Fragment>;
};

SingleField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default memo(SingleField);
