import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RequiredLabel, MaxLengthBox, TextareaLabel } from './styles';

const Textarea = ({
  label,
  value,
  placeholder,
  onChange,
  disabled,
  resize,
  height,
  width,
  isRequired,
  maxLength,
  rows,
  noMargin,
  borderColor,
  autoResize,
  onKeyUp
}) => {
  const { t } = useTranslation();

  const [inputLength, setInputLength] = useState(0);
  const [expandedHeight, setExpandedHeight] = useState(null);
  const ref = useRef();

  useEffect(() => {
    if (autoResize) {
      setExpandedHeight(ref.current.scrollHeight + 20);
    }
    // eslint-disable-next-line
  }, [value]);

  const styles = {
    ...(!resize && { resize: 'none' }),
    ...(height && { height }),
    ...(width && { width }),
    ...(borderColor && { borderColor }),
    ...(autoResize && {
      height: expandedHeight
        ? expandedHeight + 'px'
        : ref.current &&
          ref.current.clientHeight < ref.current.scrollHeight &&
          `${ref.current.scrollHeight}px`,
      overflow: 'hidden'
    })
  };

  const handleChange = e => {
    (onChange && onChange(e.target.value)) ||
      (onKeyUp && onKeyUp(e.target.value));
    if (maxLength) {
      setInputLength(e.target.value.length);
    }
  };

  return (
    <div className={`form-group ${noMargin ? 'no-margin' : ''}`}>
      {label && (
        <TextareaLabel className="form-check-label">{label}</TextareaLabel>
      )}
      <textarea
        ref={ref}
        style={styles}
        className="form-control"
        rows={rows || 3}
        defaultValue={value || ''}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyUp={handleChange}
        readOnly={disabled}
        maxLength={maxLength || null}
      />
      {isRequired && <RequiredLabel>{t('requiredField')}</RequiredLabel>}
      {maxLength && (
        <MaxLengthBox>{`${inputLength}/${maxLength}`}</MaxLengthBox>
      )}
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  resize: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  noMargin: PropTypes.bool,
  borderColor: PropTypes.any,
  autoResize: PropTypes.bool
};

export default memo(Textarea);
