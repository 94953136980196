import React, { Fragment } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SelectWrapper, SelectLabel, CustomSelect } from './styles';
import { BACKGROUND_GRAY, DARK_BLUE } from 'constants/colors';

const SelectPageSize = ({ onLimitSelect }) => {
  const { t } = useTranslation();
  const options = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 }
  ];

  const pageSize = localStorage.getItem('pageSize');

  const handleOnChange = ({ value }) => {
    onLimitSelect(value);
  };

  return (
    <Fragment>
      <SelectLabel>{t('Items per page')}</SelectLabel>
      <SelectWrapper>
        <Select
          options={options}
          defaultValue={
            (pageSize &&
              options.find(item => item.value === Number(pageSize))) ||
            options[0]
          }
          onChange={handleOnChange}
          styles={CustomSelect}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: `${BACKGROUND_GRAY}`,
              primary: `${DARK_BLUE}`
            }
          })}
        />
      </SelectWrapper>
    </Fragment>
  );
};

SelectPageSize.propTypes = {
  onLimitSelect: PropTypes.func
};

export default SelectPageSize;
