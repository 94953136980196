import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import {
  getCurrentValidUserId,
  getIsUserLoading
} from 'store/users/usersSelectors';

import { getIsAuth } from 'store/login/loginSelectors';
import { confirmAuth } from 'store/login/loginReducer';
import { fetchUserData } from 'store/users/routines';
import { changeOrganisation } from 'actions/organisationActions';

import {
  HOME_PAGE,
  LOGIN_PAGE,
  CHANGE_ORGANISATION_PAGE
} from 'constants/routingConstants';

import { getToken, getUserId } from 'helpers/cookies';

import LoadingPage from 'Pages/LoadingPage';

const PreloadHOC = ChildComponent => {
  class Preload extends Component {
    componentDidMount() {
      const { location, history } = this.props;

      if (location.pathname === CHANGE_ORGANISATION_PAGE) {
        const organisationId = new URLSearchParams(location.search).get(
          'master_id'
        );

        this.props.changeOrganisation(organisationId);
        return;
      }

      const token = getToken();
      const isTokenValid = token && (this.props.currentUserId || getUserId());
      const previousPath = localStorage.getItem('previousPath');

      if (isTokenValid) {
        this.props.fetchUserData();
      } else {
        !previousPath &&
          localStorage.setItem('previousPath', location.pathname);

        history.push(LOGIN_PAGE);
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      if (
        nextProps.location.pathname !== this.props.location.pathname ||
        this.props.isAuth !== nextProps.isAuth ||
        this.props.isLoading !== nextProps.isLoading ||
        this.props.currentUserId !== nextProps.currentUserId ||
        this.props.isUserLoading !== nextProps.isUserLoading ||
        this.props.isNewInventionTypeChooseModalVisible !==
          nextProps.isNewInventionTypeChooseModalVisible ||
        // eslint-disable-next-line eqeqeq
        this.props.showFilterAndSearch != nextProps.showFilterAndSearch ||
        this.props.top !== nextProps.top
      ) {
        return true;
      }

      return false;
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.isUserLoading !== this.props.isUserLoading &&
        !this.props.isUserLoading &&
        this.props.currentUserId
      ) {
        this.props.confirmAuth();

        if (this.props.location.pathname === CHANGE_ORGANISATION_PAGE) {
          this.props.history.push(HOME_PAGE);
        }

        return;
      }

      if (
        !this.props.isUserLoading &&
        !this.props.isAuth &&
        !this.props.currentUserId
      ) {
        this.props.history.push(LOGIN_PAGE);
      }
    }

    render() {
      if (
        this.props.currentUserId &&
        (this.props.isUserLoading || !this.props.isAuth)
      ) {
        return <LoadingPage />;
      }
      if (this.props.isAuth || this.props.location.pathname === LOGIN_PAGE) {
        return <ChildComponent isAuth={this.props.isAuth} {...this.props} />;
      }

      return null;
    }
  }

  const mapStateToProps = state => ({
    isAuth: getIsAuth(state),
    currentUserId: getCurrentValidUserId(state),
    isUserLoading: getIsUserLoading(state)
  });

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        confirmAuth,
        fetchUserData,
        changeOrganisation
      },
      dispatch
    );

  Preload.propTypes = {
    changeOrganisation: PropTypes.func,
    confirmAuth: PropTypes.func,
    currentUserId: PropTypes.string,
    fetchUserData: PropTypes.func,
    isAuth: PropTypes.bool,
    isUserLoading: PropTypes.bool,
    location: PropTypes.object
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Preload));
};

export default PreloadHOC;
