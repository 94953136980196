export const SET_FIELD = 'filterAndSearch/SET_FIELD';
export const CLEAR_ALL_FIELDS = 'filterAndSearch/CLEAR_ALL_FIELDS';
export const ARE_FILTERS_APPLIED = 'filterAndSearch/ARE_FILTERS_APPLIED';

export const setField = (field, value) => ({
  type: SET_FIELD,
  field,
  value
});

export const clearAllFields = () => ({
  type: CLEAR_ALL_FIELDS
});

export const setAreFiltersApplied = value => ({
  type: ARE_FILTERS_APPLIED,
  value
});
