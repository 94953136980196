import styled from 'styled-components';

import { DEFAULT_BLUE, LIGHT_GRAY, WHITE } from 'constants/colors';

export const ToggleIcon = styled.div`
  border-left: 1px solid ${LIGHT_GRAY};
  border-right: 1px solid ${LIGHT_GRAY};
  color: ${DEFAULT_BLUE};
  height: 50px;
  padding: 10px 15px;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  display: flex;
  z-index: 11;
  align-items: center;
  &:hover {
    background-color: ${LIGHT_GRAY};
    cursor: pointer;
  }
`;

export const LogoWrapper = styled.div`
  max-width: ${props => (props.isCollapsed ? '100px' : '239px')};
  transition-duration: 0.35s;
  vertical-align: top;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  text-align: center;
`;

export const MainHeaderStyle = {
  height: '50px',
  borderBottom: 'none',
  marginLeft: 0,
  display: 'flex',
  position: 'sticky',
  top: 0,
  width: '100%',
  background: WHITE
};

export const NavBarStyle = isCollapsed => ({
  height: '50px',
  padding: 0,
  width: `calc(100% - ${isCollapsed ? `100px` : `240px`})`
});

export const OrganisationWrapper = styled.div`
  margin: auto;
  cursor: pointer;
`;

export const FilterAndSearch = styled.div`
  margin-left: 50px;
  display: flex;
  align-items: center;
`;

export const RemoveFilters = styled.div`
  margin-left: 15px;
  cursor: pointer;

  & > span {
    margin-right: 5px;
    font-size: 14px;
  }
`;
