import React from 'react';
import FormTypeCreator from 'Elements/FormTypeCreator';
import { connect } from 'react-redux';
import { getInventionTypeMetaData } from 'store/inventionTypeForm/inventionTypeFormSelectors';

const InventionTypeCreatorPage = ({ formTypeMetaData }) => (
  <FormTypeCreator formTypeMetaData={formTypeMetaData} isInventionType />
);

const mapStateToProps = state => ({
  formTypeMetaData: getInventionTypeMetaData(state)
});

export default connect(mapStateToProps)(InventionTypeCreatorPage);
