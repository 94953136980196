import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createPropsSelector } from 'reselect-immutable-helpers';
import PropTypes from 'prop-types';

import {
  getChooseInventionTypeId,
  getChooseInventionTypeObject
} from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalSelectors';

import { HOME_PAGE } from 'constants/routingConstants';
import InventionType from 'Models/InventionType';
import CreateInventionPage from 'Pages/CreateInventionPage/CreateInventionPage';
import Spinner from 'Atoms/Spinner/Spinner';
import { fetchSingleInvention } from 'actions/singleInventionActions';
import { getInventionReviews } from 'store/singleInvention/singleInventionSelectors';
import usePrevious from 'helpers/hooks/usePrevious';

const CreateInventionPageContainer = ({
  chooseInventionTypeObject,
  fetchInvention,
  history,
  match,
  reviews
}) => {
  const [inventionTypeModel, setInventionTypeModel] = useState(null);
  const previousChooseInventionTypeObject = usePrevious(
    chooseInventionTypeObject
  );

  useEffect(() => {
    if (parseInt(match.params.id)) {
      fetchInvention(parseInt(match.params.id));
    }

    if (!chooseInventionTypeObject && !match.params.id) {
      history.push(HOME_PAGE);
    } else if (!match.params.id) {
      setInventionTypeModel(new InventionType(chooseInventionTypeObject));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      chooseInventionTypeObject !== null &&
      previousChooseInventionTypeObject !== chooseInventionTypeObject
    ) {
      setInventionTypeModel(new InventionType(chooseInventionTypeObject));
    }
    // eslint-disable-next-line
  }, [chooseInventionTypeObject]);

  return inventionTypeModel === null ? (
    <Spinner center />
  ) : (
    <CreateInventionPage
      formModel={inventionTypeModel.attributes.form}
      allowCoinventors={inventionTypeModel.attributes._allow_coinventors}
      reviews={reviews}
    />
  );
};

CreateInventionPageContainer.propTypes = {
  chooseInventionTypeObject: PropTypes.object,
  fetchInvention: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  reviews: PropTypes.object
};

const mapStateToProps = createPropsSelector({
  chooseInventionTypeId: getChooseInventionTypeId,
  chooseInventionTypeObject: getChooseInventionTypeObject,
  reviews: getInventionReviews
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchInvention: fetchSingleInvention.trigger },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateInventionPageContainer));
