import React from 'react';

import Button from 'Atoms/Button';
import { useTranslation } from 'react-i18next';
import FileBase64 from 'react-file-base64';
import { connect } from 'react-redux';

import { FileUploadWrapper, InputWrapper } from './styles';

import { addAttachment } from 'actions/newInventionFormActions';
import { addSingleInventionAttachment } from 'actions/singleInventionActions';
import { STATUS_DRAFT } from 'constants/inventionStatuses';
import { bindActionCreators } from 'redux';
import { STATUS_INVENTION } from 'constants/attachmentStatuses';

const DropzoneComponent = ({
  inventionId,
  assessmentId,
  status,
  addAttachment,
  addSingleInventionAttachment,
  type,
  isPreAssessment,
  attachments
}) => {
  const { t } = useTranslation();

  const addFile = file => {
    if (
      attachments &&
      attachments.find(el => el.data.attributes.file_name === file.name)
    ) {
      return null;
    }
    const attachmentFile = {
      data: {
        toUpload: true,
        type: 'attachment',
        attributes: {
          file_name: file.file.name,
          content: file.base64,
          status: status || STATUS_INVENTION,
          confidential: false,
          invention: {
            id: `v1/inventions/${inventionId}`,
            type: 'invention'
          },
          ...(assessmentId && {
            relation: 'assessment',
            relation_id: Number(assessmentId)
          }),
          ...(!assessmentId && {
            relation: isPreAssessment ? 'form' : 'invention',
            relation_id: Number(inventionId)
          })
        }
      }
    };

    if (STATUS_DRAFT === type) {
      return addAttachment(attachmentFile);
    }

    return addSingleInventionAttachment(attachmentFile);
  };
  return (
    <FileUploadWrapper>
      <InputWrapper>
        <FileBase64 onDone={addFile} />
      </InputWrapper>
      <Button colorVariant="blue" value={t('addFile')} />
    </FileUploadWrapper>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addAttachment, addSingleInventionAttachment }, dispatch);

export default connect(null, mapDispatchToProps)(DropzoneComponent);
