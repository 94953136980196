import TextareaSettings from 'Models/FormFieldSettings/TextareaSettings';
import RadioSettings from 'Models/FormFieldSettings/RadioSettings';
import AdvancedRadioSettings from 'Models/FormFieldSettings/AdvancedRadioSettings';
import CheckboxSettings from 'Models/FormFieldSettings/CheckboxSettings';
import SelectSettings from 'Models/FormFieldSettings/SelectSettings';

export default class FormFieldAttributes {
  constructor(rawFormFieldAttributesJSONObject) {
    this._answers = rawFormFieldAttributesJSONObject.answers;
    this._description = rawFormFieldAttributesJSONObject.description;
    this._id = rawFormFieldAttributesJSONObject.id;
    this._name = rawFormFieldAttributesJSONObject.name;
    this._position = rawFormFieldAttributesJSONObject.position;
    this._required = rawFormFieldAttributesJSONObject.required;
    this._type = rawFormFieldAttributesJSONObject.type;
    this._settings = this._prepareSettingsClass(
      this._type,
      rawFormFieldAttributesJSONObject.options
    );
  }

  _prepareSettingsClass(type, rawSettingsJSONObject) {
    switch (type) {
      case 1:
        return new TextareaSettings(rawSettingsJSONObject);
      case 2:
        return new RadioSettings(rawSettingsJSONObject);
      case 3:
        return new AdvancedRadioSettings(rawSettingsJSONObject);
      case 4:
        return new CheckboxSettings(rawSettingsJSONObject);
      case 5:
        return new SelectSettings(rawSettingsJSONObject);
      default:
        return null;
    }
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get answers() {
    return this._answers;
  }

  set answers(value) {
    this._answers = value;
  }

  get description() {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get settings() {
    return this._settings;
  }

  set settings(value) {
    this._settings = value;
  }

  get position() {
    return this._position;
  }

  set position(value) {
    this._position = value;
  }

  get required() {
    return this._required;
  }

  set required(value) {
    this._required = value;
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }
}
