import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { DownloadWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import {
  downloadPDFFile,
  fetchSingleInvention
} from 'actions/singleInventionActions';

const DownloadPDFPage = ({ downloadPDFFile, match, fetchSingleInvention }) => {
  const { t } = useTranslation();
  const downloadFileAction = () =>
    downloadPDFFile({
      url: `/v1${match.url}`
    });
  useEffect(() => {
    if (parseInt(match?.params?.inventionId)) {
      fetchSingleInvention(parseInt(match.params.inventionId));
    }
    downloadFileAction();
    // eslint-disable-next-line
  }, []);
  return (
    <DownloadWrapper>
      <p>
        {reactStringReplace(
          t('downloadFile', {
            link: 'linkToReplace',
            interpolation: { prefix: '{', suffix: '}' }
          }),
          'linkToReplace',
          (match, i) => (
            <Link key={i} to={match.url} onClick={() => downloadFileAction()}>
              {t('here')}
            </Link>
          )
        )}
      </p>
    </DownloadWrapper>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ downloadPDFFile, fetchSingleInvention }, dispatch);
export default connect(null, mapDispatchToProps)(withRouter(DownloadPDFPage));
