import typeToReducer from 'type-to-reducer';

import {
  SET_FIELD,
  CLEAR_ALL_FIELDS,
  ARE_FILTERS_APPLIED
} from 'actions/filterAndSearchActions';

const defaultState = {
  filters: {
    inventor: '',
    [`invention.inventor`]: '',
    status: '',
    [`status[]`]: '',
    name: '',
    [`invention.name`]: '',
    serialNumber: '',
    id: '',
    sumbissionDateType: '',
    sumbissionDate: null,
    [`submissionDate[after]`]: null,
    [`submissionDate[before]`]: null,
    decisionDateType: '',
    decisionDate: null,
    [`decisionDate[after]`]: null,
    [`decisionDate[before]`]: null,
    deadlineType: '',
    [`assessmentTypeVersion.deadline`]: null,
    [`assessmentTypeVersion.deadline[after]`]: null,
    [`assessmentTypeVersion.deadline[before]`]: null,
    reviewManager: '',
    [`invention.reviewManager`]: '',
    decisionMaker: '',
    [`invention..decisionMaker`]: '',
    expert: ''
  },
  areFiltersApplied: false
};

const getFieldsToClear = fieldType => {
  const field =
    fieldType === 'deadline' ? `assessmentTypeVersion.deadline` : fieldType;
  const prefix = fieldType === 'deadline' ? 'assessmentTypeVersion.' : '';
  const fieldTypeBefore = prefix + fieldType + '[before]';
  const fieldTypeAfter = prefix + fieldType + '[after]';

  return {
    inactive: [field, fieldTypeBefore, fieldTypeAfter],
    on: [fieldTypeBefore, fieldTypeAfter],
    earlierThan: [field, fieldTypeAfter],
    laterThan: [field, fieldTypeBefore],
    between: [field]
  };
};

export default typeToReducer(
  {
    [SET_FIELD]: (state, { field, value }) => {
      let fieldsToClear = null;

      if (field.slice(-4) === 'Type') {
        fieldsToClear = getFieldsToClear(field.replace('Type', ''))[value];
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [field]: value,
          ...(fieldsToClear &&
            fieldsToClear.reduce((acc, key) => ({ ...acc, [key]: null }), {}))
        }
      };
    },
    [CLEAR_ALL_FIELDS]: state => ({ ...state, filters: defaultState.filters }),
    [ARE_FILTERS_APPLIED]: (state, { value }) => ({
      ...state,
      areFiltersApplied: value
    })
  },
  defaultState
);
