import styled from 'styled-components';
import { BLACK, DEFAULT_GREEN } from 'constants/colors';

export const IconWrapper = styled.div`
  font-size: 21px;
  cursor: pointer;
  color: ${DEFAULT_GREEN};

  label {
    margin-left: 10px;
    font-size: 16px;
    color: ${BLACK};
    cursor: pointer;
    vertical-align: middle;
  }
`;
