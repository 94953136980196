import { applyMiddleware, compose, createStore } from 'redux';
import reducers from 'store/reducers';
import createSagaMiddleware from 'redux-saga';
import ReduxThunk from 'redux-thunk';
import sagas from 'sagas/rootSaga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

/**
 * This can be used to debug redux to console.
 * To use replace reducers(history) with rootReducer
 */
export const rootReducer = (state, action) => {
  // eslint-disable-next-line no-console
  console.log(action.type, {
    state,
    action
  });

  return reducers(history)(state, action);
};

export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [routerMiddleware(history), ReduxThunk, sagaMiddleware];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(sagas);
  return store;
};
