import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Radio = ({ label, checked, onCheck, disabled, inline, name, value }) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  useEffect(() => setIsChecked(checked), [checked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onCheck && onCheck(isChecked);
  };

  const pointerStyle = { cursor: 'pointer' };

  return (
    <div className={inline ? 'form-check form-check-inline' : 'form-check'}>
      <label
        className="form-check-label"
        style={!disabled ? pointerStyle : null}
      >
        <input
          className="form-check-input"
          type="radio"
          value={value}
          name={name}
          checked={isChecked}
          onClick={!disabled ? handleChange : undefined}
          disabled={disabled}
          style={!disabled ? pointerStyle : null}
        />
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  inline: PropTypes.bool
};

export default Radio;
