const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const SET_CHOOSE_INVENTION_TYPE_ID = 'SET_CHOOSE_INVENTION_TYPE_ID';
const SET_CHOOSE_INVENTION_TYPE_OBJECT = 'SET_CHOOSE_INVENTION_TYPE_OBJECT';
const CLEAR_SELECTED_INVENTION_TYPE = 'CLEAR_SELECTED_INVENTION_TYPE';

const showNewInventionTypeChooseModal = () => ({
  type: SHOW_MODAL
});

const hideNewInventionTypeChooseModal = () => ({
  type: HIDE_MODAL
});

const toggleNewInventionTypeChooseModal = visibleFlag => ({
  type: visibleFlag ? SHOW_MODAL : HIDE_MODAL
});

const setChooseInventionTypeId = id => ({
  type: SET_CHOOSE_INVENTION_TYPE_ID,
  payload: id
});

const setChooseInventionTypeObject = object => ({
  type: SET_CHOOSE_INVENTION_TYPE_OBJECT,
  payload: object
});

const clearSelectedInventionType = () => ({
  type: CLEAR_SELECTED_INVENTION_TYPE
});

export {
  SHOW_MODAL,
  HIDE_MODAL,
  CLEAR_SELECTED_INVENTION_TYPE,
  SET_CHOOSE_INVENTION_TYPE_ID,
  SET_CHOOSE_INVENTION_TYPE_OBJECT,
  showNewInventionTypeChooseModal,
  hideNewInventionTypeChooseModal,
  toggleNewInventionTypeChooseModal,
  clearSelectedInventionType,
  setChooseInventionTypeId,
  setChooseInventionTypeObject
};
