import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 25%;
    padding: 15px;
  }
  & > div:nth-child(n + 4) {
    flex-grow: 0;
  }
`;
