import FormField from 'Models/FormField';

export default class SectionAttributes {
  constructor(rawJSONObject) {
    this._description = rawJSONObject.description;
    this._name = rawJSONObject.name;
    this._fields = rawJSONObject.fields.map(
      rawJSONFieldObject => new FormField(rawJSONFieldObject.data)
    );
  }

  get fields() {
    return this._fields;
  }

  set fields(value) {
    this._fields = value;
  }

  get description() {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }
}
