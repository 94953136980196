import { all, put, takeEvery, call } from 'redux-saga/effects';
import { setLanguage } from 'helpers/i18n';

import { getUser } from 'helpers/axios/axiosRequests';
import { getUserId, clearSessionCookies } from 'helpers/cookies';
import { fetchUserData } from 'store/users/routines';

function* getUserDataSaga() {
  try {
    const userId = getUserId();

    const { data } = yield call(getUser, userId);

    yield put(fetchUserData.success(data.data));
    yield setLanguage(data.data.attributes.language);
  } catch (error) {
    yield put(fetchUserData.failure());
    yield clearSessionCookies();
    console.error(error);
  }
}

export default function* loginSaga() {
  yield all([takeEvery(fetchUserData.TRIGGER, getUserDataSaga)]);
}
