import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  IconWrapper,
  ContributorsWrapper
} from 'Pages/CreateInventionPage/Elements/Form/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { getUsersForOptions } from 'store/users/usersSelectors';

const UsersSelect = ({ usersGroup, action, addedUsers, title, type }) => {
  const { t } = useTranslation();
  const [showUsers, setShowUsers] = useState(
    addedUsers && addedUsers.length > 0
  );
  const toggleSelect = () => setShowUsers(!showUsers);

  useEffect(() => {
    if (addedUsers && addedUsers.length > 0) {
      setShowUsers(true);
    }
  }, [addedUsers]);

  return (
    <>
      <ContributorsWrapper onClick={toggleSelect}>
        <IconWrapper>
          <FontAwesomeIcon icon={faPlusCircle} />
        </IconWrapper>
        {t(title)}
      </ContributorsWrapper>
      {showUsers && (
        <Select
          menuPlacement="top"
          options={usersGroup}
          value={addedUsers}
          isMulti
          onChange={e => action(type, e)}
        />
      )}
    </>
  );
};

UsersSelect.propTypes = {
  action: PropTypes.func,
  addedUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  type: PropTypes.string,
  usersGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  inventors: getUsersForOptions(state, 'inventors'),
  experts: getUsersForOptions(state, 'experts')
});

export default connect(mapStateToProps)(UsersSelect);
