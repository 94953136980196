export const LOGIN_PAGE = '/login';
export const HOME_PAGE = '/home';
export const ACCESS_DENIED = '/access-denied';
export const NOT_FOUND = '/not-found';

export const INVENTION_TYPE = '/inventions-type';
export const ASSESSMENT_TYPE = '/assessments-type';

export const INVENTIONS_PAGE = '/inventions';

export const CREATE_INVENTION = '/create-invention';

export const ASSESSMENTS_PAGE = '/assessments';

export const ASSESSMENT_PAGE = '/assessment';

export const EDIT_INVENTION = '/edit';
export const PDF_PAGE = '/pdf';

export const NOT_ASSIGNED = '/unassigned-inventions';
export const MY_ASSESSMENTS = '/my-assessments';
export const MY_PRE_ASSESSMENTS = '/my-preassessments';
export const ALL_ASSESSMENTS = '/all-assessments';
export const KNOWLEDGE_DATABASE = '/knowledge-database';

export const CHANGE_ORGANISATION_PAGE = '/change-organisation';

export const removeSlash = path => path.replace('/', '');

export const pathTranslations = t => [
  { path: removeSlash(HOME_PAGE), translation: t('home') },
  { path: removeSlash(INVENTION_TYPE), translation: t('inventionsType') },
  { path: removeSlash(ASSESSMENT_TYPE), translation: t('assessmentsType') },
  { path: removeSlash(INVENTIONS_PAGE), translation: t('inventions') },
  { path: removeSlash(CREATE_INVENTION), translation: t('createInvention') },
  { path: removeSlash(ASSESSMENTS_PAGE), translation: t('assessments') },
  { path: removeSlash(ASSESSMENT_PAGE), translation: t('assessment') },
  { path: removeSlash(EDIT_INVENTION), translation: t('edit') },
  { path: removeSlash(NOT_ASSIGNED), translation: t('unassigned') },
  { path: removeSlash(MY_ASSESSMENTS), translation: t('myAssessments') },
  { path: removeSlash(MY_PRE_ASSESSMENTS), translation: t('myPreassessments') },
  { path: removeSlash(ALL_ASSESSMENTS), translation: t('allAssessments') },
  { path: removeSlash(KNOWLEDGE_DATABASE), translation: t('knowledgeDatabase') }
];
