import { INVENTION_TYPE, ASSESSMENT_TYPE } from 'constants/routingConstants';
import { createSelector } from 'reselect';
import { formatDate } from 'helpers/dateHelpers';

export const getFormTypeListData = createSelector(
  state => state.formTypeList.data,
  data => {
    let formTypeListData = [];
    if (data.data) {
      formTypeListData = data.data.map(el => ({
        name: el.attributes.name,
        description: el.attributes.description,
        creator: `${el.attributes.creator.data.attributes.first_name} ${el.attributes.creator.data.attributes.last_name}`,
        created_at: formatDate(el.attributes.created_at),
        modified_at: formatDate(el.attributes.modified_at),
        link: {
          link: `${
            el.type === 'invention_type' ? INVENTION_TYPE : ASSESSMENT_TYPE
          }/${el.attributes.id}`
        }
      }));
    }

    return formTypeListData;
  }
);

export const getFormTypeListMeta = createSelector(
  state => state.formTypeList.data,
  data => data.meta && data.meta
);

export const getIsFetching = state => state.formTypeList.isFetching;

export const getCurrentListType = state => state.formTypeList.currentListType;

export const getFormLimit = state => state.formTypeList.limit;
