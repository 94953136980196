import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsTabletSize } from 'store/layoutDetails/layoutDetailsSelectors';

import {
  STATUS_PENDING_DECISION,
  decisionStatuses,
  statuses,
  STATUS_DELETED,
  timelineStatuses
} from 'constants/inventionStatuses';

import { Timeline, Status, Dot } from './styles';

const StatusTimeline = ({ status, isTabletSize }) => {
  const { t } = useTranslation();

  const prepareName = el => {
    if (el === STATUS_PENDING_DECISION) {
      return t('awaitingDecision');
    }

    if (!decisionStatuses.includes(el)) {
      return statuses(t).find(e => e.id === el).name;
    }

    return t('closed');
  };

  const renderStatuses = () =>
    timelineStatuses.map(el => {
      const isActive =
        status === el || (el === STATUS_DELETED && status >= STATUS_DELETED);

      return (
        <Status key={el} active={isActive}>
          <Dot active={isActive} />
          {prepareName(el)}
        </Status>
      );
    });

  return (
    <Fragment>
      {isTabletSize ? (
        <Status active>
          <Dot active />
          {prepareName(status)}
        </Status>
      ) : (
        renderStatuses()
      )}
      <Timeline isTabletSize={isTabletSize} />
    </Fragment>
  );
};

StatusTimeline.propTypes = {
  isTabletSize: PropTypes.bool,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

const mapStateToProps = state => ({
  isTabletSize: getIsTabletSize(state)
});

export default connect(mapStateToProps)(StatusTimeline);
