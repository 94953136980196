import { createRoutine } from 'redux-saga-routines';

export const DOWNLOAD_FILE = 'attachments/DOWNLOAD_FILE';

export const uploadAttachments = createRoutine(
  'attachments/UPLOAD_ATTACHMENTS'
);
export const deleteAttachment = createRoutine('attachments/DELETE_ATTACHMENT');

export const deleteTemporaryAttachment = createRoutine(
  'attachments/DELETE_TEMPORARY_ATTACHMENT'
);

export const fetchAttachments = createRoutine('attachments/FETCH_ATTACHMENTS');

export const downloadFile = (url, filename) => ({
  type: DOWNLOAD_FILE,
  url,
  filename
});
