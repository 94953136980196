import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

import { Wrapper, CounterWrapper } from './styles';

const modulesQuill = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ list: 'ordered' }, { list: 'bullet' }, { color: [] }, 'link'],
      ['clean']
    ]
  }
};

const RichText = ({
  onChange,
  value,
  width,
  height,
  placeholder,
  label,
  disabled,
  maxLength,
  borderColor
}) => {
  const [textValue, setTextValue] = useState(value);
  const [textValueLength, setTextValueLength] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (maxLength && value) {
      setTextValueLength(ref.current.getEditor().getLength() - 1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    const charactersLength = editor.getLength() - 1;
    let temporaryContent = '';

    if (maxLength && charactersLength > maxLength) {
      temporaryContent = editor.getText().substring(0, maxLength);
    }

    setTextValue(temporaryContent || content);
    setTextValueLength(temporaryContent.length || charactersLength);
    onChange(
      temporaryContent || (content.replace(/<[^>]*>/g, '') ? content : '')
    );
  };

  const handleKeyDown = event => {
    if (
      maxLength &&
      textValueLength >= maxLength &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };

  return (
    <Wrapper className="form-group" width={width} height={height} data="quill">
      {label && <label className="form-check-label">{label}</label>}
      <ReactQuill
        ref={ref}
        bounds={`[data="quill"]`}
        style={{ ...(borderColor && { border: `1px solid ${borderColor}` }) }}
        onKeyDown={handleKeyDown}
        readOnly={disabled}
        modules={disabled ? { toolbar: false } : modulesQuill}
        value={textValue || null}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {maxLength && !disabled && (
        <CounterWrapper
          maxCount={textValueLength === maxLength}
        >{`${textValueLength}/${maxLength}`}</CounterWrapper>
      )}
    </Wrapper>
  );
};

RichText.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number
};

export default RichText;
