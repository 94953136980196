/**
 * Return value of .env file constant by getting only main part of the name - without "REACT_APP_" prefix,
 * e.x. API_URL, not REACT_APP_API_URL
 *
 * @param key
 * @param defaultValue
 * @returns {string|null}
 */
export default (key, defaultValue = null) =>
  process.env
    ? process.env['REACT_APP_' + key]
      ? process.env['REACT_APP_' + key]
      : defaultValue
    : defaultValue;
