import styled from 'styled-components';

import {
  DEFAULT_RED,
  INPUT_BORDER_GRAY,
  BACKGROUND_GRAY,
  MEDIUM_GRAY
} from 'constants/colors';

export const Label = styled.h6`
  font-weight: bold;
  font-size: 1.1rem;
  margin: 20px 5px 5px 5px;
`;

export const DescriptionWrapper = styled.div`
  font-style: italic;
  font-size: 14px;
  color: ${MEDIUM_GRAY};
  margin: 0 5px 5px;
`;

export const FieldWrapper = styled.div`
  padding: 20px;
  border: ${props =>
    props.required ? `1px solid ${DEFAULT_RED}` : `1px solid ${INPUT_BORDER_GRAY}`};
  border-radius: 5px;
  background: ${props => (props.disabled ? BACKGROUND_GRAY : 'unset')};

  label:first-child {
    margin-bottom: 10px;
  }
`;

export const RequiredLabel = styled.div`
  font-size: 12px;
  margin-top: 10px;
`;
