import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import GlobalStyle from './globalStyle';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import createStore, { history } from 'store/createStore';
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = 's1q4vxt5';

require('dotenv').config();

const store = createStore();

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <Route path="/" component={App} />
          <GlobalStyle />
        </IntercomProvider>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
