import React from 'react';
import PropTypes from 'prop-types';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

import 'react-vertical-timeline-component/style.min.css';
import { BACKGROUND_GRAY, ICON_BG_BLUE, WHITE } from 'constants/colors';

const Timeline = ({ data = [] }) => {
  const renderElements = () =>
    data.map((el, index) => (
      <VerticalTimelineElement
        key={index}
        className="vertical-timeline-element bounce-in"
        date={el.date}
        iconStyle={{ background: ICON_BG_BLUE, color: WHITE }}
        icon={
          <FontAwesomeIcon style={{ fontSize: '28px' }} icon={faCalendarWeek} />
        }
        contentStyle={{ background: BACKGROUND_GRAY }}
        contentArrowStyle={{ borderRight: `7px solid ${BACKGROUND_GRAY}` }}
      >
        <h5 className="vertical-timeline-element-subtitle">{el.title}</h5>
        <p>{el.description}</p>
      </VerticalTimelineElement>
    ));

  return (
    <VerticalTimeline
      layout={'1-column-left'}
      className="vertical-timeline vertical-timeline-custom-line"
    >
      {renderElements()}
    </VerticalTimeline>
  );
};

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string
    })
  )
};

export default Timeline;
