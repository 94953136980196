import styled from 'styled-components';

import {
  BACKGROUND_GRAY,
  DEFAULT_BLUE,
  MEDIUM_GRAY,
  LIGHT_RED
} from 'constants/colors';

export const ContentWrapper = styled.div`
  height: 80vh;
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
  position: relative;
`;

export const Column = styled.div`
  background: white;
  padding: 0 20px 20px 20px;
  ${props => props.paddingTop && `padding-top: 20px;`};
  height: calc(80vh - 34px);
  overflow: auto;
  ${props =>
    props.maxHeight && `max-height: ${props.maxHeight}px; overflow: auto;`}
  ${props =>
    props.grid &&
    `display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(min-content, max-content) minmax(
      min-content,
      max-content
    ) auto;
    `}
`;

export const TabsWrapper = styled.div`
  padding: 20px;
  background: ${BACKGROUND_GRAY};
`;

export const ActionsContainer = styled.div``;

export const Timeline = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  width: ${props => (props.isTabletSize ? '50%' : '80%')};
  height: 2px;
  margin: auto;
  background: ${DEFAULT_BLUE};
`;

export const Status = styled.div`
  display: inline-block;
  margin: 0 20px 10px 20px;
  position: relative;
  color: ${MEDIUM_GRAY};

  ${props =>
    props.active &&
    `
    font-weight: bold;
    color: black;
    `};
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: ${props => (props.active ? DEFAULT_BLUE : `white`)};
  border-radius: 50px;
  border: 2px solid ${DEFAULT_BLUE};
  position: absolute;
  bottom: -10px;
  left: calc(50% - 5px);
  z-index: 1;
`;

export const Fixed = styled.div`
  padding: 20px 0;
  z-index: 1;
  background: white;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr auto auto;

  & > div {
    align-self: center;
  }

  & > div:first-child {
    & > div:first-child {
      font-weight: 600;
      font-size: 20px;
    }
  }
`;

export const AssessmentSection = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  margin: 20px 0;
  background: ${BACKGROUND_GRAY};

  & > div:first-child {
    margin-right: 10px;
  }

  & > div:nth-child(2) {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  & > div,
  & > button {
    align-self: center;
  }
`;

export const Expert = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
`;

export const CollapseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${props => (props.removeRightValue ? 0 : `16px`)};
  top: 40vh;
  background: ${DEFAULT_BLUE};
  padding: 3px;
  border-radius: 100% 0 0 100%;

  svg {
    color: white;
  }
`;

export const ExclamationMark = styled.div`
  color: ${LIGHT_RED};
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
`;
