import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'helpers/i18n';
import { withRouter } from 'react-router-dom';

import { ErrorWrapper, ErrorContent, ErrorMessage } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Button from 'Atoms/Button';

const ErrorBoundaryHOC = ChildComponent => {
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }

    componentDidCatch(error) {
      this.setState({
        error: error
      });
    }

    render() {
      if (this.state.error) {
        return (
          <ErrorWrapper>
            <ErrorContent>
              <FontAwesomeIcon icon={faExclamationCircle} size="6x" />
              <ErrorMessage>{t('somethingWentWrong')}</ErrorMessage>
              <Button
                value={t('goBack')}
                onClick={() => this.props.history.goBack()}
              />
            </ErrorContent>
          </ErrorWrapper>
        );
      } else {
        return <ChildComponent {...this.props} />;
      }
    }
  }

  return withRouter(withTranslation('iamip')(ErrorBoundary));
};

export default ErrorBoundaryHOC;
