import { DEFAULT_RED, LIGHT_GRAY, WHITE } from 'constants/colors';
import styled from 'styled-components';

export const FormName = styled.h3`
  font-size: 22px;
  font-weight: bold;
  width: 100%;
`;

export const FormField = styled.div`
  max-width: 650px;
  padding-bottom: 10px;
`;

export const Label = styled.span``;

export const LockContainer = styled.div`
  display: flex;
  color: ${DEFAULT_RED};
  font-size: 18px;
  align-items: center;
  margin-bottom: 30px;
  & > svg {
    padding-right: 5px;
  }
`;

export const DescriptionWrapper = styled.div`
  border: 1px solid ${LIGHT_GRAY};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${WHITE};
  & > div {
    flex: 1 50%;
    max-width: 49%;
  }
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;
