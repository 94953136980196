import styled from 'styled-components';

import { LIGHT_GRAY } from 'constants/colors';

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: ${LIGHT_GRAY};
`;

export const ContentWrapper = styled.div`
  background: white;
  padding: 40px;
  border-radius: 5px;
  text-align: center;

  & > div {
    margin: 10px 0;
  }
`;
