import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getFormTypePredefinedId } from 'store/formType/formTypeSelectors';
import { getCurrentInventionSerialNumber } from 'store/singleInvention/singleInventionSelectors';

import {
  HOME_PAGE,
  pathTranslations,
  INVENTION_TYPE,
  INVENTIONS_PAGE
} from 'constants/routingConstants';

import { breadcrumbsStyles, Title } from './styles';

const Breadcrumbs = ({
  collapsed,
  isFilterSectionVisible,
  isTabletSize,
  location,
  serialNumber,
  top
}) => {
  const { t } = useTranslation();
  const paths = location?.pathname.split('/');

  const getPathTranslation = (path, prevPath) => {
    if (path === 'pdf') {
      return 'PDF';
    }

    if (prevPath === 'pdf') {
      return path;
    }

    const pathData = pathTranslations(t).find(p => p.path === path);
    const inventionsIndex = paths.findIndex(
      el => el === INVENTIONS_PAGE.slice(1, 0)
    );

    const inInventions = inventionsIndex !== -1 && paths[inventionsIndex + 1];
    const isAssessments = paths.includes('assessments') && inInventions;
    const isUniqueId =
      paths.includes(INVENTION_TYPE.slice(1, 0)) || inInventions;

    if (pathData) {
      return pathData?.translation;
    }

    if (
      (isAssessments && paths.indexOf(path) === paths.length - 1) ||
      paths.includes('assessments-type')
    ) {
      return path;
    }

    if (isUniqueId) {
      return serialNumber;
    }

    return path;
  };

  const prepareTitle = () => {
    let isTitleInAssessments = paths[paths.length - 1];

    if (!paths.includes('assessments-type') && paths.includes('assessments')) {
      isTitleInAssessments = paths[2];
    }

    if (isTitleInAssessments) {
      return getPathTranslation(isTitleInAssessments);
    }

    return t('home');
  };

  const prepareBreadcrumbPaths = () =>
    paths.map((path, index) => (
      <Fragment key={index}>
        {path && (
          <Breadcrumb.Item
            key={index}
            linkProps={{
              to: paths.slice(0, index + 1).join('/')
            }}
            active={index + 1 === paths.length || path.toLowerCase() === 'pdf'}
            bsPrefix={'breadcrumb-item'}
            linkAs={Link}
          >
            {getPathTranslation(path, index && paths[index - 1])}
          </Breadcrumb.Item>
        )}
      </Fragment>
    ));

  return (
    <div
      style={breadcrumbsStyles(
        collapsed,
        isTabletSize,
        isFilterSectionVisible && top
      )}
      className="content-header"
    >
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <Title className="m-0">{prepareTitle()}</Title>
          </div>
          <div className="col-sm-6">
            {location?.pathname !== '/' && location?.pathname !== HOME_PAGE && (
              <Breadcrumb className="float-sm-right">
                <Breadcrumb.Item
                  bsPrefix={'breadcrumb-item'}
                  linkAs={Link}
                  linkProps={{ to: '/' }}
                >
                  {t('home')}
                </Breadcrumb.Item>
                {prepareBreadcrumbPaths()}
              </Breadcrumb>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  serialNumber:
    getFormTypePredefinedId(state) || getCurrentInventionSerialNumber(state)
});

Breadcrumbs.propTypes = {
  collapsed: PropTypes.bool,
  isFilterSectionVisible: PropTypes.bool,
  isTabletSize: PropTypes.bool,
  location: PropTypes.object,
  top: PropTypes.number
};

export default connect(mapStateToProps)(withRouter(Breadcrumbs));
