import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { t } from 'helpers/i18n';

import {
  fetchSingleInvention,
  fetchInventionTimeline,
  fetchInventionNotes,
  fetchInventionAttachments,
  addNewNote,
  assignReviewManager,
  assignDecisionMaker,
  createSurveyReview,
  createAnswerReview,
  updateReview,
  changeInventionStatus,
  updateCurrentInventionStatus,
  updateNote,
  downloadPDFFile
} from 'actions/singleInventionActions';

import { downloadFile } from 'actions/attachmentsActions';

import {
  getInvention,
  patchInvention,
  createReview,
  createAnswersForReview,
  updateReview as updateReviewRequest,
  getInventionNotes,
  postNote,
  getInventionTimeline,
  getPDFUrl,
  getInventionAttachments
} from 'helpers/axios/axiosRequests';

import { setInventionFormObject } from 'store/newInventionTypeChooseModal/newInventionTypeChooseModalReducer';

import {
  getCurrentInventionId,
  getCurrentInventionStatus,
  getDecisionMaker,
  getReviewManager
} from 'store/singleInvention/singleInventionSelectors';

import {
  STATUS_PRE_ASSESSMENT,
  STATUS_DELETED
} from 'constants/inventionStatuses';

function* fetchSingleInventionSaga({ payload }) {
  try {
    const { data } = yield call(getInvention, payload);
    yield put(fetchSingleInvention.success(data.data));

    yield put(setInventionFormObject(data.data));
  } catch (error) {
    yield put(fetchSingleInvention.failure());
    yield toast.error(t('getInventionFailure'));
    console.error(error);
  }
}

export function* fetchInventionTimelineSaga({ payload }) {
  try {
    const { data } = yield call(getInventionTimeline, payload);

    yield put(fetchInventionTimeline.success(data.data));
  } catch (error) {
    yield put(fetchInventionTimeline.failure());
    yield toast.error(t('getInventionTimelineFailure'));
    console.error(error);
  }
}

function* fetchInventionNotesSaga() {
  try {
    const id = yield select(getCurrentInventionId);

    const { data } = yield call(getInventionNotes, id);

    yield put(
      fetchInventionNotes.success(
        data.data.map(note => ({
          data: { ...note }
        }))
      )
    );
  } catch (error) {
    yield put(fetchInventionNotes.failure());
    yield toast.error(t('getInventionNotesFailure'));
    console.error(error);
  }
}

export function* fetchInventionAttachmentsSaga() {
  try {
    const id = yield select(getCurrentInventionId);

    const { data } = yield call(getInventionAttachments, id);

    yield put(
      fetchInventionAttachments.success(
        data.data.map(attachment => ({
          data: { ...attachment }
        }))
      )
    );
  } catch (error) {
    yield put(fetchInventionAttachments.failure());
    yield toast.error(t('getInventionAttachmentsFailure'));
    console.error(error);
  }
}

function* addNewNoteSaga({ payload }) {
  try {
    const id = yield select(getCurrentInventionId);
    const { recipient, response_deadline, parent } = payload;

    const body = {
      data: {
        type: 'note',
        attributes: {
          comment: payload.note,
          is_confidential: payload.isConfidential,
          confidential_for: payload.confidentialFor || [],
          invention: {
            id: `/v1/inventions/${id}`,
            type: 'invention'
          },
          ...(recipient && { recipient }),
          ...(response_deadline && { response_deadline }),
          ...(parent && { parent })
        }
      }
    };

    const { data } = yield call(postNote, body);
    yield put(addNewNote.success(data));

    if (recipient && response_deadline) {
      yield toast.success(t('furtherQuestionAsked'));
    }

    if (parent) {
      yield put(updateNote(parent.id));
    }
  } catch (error) {
    yield put(addNewNote.failure());
    yield toast.error(t('addNewNoteFailure'));
    console.error(error);
  }
}

function* assignReviewManagerSaga({ payload }) {
  try {
    const id = yield select(getCurrentInventionId);
    const currentStatus = yield select(getCurrentInventionStatus);
    const decisionMaker = yield select(getDecisionMaker);

    let newPayload = { ...payload };

    if (decisionMaker && currentStatus < STATUS_PRE_ASSESSMENT) {
      newPayload = {
        ...newPayload,
        data: {
          ...newPayload.data,
          attributes: {
            ...newPayload.data.attributes,
            status: STATUS_PRE_ASSESSMENT
          }
        }
      };
    }

    const { data } = yield call(patchInvention, id, newPayload);

    yield put(
      assignReviewManager.success({
        reviewManager: data.data.attributes.review_manager,
        status: data.data.attributes.status
      })
    );

    yield toast.success(t('reviewManagerAssigned'));
    if (decisionMaker && currentStatus < STATUS_PRE_ASSESSMENT) {
      yield toast.success(t('inventionStatusChangedToPreAssessment'));
    }

    yield call(fetchInventionTimelineSaga, { payload: id });
  } catch (error) {
    yield put(assignReviewManager.failure());
    yield toast.error(t('assignReviewManagerFailure'));
  }
}

function* assignDecisionMakerSaga({ payload }) {
  try {
    const id = yield select(getCurrentInventionId);
    const reviewManager = yield select(getReviewManager);
    const currentStatus = yield select(getCurrentInventionStatus);

    let newPayload = { ...payload };

    if (reviewManager && currentStatus < STATUS_PRE_ASSESSMENT) {
      newPayload = {
        ...newPayload,
        data: {
          ...newPayload.data,
          attributes: {
            ...newPayload.data.attributes,
            status: STATUS_PRE_ASSESSMENT
          }
        }
      };
    }

    const { data } = yield call(patchInvention, id, newPayload);

    yield put(
      assignDecisionMaker.success({
        decisionMaker: data.data.attributes.decision_maker,
        status: data.data.attributes.status
      })
    );

    yield toast.success(t('decisionMakerAssigned'));

    if (reviewManager && currentStatus < STATUS_PRE_ASSESSMENT) {
      yield toast.success(t('inventionStatusChangedToPreAssessment'));
    }

    yield call(fetchInventionTimelineSaga, { payload: id });
  } catch (error) {
    yield put(assignDecisionMaker.failure());
    yield toast.error(t('assignDecisionMakerFailure'));
  }
}

function* createReviewSaga({ payload }) {
  try {
    const { data } = yield call(createReview, payload.reviewData);
    yield put(updateReview.success(data));

    yield answerReviewSaga({
      payload: payload,
      id: data.data.id,
      inventionStatus: payload.inventionStatus
    });
    const id = yield select(getCurrentInventionId);
    yield call(fetchInventionTimelineSaga, { payload: id });
  } catch (e) {
    yield toast.error(t('somethingWentWrong'));
    console.error(e);
  }

  yield put(createSurveyReview.fulfill());
}

function* updateReviewSaga({ payload }) {
  try {
    const { data } = yield call(
      updateReviewRequest,
      payload.surveyReviewId,
      payload.reviewData
    );
    yield put(updateReview.success(data));
    yield answerReviewSaga({
      payload: payload,
      inventionStatus: payload.inventionStatus
    });

    const id = yield select(getCurrentInventionId);
    yield call(fetchInventionTimelineSaga, { payload: id });
  } catch (e) {
    yield toast.error(t('somethingWentWrong'));
    console.error(e);
  }
}

function* answerReviewSaga({ payload, id, inventionStatus }) {
  try {
    let data;
    if (id) {
      payload.data.data.map(el => {
        el.attributes['survey_review'].id = id;
        return el;
      });
      data = yield call(createAnswersForReview, payload.data);
    } else {
      data = yield call(createAnswersForReview, payload.data);
    }

    if (inventionStatus) {
      const id = yield select(getCurrentInventionId);
      const payload = {
        data: {
          type: 'invention',
          attributes: {
            status: inventionStatus
          }
        }
      };
      yield call(patchInvention, id, payload);

      yield put(updateCurrentInventionStatus(inventionStatus));
    }

    yield put(createAnswerReview.success(payload, data.data.data));
    yield toast.success(t('formSubmitted'));
    const inventionId = yield select(getCurrentInventionId);
    yield call(fetchInventionTimelineSaga, { payload: inventionId });
  } catch (e) {
    yield toast.error(t('somethingWentWrong'));
    console.error(e);
  }
}

function* changeInventionStatusSaga({ payload }) {
  try {
    const { data } = yield call(patchInvention, payload.id, payload.data);
    yield put(
      changeInventionStatus.success({
        status: data.data.attributes.status,
        comments: data.data.attributes.comments
      })
    );
    toast.success(
      data.data.attributes.status === STATUS_DELETED
        ? t('inventionDeleted')
        : t('statusChanged')
    );
    yield call(fetchInventionTimelineSaga, { payload: payload.id });
  } catch (error) {
    yield toast.error(t('somethingWentWrong'));
    console.error(error);
  }
}

function* downloadPDFSaga({ payload }) {
  try {
    const { data } = yield call(getPDFUrl, payload.url);
    yield put(downloadFile(data.url, data.filename));
  } catch (e) {
    console.error(e);
  }
}

export default function* singleInventionSaga() {
  yield all([
    takeEvery(fetchSingleInvention.TRIGGER, fetchSingleInventionSaga),
    takeEvery(fetchInventionTimeline.TRIGGER, fetchInventionTimelineSaga),
    takeEvery(fetchInventionNotes.TRIGGER, fetchInventionNotesSaga),
    takeEvery(fetchInventionAttachments.TRIGGER, fetchInventionAttachmentsSaga),
    takeEvery(addNewNote.TRIGGER, addNewNoteSaga),
    takeEvery(assignReviewManager.TRIGGER, assignReviewManagerSaga),
    takeEvery(assignDecisionMaker.TRIGGER, assignDecisionMakerSaga),
    takeEvery(createSurveyReview.TRIGGER, createReviewSaga),
    takeEvery(createAnswerReview.TRIGGER, answerReviewSaga),
    takeEvery(updateReview.TRIGGER, updateReviewSaga),
    takeEvery(changeInventionStatus.TRIGGER, changeInventionStatusSaga),
    takeEvery(downloadPDFFile.TRIGGER, downloadPDFSaga)
  ]);
}
