export const validateForms = (requiredFields, answers) => {
  const emptyFields = [];
  // eslint-disable-next-line array-callback-return
  requiredFields.map(el => {
    const answer = answers.find(answer =>
      answer.fieldId
        ? answer.fieldId === el.data.id
        : answer.id === el.data.attributes.id
    );
    let checkIfExtendedField;
    if (answer && answer?.value && el.data.attributes.type === 3) {
      checkIfExtendedField = el.data.attributes.options.fields.find(
        el => el.label === answer.value
      ).isExpanded;
    }
    if (
      !answer ||
      (answer && answer.value[0] === null) ||
      (answer && answer.value === '') ||
      (answer && checkIfExtendedField && !answer.additionalValue)
    ) {
      emptyFields.push(el.data.id);
    }
  });
  return emptyFields;
};
