import React from 'react';
import PropTypes from 'prop-types';

export default class FileBase64 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
    }

    handleChange = (e) => {
        const files = e.target.files;

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {
            const file = files[i];

            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                const fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file
                };

                allFiles.push(fileInfo);

                if (allFiles.length === files.length) {
                    if (this.props.multiple) this.props.onDone(allFiles);
                    else this.props.onDone(allFiles[0]);
                }
            };
        }
    }

    render() {
        return (
            <input
                accept={this.props.allow.join(', ')}
                multiple={this.props.multiple}
                type="file"
                onChange={this.handleChange}
            />
        );
    }
}

FileBase64.propTypes = {
    allow: PropTypes.array,
    multiple: PropTypes.bool,
    onDone: PropTypes.func
};

FileBase64.defaultProps = {
    allow: [
        'image/png',
        'image/bmp',
        'image/jpeg',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'text/plain',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ],
    multiple: false
};
