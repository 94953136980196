import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'Elements/PrivateRoute';
import ErrorBoundaryHOC from 'HOC/ErrorBoundaryHOC/ErrorBoundaryHOC';
import MainLayout from 'Layouts/MainLayout';

import {
  ACCESS_DENIED,
  ASSESSMENT_TYPE,
  ASSESSMENTS_PAGE,
  CREATE_INVENTION,
  HOME_PAGE,
  INVENTION_TYPE,
  INVENTIONS_PAGE,
  LOGIN_PAGE,
  NOT_FOUND,
  NOT_ASSIGNED,
  MY_PRE_ASSESSMENTS,
  MY_ASSESSMENTS,
  ALL_ASSESSMENTS,
  KNOWLEDGE_DATABASE,
  PDF_PAGE
} from 'constants/routingConstants';
import {
  ADMIN,
  INVENTOR,
  EXPERT,
  REVIEW_MANAGER,
  REVIEW_COORDINATOR,
  DECISION_MAKER,
  SUPERVISOR
} from 'constants/userRoles';

import HomePage from 'Pages/HomePage';
import ErrorPage from 'Pages/ErrorPage';
import ListPage from 'Pages/ListsPage';
import InventionTypeCreatorPage from 'Pages/InventionTypeCreatorPage';
import AssessmentTypeCreatorPage from 'Pages/AssessmentTypeCreatorPage';
import LoginPage from 'Pages/LoginPage/LoginPage';
import CreateInventionPageContainer from 'Pages/CreateInventionPage/CreateInventionPageContainer';
import SingleInventionPage from 'Pages/SingleInventionPage/SingleInventionPage';
import InventionTypeListPage from 'Pages/InventionTypeListPage/InventionTypeListPage';
import AssessmentTypeListPage from 'Pages/AssessmentTypeListPage/AssessmentTypeListPage';
import InventionAssessmentsListPage from 'Pages/InventionAssessmentsListPage/InventionAssessmentsListPage';
import SingleAssessmentPage from 'Pages/SingleAssessmentPage';
import DownloadPDFPage from 'Pages/DownloadPDFPage';

const Routes = () => (
  <Route
    render={props => (
      <MainLayout {...props}>
        <Switch>
          <Route component={LoginPage} path={LOGIN_PAGE} />
          <PrivateRoute
            component={ErrorBoundaryHOC(HomePage)}
            path={[HOME_PAGE, '/']}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(InventionTypeCreatorPage)}
            path={`${INVENTION_TYPE}/:id`}
            requiredRoles={[ADMIN]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(AssessmentTypeCreatorPage)}
            path={`${ASSESSMENT_TYPE}/:id`}
            requiredRoles={[ADMIN]}
            exact
          />
          <PrivateRoute
            requiredRoles={[
              INVENTOR,
              REVIEW_COORDINATOR,
              REVIEW_MANAGER,
              DECISION_MAKER,
              SUPERVISOR
            ]}
            component={ErrorBoundaryHOC(ListPage)}
            path={INVENTIONS_PAGE}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(ListPage)}
            path={NOT_ASSIGNED}
            requiredRoles={[REVIEW_COORDINATOR, SUPERVISOR]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(ListPage)}
            path={MY_ASSESSMENTS}
            requiredRoles={[EXPERT]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(ListPage)}
            path={MY_PRE_ASSESSMENTS}
            requiredRoles={[REVIEW_MANAGER]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(ListPage)}
            path={ALL_ASSESSMENTS}
            requiredRoles={[REVIEW_MANAGER, DECISION_MAKER, SUPERVISOR]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(ListPage)}
            path={KNOWLEDGE_DATABASE}
            exact
            excludeAdmin
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(SingleInventionPage)}
            path={`${INVENTIONS_PAGE}/:id`}
            exact
            excludeAdmin
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(CreateInventionPageContainer)}
            path={CREATE_INVENTION}
            requiredRoles={[INVENTOR]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(CreateInventionPageContainer)}
            path={`${INVENTIONS_PAGE}/:id/edit`}
            requiredRoles={[INVENTOR]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(InventionTypeListPage)}
            path={INVENTION_TYPE}
            requiredRoles={[ADMIN]}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(AssessmentTypeListPage)}
            path={ASSESSMENT_TYPE}
            requiredRoles={[ADMIN]}
            exact
          />
          <PrivateRoute
            requiredRoles={[
              REVIEW_COORDINATOR,
              REVIEW_MANAGER,
              EXPERT,
              DECISION_MAKER,
              SUPERVISOR
            ]}
            component={ErrorBoundaryHOC(InventionAssessmentsListPage)}
            path={`${INVENTIONS_PAGE}/:id${ASSESSMENTS_PAGE}`}
            exact
          />
          <PrivateRoute
            component={ErrorBoundaryHOC(SingleAssessmentPage)}
            path={`${INVENTIONS_PAGE}/:inventionId${ASSESSMENTS_PAGE}/:id`}
            requiredRoles={[EXPERT, REVIEW_MANAGER, DECISION_MAKER, SUPERVISOR]}
            exact
          />
          <PrivateRoute
            requiredRoles={[
              INVENTOR,
              REVIEW_MANAGER,
              DECISION_MAKER,
              REVIEW_COORDINATOR
            ]}
            component={ErrorBoundaryHOC(DownloadPDFPage)}
            path={`${INVENTIONS_PAGE}/:inventionId${PDF_PAGE}/:id`}
            exact
          />
          <Route component={ErrorPage} path={ACCESS_DENIED} exact />
          <Route component={ErrorPage} path={NOT_FOUND} />
          <Redirect from="*" to={NOT_FOUND} />
        </Switch>
      </MainLayout>
    )}
  />
);

export default Routes;
