export default class SelectSettings {
  constructor(rawSettingsJSONObject) {
    this._options = rawSettingsJSONObject.fields;
    this._multiselect = rawSettingsJSONObject.multiselect;
  }

  get options() {
    return this._options;
  }

  get multiselect() {
    return this._multiselect;
  }
}
