import { getInventionTypes } from 'helpers/axios/axiosRequests';
import { getInventionTypesRoutine } from 'actions/inventionTypesActions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

function* getInventionTypesPrepSaga() {
  try {
    yield put(getInventionTypesRoutine.request());

    const response = yield call(getInventionTypes, false, 'false');

    yield put(
      getInventionTypesRoutine.success({
        data: response.data.data,
        pagination: response.data.links,
        meta: response.data.meta
      })
    );
  } catch (exception) {
    console.log(exception);

    yield put(getInventionTypesRoutine.failure());
  }

  yield put(getInventionTypesRoutine.fulfill());
}

export default function* getInventionTypesSaga() {
  yield all([
    takeEvery(getInventionTypesRoutine.TRIGGER, getInventionTypesPrepSaga)
  ]);
}
