import typeToReducer from 'type-to-reducer';

import { setCookie, ORGANISATION_ID } from 'helpers/cookies';

import { fetchUserData } from 'store/users/routines';
import { authLogout, getLoginDetails } from 'store/login/routines';

const defaultState = {
  organisationId: null,
  organisationUrl: null,
  name: '',
  isIntercomActive: null
};

const saveOrganisationData = (state, payload) => {
  const { organisation } = payload.attributes;

  setCookie(ORGANISATION_ID, organisation.data.attributes.id);

  return {
    ...state,
    organisationId: organisation.data.attributes.id,
    organisationUrl: organisation.data.id,
    name: organisation.data.attributes.name,
    isIntercomActive: organisation.data.attributes.is_intercom_active
  };
};

export default typeToReducer(
  {
    [fetchUserData.SUCCESS]: (state, { payload }) =>
      saveOrganisationData(state, payload),
    [getLoginDetails.SUCCESS]: (state, { payload }) =>
      saveOrganisationData(state, payload),
    [authLogout.SUCCESS]: () => defaultState
  },
  defaultState
);
