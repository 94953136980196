import styled from 'styled-components';

import { DEFAULT_BLUE, LIGHT_GRAY } from 'constants/colors';

export const DetailsContainer = styled.div`
  padding-bottom: 20px;
`;

export const InventionDetails = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  & > div:nth-child(2n + 1) {
    margin-right: 20px;
    font-weight: 600;
  }
`;

export const ImageWrapper = styled.div`
  color: ${LIGHT_GRAY};
  vertical-align: middle;

  img {
    max-width: 100%;
  }
`;

export const IconWrapper = styled.span`
  font-size: 21px;
  margin-right: 10px;
  color: ${DEFAULT_BLUE};
`;

export const PersonWrapper = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: ${props =>
    props.isAssignedPerson ? '30px 1fr' : '30px 1fr 1fr 100px'};
  align-items: center;
  grid-row-margin: 10px;
`;
