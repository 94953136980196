import FormFieldAttributes from 'Models/FormFieldAttributes';

export default class FormField {
  constructor(rawJSONFormFieldObject) {
    this._id = rawJSONFormFieldObject.id;
    this._type = rawJSONFormFieldObject.type;
    this._attributes = new FormFieldAttributes(
      rawJSONFormFieldObject.attributes
    );
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  get attributes() {
    return this._attributes;
  }

  set attributes(value) {
    this._attributes = value;
  }
}
