import typeToReducer from 'type-to-reducer';
import {
  SET_FIELD,
  SET_MULTIVALUE_FIELD,
  CLEAR_FIELDS
} from 'actions/newInventionFormActions';

const defaultState = {
  fields: []
};

export default typeToReducer(
  {
    [SET_FIELD]: (state, { payload }) => {
      const newField = {
        id: payload.id,
        value: payload.value || '',
        additionalValue: payload.additionalValue
      };

      const newFields = state.fields.filter(field => field.id !== newField.id);
      newFields.push(newField);

      return {
        ...state,
        fields: [...newFields]
      };
    },
    [SET_MULTIVALUE_FIELD]: (state, { payload }) => {
      let field = state.fields.find(field => field.id === payload.id);

      if (field) {
        const valueIndex = field.value.indexOf(payload.value);

        if (valueIndex === -1) {
          field.value.push(payload.value);
        } else {
          field.value = field.value.filter(
            existingValue => existingValue !== payload.value
          );
        }

        field = { ...field };
      } else {
        field = {
          id: payload.id,
          value: [payload.value]
        };
      }

      const newFields = state.fields.filter(
        existingField => existingField.id !== field.id
      );
      newFields.push(field);

      return {
        ...state,
        fields: [...newFields]
      };
    },
    [CLEAR_FIELDS]: state => ({
      ...state,
      fields: []
    })
  },
  defaultState
);
