import styled from 'styled-components';
import { BLACK, DEFAULT_RED } from 'constants/colors';

export const Wrapper = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
`;

export const CounterWrapper = styled.div`
  margin-top: 10px;
  text-align: right;
  color: ${props => (props.maxCount ? DEFAULT_RED : BLACK)};
`;
