import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import Textarea from 'Atoms/Textarea';
import Radio from 'Atoms/Radio';

import { OptionsWrapper } from './styles';

const AdvancedRadioType = ({ field, onChange, disabled }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedLabel, setExpandedLabel] = useState();
  const [expandedOptionValue, setExpandedOptionValue] = useState();
  const [radioValue, setRadioValue] = useState(null);

  useEffect(() => {
    if (field.value) {
      setRadioValue(field.value);

      const selectedFieldDetails = field.options.fields.find(
        o => o.value === field.value
      );

      if (selectedFieldDetails && selectedFieldDetails.isExpanded) {
        setIsExpanded(true);
        setExpandedOptionValue(field.additionalValue);
      }
    }

    const defaultField = field.options.fields.find(option => option.default);

    if (defaultField && !field.value) {
      setRadioValue(defaultField.value);
      onChange(field.id, defaultField.value);

      if (defaultField.isExpanded) {
        setIsExpanded(true);
        setExpandedLabel(defaultField.expandedLabel);
      }
    }
    // eslint-disable-next-line
  }, []);

  const onCheck = (field, option) => {
    setRadioValue(option.value);
    onChange(field.id, option.value);
    if (option.isExpanded) {
      setIsExpanded(true);
      setExpandedLabel(option.expandedLabel);
    }
    if (isExpanded && !option.isExpanded) {
      setIsExpanded(false);
    }
  };

  const handleInputChange = (fieldId, value) => {
    setExpandedOptionValue(value);
    onChange(fieldId, radioValue, value);
  };

  return (
    <Fragment>
      <OptionsWrapper>
        {field.options.fields.map((option, i) => (
          <Radio
            key={i}
            value={option.value}
            name={field.name}
            checked={radioValue && option.value === radioValue}
            onCheck={() => onCheck(field, option)}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </OptionsWrapper>
      {isExpanded && (
        <Textarea
          label={expandedLabel}
          onChange={value => handleInputChange(field.id, value)}
          value={field.additionalValue || expandedOptionValue}
          disabled={disabled}
          autoResize={disabled}
        />
      )}
    </Fragment>
  );
};

AdvancedRadioType.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default AdvancedRadioType;
