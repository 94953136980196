import styled from 'styled-components';

import {
  BLACK,
  DARK_BLUE,
  DARK_GRAY,
  DEFAULT_BLUE,
  LIGHT_GRAY,
  SILVER_GRAY,
  VERY_LIGHT_GRAY,
  WHITE
} from 'constants/colors';

export const UserSettings = styled.div`
  position: relative;
  color: ${WHITE};
`;

export const UserMenuToggler = styled.div`
  user-select: none;
  padding: 0 30px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${SILVER_GRAY};
  &:hover {
    background-color: ${DARK_BLUE};
  }
`;

export const UserName = styled.p`
  margin-bottom: 0;
  padding-left: 10px;
`;

export const UserMenuDropdown = styled.div`
  width: 280px;
  border: 1px solid ${LIGHT_GRAY};
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 50px;
  right: 0;
  background-color: ${DEFAULT_BLUE};
  z-index: 1;
`;

export const UserBox = styled.div`
  height: 175px;
  padding: 20px;
  text-align: center;
  position: relative;
`;

export const UserAvatar = styled.img`
  height: 96px;
  border-radius: 50%;
`;

export const UserNameDropdown = styled.div`
  font-size: 17px;
  padding-top: 5px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 20px;
`;

export const UserBody = styled.div`
  padding: 20px 12px;
  background-color: ${WHITE};
`;

export const UserFooter = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${VERY_LIGHT_GRAY};
  color: ${DARK_GRAY};
  padding: 10px;
  border-top: 1px solid ${LIGHT_GRAY};
`;

export const LanguagesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const LangugageTitle = styled.p`
  color: ${BLACK};
  text-align: center;
  border-bottom: 1px solid ${LIGHT_GRAY};
`;

export const LanguageIconWrapper = styled.div`
  display: inline-block;
  width: 40px;
  height: 28px;
  cursor: pointer;
  background: url(${props => props.flag}) center/cover no-repeat;
  border: ${props =>
    props.active ? `2px solid ${DARK_GRAY}` : '2px solid transparent'};
  &:hover {
    border: 2px solid ${DARK_GRAY};
  }
`;
